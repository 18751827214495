
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-confirm',
    template: `<h1 matDialogTitle class="mb-8">{{ data.title }}</h1>
    <div mat-dialog-content class="mb-16" [innerHTML]="data.message"></div>
    <div mat-dialog-actions>
        <button type="button" mat-raised-button color="primary"
                (click)="dialogRef.close(true)">{{ data.okButton }}
        </button>
        &nbsp;
        <span fxFlex></span>
        <button type="button" color="accent" mat-raised-button
                (click)="dialogRef.close(false)">Annuler
        </button>
    </div>`,
})
export class AppComfirmComponent {
    constructor(
        public dialogRef: MatDialogRef<AppComfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }
}

import {COLORS, defaultLayout} from '../constants'
import {Optimise} from "../../Optimise";
import * as assign from 'assign-deep';

/**
 * handle trace width
 * https://github.com/plotly/plotly.js/blob/d5303abf8dc9458cfe41660731383d8f333e7882/src/components/legend/draw.js#L711
 */
class PieAdapter {
    /**
     * Default Options for layout
     */
    static layout = (data, layout) => {
        const {
            yaxis, xaxis, // ignored values
            ...props
        } = defaultLayout({data, layout})
        return assign({}, props, {
            // TODO: use layout.title
            title: data[0] ? data[0].name : null,
            showlegend: true,
            legend: {
                yanchor: 'bottom',
                y: 1,
                bgcolor: '#fff',
            },
        })
    }

    static reduce(data, layout) {
        const nextData = PieAdapter.reduceData(data)
        const nextLayout = PieAdapter.reduceLayout(layout, data)
        return {
            data: nextData,
            layout: nextLayout,
        }
    }

    /**
     * Merge each data series with the corresponding config
     * and attach x values. x axis is common to every series
     *
     * @params: data array of data series containing y values
     *          and other infos about the type.
     */
    static reduceData(data) {
        return data.map((serie) => {
            return {
                values: serie.x,
                labels: serie.labels,
                type: 'pie',
                customdata: serie.dateStamps,
                direction: 'clockwise',
                sort: false,
                text: serie.y.map(v => `${Optimise.round(v, 0)} ${serie.unit}`),
                hoverinfo: 'all',
                textposition: 'inside',
                marker: {
                    colors: COLORS,
                },
            }
        })
    }

    /**
     * Merge default signature layout with the layout
     * passed as parameter which may contain minimal info
     * like titles for x axis and y axis
     */
    static reduceLayout(layout, data) {
        const baseLayout = PieAdapter.layout(data, layout)
        // deep merge layout
        // layout can override baseLayout
        return assign({}, baseLayout, layout)
    }
}

export default PieAdapter


import {Component, OnInit, ViewChild} from '@angular/core';
import {MatProgressBar} from "@angular/material/progress-bar";
import {MatButton} from "@angular/material/button";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../shared/services/auth.service";
import {AppService} from "../../../shared/services/app.service";

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
    userEmail;
    actionCode: string;
    newPassword: string;
    newPasswordConf: string;
    @ViewChild(MatProgressBar) progressBar: MatProgressBar;
    @ViewChild(MatButton) submitButton: MatButton;

    constructor(private activatedRoute: ActivatedRoute,
                public route: Router, public myapp: AppService,
                public auth: AuthService) {
    }

    ngOnInit() {
        this.activatedRoute.queryParams
            .subscribe(params => {
                    console.log(params);
                    this.actionCode = params['oobCode'];
                    this.verifyPasswordResetCode();
                }
            );
    }

    verifyPasswordResetCode() {
        this.auth.afAuth.verifyPasswordResetCode(this.actionCode)
            .then((email) => {

            })
            .catch(error => {
                console.log(error);
            })
    }


    updatePassword() {
        console.log('updatePassword()', this.newPassword);
        this.auth.afAuth.confirmPasswordReset(this.actionCode, this.newPassword)
            .then(() => {
                this.myapp.showMessage("Mot de passe modifié avec succès !")
                this.route.navigate(['/session/login']);
            })
            .catch(error => {
                if (error.code === 'auth/weak-password')
                    this.myapp.showError("Mot de passe trop faible");
                if (error.code === 'auth/invalid-action-code')
                    this.myapp.showError("Code reset du mot de passe invalide");
                console.error(error);
            })
    }

}

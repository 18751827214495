import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

import {H} from "../../../../shared/helpers/H";
import {AppService} from "../../../../shared/services/app.service";
import {SiteService} from "../../../../shared/services/site.service";
import {FormBuilder} from "@angular/forms";
import {BaseComponent} from "../../../../shared/BaseComponent";
import {decamelize} from "@angular-devkit/core/src/utils/strings";
import {Optimise} from "../../../../shared/helpers/Optimise";
import {PdmFieldConfig, PdmRegDef, PdmRegSyncConfig} from 'app/shared/models/PdmRegDef';
import {Pdm} from "../../../../shared/models/Pdm";

@Component({
    selector: 'app-form-reg',
    template: `
        <div fxLayout="column" fxLayoutAlign="space-between stretch" class="flex-container">
            <h2 fxLayout="row" style="position: absolute;right: 10px;top: 5px;">
                <span></span>
                <diiv fxFlex></diiv>
                <mat-chip *ngIf="insertMode">
                    <mat-icon inline="true">note_add</mat-icon>
                    Mode Nouveau
                </mat-chip>
                <mat-chip *ngIf="!insertMode" color="accent">
                    <mat-icon inline="true">edit</mat-icon>
                    Mode EDITION
                </mat-chip>
            </h2>

            <div fxLayout="row" fxFlex *ngIf="tempReg">
                <div fxFlex="40">
                    <div class="p-8 mt-4">
                        <div fxLayout="row wrap" class="mb-16">
                            <div class="full-width pb-8" *ngIf="insertMode">
                                <button mat-raised-button [color]="tempReg.reg_type_main===mType?'primary':undefined"
                                        class="btn-xs-25 mr-4"
                                        (click)="configureRegFromTypes(mType,'')" *ngFor="let mType of regTypes">[{{mType}}]
                                </button>
                            </div>
                            <div fxLayout="row" class="reg-main-type-bloc" style="font-size: 16px" *ngIf="!insertMode">
                                <div>Main type: <span class="color-blue font-weight-bold"> [{{this.tempReg.reg_type_main|uppercase}}]</span></div>
                                <div fxFlex></div>
                                <div>{{reg.uid}}</div>

                            </div>
                            <mat-form-field class="full-width mt-16">
                                <mat-label>REG SUB TYPE</mat-label>
                                <mat-select placeholder="REG SUB TYPE"
                                            [value]="tempReg.reg_type_sub"
                                            (selectionChange)="configureRegFromTypes(tempReg.reg_type_main,$event.value)">
                                    <mat-option [value]="subType" *ngFor="let subType of subTypes"> [{{subType.toUpperCase()}}]</mat-option>
                                </mat-select>
                            </mat-form-field>


                        </div>
                        <div class="form-common">
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <div fxFlex="70">
                                    <mat-form-field class="full-width">
                                        <input matInput [(ngModel)]="tempReg.reg_label" placeholder="Libellé">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="30">
                                    <mat-form-field class="full-width">
                                        <input matInput [(ngModel)]="tempReg.disp_order" type="number" placeholder="Ordre d'aff + CALC">
                                    </mat-form-field>
                                </div>
                            </div>
                            <!--bloc var expliquée -->
                            <div *ngIf="showVarExpliquee">
                                <div class="form-section">
                                    <mat-checkbox [(ngModel)]="tempReg.is_derniere_cons">Var expliquée Conso</mat-checkbox>
                                    <br>
                                    <mat-checkbox [(ngModel)]="tempReg.is_derniere_cost">Va expliquée coût</mat-checkbox>
                                    <br>
                                    <mat-checkbox [(ngModel)]="tempReg.is_excedent">Excédent énergétique</mat-checkbox>
                                    <br>
                                    <mat-checkbox [(ngModel)]="tempReg.is_econ_target">Economisable</mat-checkbox>
                                    <br>
                                    <mat-checkbox [(ngModel)]="tempReg.is_ctot_paid">cTot Paid</mat-checkbox>
                                </div>

                                <div class="form-section">
                                    <mat-checkbox [(ngModel)]="tempReg.is_transport">Registre pour le transport</mat-checkbox>
                                    <br>
                                    <mat-checkbox [(ngModel)]="tempReg.is_import">Direction du flux:
                                        <strong *ngIf="tempReg.is_import">IMPORT</strong>
                                        <strong *ngIf="!tempReg.is_import">EXPORT</strong>
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div fxFlex>
                    <div class="p-8  mt-40">

                        <!--
                        FOREIGN TOT
                        -->
                        <ng-container *ngIf="showInForeignTot">
                            <div *ngIf="syncedRegsEditMode">
                                <mat-form-field class="full-width">
                                    <mat-select placeholder="Pdm étranger pour total" [value]="tempSyncConfig.uid_pdm">
                                        <mat-option (onSelectionChange)="selectForeignPdmForSyncedReg($event,pdm)"
                                                    [value]="pdm.uid"
                                                    *ngFor="let pdm of getAllPdmsForFluid">
                                            {{pdm.label}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="full-width" *ngIf="allTotsOfForeignPdmLabelsMap">
                                    <mat-select placeholder="Type de total"
                                                [value]="tempSyncConfig.tot_type">
                                        <mat-option [value]="totType"
                                                    (onSelectionChange)="changeSyncedRegConfig($event,'tot',totType)"
                                                    *ngFor="let totType of allTotsOfForeignPdm">
                                            <span class="numeric color-blue">{{totType}}</span>
                                            | <span *ngIf="allTotsOfForeignPdmLabelsMap">{{allTotsOfForeignPdmLabelsMap[totType] }}</span> -
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </ng-container>

                        <!--
                         FOREIGN REG
                         -->
                        <ng-container *ngIf="showInForeignReg&&allRegs">
                            <div *ngIf="syncedRegsEditMode">
                                <mat-form-field class="full-width">
                                    <mat-select placeholder="Pdm parent du rég étranger" [value]="tempSyncConfig.uid_pdm">
                                        <mat-option [value]="pdm.uid"
                                                    (onSelectionChange)="selectForeignPdmForSyncedReg($event,pdm)"
                                                    *ngFor="let pdm of getAllPdmsForFluid">{{pdm.label}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="full-width">
                                    <mat-select placeholder="Num Registre étranger" [value]="tempSyncConfig.reg_num">
                                        <mat-option [value]="reg.num"
                                                    (onSelectionChange)="changeSyncedRegConfig($event,'reg',reg)"
                                                    *ngFor="let reg of regsOfForeignPdm"><b>{{reg.num}}</b>: {{reg.reg_label}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </ng-container>

                        <!--synced summary -->
                        <div *ngIf="showInForeignReg||showInForeignTot">
                            <mat-card class="m-0 mb-16 p-8" *ngIf="tempSyncConfig&&!syncedRegsEditMode&&!insertMode">
                                <div class="sync-summary">Informations de synchronisation</div>
                                Pdm distant: <b>{{tempSyncConfig.pdm_label}}</b>
                                <br>Type synchro: <b>{{tempSyncConfig.sync_type}}</b>

                                <ng-container *ngIf="showInForeignReg">
                                    <br>Reg nom: <b (dblclick)="loadAllSiteFluidRegs(false)">{{tempSyncConfig.reg_label}}</b>
                                    <br>Reg num: <b>{{tempSyncConfig.reg_num}}</b><br>
                                    <button *ngIf="tempSyncConfig.reg_num&&allRegs&&allRegs.length"
                                            (click)="cloneForeignRegIntoCurrent(tempSyncConfig)">Cloner
                                    </button>
                                </ng-container>

                                <ng-container *ngIf="showInForeignTot">
                                    <br>Tot nom: <b>{{tempSyncConfig.tot_label}}</b>
                                    <br>Tot type: <b>{{tempSyncConfig.tot_type}}</b>
                                </ng-container>

                                <mat-card-actions>
                                    <div fxFlex></div>
                                    <button mat-raised-button class="btn-xs-25" (click)="toggleSyncedRegEditMode()">Editer</button>
                                </mat-card-actions>
                            </mat-card>
                        </div>

                        <ng-container *ngIf="showFieldTypeConfigurator">
                            <!--field configurator -->
                            <app-form-field [reg]="tempReg" [field]="'i'" (entryType)="cbEntryTypeChanged($event)"></app-form-field>
                            <app-form-field [reg]="tempReg" [field]="'d'" (entryType)="cbEntryTypeChanged($event)" (showMacrosArgs)="macroEditMode=$event"></app-form-field>
                            <app-form-field [reg]="tempReg" [field]="'t'" (showMacrosArgs)="macroEditMode=$event"></app-form-field>
                            <app-form-field [reg]="tempReg" [field]="'c'" (showMacrosArgs)="macroEditMode=$event"></app-form-field>
                            <!--field configurator end-->
                        </ng-container>

                        <div class="form-section p-8" fxLayout="row" *ngIf="showFieldTypeConfigurator &&showNotInSyncedReg">
                            <mat-checkbox [(ngModel)]="tempReg.is_k_visible" fxFlex="50"
                                          *ngIf="tempReg.i_conf&&tempReg.i_conf.data_entry!==''">
                                Champs [k] visible
                            </mat-checkbox>
                            <mat-checkbox [(ngModel)]="tempReg.is_a_visible" fxFlex="50">
                                Champs [a] visible
                            </mat-checkbox>
                        </div>
                    </div>
                </div>

                <div fxLayout="column" fxFlex="210px" *ngIf="reg&&macroEditMode!==''">
                    <div class="reg-form-holder mt-40">
                        <div class="font-weight-bold border-bottom" fxLayoutAlign="space-between center" fxLayout="row">
                            <span>Macro [{{macroEditMode|uppercase}}]</span>
                            <div fxFlex></div>
                            <button mat-button (click)="macroEditMode=''">
                                <mat-icon inline="inline">close</mat-icon>
                            </button>
                        </div>
                        <div class="btn-add-field-macro-holder">
                            <div *ngFor="let r of regs" fxLayout="row" [title]="r.reg_label" [ngClass]="{'current-reg':r.uid===reg.uid}">
                                <ng-container *ngFor="let f of fieldsPrefixes">
                                    <div fxFlex="20" class="btn-add-field-macro"
                                         *ngIf="r.disp_order<=tempReg.disp_order"
                                         (click)="appendMacroDef(f+r.num)"
                                         [ngClass]="{'field-included-inmacro':reg&&reg.getMacro(macroEditMode)&&reg.getMacro(macroEditMode).includes(f+r.num+' ')}">
                                        {{f|uppercase}}{{r.num}}
                                    </div>
                                </ng-container>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div fxLayout="row" class="">
                <div fxFlex>

                    <div fxFlex>
                        {{reg.getEnergyHash()}}
                        {{reg.getTypeHash()}}
                        {{reg.getTariffHash()}}
                    </div>
                
                </div>

                <button fxFlex="150px" class="mb-16" mat-raised-button color="primary"
                        (click)="saveReg()">Sauvegarder
                </button>
            </div>
        </div>
    `,
    styleUrls: ['./form-reg.component.scss']
})
export class FormRegComponent extends BaseComponent implements AfterViewInit, OnInit, OnChanges {
    @Input('reg') reg: PdmRegDef;
    @Input('regs') regs: PdmRegDef[];
    @Input('pdm') pdm: Pdm;
    @Input('ts') ts: number;// to force update on pdm update
    @Input('snifferSelectedCol') snifferSelectedCol: string;// to force update on pdm update
    @Output('cb') cb: EventEmitter<any> = new EventEmitter<any>();

    fieldInEdition = '';
    macroEditMode = '';
    fieldsPrefixes = ['i', 'p', 'k', 'd', 't', 'c'];


    regTypes = PdmRegDef.REG_TYPES;
    typesD = PdmRegDef.TYPES_OF_D;
    typesT = PdmRegDef.TYPES_OF_T;
    typesC = PdmRegDef.TYPES_OF_C;

    syncedRegsEditMode = false;
    dbNames = [];
    subTypes = [];

    allRegs: PdmRegDef[];
    allTotsOfForeignPdm: any[];
    allTotsOfForeignPdmLabelsMap: {};

    totFieldsMap: Map<string, any> = new Map<string, any>();
    tempReg: PdmRegDef = new PdmRegDef({});
    tempSyncConfig: PdmRegSyncConfig = new PdmRegSyncConfig();
    tempDConfig: PdmFieldConfig = new PdmFieldConfig();
    tempTConfig: PdmFieldConfig = new PdmFieldConfig();
    tempCConfig: PdmFieldConfig = new PdmFieldConfig();
    insertMode: boolean;

    constructor(public myapp: AppService, public site: SiteService, private fb: FormBuilder) {
        super();
    }

    get regsOfForeignPdm(): PdmRegDef[] {
        const regsOfForeignPdm = this.allRegs.filter(reg => reg.uid_pdm === this.tempSyncConfig.uid_pdm);
        //console.log("regsOfForeignPdm()", this.allRegs, regsOfForeignPdm, this.tempSyncConfig);
        return (this.allRegs && this.allRegs.length) ? regsOfForeignPdm : [];
        //return (this.allRegs && this.allRegs.length) ? this.allRegs : [];
    }

    get showFieldTypeConfigurator() {
        return this.tempReg && this.tempReg.reg_type_sub
            && (this.tempReg.reg_type_sub !== PdmRegDef.REG_SUB_TYPES_SYNCED_REG)
            && (this.tempReg.reg_type_sub !== PdmRegDef.REG_SUB_TYPES_SYNCED_TOT);
    }

    get getAllPdmsForFluid() {
        return this.site.pdms.filter(pdm => pdm.fluid === this.site.selectedPdm.fluid && !pdm.is_vpdm && pdm.uid !== this.site.selectedPdm.uid);
    }

    get showInForeignReg() {
        return this.tempReg
            && this.tempReg.reg_type_main
            && this.tempReg.reg_type_sub
            && this.tempReg.reg_type_main === PdmRegDef.REG_TYPE_SYNCED
            && this.tempReg.reg_type_sub === PdmRegDef.REG_SUB_TYPES_SYNCED_REG;
    }

    get showInForeignTot() {
        return this.tempReg
            && this.tempReg.reg_type_main
            && this.tempReg.reg_type_sub
            && this.tempReg.reg_type_main === PdmRegDef.REG_TYPE_SYNCED
            && this.tempReg.reg_type_sub === PdmRegDef.REG_SUB_TYPES_SYNCED_TOT;
    }

    get showNotInSyncedReg() {
        return this.tempReg && this.tempReg.reg_type_main && this.tempReg.reg_type_main !== PdmRegDef.REG_TYPE_SYNCED;
    }

    get showVarExpliquee() {
        return this.tempReg && this.tempReg.reg_type_main
            // && this.tempReg.reg_type_sub !== PdmRegDef.REG_SUB_TYPES_SYNCED_REG
            && this.tempReg.reg_type_main !== PdmRegDef.REG_TYPE_VAR;
    }

    configureRegFromTypes(type, subType) {
        this.tempReg.reg_type_sub = subType;

        if (this.tempReg.reg_type_main !== type)//clear subtype is change type
            this.tempReg.reg_type_sub = '';
        this.tempReg.reg_type_main = type;
        this.checkRegType();

        this.tempReg.disp_order = Number(this.regs.length) + 1;
        this.tempReg.c_conf.type = PdmRegDef.TYPE_C_NA;
        this.tempReg.d_conf.type = PdmRegDef.TYPE_D_NA;
        this.tempReg.t_conf.type = PdmRegDef.TYPE_T_NA;

        if (!this.tempReg.reg_label || this.tempReg.reg_label.trim() === '')
            this.tempReg.reg_label = subType.replace('_', ' ');
        if (subType === 'STD_ENERGY_PROD')
            this.tempReg.is_import = false;
        else this.tempReg.is_import = true;

        if (subType === 'STD_ENERGY') {
        }
        if (subType === 'STD_ENERGY_TRANSPORT') {
            this.tempReg.is_transport = true;
        } else this.tempReg.is_transport = false;

        if (subType === 'STD_POINTE_REAL') {
            this.tempReg.d_conf.type = PdmRegDef.TYPE_D_POINTE;
        }
        if (subType === 'STD_POINTE_FACT') {
            this.tempReg.d_conf.type = PdmRegDef.TYPE_D_POINTE;
        }
        if (subType === 'STD_REAC_CONS') {
            this.tempReg.d_conf.type = PdmRegDef.TYPE_D_REAC;
        }
        if (subType === 'STD_REAC_INDEX') {
            this.tempReg.d_conf.type = PdmRegDef.TYPE_D_REAC;
        }
        switch (subType) {
            case 'COST_DATA_ONLY':
                this.tempReg.c_conf.type = PdmRegDef.TYPE_C_EO;
                break;
            case 'COST_DAY_ONLY':
                this.tempReg.c_conf.type = PdmRegDef.TYPE_C_DO;
                break;
            case 'COST_DATA_DAY':
                this.tempReg.c_conf.type = PdmRegDef.TYPE_C_ED;
                break;
            case 'COST_FINAL':
                this.tempReg.c_conf.type = PdmRegDef.TYPE_C_MN;
                break;
            case 'COST_MANUAL':
                this.tempReg.c_conf.type = PdmRegDef.TYPE_C_MN;
                break;
            case 'COST_MACRO':
                this.tempReg.c_conf.type = PdmRegDef.TYPE_C_MC;
                break;
            default:
                console.log("No such subType exists!", ">" + subType + "<");
                break;
        }
        // if (type === PdmRegDef.REG_TYPE_SYNCED) this.loadAllSiteFluidRegs();
    }

    cbEntryTypeChanged($event) {
        const {field, value} = $event;
        console.log("cbEntryTypeChanged", field, value);
    }

    toggleSyncedRegEditMode() {
        this.loadAllSiteFluidRegs();
    }

    cloneForeignRegIntoCurrent(syncConfig: PdmRegSyncConfig) {
        this.allRegs.forEach(r => {
            if (syncConfig && syncConfig.reg_num && syncConfig.reg_num === r.num
                && r.uid_pdm === syncConfig.uid_pdm) {
                const {uid, uid_pdm, num, ts_created, disp_order, reg_label, ...data} = r;

                this.tempReg = {...this.tempReg, ...data} as PdmRegDef;
                this.tempReg.reg_label = this.tempReg.reg_label + " (cloned from) " + r.reg_label;
                this.saveReg(true);
                console.log("WILL CLONE THIS:", data, " INTO: ", this.tempReg);
            }
        });
    }

    changeSyncedRegConfig(event: any, type: string, arg: any) {
        if (!event.isUserInput) return;
        console.log("changeSyncedRegConfig()", type, arg, event);
        if (type === "reg") {
            const reg = arg as PdmRegDef;
            this.tempSyncConfig.sync_type = PdmRegSyncConfig.SYNC_REG;
            this.tempSyncConfig.reg_num = reg.num;
            this.tempSyncConfig.reg_label = reg.reg_label;
            this.tempSyncConfig.tot_type = null;
            this.tempReg.i_conf = reg.i_conf;
            this.tempReg.d_conf = reg.d_conf;
            this.tempReg.t_conf = reg.t_conf;
            this.tempReg.c_conf = reg.c_conf;
            this.tempReg.is_k_visible = reg.is_k_visible;
            this.tempReg.fluid = reg.fluid;
        }
        if (type === "tot") {
            const totType = arg as string;
            this.tempSyncConfig.sync_type = PdmRegSyncConfig.SYNC_TOT;
            this.tempSyncConfig.reg_num = null;
            this.tempSyncConfig.reg_label = null;
            this.tempSyncConfig.tot_type = totType;
            this.tempSyncConfig.tot_label = this.allTotsOfForeignPdmLabelsMap[totType];
            this.tempReg.is_k_visible = false;

            /// TODO: check if TYPE_X_NA affects reliability of those lines
            if (totType.includes("De")) this.tempReg.d_conf.type = PdmRegDef.TYPE_D_ENERGY;
            if (totType.includes("Dr")) this.tempReg.d_conf.type = PdmRegDef.TYPE_D_REAC;
            if (totType.includes("Dp")) this.tempReg.d_conf.type = PdmRegDef.TYPE_D_POINTE;
            if (totType.includes("Tb")) this.tempReg.t_conf.type = PdmRegDef.TYPE_T_BT;
            if (totType.includes("Th")) this.tempReg.t_conf.type = PdmRegDef.TYPE_T_HT;
            if (totType.includes("Tn")) this.tempReg.t_conf.type = PdmRegDef.TYPE_T_NT;
            this.tempReg.c_conf.type = PdmRegDef.TYPE_C_MN;
        }
    }

    selectForeignPdmForSyncedReg = (event: any, pdm: Pdm) => {
        if (!event.isUserInput) return;
        this.tempSyncConfig = new PdmRegSyncConfig();
        console.log("selectForeignPdmForSyncedReg()", pdm, this.tempSyncConfig, this.allRegs);
        this.tempSyncConfig.uid_pdm = pdm.uid;
        this.tempSyncConfig.pdm_label = pdm.label;
        this.tempSyncConfig.pdm_prefix = pdm.pdm_prefix;

        // populate tots drop down
        if (this.reg.reg_type_sub === PdmRegDef.REG_SUB_TYPES_SYNCED_TOT) {
            let regsOfPdm = this.allRegs.filter(reg => reg.uid_pdm === this.tempSyncConfig.uid_pdm);
            regsOfPdm = this.sortArrayByKeyVal(regsOfPdm, 'disp_order');
            const totsObject = Pdm.getTotFields(regsOfPdm);
            this.allTotsOfForeignPdm = Object.keys(totsObject)
                .map(item => item.replace("dT", 'T'))
                .map(item => item.replace("cT", "T"))
                .filter((v, i, a) => a.indexOf(v) === i && v.length > 7)
                .sort();
            this.convertTotHashToHumanReadable();
            console.log("loadTotsTypesForPdm()", this.tempSyncConfig, regsOfPdm, this.allTotsOfForeignPdm);
        }
    };

    convertTotHashToHumanReadable() {
        const convertedArray = this.allTotsOfForeignPdm
            .map(item => decamelize(item))
            /*.map(item => item.replace("tot_exp", "Export "))
            .map(item => item.replace("tot_imp", "Import "))
            .map(item => item.replace("de_", "Energie "))
            .map(item => item.replace("dp_", "Pointe "))
            .map(item => item.replace("dr_", "Réactive "))
            .map(item => item.replace("tnn", "Tarif Normal "))
            .map(item => item.replace("thi", "Tarif HAUT "))
            .map(item => item.replace("tlo", "Tarif BAS "))*/
            .sort();
        this.allTotsOfForeignPdmLabelsMap = {};
        this.allTotsOfForeignPdm.forEach((key, idx) => this.allTotsOfForeignPdmLabelsMap[key] = convertedArray[idx]);
        console.log("convertTotHashToHumanReadable()", this.allTotsOfForeignPdm, convertedArray, this.allTotsOfForeignPdm);
    }

    loadAllSiteFluidRegs(gotoEditMode = true) {
        this.allRegs = [];
        this.site.api.getAllPdmsRegsForSiteAndFluid(this.site.uid, this.pdm.fluid).subscribe(resp => {
            //console.log("getAllPdmsRegs", resp);
            resp.body.forEach(rawReg => {
                this.allRegs.push(new PdmRegDef(rawReg));
            });
            // console.log("loadAllSiteFluidRegs", this.allRegs);
            this.allRegs = Optimise.sortArrayByKeyVal(this.allRegs, 'num');
            if (gotoEditMode)
                this.syncedRegsEditMode = true;
            this.myapp.toastr.success('Registres chargées', 'Evenement!');
        });
    }

    checkRegType() {
        if (this.tempReg.reg_type_main === PdmRegDef.REG_TYPE_SYNCED) {
            this.subTypes = PdmRegDef.REG_SUB_TYPES_SYNCED;
        }
        if (this.tempReg.reg_type_main === PdmRegDef.REG_TYPE_VAR) {
            this.subTypes = PdmRegDef.REG_SUB_TYPES_VAR;
        }
        if (this.tempReg.reg_type_main === PdmRegDef.REG_TYPE_DATA_STD) {
            this.subTypes = PdmRegDef.REG_SUB_TYPES_DATA_STD;
        }
        if (this.tempReg.reg_type_main === PdmRegDef.REG_TYPE_DATA_COST) {
            this.subTypes = PdmRegDef.REG_SUB_TYPES_DATA_COST;
        }
    }

    appendMacroDef(regField: string) {

        if (!this.reg.getMacro(this.macroEditMode)) this.reg[this.macroEditMode + "_conf"].macro = regField;
        else
            this.reg[this.macroEditMode + "_conf"].macro += " + " + regField + " ";
        this.reg[this.macroEditMode + "_conf"].macro = this.reg[this.macroEditMode + "_conf"].macro.replace('  ', ' ');
    }

    saveReg(cloning = false) {
        const reg: PdmRegDef = new PdmRegDef(this.tempReg);
        Object.keys(reg).forEach(key => {
            if (key.includes("is_") && reg[key] !== undefined) {
                if (String(reg[key]) === "true") reg[key] = 1;
                if (String(reg[key]) === "false") reg[key] = 0;
            }
        });

        if (this.insertMode) {
            this.regs = this.regs.sort((a, b) => a.num - b.num);
            const lastReg = this.regs[this.regs.length - 1];
            //console.log("lastReg", lastReg);
            if (lastReg)
                reg.num = Number(lastReg.num) + 1;// simulates an autoincrement
            else reg.num = 1;
            reg.uid = this.pdm.uid + "_reg" + reg.num;
            reg.uid_pdm = this.pdm.uid;
            reg.uid_client = this.pdm.uid_client;
            reg.uid_site = this.pdm.uid_site;
            reg.fluid = this.pdm.fluid;
            reg.ts_created = H.unixTs();
            this.regs = this.regs.sort((a, b) => a.disp_order - b.disp_order);
        }
        if (reg.reg_type_sub === PdmRegDef.REG_SUB_TYPES_SYNCED_MACRO) {
            reg.is_k_visible = false;
        }
        reg.uid_client = this.pdm.uid_client;//fix bug
        reg.uid_site = this.pdm.uid_site;//fix bug
        reg.ts_updated = H.unixTs();

        if (!cloning) {
            // for synced regs
            reg.sync_config = this.tempSyncConfig;
        }


        // console.log("SaveReg", reg);
        this.site.api.savePdmReg(reg, this.insertMode).subscribe(resp => {
            //     console.log("Saved Reg", resp);
            this.tempReg = new PdmRegDef(resp.body);
            this.cb.emit({arg: 'REG', obj: this.tempReg});
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log("FormPdmComponent", 'ngOnChanges', changes, this.pdm);
        // this.insertMode = true;
        // if (!this.reg) this.myapp.pdmEditorTabIndex = 1;
        if (changes['pdm']) {
            if (this.pdm.is_vpdm) this.regTypes = [
                'SYNCED',
            ]; else this.regTypes = PdmRegDef.REG_TYPES;
        }
        if (changes['reg']) {
            this.syncedRegsEditMode = false;
            if (this.reg && this.reg.uid) {
                this.insertMode = false;
                if (this.reg.sync_config) {
                    this.tempSyncConfig = new PdmRegSyncConfig(this.reg.sync_config);
                }
                if (this.reg.d_conf) {
                    this.tempDConfig = new PdmFieldConfig(this.reg.d_conf);
                }
                if (this.reg.t_conf) {
                    this.tempTConfig = new PdmFieldConfig(this.reg.t_conf);
                }
                if (this.reg.c_conf) {
                    this.tempCConfig = new PdmFieldConfig(this.reg.c_conf);
                }
                this.tempReg = this.reg;

            } else {
                this.tempSyncConfig = new PdmRegSyncConfig();
                this.insertMode = true;
                if (this.pdm.is_vpdm)
                    this.tempReg = new PdmRegDef({reg_type_main: PdmRegDef.REG_TYPE_SYNCED});
                else
                    this.tempReg = new PdmRegDef({reg_type_main: PdmRegDef.REG_TYPE_DATA_STD});
            }
            /// in all cases:
            this.checkRegType();
        }
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {

    }

}

import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PdmYearConfig} from "../../../shared/models/PdmYearConfig";
import {AppService} from "../../../shared/services/app.service";
import {SiteService} from "../../../shared/services/site.service";
import {H} from "../../../shared/helpers/H";
import {InfluenceFactorConfig, ReportSuivi} from 'app/shared/models/Suivi.model';

@Component({
    selector: 'suivi-influence-factors',
    template: `
        <div class="component-holder">
            <div>
                <table class="table-actions full-width">
                    <tr class="table-actions-head">
                        <td>
                            Indicateur
                            <mat-icon inline class="cursor-pointer inline vert-align-middle"
                                      (click)="editMode='VAREXP'">edit
                            </mat-icon>
                        </td>
                        <td class="border-right">Unité principale</td>
                        <ng-container *ngFor="let rep of reportsOfSameConvention ">
                            <td *ngIf="rep.year<=report.year" class="fact-infl-value text-right">{{ rep.year }}</td>
                        </ng-container>
                    </tr>
                    <tr class="table-row" *ngFor="let indic of reportRef.influence_factors_config">
                        <td class="color-blue text-left">
                            <span *ngIf="editMode!=='VAREXP'"> {{ indic.name }}  </span>
                            <span *ngIf="editMode!=='VAREXP'"> 
                                <mat-icon (dblclick)="delVarExp(indic)" inline class="no-print inline color-blue">delete</mat-icon>  
                            </span>
                            <mat-form-field appearance="legacy" *ngIf="editMode==='VAREXP'">
                                <input matInput placeholder="Nom var expl" [(ngModel)]="indic.name">
                            </mat-form-field>

                        </td>
                        <td class="border-right text-left fact-infl-unit">
                            <span *ngIf="editMode!=='VAREXP'">  {{ indic.unit }}   </span>
                            <mat-form-field appearance="legacy" *ngIf="editMode==='VAREXP'">
                                <input matInput placeholder="Unité" [(ngModel)]="indic.unit">
                            </mat-form-field>
                        </td>
                        <ng-container *ngFor="let rep of reportsOfSameConvention ">
                            <td *ngIf="rep.year<=report.year" class="fact-infl-value">
                                <ng-container *ngIf="editMode!=='VAREXP'||rep.year!==report.year">
                                    <span *ngIf="rep.influence_factors&&rep.year===report.year">{{ rep.influence_factors[indic.uid]|number }}</span>
                                    <span *ngIf="rep.influence_factors&&rep.year!==report.year">{{ rep.influence_factors[indic.uid]|number }}</span>
                                </ng-container>
                                <mat-form-field appearance="legacy" *ngIf="editMode==='VAREXP'&&rep.year===report.year">
                                    <input matInput [placeholder]="'Valeur '+rep.year" type="number"
                                           [title]="rep.uid+'-'+indic.uid"
                                           (keyup)="onType($event.target,indic.uid)" [ngModel]="report.influence_factors[indic.uid]">
                                </mat-form-field>
                            </td>
                        </ng-container>

                    </tr>
                </table>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <button mat-raised-button (click)="addNewVarExp()" class="m-4 btn-xs-25">
                        Ajouter variable explicative dès l'année {{ report.year }}
                    </button>
                    <div fxFlex></div>
                    <button mat-raised-button (click)="editMode=null" class="m-4 btn-xs-25">
                        Annuler
                    </button>
                    <button mat-raised-button color="primary" (click)="saveVarExpl()" class="m-4 btn-xs-25">
                        Sauvegarder
                    </button>
                </div>
            </div>

            <mark-down [content]="this.report.note_factors"
                       [report]="report" field="note_factors"></mark-down>
        </div>
    `,
    styleUrls: ['./suivi.component.scss']
})
export class SuiviInfluenceFactorsComponent implements OnInit, OnChanges {
    @Output('cb') cb: EventEmitter<any> = new EventEmitter<any>();
    @Input('year') year: number;
    @Input('report') report: ReportSuivi;
    @Input('reportRef') reportRef: ReportSuivi;
    @Input('reportsOfSameConvention') reportsOfSameConvention: ReportSuivi[];
    debug = 0;//VAREXPL - notes
    editMode = '';//VAREXPL - notes
    valuesMappedToMetric: Map<string, number> = new Map<string, number>();
    valuesToSend = {}

    constructor(public myapp: AppService, public site: SiteService) {
    }

    ngOnInit() {
        this.site.siteLoadingStatus.subscribe(level => {
            if (level === SiteService.MAX_SITE_LOADING_STATUS) {
                this.bootstrap();
            }
        });
    }

    bootstrap() {
        //  if (!this.report.influence_factors.length) this.report.influence_factors = {};
        // if (!this.report.influence_factors) this.report.influence_factors.length = {};
        console.log("bootstrap", this.report.year, this.report.uid, this.report.influence_factors);
        this.reportRef.influence_factors_config.forEach(conf => {
            this.valuesToSend[conf.uid] = this.report.influence_factors[conf.uid]
        });
    }

    addNewVarExp() {
        const varExp = new InfluenceFactorConfig({});
        varExp.startYear = this.report.year;
        varExp.name = "A modifier";
        varExp.unit = "-";
        varExp.uid = H.randomStr(30);
        if (!this.reportRef.influence_factors_config) this.reportRef.influence_factors_config = [];
        this.reportRef.influence_factors_config.push(varExp);
    }

    onType(evTarget, factInfluenceId) {
        console.log("valChange", factInfluenceId, evTarget.value);
        this.report.influence_factors[factInfluenceId] = Number(evTarget.value)
        this.valuesToSend[factInfluenceId] = Number(evTarget.value);

    }

    delVarExp(indic) {
        console.log("delVarExp", indic)
        this.myapp.confirm.confirm({
            title: "Demande de confirmation",
            message: "Cette variable explicative sera définitivement supprimée."
        }).subscribe(ok => {
            if (ok) {
                this.site.api.suiviVarExplDel(this.site.uid, indic.uid).subscribe(resp => {
                    console.log("delVarExp", resp);
                    if (resp.status === 1) {
                        this.site.reload();
                    }
                });
            }
        });
    }

    saveVarExpl() {
        console.log("saveVarExpl Config and values", this.reportRef.influence_factors_config, this.report.influence_factors);
        this.site.api.saveSuivi(this.reportRef, {influence_factors_config: this.reportRef.influence_factors_config})
            .subscribe(resp => {
                if (resp.status === 1) this.myapp.showMessage("Variables expl. enregistrées");
                console.log("saveVarExpl Values", this.report.influence_factors, this.valuesToSend);
                this.site.api.saveSuivi(this.report, {influence_factors: this.valuesToSend})
                    .subscribe(resp2 => {
                        if (resp2.status === 1) {
                            this.cb.emit({reload: true});
                            this.myapp.showMessage("Valeur des variables enregistrées");
                            this.editMode = null;
                            //this.myapp.appInitStatus.next(1);//force reloading cycle
                        }

                    });
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        //console.log('ngOnChanges():', changes);
        this.bootstrap();
    }

}

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppService} from "../../../shared/services/app.service";
import {SiteService} from "../../../shared/services/site.service";
import {UserSession} from "../../../shared/models/UserSession.model";
import {ProtoReportPage, ReportPublish} from "../../../shared/models/ProtoHebdo";
import {H} from "../../../shared/helpers/H";
import moment from "moment";
import {Duration} from 'luxon';
import {PlotlyComponent, PlotlyService} from "angular-plotly.js";
import {graph, barDataItem, perHourLayout, progressData, progressGaugeLayout, pieData, pieLayout, colorsMap} from "./plots";
import {interval} from "rxjs";
import Plotly from 'plotly.js-dist-min'


@Component({
    selector: 'monitor-reporting',
    template: `
        <div fxLayout="column" class="full-width h100 h-100">
            <!-- left listfxLayoutAlign="space-between start" -->
            <mat-card class="default full-width">
                <div fxLayout="row">
                    <button mat-raised-button class="btn-xs" [class.active]="selectedYear===y"
                            *ngFor="let y of years" (click)="selectYear(y)">
                        {{ y }}
                    </button>
                </div>
            </mat-card>

            <div fxLayout="row" class="h-100">
                <div fxLayout="column" fxFlex="800px" fxLayoutAlign="space-between none">
                    <mat-card class="p-0 m-4">
                        <plotly-plot #plotly1
                                     (plotlyClick)="plotClic($event )"
                                     [config]="myapp.k.defaultConfig"
                                     [data]="graph.data"
                                     [layout]="graph.layout">
                        </plotly-plot>
                    </mat-card>
                    <mat-card class="p-0 m-4">
                        <div id="scatterPlot"></div>
                    </mat-card>
                    <mat-card class="p-4 m-4 plot-comm-holder">
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="report-item report-item-head ">
                            <div fxFlex="120px" class="font-weight-bold">
                                Date
                            </div>
                            <div fxFlex="30px" class="weight-bold">
                                Part.
                            </div>
                            <div fxFlex="240px" class="weight-bold">
                                Bâtiment et nb. de pages
                            </div>
                            <div fxFlex fxLayout="row" class="holder" fxLayoutAlign="start none">
                                Liste d'évenements
                            </div>

                        </div>
                        <div class="list-scroller">
                            <div *ngFor="let rep of reportsToShow"
                                 fxLayout="row" fxLayoutAlign="space-between center" class="report-item" (click)="selectReport(rep)">
                                <div fxFlex="120px" class="font-weight-bold">
                                    {{ rep.dateTimeStr }}
                                </div>
                                <div fxFlex="30px" class="weight-bold">
                                    <div *ngIf="rep.events.length>0" class="shared">
                                        <mat-icon inline>share</mat-icon>
                                    </div>
                                </div>
                                <div fxFlex="240px" class="weight-bold">
                                    {{ rep.domain_ref }} ({{ rep.page_count }}p)
                                </div>
                                <div fxFlex fxLayout="row" class="holder" fxLayoutAlign="start none">
                                    <ng-container *ngFor="let e of rep._history|keyvalue">
                                        <ng-container *ngIf="rep.getMinutesOffset(e.value) as dt">
                                            <div class="event-marker {{e.value.newStatus}}  {{dt.class}}" (click)="myapp.showConsole(e.value)"
                                                 *ngIf="e.value.event==='statusChanged'">
                                                <mat-icon *ngIf="e.value.newStatus==='PUB_OK'" inline>thumb_up_alt</mat-icon>
                                                <mat-icon *ngIf="e.value.newStatus==='READY'" inline>check</mat-icon>
                                                <span class="name-avatar" [innerText]="dt.user"
                                                      [style.background-color]="colorsMap[dt.first_name]"
                                                      [title]="dt.email"></span>
                                                <span class="infos" *ngIf="e.value.newStatus!=='PENDING'"
                                                      [title]="e.value.dateStr" [innerHTML]="dt.label"></span>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>

                            </div>
                        </div>
                    </mat-card>
                </div>

                <div fxLayout="column" fxFlex="820px" class="p-0 m-4">
                    <mat-card class="m-0 mb-8 p-4" fxLayout="column">
                        <h3>Rapports publiés | <b>Semaine N° {{ selectedWeek }}</b></h3>
                        <div fxFlex fxLayout="row">
                            <div id="gaugeProgress-PENDING"></div>
                        </div>
                    </mat-card>
                    <mat-card class="m-0 mb-8 p-4" fxLayout="column">
                        <h3>Pubblications validées</h3>
                        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                            <div fxFlex="250px" id="gaugeProgress-PUB_OK"></div>
                            <div fxFlex="550px" id="pie-PUB_OK"></div>
                        </div>
                    </mat-card>
                    <mat-card class="m-0 mb-8 p-4" fxLayout="column">
                        <h3>Rapport prêt au partage</h3>
                        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                            <div fxFlex="250px" id="gaugeProgress-READY"></div>
                            <div fxFlex="550px" id="pie-READY"></div>
                        </div>
                    </mat-card>
                    <mat-card class="m-0 mb-8 p-4" fxLayout="column">
                        <h3>Partage</h3>
                        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                            <div fxFlex="250px" id="gaugeProgress-SHARED"></div>
                            <div fxFlex="550px" id="pie-SHARED"></div>
                        </div>
                    </mat-card>
                </div>

                <mat-card class="p-8 m-4" fxFlex *ngIf="selectedReport||selectedUserEmail">
                    <ng-container *ngIf="selectedUserEmail">
                        <h3>Sites traités par <b>{{ getFirstName(selectedUserEmail) }}</b></h3>
                        <div class="user-activities-scroller">
                            <div *ngFor="let rep of reportsOfSelectedUser">
                                <div fxFlex="240px" class="weight-bold cursor-pointer"
                                     (click)="myapp.showConsole(rep);selectReport(rep)">
                                    <b>{{ rep.domain_ref }} ({{ rep.page_count }}p)</b>
                                </div>
                                <div fxFlex fxLayout="row" class="holder-row" fxLayoutAlign="start none">
                                    <div fxFlex="25">
                                        <div *ngIf="rep._log_final['PENDING'] as ev">Publié
                                            <span>{{ ev.date }}</span>
                                        </div>
                                    </div>
                                    <div fxFlex="25">
                                        <div *ngIf="rep._log_final['PUB_OK'] as ev">
                                            <span class="name-avatar" [innerText]="ev.user" [title]="ev.email"
                                                  [style.background-color]="colorsMap[ev.first_name]"></span>
                                            Validé
                                            <span>{{ ev.date }}</span>
                                        </div>
                                    </div>
                                    <div fxFlex="25">
                                        <div *ngIf="rep._log_final['READY'] as ev">
                                            <span class="name-avatar" [innerText]="ev.user" [title]="ev.email"
                                                  [style.background-color]="colorsMap[ev.first_name]"></span>
                                            Prêt
                                            <span>{{ ev.date }}</span>
                                        </div>
                                    </div>
                                    <div fxFlex="25">
                                        <div *ngIf="rep._log_final['SHARED'] as ev">
                                            <span class="name-avatar" [innerText]="ev.user" [title]="ev.email"
                                                  [style.background-color]="colorsMap[ev.first_name]"></span>
                                            Partagé
                                            <span>{{ ev.date }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                    </ng-container>
                    <ng-container *ngIf="selectedReport&&selectedReport.events">
                        <div *ngFor="let ev of selectedReport.events">
                            <b>Partagé le:</b> {{ ev.ts|amFromUnix }}
                            <hr>
                            <b>Sujet:</b>
                            <pre [innerText]="ev.subject"></pre>
                            <hr>
                            <b>Body:</b>
                            <pre [innerText]="ev.body"></pre>
                            <hr>
                            <b>Destinataires:</b>
                            <pre>{{ ev.target|json }}</pre>
                            <hr>
                            <b>Envoyé par:</b>
                            <div>{{ ev.user_metas.firstName }} {{ ev.user_metas.lastName }}</div>
                        </div>
                    </ng-container>
                </mat-card>
            </div>


        </div>
    `,
    styleUrls: ['./manager.component.scss']
})
export class MonitorReportingComponent implements OnInit, OnDestroy {
    @ViewChild('plotly1', {static: true}) plotly1: PlotlyComponent;
    @ViewChild('plotProgress', {static: true}) plotProgress: PlotlyComponent;

    protected readonly progressData = progressData;
    protected readonly progressGaugeLayout = progressGaugeLayout;
    protected readonly pieData = pieData;
    protected readonly pieLayout = pieLayout;
    protected readonly colorsMap = colorsMap;
    protected readonly graph = graph;
    protected readonly perHourLayout = perHourLayout;
    protected readonly barDataItem = barDataItem;

    refList: string[] = [];
    reportItems: ReportPublish[] = [];

    countByBatRef: Map<string, number> = new Map<string, number>();
    years: number[] = [];
    reportsToShow: ReportPublish[] = [];
    reportsOfSelectedUser: ReportPublish[] = [];
    selectedUserEmail = '';
    selectedYear = 0;
    selectedWeek = 0;
    selectedReport: ReportPublish = null;
    hoursToShow = [];
    weeksRange = [];
    sortBy = 'dayNo';//batName

    // data analysis
    perHour: Map<string, number> = new Map<string, number>();
    perDay: Map<string, number> = new Map<string, number>();
    perDayBlocEvent: Map<string, number> = new Map<string, number>();//1 entry per day, bloc(8h range) amd event type
    perDayBlocEventLabel: Map<string, string> = new Map<string, string>();//1 entry per day, bloc(8h range) amd event type
    perUser: Map<string, number> = new Map<string, number>();
    perUserLabel: Map<string, string> = new Map<string, string>();
    daysMap = {0: 'Dim', 1: 'Lun', 2: 'Mar', 3: 'Mer', 4: 'Jeu', 5: 'Ven', 6: 'Sam', 7: 'Dim'};

    userLabelToEmailMap: Map<string, string> = new Map<string, string>();

    renderedDivIds = {};//to decide if to animate or newPlot
    constructor(
        public myapp: AppService,
        public site: SiteService,
        public plotlyServ: PlotlyService
    ) {

    }

    ngOnInit(): void {
        this.selectedYear = moment().year();
        this.selectedWeek = moment().week();

        this.years.push(2023);
        this.years.push(2024);
        for (let w = 1; w < 55; w++) this.weeksRange.push(w);
        for (let h = 1; h < 30; h++) this.hoursToShow.push(h);
        if (this.myapp.user) {
            this.preRun();
        } else {
            this.myapp.appInitStatus.subscribe(step => {
                if (this.myapp.user)
                    this.preRun();
            });
        }
    }

    preRun() {
        if (this.myapp.user.isAdminOrSuperUser)
            this.run();
        else {
            this.myapp.showError("Accès reservé aux administrateurs");
            setTimeout(() => {
                window.history.back();
            }, 4000);
        }
    }

    run() {
        this.myapp.storeCurrentRoute();
        this.selectWeek(0);
    }

    selectYear(y: number) {
        this.perDay = new Map<string, number>();
        this.selectedYear = y;
        this.selectedWeek = 0;
        this.getData();
    }

    selectWeek(w: number) {
        this.selectedWeek = Number(w);
        this.getData();
    }

    getData() {
        this.myapp.loader.open('Chargement...');
        this.myapp.api.getProtoEventsForMonitoring(this.selectedWeek, this.selectedYear).subscribe(resp => {
            this.reportItems = resp.data.map(it => new ReportPublish(it));
            if (resp['batRefs'])
                Object.keys(resp['batRefs']).forEach(ref => this.countByBatRef.set(ref, resp['batRefs'][ref]));
            if (this.reportItems) {
                console.log("reportItem", this.reportItems);
                console.log("countByBatRef", this.countByBatRef);
                console.log("Refs", resp['refs']);
                this.years = H.arrayUnique(this.years).sort();
                this.refList = resp['refs'];
                if (this.selectedWeek === 0) {
                    this.analyzeData();
                } else
                    this.applyFilters();

                this.myapp.loader.close();
            }
        });
    }

    selectReport(rep) {
        console.log("ReportPublish", rep)
        this.selectedReport = rep;
    }

    analyzeData() {
        this.perDay = new Map<string, number>();
        const weeklyStatMon = JSON.parse(JSON.stringify(this.barDataItem));
        const weeklyStatTue = JSON.parse(JSON.stringify(this.barDataItem));
        const weeklyStatWed = JSON.parse(JSON.stringify(this.barDataItem));
        const weeklyStatThu = JSON.parse(JSON.stringify(this.barDataItem));
        const weeklyStatFri = JSON.parse(JSON.stringify(this.barDataItem));
        weeklyStatMon.name = "Lundi";
        weeklyStatTue.name = "Mardi";
        weeklyStatWed.name = "Mercredi";
        weeklyStatThu.name = "Jeudi";
        weeklyStatFri.name = "Vendredi";

        weeklyStatMon.marker.color = '#333333';
        weeklyStatTue.marker.color = '#aaaa33';
        weeklyStatWed.marker.color = '#aa6622';
        weeklyStatThu.marker.color = '#5555ff';
        weeklyStatFri.marker.color = '#000000';
        this.graph.data = [weeklyStatFri, weeklyStatThu, weeklyStatWed, weeklyStatTue, weeklyStatMon];
        for (let s = 0; s < 5; s++) {
            this.graph.data[s].x = [];
            this.graph.data[s].y = [];
            this.graph.data[s].values = [];
            this.graph.data[s].labels = [];
        }
        this.graph.layout.datarevision++;


        this.reportItems
            .sort((a, b) => a.date_create_ts - b.date_create_ts)
            .forEach(it => {
                const weekStr = it._week < 10 ? '0' + it._week : it._week.toString();
                const keyWeekDay = weekStr + '_' + it._day;
                const keyWeekYear = 'w_' + weekStr;
                this.perDay = H.incrStatMap(this.perDay, keyWeekDay);
                this.perDay = H.incrStatMap(this.perDay, keyWeekYear);
            });

        for (let w = 1; w < 52; w++) {
            const weekStr = w < 10 ? '0' + w : w.toString();
            const keyWeekDayMon = weekStr + '_lundi';
            const keyWeekDaytue = weekStr + '_mardi';
            const keyWeekDayWed = weekStr + '_mercredi';
            const keyWeekDayThu = weekStr + '_jeudi';
            const keyWeekDayFri = weekStr + '_vendredi';
            for (let s = 0; s < 5; s++)
                this.graph.data[s].x.push(weekStr);

            const mon = this.perDay.has(keyWeekDayMon) ? this.perDay.get(keyWeekDayMon) : 0;
            const tue = this.perDay.has(keyWeekDaytue) ? this.perDay.get(keyWeekDaytue) : 0;
            const wed = this.perDay.has(keyWeekDayWed) ? this.perDay.get(keyWeekDayWed) : 0;
            const thu = this.perDay.has(keyWeekDayThu) ? this.perDay.get(keyWeekDayThu) : 0;
            const fri = this.perDay.has(keyWeekDayFri) ? this.perDay.get(keyWeekDayFri) : 0;
            this.graph.data[0].y.push(fri);
            this.graph.data[1].y.push(thu);
            this.graph.data[2].y.push(wed);
            this.graph.data[3].y.push(tue);
            this.graph.data[4].y.push(mon);
            this.graph.layout.datarevision++;
        }
        console.log(this.perDay);

        /*
        interval(5).subscribe(() => {
            this.graph.data[0].x.push(this.graph.data[0].x.length + 1);
            this.graph.data[1].x.push(this.graph.data[1].x.length + 1);
            this.graph.data[0].y.push(H.randomInt(0, 20));
            this.graph.data[1].y.push(H.randomInt(5, 10));
            this.graph.layout.datarevision++;
            // if (this.plotly1) this.plotly1.data = this.graph.data;
            console.log("interval", this.graph.data, this.plotly1);
        });*/

    }

    applyFilters() {
        this.reportsToShow = this.reportItems
            .filter(it => it._week === this.selectedWeek)
            .sort((a, b) => {
                if (this.sortBy === 'dayNo') {
                    return a.date_create_ts - b.date_create_ts;
                }
            });

        this.perHour = new Map<string, number>();
        this.perUser = new Map<string, number>();
        this.reportsToShow.forEach(it => {
            it._history.forEach((v, k) => {
                const m = moment.unix(v.ts);
                const keyforTime = m.format('d-HH');
                const keyforTimeLabel = H.ucfirst(m.format('ddd-HH') + 'h');
                this.perUserLabel.set(keyforTime, keyforTimeLabel);
                if (v.email) {
                    const key = v.newStatus + ':' + v.email;
                    H.incrStatMap(this.perUser, key);
                }
                H.incrStatMap(this.perHour, keyforTime);
            });
            if (it.events[0]) {
                const m = moment.unix(it.events[0].ts);
                const keyforTime = m.format('d-HH');
                const keyforTimeLabel = m.format('ddd-HH');
                const key = 'SHARED:' + it.events[0].user_metas.email;
                this.perUserLabel.set(keyforTime, keyforTimeLabel);
                H.incrStatMap(this.perUser, key);
                H.incrStatMap(this.perHour, keyforTime);
            }
        });

        const pieDataPubOk = JSON.parse(JSON.stringify(this.pieData));
        const pieDataREADY = JSON.parse(JSON.stringify(this.pieData));
        const pieDataSHARED = JSON.parse(JSON.stringify(this.pieData));
        this.perUser.forEach((v, k) => {
            const chunks = k.split(':');
            const type = chunks[0];
            const user = H.getFirstNameFromEmail(chunks[1]);
            const userLabel = user + ' (' + v + ')';
            this.userLabelToEmailMap.set(userLabel, chunks[1]);
            // this.colorsMap[user] = '#9823af';
            if (type === "PUB_OK") {
                pieDataPubOk[0].values.push(v);
                pieDataPubOk[0].labels.push(userLabel);
                pieDataPubOk[0].marker.colors.push(this.colorsMap[user]);
            }
            if (type === "READY") {
                pieDataREADY[0].values.push(v);
                pieDataREADY[0].labels.push(userLabel);
                pieDataREADY[0].marker.colors.push(this.colorsMap[user]);
            }
            if (type === "SHARED") {
                pieDataSHARED[0].values.push(v);
                pieDataSHARED[0].labels.push(userLabel);
                pieDataSHARED[0].marker.colors.push(this.colorsMap[user]);
            }
        });
        console.log("Color", this.colorsMap)

        this.pieLayout.title.text = "";
        Plotly.newPlot('pie-PUB_OK', pieDataPubOk, this.pieLayout).then(gd => {
            gd.on('plotly_legendclick', (e) => {
                const userMail = this.userLabelToEmailMap.get(e.label);
                this.showActivityPerUser(userMail);
                return false;
            });
        });
        Plotly.newPlot('pie-READY', pieDataREADY, this.pieLayout).then(gd => {
            gd.on('plotly_legendclick', (e) => {
                const userMail = this.userLabelToEmailMap.get(e.label);
                this.showActivityPerUser(userMail);
                return false;
            });
        });
        Plotly.newPlot('pie-SHARED', pieDataSHARED, this.pieLayout).then(gd => {
            gd.on('plotly_legendclick', (e) => {
                const userMail = this.userLabelToEmailMap.get(e.label);
                this.showActivityPerUser(userMail);
                return false;
            });
        });

        const statesLabel = ['PENDING', 'PUB_OK', 'READY'];
        statesLabel.forEach(lb => {
            const reportsPublished = this.reportItems.filter(it => it._log[lb] !== undefined);
            const gaugeData = JSON.parse(JSON.stringify(this.progressData));
            gaugeData[0].value = reportsPublished.length;
            this.progressGaugeLayout['title']['text'] = '';
            // Plotly.newPlot('gaugeProgress-' + lb, gaugeData, this.progressGaugeLayout);
            this.renderPlot('gaugeProgress-' + lb, gaugeData, this.progressGaugeLayout);
        });
        const reportsShared = this.reportItems.filter(it => it.events.length > 0);
        const gaugeDataShared = JSON.parse(JSON.stringify(this.progressData));
        gaugeDataShared[0].value = reportsShared.length;
        this.progressGaugeLayout['title']['text'] = "";

        this.renderPlot('gaugeProgress-SHARED', gaugeDataShared, this.progressGaugeLayout);
        // Plotly.newPlot('gaugeProgress-SHARED', gaugeDataShared, this.progressGaugeLayout);

        this.plotHourlyStats();

        console.log("applyFilters:this.perUser", this.perUser);
    }

    showActivityPerUser(userEmail: string) {
        this.selectedReport = null;
        this.selectedUserEmail = userEmail;
        this.reportsOfSelectedUser = this.reportsToShow.filter(it => {
            const objStr = JSON.stringify(it);
            return objStr.includes(userEmail);
        }).sort((a, b) => a.domain_ref.localeCompare(b.domain_ref));
        console.log("showActivityPerUser---------------------", userEmail, this.reportsToShow, this.reportsOfSelectedUser);
    }

    plotHourlyStats() {
        this.perDayBlocEvent = new Map<string, number>();
        const hourlyGen = JSON.parse(JSON.stringify(this.barDataItem));
        const hourlyPubOk = JSON.parse(JSON.stringify(this.barDataItem));
        const hourlyPubReady = JSON.parse(JSON.stringify(this.barDataItem));
        const hourlyPubShared = JSON.parse(JSON.stringify(this.barDataItem));
        hourlyGen.name = "Publication";
        hourlyPubOk.name = "Validation";
        hourlyPubReady.name = "Prêt au partage";
        hourlyPubShared.name = "Partage";

        hourlyGen.marker.color = '#444555';
        hourlyPubOk.marker.color = '#66cc33';
        hourlyPubReady.marker.color = '#3355ff';
        hourlyPubShared.marker.color = '#ff0000';

        const ex = {
            "PENDING": 1713946518,
            "PUB_OK": 1713946653,
            "READY": 1713972487,
            "SHARED": 1713972712
        };

        this.reportItems
            .sort((a, b) => a.date_create_ts - b.date_create_ts)
            .forEach(it => {
                this.countEvents(it, 'PENDING');
                this.countEvents(it, 'PUB_OK');
                this.countEvents(it, 'READY');
                this.countEvents(it, 'SHARED');
            });
        console.log("----this.perDayBlocEvent", this.perDayBlocEvent)
        let xCurrent = 0;
        for (let d = 1; d <= 6; d++)
            for (let bloc = 1; bloc <= 3; bloc++) {
                hourlyGen.x.push(xCurrent);
                hourlyPubOk.x.push(xCurrent);
                hourlyPubReady.x.push(xCurrent);
                hourlyPubShared.x.push(xCurrent);
                let blocLabel = '';
                if (bloc === 1) blocLabel = '6 à 12h';
                if (bloc === 2) blocLabel = '12 à 18h';
                if (bloc === 3) blocLabel = '18 à 24h';
                this.perHourLayout.xaxis.labelalias[xCurrent] = this.daysMap[d] + ' ' + blocLabel;

                const keyReportGen = d + '_' + bloc + '_PENDING';
                const keyReportVal = d + '_' + bloc + '_PUB_OK';
                const keyReportReady = d + '_' + bloc + '_READY';
                const keyReportShared = d + '_' + bloc + '_SHARED';
                if (this.perDayBlocEvent.has(keyReportGen))
                    hourlyGen.y.push(this.perDayBlocEvent.get(keyReportGen));
                else
                    hourlyGen.y.push(0);


                if (this.perDayBlocEvent.has(keyReportVal))
                    hourlyPubOk.y.push(this.perDayBlocEvent.get(keyReportVal));
                else
                    hourlyPubOk.y.push(0);

                if (this.perDayBlocEvent.has(keyReportReady))
                    hourlyPubReady.y.push(this.perDayBlocEvent.get(keyReportReady));
                else
                    hourlyPubReady.y.push(0);

                if (this.perDayBlocEvent.has(keyReportShared))
                    hourlyPubShared.y.push(this.perDayBlocEvent.get(keyReportShared));
                else
                    hourlyPubShared.y.push(0);

                xCurrent++;
            }


        Plotly.newPlot('scatterPlot', [hourlyGen, hourlyPubOk, hourlyPubReady, hourlyPubShared], this.perHourLayout, this.myapp.k.defaultConfig);
        console.log("plotHourlyStats", this.perHourLayout, hourlyGen, hourlyPubOk, hourlyPubReady, hourlyPubShared);
    }

    countEvents(reportPublish: ReportPublish, name: string) {
        if (reportPublish._log[name]) {
            const momGen = moment(reportPublish._log[name] * 1000);
            const day = momGen.isoWeekday();
            const h = momGen.hour();
            let bloc = 0;

            if (h < 12 && h >= 6) bloc = 1;
            if (h < 18 && h >= 12) bloc = 2;
            if (h < 24 && h >= 18) bloc = 3;
            const key = day + '_' + bloc + '_' + name;
            this.perDayBlocEvent = H.incrStatMap(this.perDayBlocEvent, key);
        }
    }

    plotClic(ev) {
        let week = 1;
        if (ev['points'] && ev['points'][0] && ev['points'][0].x) {
            week = ev['points'][0].x;
            this.selectWeek(week);
        }
    }

    ngOnDestroy() {
    }

    renderPlot(divID: string, data, layout) {
        if (this.renderedDivIds[divID]) {
            console.log("Animating", divID)
            Plotly.animate(divID, {
                data: data,
                traces: [0],
                layout: layout
            }, {
                transition: {
                    duration: 500,
                    easing: 'cubic-in-out'
                },
                frame: {
                    duration: 500
                }
            });
        } else {
            this.renderedDivIds[divID] = true;
            Plotly.newPlot(divID, data, layout);
        }
    }

    getFirstName(email: string) {
        return H.getFirstNameFromEmail(email);
    }
}

<mat-toolbar class="topbar mat-bg-card">
    <!-- Sidenav toggle button -->
    <button
            *ngIf="layoutConf.sidebarStyle !== 'compact'&&myapp.isAdminOrSuperUser"
            mat-icon-button
            id="sidenavToggle"
            (click)="toggleSidenav()"
            matTooltip="Toggle Hide/Open"
    >
        <mat-icon>menu</mat-icon>
    </button>

    <div fxFlex="370px" class="site-name" [routerLink]="['/sites']">
        <img src="assets/images/enerplan.svg" alt="" class="app-logo">
        <span *ngIf="site.clientSite"> {{ site.clientSite.name }}</span>
        <span *ngIf="!site.clientSite"> Selectionner un site</span>
    </div>

    <div fxFlex fxLayout="row" class="top-nav" fxLayoutAlign="start center">
        <button mat-menu-item
                [title]="router.url+'/'+nav.link"
                [ngClass]="{active:router.url==='/'+nav.link}"
                *ngFor="let nav of topLinks" [routerLink]="['/'+nav.link]">
            <mat-icon>{{ nav.icon }}</mat-icon>
            <span>{{ nav.label }}</span>
        </button>
    </div>

    <span fxFlex="100px" class="version">ver: {{ environment.ver }}</span>
    <span fxFlex>
        <ng-container *ngIf="myapp.isAdmin">
              <span class="text-muted color-gray">{{ myapp.appInitStatus|async }}</span> -
        <span class="text-muted color-gray">{{ site.siteLoadingStatus|async }}</span>
        </ng-container>

    </span>
    <!-- Top left user menu -->
    <button mat-icon-button
            [matMenuTriggerFor]="accountMenu"
            class="topbar-button-right img-button">
        <ngx-avatar [name]="myapp.user_display_name_short" size="40"></ngx-avatar>
    </button>

    <mat-menu #accountMenu="matMenu">
        <button mat-menu-item [routerLink]="['/site-home']">
            <mat-icon>home</mat-icon>
            <span>Tableau de bord</span>
        </button>
        <button mat-menu-item [routerLink]="['/sites']">
            <mat-icon>settings</mat-icon>
            <span>Choix du bâtiment</span>
        </button>
        <button mat-menu-item (click)="myapp.logout()">
            <mat-icon>logout</mat-icon>
            <span>Deconnexion</span>
        </button>
        <!--
        <button mat-menu-item (click)="auth.doLogout()">
            <mat-icon>exit_to_app</mat-icon>
            <span>{{ "SIGNOUT"   }}</span>
        </button>--->
    </mat-menu>
</mat-toolbar>

import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {AppService} from "../../shared/services/app.service";
import {ApiService} from "../../shared/services/api.service";
import {SiteService} from "../../shared/services/site.service";
import {DatePipe} from "@angular/common";
import {deburr} from 'lodash';
import moment from "moment";
import {Action} from "../../shared/models/Action.model";

@Component({
    selector: 'actions-print-preview',
    template: `
        <div fxLayout="row" fxLayoutAlign="space-between center" style="background: #fafafa;">
            <button fxFlex="120px" (click)="getPdf()" mat-raised-button class="btn-xs-25 m-4">Générer le pdf</button>
            <mat-icon *ngIf="printLoading">hourglass_empty</mat-icon>
            <a class="color-blue font-weight-bold"
               *ngIf="linkToPdf" target="_blank" [href]="linkToPdf">
                <mat-icon style="font-size: 30px;margin: -10px 0 -10px 5px;color: #d9453d" inline>picture_as_pdf
                </mat-icon>
                Lien vers le pdf: [{{ fileName }}]</a>
            <div fxFlex></div>
        </div>
        <div id="body" #rawTable>
            <table>
                <tr *ngFor="let f of filtersMap|keyvalue">
                    <ng-container *ngIf="filtersMultipleValue.includes(f.key)">
                        <td class="m-8 color-blue">{{ extracolsLabel[f.key] }}:</td>
                        <td class="actions-filter-chips">{{ chaptersMap.get(f.value) }}</td>
                        <td *ngIf="f.key!=='chapterId'" class="actions-filter-chips">{{ f.value }}</td>
                    </ng-container>
                    <ng-container *ngIf="!filtersMultipleValue.includes(f.key)">
                        <td colspan="2" class="actions-filter-chips">{{ extracolsLabel[f.key] }}:</td>
                    </ng-container>
                </tr>
            </table>
            <table class="full-width action-print-table">
                <thead>
                <tr class="head">
                    <td class="bord-t cell-action-num">Num</td>
                    <td class="bord-t td-big-text">Description/Constat</td>
                    <td class="bord-t td-auto">Solution/Proposition</td>
                    <td class="cell-priority ">Statut/Priorité</td>
                    <td class="cell-fluid" *ngFor="let fl of availableFluids"> {{ myapp.k.FluidsLabel[fl] }}</td>
                    <td class="cell-economy-energy">Économies</td>
                    <td class="cell-economy">Invest(Inv.) /Payback(Pb.)</td>
                    <td class="cell-date">Dates</td>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let act of actions">
                    <tr class="" [ngClass]="act.status">
                        <td class="cell-action-num">
                            <div>
                                <label>N° {{ act.num }}</label>
                            </div>
                        </td>
                        <td class="col1-title-desc">
                            <div>
                                <b class="color-blue" *ngIf="act.uid_chapter">
                                    <ng-container *ngIf="site.chaptersMap.get(act.uid_chapter) as ch">
                                        [{{ ch.title }}]
                                    </ng-container>
                                </b>

                                <i class="is-ref" *ngIf="act.is_ref">[Réf]</i>
                                <b>{{ act.title }}</b>
                                <div [innerHTML]="act.description|nl2br"></div>
                                <div class="remarque" *ngIf="act.note">
                                    <b>Remarques: </b> <i [innerHTML]="act.note|nl2br"></i>
                                </div>
                                <div class="Documents">
                                    <div *ngFor="let doc of act.attachments">
                                        <span *ngIf="site.getDocByPath(doc) as d">Annexe: {{ d.title }}</span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="bord-t td-auto">
                            <div [innerHTML]="act.solution|nl2br"></div>
                        </td>
                        <td class="cell-priority">
                            <div>
                                <div>
                                    <mat-icon class="text-green"> {{ myapp.k.StatusIcon[act.status] }}</mat-icon>
                                    {{ myapp.k.StatusDisplayName[act.status] }}
                                </div>
                                <div>
                                    <mat-icon mat-sm-chip [style]="{color:myapp.k.PriorityColor[act.priority]}">
                                        flag_circle
                                    </mat-icon>
                                    {{ myapp.k.PriorityDisplayName[act.priority] }}
                                </div>
                            </div>
                        </td>
                        <td class="cell-fluid fluid-{{fl}}" *ngFor="let fl of availableFluids">
                            <span class="val" *ngIf="act._econsByFluid[fl]">{{ act._econsByFluid[fl] }} </span>
                            <span class="unit" *ngIf="act._unitByFluid[fl]"> {{ act._unitByFluid[fl] }}</span>
                        </td>

                        <td class="cell-economy-energy" [ngClass]="act.status">
                            <div>
                                <table class="inner-table-econ-energy">
                                    <tr>
                                        <td class="val">
                                            <ng-container *ngIf="act.economies_total&&act.economies_total>0">
                                                <span>{{ act.economies_total|number:"1.0-0" }} </span>
                                            </ng-container>
                                            <span *ngIf="!act.economies_total">0</span>
                                        </td>
                                        <td>
                                            Fr.
                                        </td>
                                    </tr>
                                    <tr *ngFor="let econ of act.economies" [ngClass]="econ.fluid">
                                        <td class="val" (click)="myapp.showConsole(econ)">
                                            <span *ngIf="getTarifObj(econ.uid_tariff) as t">
                                            {{ econ.amount(t)|number }}
                                            </span>
                                            <span *ngIf="!getTarifObj(econ.uid_tariff) ">
                                            {{ econ.econEstimated|number }}
                                            </span>
                                        </td>
                                        <td class="w30" *ngIf="getTarifObj(econ.uid_tariff) as t">
                                            {{ t.renderAmount(econ.value).unit }}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </td>
                        <td class="cell-economy">
                            <div>
                                <div>
                                    <label>Inv. Tot:</label>
                                    <ng-container *ngIf="act.investment&&act.investment>0">
                                        <span class="unit">Fr.</span>
                                        {{ act.investment|number:"1.0-0" }}
                                    </ng-container>
                                    <span *ngIf="!act.investment">Fr. 0 </span>
                                </div>
                                <div>
                                    <label>Inv. Net:</label>
                                    <ng-container *ngIf="act.investment_real&&act.investment_real>0">
                                        <span class="unit">Fr. </span>
                                        <span>{{ act.investment_real|number:"1.0-0" }} </span>
                                    </ng-container>
                                    <span *ngIf="!act.investment_real">Fr. 0 </span>
                                </div>
                                <div *ngIf="act.investment&&act.investment>0">
                                    <label>Pb. Brut: </label>{{ act.paybackBrut| number: '1.0-2' }} ans<br>
                                    <label>Pb. Net: </label>{{ act.payback| number: '1.0-2' }} ans
                                </div>

                            </div>
                        </td>
                        <td class="cell-date">
                            <table>
                                <tr>
                                    <td class="col1">P</td>
                                    <td>{{ act.dateProposed }}</td>
                                </tr>
                                <tr>
                                    <td class="col1">D</td>
                                    <td>{{ act.dateDue }}</td>
                                </tr>
                                <tr>
                                    <td class="col1">T</td>
                                    <td>{{ act.dateCompleted }}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                </ng-container>
                </tbody>
                <tfoot>
                <tr>
                    <td colspan="7"></td>
                </tr>
                </tfoot>
            </table>
        </div>
    `,
    styleUrls: ['./actions-subs.component.scss']
})
export class ActionPrintComponent implements OnInit {
    @Input('chaptersMap') chaptersMap: Map<string, string>;
    @Input('availableFluids') availableFluids: string[];
    @Input('extracolsLabel') extracolsLabel: any;
    @Input('filtersMultipleValue') filtersMultipleValue: string[];
    @Input('filtersMap') filtersMap: Map<string, any>;
    @Input('actions') actions: Action[];
    @ViewChild('rawTable') rawTable: ElementRef;
    @Output('cb') cb: EventEmitter<any> = new EventEmitter<any>();
    linkToPdf: string;
    fileName: string;
    private _datePipe: DatePipe;
    public checked = false;
    public printLoading = false;

    constructor(public myapp: AppService,
                public api: ApiService,
                public site: SiteService,) {
    }

    /*
    this.act.attachments.map(it => {
                                    this.getDocByPath(it)
                                    });
     */
    ngOnInit(): void {
    }

    getTarifObj(tarifUID: string) {
        return this.site.getTariffObj(tarifUID);
    }

    getPdf() {
        if (!this.rawTable) {
            return;
        }

        const blocsRawHtml = this.rawTable.nativeElement;
        console.log("getPdf", blocsRawHtml.outerHTML);
        //   console.log("getPdf", JSON.stringify(this.decycle(blocsRawHtml)));
        // console.log("getPdf", this.objToString(blocsRawHtml));
        this.fileName = null;
        this.linkToPdf = null;
        const b64Html = ApiService.bs64FromRawStr(blocsRawHtml.outerHTML);
        const title = deburr(this.site.clientSite.ref) + ' - Liste d\'actions';
        // const title = deburr(this.site.clientSite.ref) + ' - Liste d\'actions - ' + moment().format('YYYY-MM-DD HH:mm');
        this.printLoading = true;
        this.site.api.printActions(b64Html, title).subscribe(resp => {
            this.printLoading = false;
            this.fileName = resp.filename;
            this.linkToPdf = this.site.api.getLink('show-printed-actions?f=' + resp.filename, true);
            console.log("respPrint", resp);
        });
    }
}

<div class="flex-container" fxLayout="column" style="height: 100%" #plotHolder>

    <div fxLayout="column" class="toolbar-top" style="height: auto" [@animate]="{ value: '*', params: { y: '-190px', delay: '300ms' } }">
        <h2 fxFlex class="ml-16">
            Bilan des économies et pertes énergétiques et financières
        </h2>
        <div fxLayout="row" style="height: 50px;position: relative;margin-bottom: -5px" fxFlexAlign="space-between end">

            <mat-form-field fxFlex="200px" style="height: 50px;margin: 0 15px ">
                <mat-select placeholder="Selectionner l'année" [value]="selectedYear">
                    <mat-option (click)="selectYear(y)" [value]="y" *ngFor="let y of availableYears">{{y}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="200px" style="height: 50px;margin: 0 15px ">
                <mat-select [value]="groupRowsByValue">
                    <mat-option (click)="groupRowsBy('fluid')" [value]="'fluid'">Grouper par fluide</mat-option>
                    <mat-option (click)="groupRowsBy('place')" [value]="'place'">Grouper par emplacement</mat-option>
                </mat-select>
            </mat-form-field>


            <div fxFlex>
                <button *ngIf="selectedMonth!==undefined&&selectedYear" class="m-4"
                        (click)="noteEditMode=true" mat-raised-button>
                    <span *ngIf="noteText===''">Ajouter</span>
                    <span *ngIf="noteText!==''">Editer</span>
                    note pour le
                    <b class="color-blue">{{selectedMonth + 1}}.{{selectedYear}}</b>
                </button>
            </div>
            <div *ngIf="selectedYear" style="height: 40px;vertical-align: bottom;margin-top: 8px;">
                <button mat-button *ngFor="let m of months;let i=index;" class="btn-tab" [ngClass]="{active:selectedMonth===i}"
                        [matBadge]="commentCountPendingPerMonth[i.toString()]||null"
                        matBadgePosition="after" matBadgeColor="accent" matBadgeSize="small" matBadgeOverlap="true"
                        (click)="selectMonthIndex(i)">
                    {{m.toUpperCase() + ' ' + selectedYear}}
                </button>
            </div>
            <span class="vert-divider"></span>

            <button class="mt-8" mat-button (click)="toggleSideBar()" *ngIf="selectedYearlyPdmConfig">
                <mat-icon class="color-blue">comment</mat-icon>
            </button>

        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex="">
        <mat-sidenav-container fxFlex fxFlexFill [hasBackdrop]="false">

            <mat-sidenav #sidenav [mode]="'side'" [position]="'end'" style="width: 290px" fxLayout="column" (openedChange)="sideNavListener($event)">

                <div fxLayout="row" class="sidenav-head">
                    <div *ngIf="sideNavMode==='comment'">Commentaires</div>
                    <div *ngIf="sideNavMode==='addAnnotation'">Commenter cette ligne</div>
                    <div fxFlex=""></div>
                    <button class="btn-xs" mat-stroked-button color="primary" (click)="sidenav.toggle()">
                        Fermer
                    </button>
                </div>

                <!--Add comment form panel-->
                <div *ngIf="sideNavMode==='addAnnotation'" fxLayout="column" fxLayoutAlign="space-between">
                    <mat-divider></mat-divider>
                    <div class="p-4 mat-card" fxLayout="column" fxLayoutAlign=" end">
                        <mat-button-toggle-group fxLayout="row" class="full-width m-4">
                            <mat-button-toggle (click)="annotationPriority='NORMAL'" fxFlex="33" [checked]="annotationPriority==='NORMAL'">
                                Normal
                            </mat-button-toggle>
                            <mat-button-toggle (click)="annotationPriority='MEDIUM'" fxFlex="33" [checked]="annotationPriority==='MEDIUM'">
                                Moyen
                            </mat-button-toggle>
                            <mat-button-toggle (click)="annotationPriority='HIGH'" fxFlex="33" [checked]="annotationPriority==='HIGH'">
                                haut
                            </mat-button-toggle>
                        </mat-button-toggle-group>

                        <mat-form-field class="full-width p-4" appearance="fill">
                            <mat-label>Commenter cette ligne</mat-label>
                            <textarea matInput (keyup)="cleanCommentText()" [(ngModel)]="annotationText" placeholder="Texte  ici..."></textarea>
                        </mat-form-field>

                        <div fxLayout="row" class="full-width m-4">
                            <button class="ml-8" mat-raised-button (click)="sideNavMode='comment'">Annuler</button>
                            <div fxFlex=""></div>
                            <button-loading [loading]="annotationSavingInProgress"
                                            class="mb-4 btn-xs-25"
                                            color="primary" (click)="insertComment()">
                                Sauvegarder
                            </button-loading>
                        </div>

                    </div>

                </div>

                <!--Comments-list -->
                <mat-list *ngIf="sideNavMode==='comment'&&selectedYearlyPdmConfig" fxLayout="column" fxLayoutAlign="space-between">
                    <mat-button-toggle-group fxLayout="row" class="full-width m-4">
                        <mat-button-toggle (click)="annotationFilter='ALL'" fxFlex="50" [checked]="annotationFilter==='ALL'">
                            Tout
                        </mat-button-toggle>
                        <mat-button-toggle (click)="annotationFilter='PENDING'" fxFlex="50" [checked]="annotationFilter==='PENDING'">
                            A traiter
                        </mat-button-toggle>
                        <mat-button-toggle (click)="annotationFilter='COMPLETED'" fxFlex="50" [checked]="annotationFilter==='COMPLETED'">
                            Traités
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                    <ng-container *ngFor="let comment of commentsForSelectedStatus">
                        <mat-card [id]="'comment-'+comment.uid" class="p-8"
                                  *ngIf="comment.id4===selectedYearlyPdmConfig.uid_pdm">
                            <mat-card-title style="font-size: 12px;" class="color-blue">
                                {{comment.display_name }}
                                <span fxFlex=""></span>
                                <mat-checkbox
                                        [checked]="comment.status==='COMPLETED'"
                                        (change)="setCommentStatus(comment.uid,$event.checked)"
                                        name="agreed"
                                        class="">
                                </mat-checkbox>
                            </mat-card-title>
                            <mat-card-content>

                                <span [innerHTML]="comment.comment"></span>
                                <div fxLayout="row" class="text-muted " fxLayoutAlign="space-between center">
                                    <small class=""> {{comment.ts_created  | date}}  </small>

                                    <div fxflex></div>
                                    <small class="text-green cursor-pointer" (click)="pan.toggle()" *ngIf="comment.replies">
                                        {{comment.replies ? comment.replies.length : 0}} réponse(s)
                                    </small>
                                    <span class="text-green cursor-pointer" (click)="commentReplyMode=true;pan.open();">Répondre</span>
                                </div>

                                <mat-expansion-panel #pan style="elevation: 0" [expanded]="false">

                                    <div *ngIf="comment.replies" class="comment-reply-bloc">
                                        <div *ngFor="let rep of comment.replies">
                                            <div class="reply-bloc">
                                                <div style="width: 100%">
                                                    <b>{{rep['name']}}</b> |
                                                    <small class="text-muted">{{rep['timestamp'] | date}}</small>
                                                </div>
                                                {{ rep['message']}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="comment-reply-form">

                                        <div *ngIf="commentReplyMode" fxLayout="column">
                                            <mat-divider></mat-divider>

                                            <mat-form-field class="full-width p-4" appearance="fill">
                                                <mat-label>Votre réponse</mat-label>
                                                <textarea matInput [(ngModel)]="replyText"
                                                          placeholder="Texte  ici..."></textarea>
                                            </mat-form-field>

                                            <div fxLayout="row">
                                                <button class="btn-xs" mat-stroked-button (click)="commentReplyMode=false">Annuler</button>
                                                <div fxFlex></div>
                                                <button-loading
                                                        [btnClass]="'btn-xs mat-stroked-button'"
                                                        [loading]="commentSavingInProgress"
                                                        [raised]="false"
                                                        color="primary"
                                                        (click)="sendReply(comment.uid)">Sauvegarder
                                                </button-loading>
                                            </div>
                                        </div>

                                        <div fxLayout="row" *ngIf="!commentReplyMode">
                                            <button class="btn-xs" mat-stroked-button (click)="pan.close()">Fermer</button>
                                            <div fxFlex></div>
                                            <button class="btn-xs" mat-stroked-button (click)="commentReplyMode=true">Répondre</button>
                                        </div>
                                    </div>

                                </mat-expansion-panel>
                            </mat-card-content>
                        </mat-card>
                    </ng-container>
                    <div class="p-8" *ngIf="commentsForSelectedStatus&&commentsForSelectedStatus.length>0">
                        <button mat-raised-button color="primary" class="btn-xs-25" (click)="showAnnotationOnChart()">Afficher les annotations</button>
                    </div>

                    <div *ngIf="commentsForSelectedStatus.length===0" fxFlex="50"
                         class="p-16 m-16 border font-weight-bold color-blue">Pas de commentaire ds
                        cette section
                    </div>
                    <div fxFlex></div>
                    <div fxFlex>
                        <button (click)="togleCommentMode()" mat-raised-button color="primary" class="m-8">Commenter</button>
                    </div>

                </mat-list>


            </mat-sidenav>

            <mat-sidenav-content>
                <div fxLayout="row">
                    <div fxFlex="1100px">

                        <mat-card class="p-4" *ngIf="!noteEditMode&&noteText!==''" (dblclick)="noteEditMode=true">
                            {{noteText}}
                        </mat-card>

                        <mat-card class="p-4" *ngIf="noteEditMode">
                            <mat-form-field class="full-width p-4" appearance="fill">
                                <mat-label>Note pour le mois selectionné</mat-label>
                                <textarea matInput [(ngModel)]="noteText" placeholder="Note pour le mois selectionné"></textarea>
                            </mat-form-field>
                            <div fxLayout="row">
                                <button mat-raised-button (click)="noteEditMode=false">Annuler</button>
                                <div fxFlex=""></div>
                                <button mat-raised-button color="primary" (click)="saveNoteForMonth()">Sauvegarder</button>
                            </div>
                        </mat-card>


                        <mat-card class="p-4" *ngFor="let fluid of site.availableFluids;let i=index"
                                  [@animate]="{ value: '*', params: { y: '390px', delay: (300+i*200)+'ms' } }">
                            <mat-card-header>
                                <mat-card-title [title]="fluid">
                                    {{fluid}}
                                </mat-card-title>
                            </mat-card-header>
                            <div class="bloc-pdm-calc" fxLayout="column">
                                <div class="full-width summary-head" fxLayout="row">
                                    <div [fxFlex]="colWidth[0]">Catégorie</div>
                                    <div [fxFlex]="colWidth[1]">Évolution conso. corrélée</div>
                                    <div [fxFlex]="colWidth[2]">Évolution corr. en Fr.</div>
                                    <div [fxFlex]="colWidth[3]">Cumul</div>
                                    <div [fxFlex]="colWidth[4]">Coûts cumulés</div>
                                    <div [fxFlex]="colWidth[5]">Tarif moy.</div>
                                    <div [fxFlex]="colWidth[6]">mois.année / ref.</div>
                                </div>
                            </div>

                            <ng-container *ngFor="let pdmConfigForYear of selectedFinalPdmsDataArray;let i=index;">
                                <row-item
                                        *ngIf="pdmConfigForYear.fluid===fluid"
                                        [year]="pdmConfigForYear.year"
                                        [colWidth]="colWidth"
                                        [commentsStats]="{pending:commentCountPendingPerPdmAndMonth[pdmConfigForYear.uid_pdm+selectedMonth],total:commentCountTotalPerPdmAndMonth[pdmConfigForYear.uid_pdm+selectedMonth]}"
                                        [yearRef]="pdmConfigForYear.year_ref"
                                        [month]="selectedMonth"
                                        (callback)="doFromRow($event)"
                                        [dataItem]="pdmConfigForYear"
                                        [selectedDataItem]="selectedYearlyPdmConfig"
                                ></row-item>
                            </ng-container>
                        </mat-card>
                    </div>

                    <mat-card class="p-4">
                        <div class="tabs-holder">
                            <button mat-button *ngFor="let key of plotKeys" class="btn-tab" (click)="loadDataIntoPlot(key)"
                                    [ngClass]="{active:selectedKey===key}">{{key}}</button>
                            <div>{{withCategories}}</div>
                        </div>

                        <highcharts-chart
                                *ngIf="optimiseChart&&withCategories"
                                [Highcharts]="Highcharts"
                                [oneToOne]="false"
                                [options]="chartOptions"
                                [(update)]="updateFlag"
                                (chartInstance)="onChartInstance($event)"
                                style="width: 950px; height: 550px; display: block;margin: 5px">
                        </highcharts-chart>

                        <!--
                        1 instance per config. xSeries.category doesnt update on change config
                        <statmens-preview [historyCount]="historyCount"></statmens-preview>
                        -->
                        <highcharts-chart
                                *ngIf="optimiseChart&&chartOptions&&!withCategories"
                                [oneToOne]="false"
                                [Highcharts]="Highcharts"
                                [options]="chartOptions"
                                [(update)]="updateFlag"
                                (chartInstance)="onChartInstance($event)"
                                style="width: 100%; height: 650px; display: block;margin: 5px">
                        </highcharts-chart>
                    </mat-card>

                </div>

            </mat-sidenav-content>

        </mat-sidenav-container>

    </div>

</div>


import {H} from "../helpers/H";
import moment from "moment/moment";
import {Optimise} from "../helpers/Optimise";
import {K} from "./K";
import {StorageDocument, UserMini} from "./models";

export class InfluenceFactorConfig {
    uid: string;
    unit: string;
    name: string;
    description: string;
    startYear: number;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
    }
}

export class InfluenceFactorValue {
    value: number;
    parent: string;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
    }
}

export class SuiviDocument {
    uid: string;
    full_path: string;
    section: string;
    ts: number = 0;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
        if (!this.ts) this.ts = H.unixTs();
    }
}

export class ReportRevision {
    message: string;
    status: string;
    version: number;
    ts: number;
    user: UserMini;
    path_report: string;// path to generated pdf
    path_full: string;// path to generated pdf with annexes
    page_count_main: number;
    page_count_full: number;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
        if (this.user) this.user = new UserMini(this.user);
    }

    get isSubmission() {
        return this.status && this.status === "SUBMITTED";
    }

    get date() {
        return H.dateToStr(new Date(this.ts * 1000), false);
    }

    get dateFull() {
        moment().locale('fr');
        const oldWinnerMoment = moment(this.ts * 1000);
        return oldWinnerMoment.format('LLL');
    }
}

export class ReportSuivi {
    uid: string;
    uid_parent: string;
    uid_site: string;
    uid_user: string;

    infos: ReportSuiviInfos;
    note_factors: any;
    note_goals: any;
    note_previsions: any;
    note_updates: any;
    notes: any;

    notes_tariffs: any;// not md, plain text with bmens_data uid
    notes_meters: any;// not md, plain text with bmens_data uid
    notes_md: string;
    notes_factors_md: string;
    notes_goals_md: string;
    notes_previsions_md: string;
    notes_updates_md: string;

    status: string;
    type: string;//REF, HIDDEN_REF_DATA, SUIVI_FIRST
    ts_created: number;
    ts_updated: number;
    goal: number;
    year: number;
    year_offset: number;
    year_start: number;

    influence_factors: any;
    influence_factors_config: InfluenceFactorConfig[] = [];
    revisions: ReportRevision[];
    validation: ReportRevision[];//new column that does same work as revisions, but with new plateform
    documents: SuiviDocument[];
    initialized = 0;
    dateFull: string;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
        if (!this.notes_meters) this.notes_meters = {};
        if (this.infos) this.infos = new ReportSuiviInfos(this.infos);
        else this.infos = new ReportSuiviInfos({});
        if (this.documents && this.documents.length > 0)
            this.documents = this.documents.map(it => new StorageDocument(it));

        if (!this.influence_factors) this.influence_factors = {};
        if (this.influence_factors_config && this.influence_factors_config.length > 0)
            this.influence_factors_config = this.influence_factors_config.map(it => new InfluenceFactorConfig(it));
        else
            this.influence_factors_config = [];

        if (this.revisions && this.revisions.length > 0) {
            this.revisions = this.revisions.map(it => new ReportRevision(it));
            this.revisions = Optimise.sortArrayByKeyVal(this.revisions, 'ts').reverse();
        }
        if (this.validation && this.validation.length > 0) {
            this.validation = this.validation.map(it => new ReportRevision(it));
            this.validation = Optimise.sortArrayByKeyVal(this.validation, 'ts').reverse();
        }
        this.initialized = 1;
        moment().locale('fr');
        const createdMomObj = moment(this.ts_created * 1000);
        const updatedMomObj = moment(this.ts_updated * 1000);
        if (this.ts_updated > 0)
            this.dateFull = updatedMomObj.format('LLL');
        else this.dateFull = createdMomObj.format('LLL');
    }

    get isValidated(): boolean {
        return (this.revisions && this.revisions.length > 0 && this.revisions.some(it => it.status === "VALIDATED")) ||
            (this.validation && this.validation.length > 0 && this.validation.some(it => it.status === "VALIDATED"));
    }

    get isSubmitted(): boolean {
        return this.validation && this.validation.length > 0;
    }

    get nextRevisionVersion(): number {
        if (!this.validation || !this.validation.length) return 1;
        const submissionsItems = this.validation.filter(it => it.status === "SUBMITTED");
        return submissionsItems.length + 1;
    }

    get latestRevisionVersion(): number {
        if (!this.validation || !this.validation.length) return 1;
        const submissionsItems = this.validation.filter(it => it.status === "SUBMITTED");
        return submissionsItems.length;
    }

    get isRef() {
        return this.type === 'REF';
    }

    get isHidden() {
        return this.type === 'HIDDEN_REF_DATA';
    }

    get oldUrl() {
        let url = `https://optimise.enerplan.ch/d/${this.uid_site}/grands-consommateurs/situation-de-reference/${this.uid}`;
        if (!this.isRef)
            url = `https ://optimise.enerplan.ch/d/${this.uid_site}/grands-consommateurs/suivi-iteratif/${this.uid}`;
        return url;
    }

    getInfoFields() {
        return Object.keys(this.infos.suiviRefFields);
        // if (this.isRef) return Object.keys(this.infos.suiviRefFields);
        // else return this.infos.suiviIteratifFields;
    }
}

export class ReportSuiviInfos {
    public static TYPE_REF = "TYPE_REF";
    public static TYPE_ITERATIF = "TYPE_ITERATIF";
    no_ide: string;
    type_engagement: string;
    nb_sites: number;
    code_noga: string;
    no_dossier: string;
    no_egid: string;
    aLattentionDe: string;
    raison_social: string;
    address_contact: string;
    referent_externe: string;
    referent_interne: string;
    year: number;
    year_start: number;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
    }

    labelForField(field: string) {
        return K.InfoFieldDisplayName[field];
    }

    get suiviRefFields() {
        return {
            no_ide: 'input',
            // type_engagement: 'select:COC,ACE,RAO',
            nb_sites: 'input', code_noga: 'input', no_dossier: 'input',
            aLattentionDe: 'textarea', raison_social: 'textarea', address_contact: 'textarea', no_egid: 'textarea',
            referent_externe: 'textarea', referent_interne: 'textarea',
        };
    }

    get suiviIteratifFields() {
        return {
            no_ide: 'input',
            // type_engagement: 'select:COC,ACE,RAO',
            nb_sites: 'input', code_noga: 'input', no_dossier: 'input',
            aLattentionDe: 'textarea', raison_social: 'textarea', address_contact: 'textarea', no_egid: 'textarea',
            referent_externe: 'textarea', referent_interne: 'textarea',
        };
    }

    // get suiviIteratifFields() {
    //     return ['referent_externe', 'referent_interne'];
    // }
}

import {Component, OnInit} from '@angular/core';
import {AppService} from "../../../shared/services/app.service";
import {NavigationEnd, Router} from "@angular/router";

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

    constructor(public router: Router, public myapp: AppService) {
    }

    ngOnInit() {
        console.log('NotFoundComponent::ngOnInit');
        this.myapp.appInitStatus.subscribe(step => {
            if (step === 1 && this.myapp.currentUrl) {
                this.myapp.sendSpecialErrorReportRDB("Route not found",
                    {curr: this.myapp.currentUrl})
            }
        });

    }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {RelativeTimePipe} from './relative-time.pipe';
import {ExcerptPipe} from "./excerpt.pipe";
import {GetValueByKeyPipe} from './get-value-by-key.pipe';
import {Nl2BrPipe} from "./nl2br.pipe";
import {SafePipe} from "./safe.pipe";

const pipes = [
    RelativeTimePipe,
    ExcerptPipe,
    GetValueByKeyPipe,
    SafePipe,
    Nl2BrPipe,
]

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: pipes,
    exports: pipes
})
export class SharedPipesModule {
}

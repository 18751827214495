<!--Main bloc-->
<div fxLayout="row" *ngIf="myapp.user&&site.clientSite"
     fxLayoutAlign="space-between none">
    <mat-card fxFlex="360px" class="default m-4">
        <mat-card-title class="p-8">
            <div fxLayoutAlign="space-between center">
                <span (dblclick)="site.loadSitePdms()">Points de mesure</span>
                <span *ngIf="site.pdms" class="text-muted">({{ site.pdms.length }})</span>
                <mat-chip style="float: right" *ngIf="emptyMetas>0">Has {{ emptyMetas }} metas</mat-chip>
            </div>

        </mat-card-title>
        <mat-card-content fxLayout="column" fxLayoutAlign="start stretch" class="flex-container p-0 pb-4">
            <div class="p-8" fxFlex="25px">
                <div fxLayout="row">
                    <button mat-raised-button color="primary" fxFlex="24" class="btn-xs-25 mr-4"
                            [ngClass]="{active:site.selectedFluid===fl}"
                            (click)="site.selectFluid(fl)"
                            *ngFor="let fl of myapp.k.fluids">{{ fl }}
                    </button>
                </div>
            </div>
            <mat-list class="full-width mb-32 compact-list" fxFlex="80">
                <ng-container *ngFor="let pdm of site.pdms">
                    <mat-list-item class="full-width pt-4 pr-4 mat-list-p-0"
                                   *ngIf="pdm.fluid===site.selectedFluid"
                                   [ngClass]="{active:site.selectedPdm&&pdm.uid===site.selectedPdm.uid}"
                                   (click)="selectPdm(pdm)">
                        <icon-fluid style="width: 20px" [fluid]="pdm.fluid"></icon-fluid>
                        <div fxLayout="row" fxFlex="100">
                            <h6 class="m-0 ml-8" fxFlex="70">{{ pdm.label }}<br>
                                <span class="text-muted"><b>{{ pdm.pdm_prefix }}</b> | {{ pdm.label_place }} | {{ pdm.xls }}</span>
                            </h6>
                            <span fxFlex></span>
                            <mat-icon inline="true" *ngIf="pdm.is_vpdm" class="icon-vpdm">bluetooth_connected</mat-icon>
                            <mat-chip class="p-4 mr-4 text-small-1">{{ pdm.status }}</mat-chip>
                        </div>
                    </mat-list-item>
                </ng-container>
            </mat-list>
            <div fxFlex="100px" fxLayout="row" fxLayoutAlign="center start">
                <button mat-raised-button class="m-8 full-width" (click)="createDraftPdm(site.selectedFluid)">
                    <mat-icon inline="true">add</mat-icon>
                    {{ site.selectedFluid }}
                </button>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card class="p-0 m-4" fxFlex>
        <mat-card-content class="p-0 flex-container">
            <div class="p-8 flex-container" fxLayout="row">
                <mat-tab-group [(selectedIndex)]="myapp.pdmEditorTabIndex" *ngIf="site.selectedPdm" class="full-width">
                    <mat-tab label="Edition PDM">
                        <app-form-pdm
                                [pdm]="site.selectedPdm"
                                [regs]="regs"
                                [pdmUID]="site.selectedPdm.uid"
                                [autoFields]="autoFields"
                        ></app-form-pdm>
                        <button *ngIf="!regs||!regs.length" (click)="deletePdm()" mat-raised-button class="btn-xs">Del Pdm</button>
                    </mat-tab>
                    <mat-tab label="Edition registres" fxLayout="column">
                        <ng-template matTabContent>
                            <app-form-reg
                                    [reg]="selectedReg"
                                    [regs]="regs"
                                    [pdm]="site.selectedPdm"
                                    [ts]="site.selectedPdm.ts_update"
                                    [snifferSelectedCol]="snifferSelectedCol"
                                    (cb)="formCallBack($event)"></app-form-reg>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Paramétrage annuel" fxLayout="column">
                        <pdm-yearly-config
                                [pdm]="this.site.selectedPdm"
                                [ts]="this.site.selectedPdm.ts_update"></pdm-yearly-config>
                    </mat-tab>
                    <mat-tab label="Import de masse" fxLayout="column" *ngIf="regs.length===0">
                        <pdm-importer [pdm]="site.selectedPdm" (cb)="formCallBack($event)"></pdm-importer>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="p-4 m-4" fxFlex="520px">
        <mat-card-title (dblclick)="scrollToBottom()">
            Liste des régistres du PDM
            <button *ngIf="site.selectedPdm&&selectedReg " mat-raised-button color="primary" class="btn-xs-25 m-4 float-right"
                    (click)="selectedReg=null;myapp.pdmEditorTabIndex=1">Ajouter registre
            </button>
        </mat-card-title>
        <mat-card-content style="">
            <div style="height: 530px;overflow-y: scroll">
                <table class="numeric regs-summary">
                    <tr>
                        <td class="head" *ngFor="let f of regFieldsToDisplaySummary">
                            <span *ngIf="f!=='num'">{{ f }}</span>
                            <span *ngIf="f==='num'">Nu/Or</span>
                        </td>
                        <td class="head">Hash D</td>
                        <td class="head">Hash C</td>
                    </tr>
                    <tr *ngFor="let reg of regs" (click)="selectReg(reg)"
                        [ngClass]="{active:selectedReg&&reg.num===selectedReg.num,foreign:reg.uid_site!==site.uid}">
                        <td *ngFor="let f of regFieldsToDisplaySummary" (dblclick)="deleteReg(reg.uid)">
                            <span *ngIf="f==='num'">{{ reg[f] }}/{{ reg['disp_order'] }}</span>
                            <span *ngIf="f!=='num'">{{ reg[f] }}</span>
                        </td>
                        <td>
                            {{ reg.getTypeHash('d') }}
                        </td>
                        <td>
                            {{ reg.getTypeHash('c') }}
                        </td>
                    </tr>
                </table>

            </div>

        </mat-card-content>
    </mat-card>
</div>
<!--popup SNIFFER-->
<div *ngIf="site.selectedPdm&&selectedReg&&dbKeyName&&showPopupSniffer"
     style="position: absolute;top:0;left: 0;width: 100%;height: 100%;background: #00000099">
    <div class="popup-fixed">
        <div class="popup-fixed-header">
            <span>DataSniffer - Synchro EP BDD [{{ site.selectedPdm.ep_db_name }}:{{ dbKeyName }}] -> [{{ sniffer_field }}]</span>
            <div fxFlex></div>
            <button mat-button (click)="showPopupSniffer = false">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <app-sniffer [dbKeyName]="dbKeyName"
                     [field]="sniffer_field"
                     [rows]="selectedPdmData"
                     [reg]="selectedReg"
                     [update]="selectedReg.ts_updated"
                     (cb)="formCallBack($event)"
        ></app-sniffer>
    </div>
</div>
<!--popup XLS mapper-->
<div *ngIf="site.selectedPdm&&showPopup" style="position: absolute;top:0;left: 0;width: 100%;height: 100%;background: #00000099">
    <div class="popup-fixed">
        <div class="popup-fixed-header">
            <span *ngIf="selectedReg">
                Choix de la colonne à importer dans
                <span class="color-blue" *ngIf="selectedReg">  [Régistre: {{ selectedReg.reg_label }}]</span> ->
                <strong>[{{ selectedField }}] </strong>
            </span>
            <span *ngIf="!selectedReg">Mode consultation XLS</span>
            <div fxFlex></div>
            <button mat-button (click)="showPopup = false">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <app-xls-mapper [filter]="site.selectedPdm.xls"
                        [pdmUid]="site.selectedPdm.uid"
                        [reg]="selectedReg"
                        (cb)="assignDataToFieldFromxls($event)"
                        [regField]="selectedField"></app-xls-mapper>
    </div>
</div>

<!--releves-buttons-panel-->
<div fxLayout="row" class="releves-buttons-panel" (dblclick)="site.populatePdmCache()">
    <span fxFlex="10px"></span>
    <ng-container *ngIf="selectedPdmData&&selectedPdmData.size>0">
        <button mat-raised-button
                [ngClass]="{active:selectedRelevesPage===p}"
                fxFlex="45px"
                color="primary"
                class="mr-4 btn-xs-25"
                *ngFor="let p of relevePagesArr" (click)="gotoPage(p)">{{ p }}
        </button>

        <div class="releves-current-range">
            Affichage des relevés <b>{{ relevesRowsStartIndex }}</b> à <b>{{ relevesRowsEndIndex }}</b> de <b>{{ selectedPdmData.size }}</b>
        </div>

        <button mat-raised-button fxFlex="90px" color="accent" class="ml-4 btn-xs-25" (click)="calculateFields()">Calculer</button>
        <button mat-raised-button fxFlex="90px" color="accent" class="ml-4 btn-xs-25" (click)="calculateFields(true)">Calc. Force</button>
        <button mat-raised-button fxFlex="90px" color="primary" class="ml-4 btn-xs-25"
                (click)="saveToServer()">Save data
        </button>
        <button mat-raised-button fxFlex="90px" color="warn" class="ml-16 btn-xs-25" (click)="deleteAll()">Effacer tout</button>
    </ng-container>
    <ng-container *ngIf="site.selectedPdm&&pdmHasSyncedRegs">
        <button mat-raised-button fxFlex="80px" color="accent" class="ml-4 btn-xs-25" (click)="assignVirtualRegs()">
            <mat-icon class="color-blue" inline="true">sync</mat-icon>
            Vpdm
        </button>
    </ng-container>

    <div fxFlex></div>
    <ng-container *ngIf="selectedPdmData ">
        <button mat-raised-button fxFlex="70px" color="default" class="ml-4 btn-xs-25" (click)="exportXls()">
            <mat-icon class="color-blue" inline="true" style="font-size: 20px">downloading</mat-icon>
            Xlsx
        </button>
        <a mat-raised-button fxFlex="20px" color="accent" target="_blank" class="btn-xs-25 mr-16" [href]="xlsFileLink" *ngIf="xlsFileLink">
            <mat-icon inline="true">download_done</mat-icon>
        </a>
        <button mat-raised-button fxFlex="60px" color="default" class="ml-4 btn-xs-25" (click)="syncFtp('ep_links')">Sync EP</button>
        <button mat-raised-button fxFlex="60px" color="default" class="ml-4 btn-xs-25" (click)="syncHoraire()">Horaire</button>
        <button mat-raised-button fxFlex="60px" color="default" class="ml-4 btn-xs-25" *ngIf="site.selectedPdm" (click)="syncMeteo()">Méteo</button>


        <button mat-raised-button fxFlex="90px" color="default" class="ml-4 btn-xs-25"
                *ngIf="site.selectedPdm" (click)="selectedField=null;showPopup = true">
            Consulter Xls
        </button>
        <button mat-raised-button routerLink="/saisie" class="ml-4 btn-xs-25" color="warn">Saisie</button>
        <button mat-raised-button routerLink="/statmens-conf" class="ml-4 btn-xs-25" color="warn">Statmens</button>
    </ng-container>
</div>
<!--relevés table-->
<div *ngIf="site.selectedPdm&&regs&&regs.length">
    <div class="table-regs-holder">
        <div [style]="{width:(regs.length*700+4000 )+'px',position:'relative' }" fxLayout="row" fxLayoutAlign="start start">
            <!--Contextual Menu-->
            <div fxLayout="column"
                 [style]="{left:fieldMenuOffset+'px'}"
                 fxLayoutAlign="space-between none"
                 class="field-buttons-panel" *ngIf="selectedFieldForActions&&selectedFieldForActions!==''">
                <div class="field-buttons-panel-head">
                    Options pour [{{ selectedFieldForActions }}]
                </div>
                <button class="btn-xs-25 mb-4" fxFlex="32" mat-raised-button color="primary" (click)="openXlsMapper()">
                    Import depuis Xls
                </button>
                <button class="btn-xs-25 mb-4" fxFlex="32" mat-raised-button (click)="delField(selectedFieldForActions,'notEdited')">
                    Del not edited
                </button>
                <button class="btn-xs-25 mb-4" fxFlex="32" mat-raised-button (click)="delField(selectedFieldForActions,'all')">
                    Del all
                </button>
                <button class="btn-xs-25 mb-4" fxFlex="32" mat-raised-button (click)="delField(selectedFieldForActions,'map')">
                    Del xls map
                </button>
                <button class="btn-xs-25" fxFlex="32" color="accent" mat-raised-button (click)="selectedFieldForActions=''">
                    close
                </button>
            </div>

            <table class="table-regs" *ngFor="let reg of regs;let i=index;">
                <tr *ngFor="let field of regFieldsToDisplayInReleve">
                    <td class="reg-def-head" *ngIf="i===0">{{ field }}</td>
                    <td colspan="5" class="reg-def-val" [matTooltip]="field+':'+reg[field]">
                        <span>{{ reg[field] }}</span>
                    </td>
                </tr>
                <tr class="rows-but">
                    <td class="date" *ngIf="i===0">Saisie</td>
                    <td class="field" colspan="5" style="height: 25px;position: relative">
                        <div fxLayout="row">
                            <div class="flex-container-td" fxLayout="row">
                                <ng-container *ngFor="let f of ['i','k','d','a','t','c']">
                                    <div class="field-entry field-width" *ngIf="reg.shouldShow(f)">
                                        {{ reg.getEntryType(f) }}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="rows-head">
                    <td class="date" *ngIf="i===0"></td>
                    <td fxLayout="row">
                        <div class="flex-container-td" fxLayout="row">
                            <ng-container *ngFor="let f of ['i','k','d','a','t','c']">
                                <div class="field field-width"
                                     *ngIf="reg.shouldShow(f)"
                                     [ngClass]="{active:selectedFieldForActions===(f+reg.num)}"
                                     (click)="showFieldMenu(reg,f,$event)">
                                    {{ f + reg.num|uppercase }}
                                </div>
                            </ng-container>

                        </div>
                    </td>
                </tr>
            </table>
            <table class="table-regs">
                <tr *ngFor="let field of [1,2,3,4,5,6,7,8,9]">
                    <td title="cTotPaid">+</td>
                    <td title="cTotDiff">+</td>
                    <td title="VE">+</td>
                    <td class="field field-width" *ngFor="let autoField of autoFields|keyvalue">-</td>
                    <td>.</td>
                    <td>.</td>
                    <td>.</td>
                </tr>
                <tr class="rows-head">
                    <td class="field field-width field-extra">cTotPaid</td>
                    <td class="field field-width field-extra">cTotDiff</td>
                    <td class="field field-width field-extra">VE</td>
                    <td class="field field-width field-extra field-extra-tot"
                        *ngFor="let autoFieldKV of autoFields|keyvalue"
                        [matTooltip]="autoFieldKV.key">{{ autoFieldKV.key }}
                    </td>
                    <td class="field field-width field-extra">T°Moy</td>
                    <td class="field field-width field-extra">HR</td>
                    <td class="field field-width field-extra">Ray</td>
                </tr>
            </table>
        </div>

        <div [style]="{width:(tableWidth)+'px'}" class="releves-scroller" (keydown)="startEdit($event)">
            <ng-container *ngFor="let dataItemKV of selectedPdmData|keyvalue;let i=index">
                <releve-row [index]="i" *ngIf="i>=(relevesRowsStartIndex-1)&&i<relevesRowsEndIndex"
                            [ts]="dataItemKV.value.ts"
                            [rowData]="dataItemKV.value"
                            (cb)="formCallBack($event)"
                            [regs]="regs"
                            [ctrlPressField]="ctrlPressed"
                            [firstRowDateMassEdit]="firstRowDateMassEdit"
                            [lastRowDateMassEdit]="lastRowDateMassEdit"
                ></releve-row>
            </ng-container>

        </div>
    </div>
</div>
<!--SPINNERS-->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" name="spinnerAssignMap" type="timer" [fullScreen]="false">
    <p style="color: white"> Sauvegarde en cours {{ assignProgress }}/{{ assignTot }} </p>
    <mat-progress-bar
            style="height: 30px"
            [color]="'primary'"
            [mode]="'determinate'"
            [value]="100*assignProgress/assignTot">
    </mat-progress-bar>
</ngx-spinner>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff"
             (dblclick)="clearLocalStorage()" name="spinnerSaveServer"
             type="timer" [fullScreen]="false">
    <p style="color: white"> Sauvegarde en cours {{ saveProgress }}% </p>
    <mat-progress-bar
            style="height: 30px"
            [color]="'primary'"
            [mode]="'determinate'"
            [value]="saveProgress">
    </mat-progress-bar>
</ngx-spinner>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" name="spinnerCalc" type="timer" [fullScreen]="true" *ngIf="calculationMonitor">
    <p style="color: white"> Calcul en cours {{ selectedPdmDataCalcHolder.size }}/{{ selectedPdmData.size }} </p>
    <p style="color: white"> {{ calculationMonitor.currRowDate }}: {{ calculationMonitor.currRegLabel }} Num: {{ calculationMonitor.currRegNum }} </p>
    <mat-progress-bar style="height: 30px;margin-bottom: 5px" [color]="'primary'" [mode]="'determinate'"
                      [value]="100*selectedPdmDataCalcHolder.size/selectedPdmData.size"></mat-progress-bar>
    <mat-progress-bar style="height: 30px" [color]="'primary'" [mode]="'determinate'"
                      [value]="100*calculationMonitor.fieldsCalced/calculationMonitor.fieldsTotCount"></mat-progress-bar>
    <div style="height: 300px;overflow-y: scroll;width: 900px;margin: 10px;font-size: 10px;color: white;text-shadow: 1px 1px 2px #000;">
        <pre>{{ calculationMonitor.results|json }}</pre>
        <pre>{{ calculationMonitor.currDataSummary|json }}</pre>
    </div>
</ngx-spinner>

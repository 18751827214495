import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AppService} from "../../../shared/services/app.service";
import {ApiService} from "../../../shared/services/api.service";
import {SiteService} from "../../../shared/services/site.service";
import {PlotlyService} from "angular-plotly.js";
import {LayoutService} from "../../../shared/services/core/layout.service";
import {BaseComponent} from "../../../shared/BaseComponent";
import {H} from "../../../shared/helpers/H";
import {Observable} from "rxjs";

@Component({
    selector: 'app-global-data',
    templateUrl: './global-data.component.html',
    styleUrls: ['./global-data.component.scss']
})
export class GlobalDataComponent extends BaseComponent implements OnInit {


    public selectedDs: any;
    public selectedDsData: any;
    public meteoFtpMetas: any[] = [];

    constructor(private http: HttpClient, public myapp: AppService,
                public api: ApiService, public site: SiteService,
                public plotlyServ: PlotlyService, public layout: LayoutService) {
        super();
    }

    ngOnInit(): void {
        this.myapp.appInitStatus.subscribe(step => {
            if (step === 1)
                this.myapp.storeCurrentRoute();
        });
        this.loadMeteoFTPStatus();
    }

    loadMeteoFTPStatus() {
        this.api.getMeteoStations().subscribe(resp => {
            this.meteoFtpMetas = Object.values(resp.body);
            console.log("this.meteoFtpMetas", this.meteoFtpMetas);
        });
    }

    updateMeteoSpot() {
        this.api.syncWithFtp('meteo').subscribe(resp => {
            console.log("updateMeteoSpot", resp.list);
            this.loadMeteoFTPStatus();
        });
    }

    selectMeteoDS(ds) {
        this.selectedDs = ds;
        console.log("selectMeteoDS()", ds);

        this.api.getMeteoStations().subscribe(resp => {
            this.selectedDsData = resp.file;
            this.selectedDsData = this.sortObjectKeys(this.selectedDsData);
            console.log("this.meteoFtpMetas", this.meteoFtpMetas);
        });
    }

    selectSpot(spot) {

    }

}

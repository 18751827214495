import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {PlotlyComponent, PlotlyService} from "angular-plotly.js";
import {Optimise} from "../../../shared/helpers/Optimise";
import {ReportAnnotation} from "../../../shared/models/models";

@Component({
    selector: 'app-print-proto-page',
    templateUrl: './print-proto-page.component.html',
    styleUrls: ['./print-proto-page.component.scss']
})
export class PrintProtoPageComponent implements OnInit {
    @Input() visualKeysToAnnotationsMap: Map<string, Map<string, ReportAnnotation>>;
    @Input() selectedReport: any;
    @Input() index: number;
    @Input() page: any;
    @Input() pageList: any[];
    @Input() pagesGraphs: any;

    graphs: any[];
    graphsAdapted: any[];
    graphsRow1: any[] = [];
    graphsRow2: any[] = [];
    graphsTitles: string[] = [];

    public plotsToPrint = {};
    today: number = Date.now();

    constructor(public sanitizer: DomSanitizer, public plotlyServ: PlotlyService,) {
    }

    ngOnInit(): void {
        console.log("this.pagesGraphs", this.pagesGraphs);
        let pageRawJson = this.pagesGraphs[this.page.position];//localStorage.getItem("p-" + this.currentPage.position);
        if (pageRawJson) pageRawJson = pageRawJson.body;
        this.graphs = Object.keys(pageRawJson)
            .filter(key => key !== 'metas')
            .map(key => {
                    let o = pageRawJson[key];
                    o['key'] = key;
                    return o;
                }
            );
        this.graphsAdapted = [];
        for (let j = 0; j < 8; j++) {
            if (this.graphs[j]) {
                const el = Optimise.fixRawGraphData(this.graphs[j]);
                if (el.layout && el.layout['title'] && el.layout['title'] !== '') {
                    el.plotData.layout.annotations = this.getStyledAnnotsforVisualKey(el.key);
                    this.graphsTitles[el.key] = el.layout['title'];
                    if (typeof el.layout['title'] === 'object') this.graphsTitles[el.key] = '';
                    el.layout['title'] = "";
                    el.layout['title'] = el.layout['title'].replaceAll('  ', ' ');
                    el.layout['title'] = el.layout['title'].replaceAll('  ', ' ');
                }

                this.graphsAdapted[j] = el;
            } else {
                this.graphsAdapted[j] = {data: null, layout: null, origin: null, shape: null};
            }
        }

        this.graphsAdapted.forEach(gr => {
            if (!gr.origin) return;
            if (gr.origin.y === 0) this.graphsRow1.push(gr);
            if (gr.origin.y === 1) this.graphsRow2.push(gr);
        });
        this.generatePlotsToPngOfCurrentPage();
    }

    generatePlotsToPngOfCurrentPage() {
        this.graphs.forEach(graph => {
            let elem = document.createElement('div') as HTMLDivElement;
            let annotsToShow: ReportAnnotation[] = [];

            const fixedGraph = Optimise.fixRawGraphData(graph);
            console.log('fixedGraph', fixedGraph, graph);
            let layout = fixedGraph.plotData['layout'];
            let shape = fixedGraph.shape;
            let w = 1600 / this.page.shape.cols;
            layout['width'] = w * shape.cols;
            layout.height = 485 * shape.rows;
            layout['legend'] = {
                bgcolor: "transparent",
                orientation: "h",
                tracegroupgap: 0,
                traceorder: "normal",
                borderwidth: 1,
                pad: 0,
                bordercolor: '#fff',
                x: 0.5,
                xanchor: "center",
                y: 1.01,
                yanchor: "bottom",
                font: {
                    family: 'sans-serif',
                    size: 10,
                    color: '#333'
                }
            };
            layout['margin'] = {
                pad: 1, r: 50,
                t: 5,
                b: 40,
                l: 50
            };

            layout.annotations = this.getStyledAnnotsforVisualKey(fixedGraph.key);

            console.log('generatePlotsToPngOfCurrentPage', fixedGraph, layout, annotsToShow);
            this.plotlyServ.newPlot(elem, fixedGraph.plotData['data'], layout, {staticPlot: false}).then(res => {
                this.plotlyServ.getPlotly()
                    .then(plotly => {
                        return plotly.toImage(res, {
                            format: 'svg',
                            width: layout['width'],
                            height: layout.height
                        });
                    })
                    .then(i => {
                        this.plotsToPrint[graph.key] = i;
                    });
            });
        });
    }

    getStyledAnnotsforVisualKey(key: string) {
        if (!this.visualKeysToAnnotationsMap.has(key)) return [];
        //console.log('PrintProtoPageComponent:ngOnInit', fixedGraph.key, fixedGraph, annotsToShow);
        let annotsToShow: any[] = Array.from(this.visualKeysToAnnotationsMap.get(key).values());
        annotsToShow = annotsToShow.map(it => {
            it = new ReportAnnotation(it);
            it.styling();
            return {...it};
        });
        return annotsToShow;
    }

    getArray(forRowIndex: number) {
        let arr = [];
        for (let i = 0; i < this.page.cols; i++) {
            if (forRowIndex === 0) {
                if (this.graphsAdapted[i] && this.graphsAdapted[i].data && this.graphsAdapted[i].origin.y === 0) {
                    arr.push(i);
                }
            }
            if (forRowIndex === 1) {
                if (this.graphsAdapted[i] && this.graphsAdapted[i].data && this.graphsAdapted[i].origin.y === 0) {
                    arr.push(i);
                }
            }

        }
        return arr;
    }
}

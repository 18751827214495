import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {DatePipe} from "@angular/common";
import moment from "moment";
import {AppService} from "../../../shared/services/app.service";
import {SiteService} from "../../../shared/services/site.service";

@Component({
    selector: 'bmens-comment',
    template: `
        <div class="p-8" [class.noprint]="!markdownNote||!markdownNote[key]">
            <h5 fxLayout="row" class="comment-holder">
                <div *ngIf="key==='pageComment'" (click)="myapp.showConsole(groups)">
                    Commentaire <span class="color-blue">global </span>
                </div>
                <div *ngIf="key!=='pageComment'" (click)="myapp.showConsole(groups)">
                    Commentaire <span class="color-blue">{{groupLabel}}</span>
                </div>
                <span fxFlex></span>
                <div class="noprint" *ngIf="myapp.getIfUserCanDo('COMMENT')" (click)="commentEditMode[key] = commentEditMode&&commentEditMode[key]?false:true;">
                    <mat-icon *ngIf="!commentEditMode[key]">edit</mat-icon>
                    <mat-icon *ngIf="commentEditMode[key]">cancel</mat-icon>
                </div>

            </h5>
            <div class="comment-holder-content">
                <div *ngIf="!markdownNote||!markdownNote[key]">
                    <i>Pas de commentaire</i>
                </div>

                <markdown *ngIf="!commentEditMode[key]&&markdownNote[key]"
                          [data]="markdownNote[key]"></markdown>

                <div *ngIf="commentEditMode[key]">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div fxFlex>
                            <textarea #autosize cdkTextareaAutosize="true" [(ngModel)]="markdownNote[key]"></textarea>
                        </div>
                        <button mat-raised-button color="primary" class="btn-xs-25 mt-4" (click)="saveNoteForMonth(key)"> Sauvegarder</button>
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./bmens.component.scss'],
})
export class BmensCommentComponent implements OnInit, OnChanges {
    @Input('groups') groups: Map<string, string>;
    @Input('commentEditMode') commentEditMode: any;
    @Input('markdownNote') markdownNote: any;
    @Input('key') key: string;

    @Input('yearMonthKey') yearMonthKey: string;
    @Input('forceUpdate') forceUpdate: number;
    @Output('cb') cb: EventEmitter<any> = new EventEmitter<any>();

    get groupLabel() {
        const groups = this.site.clientSite.bmens_groups;
        const item = groups.find(it => it.uid === this.key);
        if (item) return item.label;
        else return 'global'
    }

    constructor(public myapp: AppService, public site: SiteService,) {
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
    }

    saveNoteForMonth(page_key: string) {
        if (!this.markdownNote[page_key]) {
            console.error("saveNoteForMonth", "this.markdownNote[page_key]", page_key, null);
            return;
        }
        console.log("saveNoteForMonth", this.markdownNote);
        const data = {
            key_report: this.yearMonthKey,
            priority: 'MEDIUM',
            status: 'PROPOSAL',
            comment: this.markdownNote[page_key],
            key_page: page_key,
            display_name: this.myapp.user_display_name,
            type: 'bmens',
            title: 'Commentaire général',
            uid_user: this.myapp.user.uid,
            uid_site: this.site.uid
        }
        this.site.api.saveOrCreateBmensComment(this.site.uid, this.yearMonthKey, page_key, data)
            .subscribe(resp => {
                if (resp.status === 1) {
                    this.commentEditMode[this.key] = false;
                    this.cb.emit(true);
                }
                // console.log("addNoteForMonth()", resp);
            });
    }
}

import _sortBy from 'lodash/sortBy'
import moment from 'moment'
import {COLORS_CURVE, defaultLayout} from '../constants'
import {configureTicks, configureTicksFromY1, findYValues, rangeFromValues} from '../utils'
import * as assign from 'assign-deep';

function rounded(date, precision = 30) {
    const start = moment(date)
    const remainder = (start.minutes() % precision)
    return remainder
        ? moment(date).add(precision - remainder, 'minutes').startOf('minutes')
        : moment(date).startOf('minutes')
}

class CurveAdapter {
    /**
     * Default Options for layout
     */
    static layout = (data, layout) => {
        const tickmode = 'tozero'
        const filterYaxis1 = serie => serie.yaxis !== 'y2'
        const filterYaxis2 = serie => serie.yaxis === 'y2'

        const minX = _sortBy(data.map(({x = []}) => x[0]), v => moment(v).valueOf())[0]
        const maxX = _sortBy(data.map(({x = []}) => x.slice(-1)[0]), v => moment(v).valueOf())[0]
        const xrange = [minX, maxX].map(v => rounded(v).toISOString(true))

        const yaxisTicks = configureTicks({values: findYValues(data, filterYaxis1), tickmode, nticks: 8})
        let yaxis2Config = {}
        if (layout && layout.yaxis2) {
            const y2Values = findYValues(data, filterYaxis2)
            const y2Range = rangeFromValues(y2Values, tickmode)
            const yaxis2Ticks = configureTicksFromY1(yaxisTicks, y2Range)
            yaxis2Config = {
                yaxis2: {
                    side: 'right',
                    overlaying: 'y',
                    rangemode: 'nonnegative',
                    showgrid: false,
                    ...yaxis2Ticks,
                },
            }
        }
        return assign({}, defaultLayout({data, layout}), {
            xaxis: {
                type: 'date',
                hoverformat: '%a %d.%m.%y %H:%M',
                tickformat: '%d.%m.%y',
                range: xrange,
            },
            yaxis: {
                ...yaxisTicks,
            },
            ...yaxis2Config,
            barmode: 'stack',
            hovermode: 'x',
        })
    }

    static getConfig(index) {
        return {
            showlegend: true,
            legends: {
                orientation: 'h',
            },
            type: 'scatter',
            mode: 'lines',
            marker: {
                color: COLORS_CURVE[index],
            },
            line: {
                width: 1.4,
            },
        }
    }

    static reduce(data, layout) {
        const nextData = CurveAdapter.reduceData(data)
        const nextLayout = CurveAdapter.reduceLayout(layout, nextData)
        return {
            data: nextData,
            layout: nextLayout,
        }
    }

    /**
     * Merge each data series with the corresponding config
     * and attach x values. x axis is common to every series
     *
     * @params: data array of data series containing y values
     *          and other infos about the type.
     */
    static reduceData(data) {
        return data.map((serie, index) => {
            const {dateStamps, ...others} = serie
            const config = CurveAdapter.getConfig(index)
            return {
                ...others,
                ...config,
                x: dateStamps,
            }
        })
    }

    /**
     * Merge default signature layout with the layout
     * passed as parameter which may contain minimal info
     * like titles for x axis and y axis
     */
    static reduceLayout(layout, data) {
        const baseLayout = CurveAdapter.layout(data, layout)
        // deep merge layout
        // layout can override baseLayout
        return assign({}, baseLayout, layout)
    }
}

export default CurveAdapter


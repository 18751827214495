import {Component, OnInit, OnDestroy, AfterViewInit} from "@angular/core";
import {NavigationService} from "../../services/core/navigation.service";
import {ThemeService} from "../../services/core/theme.service";
import {Subscription} from "rxjs";
import {ILayoutConf, LayoutService} from "app/shared/services/core/layout.service";
import {AppService} from '../../services/app.service';
import {AuthService} from '../../services/auth.service';

@Component({
    selector: "app-sidebar-side",
    templateUrl: "./sidebar-side.component.html"
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
    public menuItems: any[];
    public hasIconTypeMenuItem: boolean;
    public iconTypeMenuTitle: string;
    private menuItemsSub: Subscription;
    public layoutConf: ILayoutConf;

    constructor(
        private navService: NavigationService,
        public themeService: ThemeService,
        //public auth: AuthService,
        private layout: LayoutService
    ) {
    }

    ngOnInit() {
        this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
        this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
            this.menuItems = menuItem;
            //   console.log("menuItemsSub",menuItem);
            //Checks item list has any icon type.
            this.hasIconTypeMenuItem = !!this.menuItems.filter(
                item => item.type === "icon"
            ).length;
        });
        this.layoutConf = this.layout.layoutConf;
    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
        if (this.menuItemsSub) {
            this.menuItemsSub.unsubscribe();
        }
    }

    toggleCollapse() {
        if (
            this.layoutConf.sidebarCompactToggle
        ) {
            this.layout.publishLayoutChange({
                sidebarCompactToggle: false
            });
        } else {
            this.layout.publishLayoutChange({
                // sidebarStyle: "compact",
                sidebarCompactToggle: true
            });
        }
    }
}

import {Pipe, PipeTransform} from "@angular/core";
import moment from "moment";

@Pipe({name: 'relativeTime'})
export class RelativeTimePipe implements PipeTransform {
    transform(value: Date) {
        moment().locale('fr');
        if (!(value instanceof Date))
            value = new Date(value);

        const momObj = moment(value);
        return momObj.fromNow();
    }
}

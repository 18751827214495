import {Component, OnInit, EventEmitter, Input, ViewChildren, Output, Renderer2} from '@angular/core';
import {ThemeService} from '../../services/core/theme.service';
import {LayoutService} from '../../services/core/layout.service';
import {AppService} from '../../services/app.service';
import {AuthService} from '../../services/auth.service';
import {NavLink} from "../../models/models";
import {Router} from "@angular/router";
import {SiteService} from "../../services/site.service";
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-header-side',
    templateUrl: './header-side.template.html'
})
export class HeaderSideComponent implements OnInit {
    public egretThemes;
    public layoutConf: any;
    public topLinks: NavLink[] = [];

    constructor(
        public site: SiteService,
        public myapp: AppService,
        private themeService: ThemeService,
        private layout: LayoutService,
        public router: Router,
        private renderer: Renderer2,
    ) {
        this.layoutConf = this.layout.layoutConf;
        this.egretThemes = this.themeService.egretThemes;
        this.myapp.appInitStatus.subscribe(state => {
            if (state === 3) {
                this.reloadTopMenu();
            }
        });
        this.site.siteLoadingStatus.subscribe(status => {

        });
    }

    ngOnInit() {

    }

    reloadTopMenu() {
        this.topLinks = [];

        this.topLinks.push({
            label: "Dashboard",
            icon: "dashboard",
            link: "site-home",
            state: "site-home"
        });
        this.topLinks.push({
            label: "Protocole hebdo",
            icon: "bar_chart",
            link: "proto-hebdo",
            state: "proto-hebdo"
        });
        this.topLinks.push({
            label: "Liste d'actions ",
            icon: "task",
            link: "actions",
            state: "actions"
        });
        /*
        this.topLinks.push({
            label: "Bilan mensuel",
            icon: "bar_chart",
            link: "balance-sheets"
        });*/
        this.topLinks.push({
            label: "Bilan mensuel",
            icon: "account_balance",
            link: "bmens",
            state: "bmens"
        });
        this.topLinks.push({
            label: "Documents",
            icon: "cloud_circle",
            link: "docs",
            state: "docs"
        });
        if (this.site.clientSite && this.site.clientSite.type_suivi !== '')
            this.topLinks.push({
                label: "Rapport de suivi",
                icon: "summarize",
                link: "suivi",
                state: "suivi"
            });
        /*
        else this.topLinks.push({
        label: "Rapport annuel",
        icon: "article", link: "annuel",  state: "annuel"  });*/

        this.topLinks = this.myapp.user ? this.myapp.user.filterNavLinks(this.topLinks) : [];
        this.layout.publishLayoutChange({sidebarStyle: 'closed'});
    }


    toggleSidenav() {
        if (this.layoutConf.sidebarStyle === 'closed') {
            return this.layout.publishLayoutChange({sidebarStyle: 'full'})
        }
        this.layout.publishLayoutChange({sidebarStyle: 'closed'})
    }

    toggleCollapse() {
        // compact --> full
        if (this.layoutConf.sidebarStyle === 'compact') {
            return this.layout.publishLayoutChange({
                sidebarStyle: 'full',
                sidebarCompactToggle: false
            }, {transitionClass: true})
        }

        // * --> compact
        this.layout.publishLayoutChange({
            sidebarStyle: 'compact',
            sidebarCompactToggle: true
        }, {transitionClass: true})

    }

    protected readonly environment = environment;
}

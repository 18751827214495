<div class="d-flex align-items-center h-full mat-bg-card" style="background: #cecece">
    <div class="app-error">
        <div class="fix">
            <mat-icon class="error-icon" color="warn">error</mat-icon>
            <div class="error-text">
                <h1 class="error-title">404</h1>
                <div class="error-subtitle">Page non trouvée!</div>
                <div>
                    <span class="text-muted">{{myapp.previousUrl}}</span>
                    <span class="text-muted">{{myapp.currentUrl}}</span>
                </div>
            </div>
        </div>

        <div class="error-actions p-20">
            <button mat-raised-button color="primary" class="mb-16 mr-8"
                    [routerLink]="['/']">Retour à la page d'accueil
            </button>
        </div>
    </div>
</div>

import {Injectable, isDevMode} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import 'rxjs/add/operator/switchMap';
import {ClientSite, Resp, StorageDocument} from '../models/models';
import {H} from "../helpers/H";
import * as Buffer from "buffer";
import {PdmRegDef} from "../models/PdmRegDef";
import {Pdm} from "../models/Pdm";
import {ReportSuivi} from "../models/Suivi.model";
import {BmensData, BmensItem, BmensMeter} from "../models/Bmens.model";
import {SiteEvent} from "../models/Notifs";
import {Chapter} from "../models/Action.model";

@Injectable()
export class ApiService {
    baseUrl: string = 'https://www.optimigration.ch/storage/';
    baseStorageAppUrl: string = 'https://www.optimigration.ch/app/api/';
    // baseStorageAppUrl: string = 'http://localhost:8080/endpoint/api/';
    // basePrintUrl: string = 'https://195.15.213.226/api/index.php/print/';
    basePrintUrl: string = 'https://tool.optimise.swiss/api/index.php/print/';
    // basePrintUrl: string = 'https://api.optimigration.ch/print/index?act=';
    reqUrl: string = '';

    public static bs64FromRawStr(str) {
        return Buffer.Buffer.from(str).toString('base64');
    }

    public static bs64FromJsonStr(obj) {
        return Buffer.Buffer.from(JSON.stringify(obj)).toString('base64');
    }

    public static jsonStrFromB64(str64) {
        const decodedRequestBodyString = Buffer.Buffer.from(str64, "base64");
        return JSON.parse(decodedRequestBodyString.toString());
    }

    constructor(public http: HttpClient) {
        this.baseUrl = 'https://api.optimigration.ch/';
        console.info("APIService constructor", this.baseUrl);
        // if (isDevMode() || true) this.setDev();
    }

    setDev() {
        this.baseUrl = 'http://localhost:8080/';
        return;
    }

    getLinkXls(path: string) {
        return this.baseUrl + "/mens/show-pdm-xls?path=" + path;
    }

    getLink(path: string, toolServer = false) {
        if (toolServer)
            return this.basePrintUrl + path;
        else
            return this.baseUrl + "endpoint" + path;
    }

    sendGet(url: string) {
        const optionRequete = {
            headers: new HttpHeaders({})
        };
        return this.http.get<Resp>(url, optionRequete);
    }

    /*legacy

    */
    getMetrics(type = 'id') {
        this.reqUrl = this.baseUrl + "sync/get-stored-metrics?type=" + type;
        return this.sendGet(this.reqUrl);
    }

    getAllSuiviReports() {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=getAllSuiviReports';
        let params = new FormData();
        return this.http.post<any>(this.reqUrl, params);
    }

    generateDatasource(xls: string, siteRef: string, fluid: string) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=genMeasureSpot';
        let params = new FormData();
        params.append('file', xls);
        params.append('ref', siteRef);
        params.append('fluid', fluid);
        return this.http.post<any>(this.reqUrl, params);
    }

    getHtmlFromXls(fileName: string, type: string) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=getHtmlOfXls';
        let params = new FormData();
        params.append('fileName', fileName);
        params.append('type', type);
        return this.http.post<any>(this.reqUrl, params);
    }

    saveClients(data: any) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=saveClient';
        let params = new FormData();
        params.append('data', H.utf8_to_b64(JSON.stringify(data)));
        return this.http.post<any>(this.reqUrl, params);
    }

    getClients() {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=getClients';
        let params = new FormData();
        return this.http.post<any>(this.reqUrl, params);
    }

    getSitesOfClient(clientUID: string) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=getClientsSite';
        let params = new FormData();
        params.append('client', clientUID);
        return this.http.post<any>(this.reqUrl, params);
    }

    getAllBmens(site: string) {
        this.reqUrl = this.baseUrl + 'endpoint/bmens-data?do=all';
        let params = new FormData();
        params.append('site', site);
        return this.http.post<any>(this.reqUrl, params);
    }

    /*
    getDecemberBmens(site: string) {
        this.reqUrl = this.baseUrl + 'endpoint/bmens-data?do=last';
        let params = new FormData();
        params.append('site', site);
        return this.http.post<any>(this.reqUrl, params);
    }

     */

    getBalanceSheets(site: string) {
        this.reqUrl = this.baseUrl + 'endpoint/get-balance-sheets';
        let params = new FormData();
        params.append('site', site);
        return this.http.post<any>(this.reqUrl, params);
    }

    saveOrCreateBmensComment(site: string, key: string, key_page: string, data: any) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=saveOrCreateBmensComment';
        let params = new FormData();
        params.append('site', site);
        params.append('key', key);
        params.append('key_page', key_page);
        params.append('data', H.utf8_to_b64(JSON.stringify(data)));
        return this.http.post<any>(this.reqUrl, params);
    }

    saveBalanceSheet(item: BmensItem) {
        this.reqUrl = this.baseUrl + 'endpoint/save-balance-sheets';
        let params = new FormData();
        params.append('data', H.utf8_to_b64(JSON.stringify(item)));
        return this.http.post<any>(this.reqUrl, params);
    }

    getMetersData(meterUid: string) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=getBmensMeterData';
        let params = new FormData();
        params.append('pk', meterUid);
        return this.http.post<any>(this.reqUrl, params);
    }

    getAllMetersData(site: string) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=getBmensAllMeterData';
        let params = new FormData();
        params.append('site', site);
        return this.http.post<any>(this.reqUrl, params);
    }

    mergeBmensMeters(pkSource: string, pkTarget: string) {
        //// bmensMeterMerge pkSource pkTarget
        this.reqUrl = this.baseUrl + 'endpoint/api?do=bmensMeterMerge';
        let params = new FormData();
        params.append('pkSource', pkSource);
        params.append('pkTarget', pkTarget);
        return this.http.post<any>(this.reqUrl, params);
    }

    saveMetersData(bmensData: BmensData) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=saveBmensData';
        let params = new FormData();
        params.append('pk', bmensData.uid);
        params.append('data', H.utf8_to_b64(JSON.stringify(bmensData)));
        return this.http.post<any>(this.reqUrl, params);
    }

    deleteMetersData(bmensData: BmensData) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=deleteBmensData';
        let params = new FormData();
        params.append('pk', bmensData.uid);
        return this.http.post<any>(this.reqUrl, params);
    }

    syncEventsForSite(siteName: string, siteUID: string) {// sync energy stats and Suivi iteratif submeters
        this.reqUrl = this.baseUrl + 'sync/events';
        let params = new FormData();
        params.append('siteName', siteName);
        params.append('siteUID', siteUID);
        return this.http.post<any>(this.reqUrl, params);
    }

    getBmensMeters(site: string) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=getBmensMeters';
        let params = new FormData();
        params.append('site', site);
        return this.http.post<any>(this.reqUrl, params);
    }

    saveBmensMeters(meter: BmensMeter) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=saveBmensMeters';
        let params = new FormData();
        params.append('pk', meter.uid);
        params.append('data', H.utf8_to_b64(JSON.stringify(meter)));
        return this.http.post<any>(this.reqUrl, params);
    }

    sendErrorLog(errObj: any) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=errorLog';
        let params = new FormData();
        params.append('error', H.utf8_to_b64(JSON.stringify(errObj)));
        return this.http.post<any>(this.reqUrl, params);
    }

    sendShareEvent(event: SiteEvent) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=newEvent';
        let params = new FormData();
        params.append('data', H.utf8_to_b64(JSON.stringify(event)));
        return this.http.post<any>(this.reqUrl, params);
    }

    loadComments(site_uid: string, key_report: string, key_page: string = null) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=getComments';
        let params = new FormData();
        params.append('site_uid', site_uid);
        params.append('key_report', key_report);
        if (key_page)
            params.append('key_page', key_page);
        return this.http.post<any>(this.reqUrl, params);
    }

    permutateChapters(site: string, uid1: string, uid2: string, dispOrder1: string, dispOrder2: string) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=chapterPermute';
        let params = new FormData();
        params.append('uid1', uid1);
        params.append('uid2', uid2);
        params.append('dispOrder1', dispOrder1);
        params.append('dispOrder2', dispOrder2);
        params.append('site', site);
        return this.http.post<any>(this.reqUrl, params);
    }

    deleteChapter(chapter: Chapter) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=chapterDelete';
        let params = new FormData();
        params.append('uid', chapter.uid);
        params.append('uid_site', chapter.uid_site);
        return this.http.post<any>(this.reqUrl, params);
    }

    saveOrCreateChapter(siteUid: string, data: string, pkToUpdate: string) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=chapterUpdate';
        let params = new FormData();
        params.append('pk', pkToUpdate);
        params.append('site', siteUid);
        params.append('data', H.utf8_to_b64(data));
        return this.http.post<any>(this.reqUrl, params);
    }

    commentReply(uid: string, data: string) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=commentReply';
        let params = new FormData();
        params.append('data', H.utf8_to_b64(data));
        params.append('uid', uid);
        return this.http.post<any>(this.reqUrl, params);
    }

    updateCommentStatus(uid: string, status: boolean) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=updateCommentStatus';
        let params = new FormData();
        params.append('uid', uid);
        params.append('status', status ? 'COMPLETED' : 'PROPOSAL');
        return this.http.post<any>(this.reqUrl, params);
    }

    updateAnnotation(uidComment: string, uidAnn: string, data: any) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=updateAnnotation';
        let params = new FormData();
        params.append('uid_comment', uidComment);
        params.append('uid_annotation', uidAnn);
        params.append('data', H.utf8_to_b64(JSON.stringify(data)));
        return this.http.post<any>(this.reqUrl, params);
    }

    createComment(data: any) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=addComment';
        let params = new FormData();
        params.append('data', H.utf8_to_b64(JSON.stringify(data)));
        return this.http.post<any>(this.reqUrl, params);
    }

    updateCommentData(uid: string, data: any) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=updateCommentData';
        let params = new FormData();
        params.append('pk', uid);
        params.append('data', H.utf8_to_b64(JSON.stringify(data)));
        return this.http.post<any>(this.reqUrl, params);
    }

    listSites(site: string) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=getSiteByRef';
        let params = new FormData();
        params.append('site', site);
        return this.http.post<any>(this.reqUrl, params);
    }

    syncWithFtp(wat: string = null) {
        this.reqUrl = this.baseUrl + 'endpoint/sync-sftp?wat=' + wat;
        return this.http.get<any>(this.reqUrl);
    }


    saveStatMensPlotsCacheToServer(path: string, data: any, pdmDataSummary: any, siteName: string) {
        this.reqUrl = this.basePrintUrl + 'save-statmens-cache';
        const dt64 = Buffer.Buffer.from(JSON.stringify(data)).toString('base64');
        const dt64Summary = Buffer.Buffer.from(JSON.stringify(pdmDataSummary)).toString('base64');
        let params = new FormData();
        params.append('data', dt64);
        params.append('path', path);
        params.append('data_summary', dt64Summary);
        params.append('site_name', siteName);
        return this.http.post<any>(this.reqUrl, params);
    }

    generateStatmensPdf(path: string) {
        this.reqUrl = this.basePrintUrl + 'save-statmens-preview';
        let params = new FormData();
        params.append('path', path);
        params.append('do', '0');
        return this.http.post<any>(this.reqUrl, params);
    }

    getIsStatMensPdfExist(path: string) {
        this.reqUrl = this.basePrintUrl + 'statmens-pdf-exists';
        let params = new FormData();
        params.append('path', path);
        return this.http.post<any>(this.reqUrl, params);
    }

    getStatMensPlotsCache(path) {
        this.reqUrl = this.baseUrl + 'endpoint/load-stat-mens';
        let params = new FormData();
        params.append('path', path);
        return this.http.post<any>(this.reqUrl, params);
    }

    getProtoEvents(site: string, type: string) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=getEvents';
        let params = new FormData();
        params.append('site', site);
        params.append('wat', type);
        return this.http.post<any>(this.reqUrl, params);
    }

    getProtoEventsForMonitoring(week: number, year: number) {
        this.reqUrl = this.baseUrl + 'endpoint/monitor';
        let params = new FormData();
        params.append('week', week.toString());
        params.append('year', year.toString());
        if (week > 0)
            params.append('withevent', '1'.toString());
        return this.http.post<any>(this.reqUrl, params);
    }

    getBmensEvents(year: number) {
        this.reqUrl = this.baseUrl + 'endpoint/monitor-bmens';
        let params = new FormData();
        params.append('year', year.toString());
        return this.http.post<any>(this.reqUrl, params);
    }

    getBmensData(year: number, month: number) {
        this.reqUrl = this.baseUrl + 'endpoint/monitor-bmens-data';
        let params = new FormData();
        params.append('year', year.toString());
        params.append('month', month.toString());
        return this.http.post<any>(this.reqUrl, params);
    }

    printProtoHebdo(html: string, title: string, withComments: boolean = false) {
        this.reqUrl = this.basePrintUrl + 'print-proto-hebdo';
        let params = new FormData();
        params.append('html', html);
        params.append('title', title);
        if (withComments)
            params.append('comments', '1');
        return this.http.post<any>(this.reqUrl, params);
    }

    printActions(html: string, title: string) {
        this.reqUrl = this.basePrintUrl + 'print-actions';
        let params = new FormData();
        params.append('html', html);
        params.append('title', title);
        return this.http.post<any>(this.reqUrl, params);
    }

    printSuiviAnnexes(files: string[], ref: string, uid_report: string, year: string, isRef: string, version: string) {
        this.reqUrl = this.basePrintUrl + 'suivi-merge-pdf';
        let params = new FormData();
        const filesHashed = Buffer.Buffer.from(JSON.stringify(files)).toString('base64');
        params.append('files', filesHashed);
        params.append('uid_report', uid_report);
        params.append('ref', ref);
        params.append('year', year);
        params.append('isRef', isRef);
        params.append('version', version);
        return this.http.post<any>(this.reqUrl, params);
    }

    printSuivi(cover: string, html: string, plotImages: string, path: string, type = 'suivi') {
        this.reqUrl = this.basePrintUrl + 'print-suivi';
        let params = new FormData();
        params.append('type', type);
        params.append('cover', cover);
        params.append('html', html);
        params.append('plotImages', plotImages);
        params.append('path', path);
        return this.http.post<any>(this.reqUrl, params);
    }

    getIsSuiviPdfExist(path: string) {
        this.reqUrl = this.basePrintUrl + 'suivi-pdf-exists';
        let params = new FormData();
        params.append('path', path);
        return this.http.post<any>(this.reqUrl, params);
    }


    printActionsToXls(dataObj: any, uid_site: string) {
        this.reqUrl = this.baseUrl + 'endpoint/export-xls';
        let params = new FormData();
        const actionIds = ApiService.bs64FromJsonStr(dataObj);
        params.append('uid_site', uid_site);
        params.append('ids', actionIds);
        return this.http.post<any>(this.reqUrl, params);
    }

    getSiteList() {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=getSites';
        let params = new FormData();
        return this.http.post<any>(this.reqUrl, params);
    }

    getSiteSuiviReports(site: string) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=getSuivis';
        let params = new FormData();
        params.append('site', site);
        return this.http.post<any>(this.reqUrl, params);
    }

    getSiteById(uid: string) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=getSiteById';
        let params = new FormData();
        params.append('pk', uid);
        return this.http.post<any>(this.reqUrl, params);
    }

    createSite(dataObj: any) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=createSite';
        let params = new FormData();
        params.append('data', H.utf8_to_b64(JSON.stringify(dataObj)));
        return this.http.post<any>(this.reqUrl, params);
    }

    updateSite(dataObj: any, uidSite: string) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=updateSite';
        let params = new FormData();
        params.append('uid', uidSite);
        params.append('data', H.utf8_to_b64(JSON.stringify(dataObj)));
        return this.http.post<any>(this.reqUrl, params);

    }


    syncFtp(wat: string) {
        this.reqUrl = this.baseUrl + 'endpoint/sync-ftp?wat=' + wat;
        return this.http.get<any>(this.reqUrl);
    }

    saveAction(dataObj: any, uidAction: string) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=saveAction';
        let params = new FormData();
        params.append('uid', uidAction);
        params.append('data', ApiService.bs64FromJsonStr(dataObj));
        return this.http.post<any>(this.reqUrl, params);
    }

    getActions(siteId: string) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=getActions';
        let params = new FormData();
        params.append('site', siteId);
        return this.http.post<any>(this.reqUrl, params);
    }

    getAllActions(email: string) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=getAllActions';
        let params = new FormData();
        params.append('email', email);
        return this.http.post<any>(this.reqUrl, params);
    }

    getDocuments(siteId: string) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=getDocuments';
        let params = new FormData();
        params.append('site', siteId);
        return this.http.post<Resp>(this.reqUrl, params);
    }

    newDocument(doc: StorageDocument) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=setDocument';
        let params = new FormData();
        let data = {...doc};
        params.append('pk', doc.uid);
        params.append('insert', '1');
        params.append('data', ApiService.bs64FromJsonStr(data));
        return this.http.post<any>(this.reqUrl, params);
    }

    saveDocument(doc: StorageDocument) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=setDocument';
        let params = new FormData();
        let data = {
            removed: doc.removed,
            uid_domain: doc.uid_domain,
            chapter: doc.chapter,
            storage_metas: doc.storage_metas,
            full_path: doc.full_path
        };
        params.append('pk', doc.uid);
        params.append('data', ApiService.bs64FromJsonStr(data));
        return this.http.post<any>(this.reqUrl, params);
    }

    syncSuiviDocuments(sitePK: string, report: string) {
        this.reqUrl = this.baseUrl + 'endpoint/download-suivi-docs?do=notall';
        let params = new FormData();
        params.append('site', sitePK);
        params.append('report', report);
        return this.http.post<any>(this.reqUrl, params);
    }

    suiviDeleteAll(sitePK: string) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=suiviDeleteAll';
        let params = new FormData();
        params.append('site', sitePK);
        return this.http.post<any>(this.reqUrl, params);
    }

    suiviVarExplDel(sitePK: string, indic: string) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=suiviVarExplDel';
        let params = new FormData();
        params.append('site', sitePK);
        params.append('indic', indic);
        return this.http.post<any>(this.reqUrl, params);
    }

    createSuivi(site: string, user: string) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=createSuivi';
        let params = new FormData();
        params.append('site', site);
        params.append('user', user);
        return this.http.post<any>(this.reqUrl, params);
    }

    saveSuivi(suivi: ReportSuivi, dataToDave: any) {
        this.reqUrl = this.baseUrl + 'endpoint/api?do=saveSuivi';
        let params = new FormData();
        params.append('pk', suivi.uid);
        params.append('data', ApiService.bs64FromJsonStr(dataToDave));
        return this.http.post<any>(this.reqUrl, params);
    }

    // storage app
    getReportByRefKeyAndDate(ref: string, repKey: string, date: string) {
        this.reqUrl = this.baseStorageAppUrl + 'get-report';
        let params = new FormData();
        params.append('ref', ref);
        params.append('repKey', repKey);
        params.append('date', date);
        return this.http.get<any>(this.reqUrl + "?ref=" + ref);
    }

    // storage app
    getAllReportsForRef(ref: string, isAdminOrSuperUser: boolean) {
        this.reqUrl = this.baseStorageAppUrl + 'get-all-reports';
        let params = new FormData();
        params.append('ref', ref);
        params.append('show', isAdminOrSuperUser ? 'ALL' : 'READY');
        return this.http.post<any>(this.reqUrl, params);
    }

    getReportData(ref: string, date: string) {
        this.reqUrl = this.baseStorageAppUrl + 'get-report';
        let params = new FormData();
        params.append('ref', ref);
        params.append('date', date);
        return this.http.post<any>(this.reqUrl, params);
    }

    setReportStatus(uid: string, status: string, email: string, targets: any) {
        this.reqUrl = this.baseStorageAppUrl + 'set-report';
        let params = new FormData();
        params.append('uid', uid);
        params.append('status', status);
        params.append('email', email);
        params.append('targets', ApiService.bs64FromJsonStr(targets));
        return this.http.post<any>(this.reqUrl, params);
    }

    getInvitation(inviteToken: string) {
        this.reqUrl = this.baseUrl + 'endpoint/get-invitation';
        let params = new FormData();
        params.append('inviteToken', inviteToken);
        return this.http.post<any>(this.reqUrl, params);
    }

    confirmInvitation(oldUID: string, newFbUser: string) {
        this.reqUrl = this.baseUrl + 'endpoint/confirm-invitation';
        let params = new FormData();
        params.append('oldUserUID', oldUID);
        params.append('newUserUID', newFbUser);
        return this.http.post<any>(this.reqUrl, params);
    }

    sendInviteEmail(inviteToken: string, userEmail: string) {
        this.reqUrl = this.baseUrl + 'endpoint/send-invite';
        let params = new FormData();
        /*
          $userEmail = $_POST['userEmail'];
        $inviteToken = $_POST['inviteToken'];
         */
        params.append('userEmail', userEmail);
        params.append('inviteToken', inviteToken);
        return this.http.post<any>(this.reqUrl, params);
    }


    getPageVisuals(ref: string, date: string, key: string, position: number) {
        this.reqUrl = this.baseStorageAppUrl + 'get-page-visuals';
        let params = new FormData();
        params.append('ref', ref);
        params.append('date', date);
        params.append('key', key);
        params.append('pos', position.toString());
        return this.http.post<any>(this.reqUrl, params);
    }

    protoCheckPublishing(ref: string, date: string) {
        this.reqUrl = this.baseStorageAppUrl + 'check-page-publishing';
        let params = new FormData();
        params.append('ref', ref);
        params.append('date', date);
        return this.http.post<any>(this.reqUrl, params);
    }

    protoSavePagesFromJson(ref: string, date: string) {
        this.reqUrl = this.baseStorageAppUrl + 'save-pages';
        let params = new FormData();
        params.append('ref', ref);
        params.append('date', date);
        return this.http.post<any>(this.reqUrl, params);
    }

    protoPopulatePages(ref: string) {
        this.reqUrl = this.baseStorageAppUrl + 'populate-pages';
        let params = new FormData();
        params.append('ref', ref);
        return this.http.post<any>(this.reqUrl, params);
    }

    // STATMENS - PDMS

    loadStatmens(key: string) {
        this.reqUrl = this.baseUrl + 'mens/api?do=listStatmens';
        let params = new FormData();
        params.append('key', key);
        return this.http.post<any>(this.reqUrl, params);
    }

    getStatMensFull(uid: string) {
        this.reqUrl = this.baseUrl + 'mens/api?do=getStatMens';
        let params = new FormData();
        params.append('pk', uid);
        return this.http.post<any>(this.reqUrl, params);
    }

    checkStatmens(xls: string, siteRef: string, fluid: string) {
        this.reqUrl = this.baseUrl + 'mens/api?do=checkStatmens';
        let params = new FormData();
        params.append('file', xls);
        params.append('ref', siteRef);
        params.append('fluid', fluid);
        return this.http.post<any>(this.reqUrl, params);
    }

    syncPdmsMeteo(site: ClientSite, pdmUID: string, updateAll = '0') {
        this.reqUrl = this.baseUrl + 'mens/popu-meteo';
        let params = new FormData();

        params.append('forPdmId', pdmUID);
        params.append('forSiteId', site.uid);
        params.append('meteoStation', site.weather.replace('.json', ''));
        params.append('updateAll', updateAll);
        return this.http.post<any>(this.reqUrl, params);
    }

    syncPdmsHoraire(site: ClientSite, forPdmId: string, updateAll = '0') {
        this.reqUrl = this.baseUrl + 'mens/popu-horaires';
        let params = new FormData();
        params.append('forPdmId', forPdmId);
        params.append('vars', H.utf8_to_b64(JSON.stringify(site.vars_extra)));
        params.append('updateAll', updateAll);
        return this.http.post<any>(this.reqUrl, params);
    }

    getGlobalVarExpl() {
        this.reqUrl = this.baseUrl + 'mens/api?do=getExtraVars';
        let params = new FormData();
        return this.http.post<Resp>(this.reqUrl, params);
    }

    printPdmToXlsOld(pdmUID: string) {
        this.reqUrl = this.baseUrl + 'mens/pdm-to-xls';
        let params = new FormData();
        params.append('pdmUID', pdmUID);
        return this.http.post<any>(this.reqUrl, params);
    }

    printPdmToXls(dataObj: any) {
        this.reqUrl = this.baseUrl + 'mens/pdm-to-xls';
        let params = new FormData();
        const dataToSend = ApiService.bs64FromJsonStr(dataObj);
        params.append('data', dataToSend);
        return this.http.post<Resp>(this.reqUrl, dataToSend, {
            /// WHEN HEADERS IS application/json $_POST will not populate
            headers: new HttpHeaders(
                //{'Content-Type': 'application/json'},
                {'Content-Type': 'text/html; charset=utf-8'},
            ),
            observe: 'events',
            reportProgress: true,
            withCredentials: false,
            responseType: "json",
        });
    }

    getMeteoStations() {
        this.reqUrl = this.baseUrl + 'mens/api?do=getMeteoStations';
        let params = new FormData();
        return this.http.post<any>(this.reqUrl, params);
    }


    savePdm(dataObj: Pdm, insert: boolean = false) {
        this.reqUrl = this.baseUrl + 'mens/api?do=savePdm';
        let params = new FormData();
        params.append('insert', insert ? '1' : '0');
        params.append('uid', dataObj.uid);
        params.append('data', H.utf8_to_b64(JSON.stringify(dataObj)));
        return this.http.post<any>(this.reqUrl, params);
    }

    getPdms(forSiteId: string) {//forSiteId='all' to get all pdms
        this.reqUrl = this.baseUrl + 'mens/api?do=getPdms';
        let params = new FormData();
        params.append('site', forSiteId);
        return this.http.post<any>(this.reqUrl, params);
    }

    populatePdmSummary(uidPdm: string) {
        this.reqUrl = this.baseUrl + 'mens/api?do=populatePdmSummary';
        let params = new FormData();
        params.append('uidPdm', uidPdm);
        return this.http.post<any>(this.reqUrl, params);
    }

    savePdmReg(dataObj: PdmRegDef, insert: boolean = false) {
        this.reqUrl = this.baseUrl + 'mens/api?do=savePdmReg';
        let params = new FormData();
        params.append('insert', insert ? '1' : '0');
        params.append('uid', dataObj.uid);
        params.append('data', ApiService.bs64FromJsonStr(dataObj));
        return this.http.post<any>(this.reqUrl, params);
    }

    getPdmRegs(forPdmId: string) {
        this.reqUrl = this.baseUrl + 'mens/api?do=getPdmRegs';
        let params = new FormData();
        params.append('pdm', forPdmId);
        return this.http.post<any>(this.reqUrl, params);
    }

    getAllPdmsRegsForSite(forSiteId: string) {
        this.reqUrl = this.baseUrl + 'mens/api?do=getAllPdmsRegsForSite';
        let params = new FormData();
        params.append('site', forSiteId);
        return this.http.post<any>(this.reqUrl, params);
    }

    getAllPdmsRegsForSiteAndFluid(forSiteId: string, fluid: string) {
        this.reqUrl = this.baseUrl + 'mens/api?do=getAllPdmsRegsForSiteAndFluid';
        let params = new FormData();
        params.append('site', forSiteId);
        params.append('fluid', fluid);
        return this.http.post<any>(this.reqUrl, params);
    }

    setPdmYearConfig(pdm: Pdm, site: ClientSite, year: number, wat: string, data: any) {
        this.reqUrl = this.baseUrl + 'mens/api?do=setPdmYearConfig';
        let params = new FormData();
        const ids = {
            uid_site: site.uid,
            uid_client: site.uid_client,
            uid_pdm: pdm.uid,
            fluid: pdm.fluid,
            year: year
        };
        params.append('uid', pdm.uid + "_" + year);
        params.append('wat', wat);
        params.append('data', ApiService.bs64FromJsonStr(data));
        params.append('ids', ApiService.bs64FromJsonStr(ids));
        return this.http.post<Resp>(this.reqUrl, params);
    }

    getAllPdmsYearlyConfigForSite(uidSite: string) {
        this.reqUrl = this.baseUrl + 'mens/api?do=getPdmYearConfigForSite';
        let params = new FormData();
        params.append('site', uidSite);
        return this.http.post<Resp>(this.reqUrl, params);
    }

    savePdmDataOneChunk(dataObj: any, isVpdm: boolean) {
        this.reqUrl = this.baseUrl + 'mens/api?do=savePdmDataBigChunk';
        let params = new FormData();
        params.append('isVpdm', isVpdm ? '1' : '0');
        params.append('data', ApiService.bs64FromJsonStr(dataObj));
        return this.http.post<Resp>(this.reqUrl, params, {
            /// WHEN HEADERS IS application/json $_POST will not populate
            headers: new HttpHeaders(
                //{'Content-Type': 'application/json'},
            ),
            observe: 'events',
            reportProgress: true,
            withCredentials: false,
            responseType: "json",
        });
    }

    getPdmData(forPdmId: string, withMeta: boolean = true, isVpdm: boolean) {
        this.reqUrl = this.baseUrl + 'mens/api?do=getPdmData';
        let params = new FormData();
        params.append('pdm', forPdmId);
        params.append('isVpdm', isVpdm ? '1' : '0');
        params.append('withMeta', withMeta ? '1' : '0');
        return this.http.post<any>(this.reqUrl, params);
    }

    savePdmDataIncrementaly(dataObj: any) {
        this.reqUrl = this.baseUrl + 'mens/api?do=savePdmData';
        let params = new FormData();
        params.append('uid', dataObj.uid);
        params.append('data', H.utf8_to_b64(JSON.stringify(dataObj)));
        return this.http.post<any>(this.reqUrl, params);
    }

    delPdm(uidPdm: string) {
        this.reqUrl = this.baseUrl + 'mens/api?do=delPdm';
        let params = new FormData();
        params.append('uid', uidPdm);
        return this.http.post<any>(this.reqUrl, params);
    }

    delPdmReg(uidReg: string) {
        this.reqUrl = this.baseUrl + 'mens/api?do=delPdmReg';
        let params = new FormData();
        params.append('uid', uidReg);
        return this.http.post<any>(this.reqUrl, params);
    }

    delPdmData(uid_pdm: string) {
        this.reqUrl = this.baseUrl + 'mens/api?do=delPdmData';
        let params = new FormData();
        params.append('uid_pdm', uid_pdm);
        return this.http.post<any>(this.reqUrl, params);
    }

    getEpLinks(prefix: string) {
        this.reqUrl = this.baseUrl + 'mens/ep-links';
        let params = new FormData();
        params.append('prefix', prefix);
        return this.http.post<any>(this.reqUrl, params);
    }

    getRegLinks(dbName: string) {
        this.reqUrl = this.baseUrl + 'mens/api?do=getPdmEpLinkDb';
        let params = new FormData();
        params.append('dbName', dbName);
        return this.http.post<any>(this.reqUrl, params);
    }

    getBatList(dbName: string) {//temp for R&D
        this.reqUrl = this.baseUrl + 'endpoint/list';
        let params = new FormData();
        params.append('dbName', dbName);
        return this.http.post<any>(this.reqUrl, params);
    }

}

import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {BaseComponent} from "../../shared/BaseComponent";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AppService} from "../../shared/services/app.service";
import {SiteService} from "../../shared/services/site.service";
import {EnergyStats} from "app/shared/models/EnergyStats.model";
import {BmensData, BmensItem, BmensMeter} from "app/shared/models/Bmens.model";
import {environment} from "../../../environments/environment";
import {Notif} from "../../shared/models/Notifs";

@Component({
    selector: 'bloc-cons',
    template: `
        <div fxLayout="row">
            <div fxLayout="column" fxFlex="50">
                <mat-card class="p-16">
                    <h2>Conso. d'énergie et d'eau {{ site.clientSite.year_tariff_display }}</h2>
                    <table class="home-cons-table" *ngIf="myapp.getIfUserCanDo('BILAN_CONSO')">
                        <tr class="head">
                            <td class="col1">Agent énergétique</td>
                            <td>Consommation/an</td>
                            <td>Coûts/an</td>
                            <td>Prix unitaire</td>
                        </tr>

                        <tr *ngFor="let tc of site.clientSite.tariff_config">
                            <ng-container *ngIf="site.getTariffDataForYear(tc.uid,1) as tarifData">
                                <td class="col1">{{ tc.label }}</td>
                                <td>{{ tarifData.energyAdapted| number }} <span>{{ tc.unitStr }}</span></td>
                                <td>{{ tarifData.cost | currency }}</td>
                                <td>{{ tarifData.priceAdapted| currency }}
                                    <span>{{ tc.priceUnitStr }}</span>
                                </td>
                            </ng-container>

                        </tr>
                        <tr class="row-total">
                            <td class="col1 align-right">Total</td>
                            <td><!--{{totAdaptedAmount | number}} <span>{{amountUnit}}</span>--></td>
                            <td>{{ totCost | currency }}</td>
                            <td></td>
                        </tr>
                    </table>
                    <div *ngIf="!myapp.getIfUserCanDo('BILAN_CONSO')" class="p-32"><i>Accès réservé</i></div>
                </mat-card>
                <mat-card *ngIf="site.clientSite.map_url">
                    <iframe frameborder="0"
                            [src]="site.clientSite.map_url|safe"
                            width="100%" height="405px"></iframe>
                </mat-card>
            </div>
            <div fxLayout="column" fxFlex="50">
                <mat-card class="p-16" *ngIf="lastRelevesOfTotal&&lastRelevesOfTotal.length">
                    <h2>Dernier bilan : {{ lastRelevesOfTotal[0].dateStr }}</h2>
                    <table class="home-cons-table" *ngIf="myapp.getIfUserCanDo('BILAN_LAST')">
                        <tr class="head">
                            <td class="text-left" style="50px">Fluides</td>
                            <td>Év. conso. corrélée</td>
                            <td>Év. corrélée en Fr.</td>
                            <td>Conso. cumulée</td>
                            <td>Coûts cumulés</td>
                            <td>Tarif moy.</td>
                        </tr>
                        <tr *ngFor="let row of lastRelevesOfTotal">
                            <ng-container *ngIf="metersByUIDMap.get(row.uid_meter) as meter">
                                <ng-container *ngIf="meter.is_total">
                                    <td class="text-left color-blue"> {{ meter.label }}</td>
                                    <td class="no-wrap">
                                        <span class="p-0" *ngIf="row.rows_cache.economy_evol_value>0">+</span>
                                        <span>{{ row.rows_cache.economy_evol_value }}</span>
                                        <span class="unit">{{ row.rows_cache.energy_usage_unit }}</span>
                                        <ng-container *ngIf="row.rows_cache.economy_evol_percent">
                                            <span style="width: auto">{{ row.rows_cache.economy_evol_percent }}</span>
                                            <span>%</span>
                                        </ng-container>
                                    </td>
                                    <td>
                                        <span>{{ row.rows_cache.cost_corr_evol|number:'1.0' }}</span>
                                        <span *ngIf="row.rows_cache.cost_corr_evol" class="unit">Fr. HTVA</span>
                                    </td>
                                    <td title="energy_usage and unit" (click)="myapp.showConsole(meter,row)">
                                        <span>{{ row.rows_cache.energy_usage|number:'1.0' }}</span>
                                        <span class="unit"> {{ meter.energyUnit }}</span>
                                    </td>
                                    <td>
                                        <span>{{ row.rows_cache.energy_cost|currency }}</span>
                                    </td>
                                    <td title="tariff">
                                        <span>{{ row.rows_cache.tariff|number:'1.1-2' }}</span>
                                        <!-- <span *ngIf="row.rows_cache.tariff" class="unit">{{ row.rows_cache.tariff_unit }}</span>-->
                                        <span *ngIf="meter.tariff_unit" class="unit">{{ meter.tariff_unit }}</span>
                                    </td>
                                </ng-container>
                            </ng-container>

                        </tr>
                        <tr class="row-total">
                            <td colspan="2" class="col1 align-right">Total</td>
                            <td>{{ totEvolCorr }} Fr. HTVA</td>
                            <td colspan="2">{{ totCostCuml|currency }}</td>
                            <td colspan="2"></td>
                        </tr>
                    </table>
                    <div *ngIf="!myapp.getIfUserCanDo('BILAN_LAST')" class="p-32"><i>Accès réservé</i></div>
                </mat-card>

                <mat-card class="p-4"
                          [style.background-color]="myapp.newerAppVerion?'yellow':'white'">
                    <div *ngIf="env" class="app-ver" fxLayout="row">
                        <span fxFlex>{{ env.version }}</span>
                        <span fxFlex>{{ env.version_ts|amTimeAgo }}</span>
                        <button *ngIf="myapp.newerAppVerion" (click)="reloadApp()"
                                mat-raised-button class="btn-xs-25">
                            Recharger l'application
                        </button>
                    </div>
                    <div *ngIf="env" class="app-ver">
                        <p *ngIf="myapp.newerAppVerion">
                            Une nouvelle version est disponible
                        </p>
                    </div>
                </mat-card>


                <mat-card class="p-8 notifs-card" *ngIf="site.notifsOfCurrentsite.length">
                    <h2>Notifications</h2>
                    <div class="p-4 item" *ngFor="let n of site.notifsOfCurrentsite" fxLayout="row"
                         fxLayoutAlign="space-between center">
                        <div fxFlex>
                            <div>
                                <b class="color-blue">{{ n.route }}</b>: <i>Par {{ n.sender.name }}</i>
                                {{ n._mom.fromNow() }}
                            </div>
                            <div *ngIf="n.key.includes('PROTOHEBDO_NEWCOMM')">
                                Nouvelle annotation: Page: {{ n.position }} | {{ n.label }}
                            </div>
                            <div *ngIf="n.key.includes('PROTOHEBDO_NEWREPLY')">
                                Nouvelle réponse: Page: {{ n.position }} | {{ n.label }}
                            </div>
                        </div>
                        <div fxFlex="50px">
                            <button mat-raised-button class="btn-xs-25" color="primary" (click)="openNotif(n)">
                                Ouvrir
                            </button>
                        </div>
                    </div>
                </mat-card>
            </div>

        </div>
    `,
    styleUrls: ['./site-home.component.scss']
})

export class BlocConsComponent extends BaseComponent implements AfterViewInit, OnInit, OnChanges {
    @Input('forceUpdate') forceUpdate: number;

    env: any;

    totAdaptedAmount = 0;
    amountUnit = '';
    totCost = 0;

    totEvolCorr = 0;
    totCostCuml = 0;

    metersByUIDMap: Map<string, BmensMeter> = new Map<string, BmensMeter>();
    lastReleve: BmensData; // detect latest key
    allReleves: BmensData[] = [];
    lastRelevesOfTotal: BmensData[] = [];

    constructor(
        public myapp: AppService,
        public site: SiteService,
        private fb: FormBuilder,
    ) {
        super();
    }

    ngOnInit() {
        this.totAdaptedAmount = 0;
        this.totCost = 0;

        if (this.site.clientSite.tariff_config) {
            this.site.clientSite.tariff_config.forEach(tc => {
                const tariffDataForDispplayedYear = this.site.getTariffDataForYear(tc.uid, 1);
                if (tariffDataForDispplayedYear) {
                    this.totAdaptedAmount += tariffDataForDispplayedYear.energyAdapted;
                    this.totCost += Number(tariffDataForDispplayedYear.cost || 0);
                    // console.log('this.totCost:', this.totCost, tariffDataForDispplayedYear.cost);
                    this.amountUnit = tariffDataForDispplayedYear.unit;
                }

            });
        }

        this.env = environment;
        this.env.version_ts = Number(environment.version_ts);
        this.loadData();
    }

    loadData() {

        this.site.api.getAllBmens(this.site.uid).subscribe(resp => {
            this.allReleves = resp.body.data.map(it => new BmensData(it));
            this.lastReleve = this.allReleves[this.allReleves.length - 1];
            resp.body.meters.map(it => {
                const meter = new BmensMeter(it);
                this.metersByUIDMap.set(meter.uid, meter);
            });
            this.lastRelevesOfTotal = this.allReleves.filter(it => it.releve_key === this.lastReleve.releve_key);
            if (this.lastRelevesOfTotal && Array.isArray(this.lastRelevesOfTotal))
                this.calcTotal();
            console.log('getAllBmens():', this.metersByUIDMap, this.lastRelevesOfTotal, this.lastReleve);
        });
    }

    calcTotal() {
        this.totEvolCorr = 0;
        this.totCostCuml = 0;
        this.lastRelevesOfTotal.forEach(releve => {
            const parentMeter = this.metersByUIDMap.get(releve.uid_meter);
            if (parentMeter.is_total) {
                this.totEvolCorr += Number(releve.rows_cache.cost_corr_evol);
                this.totCostCuml += Number(releve.rows_cache.energy_cost);
            }
        });
    }


    reloadApp() {
        location.reload();
        window.location.href = window.location.href;
    }

    openNotif(n: Notif) {
        if (n.route === 'PROTOHEBDO') {
            this.site.protoHebdoPageToRedirect = n.position;
            this.site.router.navigate(
                ['/proto-hebdo/' + this.site.clientSite.uid + "/"
                + n.year + '/' + n.week]);
        }

    }

    bootstrap() {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.bootstrap();
    }

    ngAfterViewInit(): void {
    }
}

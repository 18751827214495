import moment from "moment/moment";
import {K} from "./K";
import {H} from "../helpers/H";
import {ClientSite, User} from "./models";
import {EnergyStats} from "./EnergyStats.model";
import {SiteService} from "../services/site.service";

export class UserSession {
    appVersion: number;
    history: any;
    lastOnline: number;
    name: string;
    siteREF: string;
    wat: string;
    historyList: UserHistory[] = [];
    visitedRefs: UserHistory[] = [];

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
        if (this.history) {
            this.historyList = [];
            Object.keys(this.history).forEach(ref => {
                const h = new UserHistory(this.history[ref]);
                Object.values(h).forEach(histItem => {
                    this.historyList.push(histItem);
                });

                //console.log("historyList", this.historyList, h);
            });
            this.visitedRefs = Object.keys(this.history)
                .map(ref => this.getLatestHistory(ref))
                .sort((a, b) => {
                    return b.lastOnline - a.lastOnline;
                });
        }
    }


    getLatestHistory(siteRef: string): UserHistory {
        let historyForSiteArr: UserHistory[] = [];
        const historyForSiteObj = this.history[siteRef];
        Object.values(historyForSiteObj).forEach(hist => {
            const h = new UserHistory(hist);
            historyForSiteArr.push(h);
        });

        historyForSiteArr.sort((a, b) => {
            return b.lastOnline - a.lastOnline;
        });
        return historyForSiteArr[0];
    }

    getHistory(siteRef: string): UserHistory[] {
        const historyForSiteArr: UserHistory[] = [];
        const historyForSiteObj = this.history[siteRef];
        Object.values(historyForSiteObj).forEach(hist => {
            const h = new UserHistory(hist);
            historyForSiteArr.push(h);
        });

        return historyForSiteArr.sort((a, b) => {
            return b.lastOnline - a.lastOnline;
        });
    }
}


export class UserHistory {
    appVersion: number;
    lastOnline: number;
    siteREF: string;
    siteUID: string;
    wat: string;

    constructor(dynProps: any = {}) {
        this.appVersion = 0;
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
    }
}

import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewContainerRef
} from '@angular/core';
import {AppService} from "../../../shared/services/app.service";
import {ApiService} from "../../../shared/services/api.service";
import {Overlay} from "@angular/cdk/overlay";
import {SiteService} from "../../../shared/services/site.service";
import {LayoutService} from "../../../shared/services/core/layout.service";
import {DomSanitizer} from "@angular/platform-browser";
import {egretAnimations} from "../../../shared/animations/egret-animations";
import {BaseComponent} from "../../../shared/BaseComponent";
import {RegFieldXlsMappingConfigItem} from "../../../shared/models/PdmDataRow";
import {PdmRegDef} from "../../../shared/models/PdmRegDef";
import {NgxFileDropEntry} from "ngx-file-drop";
import {HttpHeaders} from "@angular/common/http";

@Component({
    selector: 'app-xls-mapper',
    template: `
        <div class="flex-container full-width "
             fxLayout="row" fxLayoutAlign="space-between stretch" style="height: 95%" fxFlex>

            <div #xlsParent fxFlex="1100px" fxLayout="column" fxLayoutAlign="space-between stretch">
                <div fxFlex="80px" class="mr-8">
                    <mat-form-field class="full-width p-8 mr-8 border-bottom">
                        <mat-select placeholder="Choix du statmens"
                                    [value]="lastXlsSelected"
                                    (selectionChange)="selectStatmens($event.source.value)">
                            <mat-option *ngFor="let mens of list_statmens" [value]="mens.file_name">
                                {{ mens.file_name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="90">
                    <ngx-spinner bdColor="rgba(150, 100, 250, 0.7)"
                                 size="medium" color="#fff"
                                 name="xls"
                                 type="timer" [fullScreen]="false">
                        <p style="color: white"> Chargement </p>
                    </ngx-spinner>

                    <div *ngIf="htmlEtats&&htmlDonnees" fxFlex fxLayout="column" style="height: 97%"
                         [@animate]="{ value: '*', params: { opacity: '0', delay: '600ms' } }">
                        <div id="DONNEES" fxFlex="50" class=" html-holder" #donnees [innerHTML]="htmlDonnees"></div>
                        <div id="ETATS" fxFlex="50" class=" html-holder" #etats [innerHTML]="htmlEtats"></div>
                    </div>
                </div>
            </div>

            <div fxFlex fxLayout="column" fxLayoutAlign="space-between stretch">
                <!-- SUMMARY -->
                <div fxLayout="row" class="panel-summary">
                    <div fxLayout="column" fxFlex="60">
                        <div fxLayout="row" class="bg-white border-bottom"
                             style="font-size: 14px" *ngFor="let v of regFieldXlsMappingConfig|keyvalue">
                            <div fxFlex="150px" style="background: white;font-weight: bold"> {{ v.key }}</div>
                            <div class="numeric" fxFlex="150px" style="font-size: 12px">
                                {{ v.value }}
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex>
                        <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)"
                                       (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
                            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                                <button type="button" mat-raised-button
                                        (click)="openFileSelector()">Parcourir statmens Xls
                                </button>
                            </ng-template>
                        </ngx-file-drop>
                    </div>
                </div>
                <!--
                  Extracted data
                  -->
                <div fxLayout="column" fxFlex>
                    <div fxFlex="50px" fxLayout="row" class="xls-extracted-data-list-header">
                        <div fxFlex="30px" style="font-weight: bold">Ln</div>
                        <div fxFlex="150px" style="font-weight: bold"> DATE</div>
                        <div fxFlex="150px" class="numeric" style="font-size: 14px">
                            {{ regField|uppercase }}
                            <span *ngIf="reg">{{ reg.num }}</span>
                        </div>
                        <div fxFlex>-</div>
                    </div>
                    <div fxFlex style="overflow-y: scroll" class="scroller">
                        <div fxLayout="column" class="xls-extracted-data-list">
                            <div #extractedRow fxLayout="row"
                                 class="xls-extracted-data-item" fxLayoutAlign="space-between center"
                                 [ngClass]="{highlightEnd:v.key===endDateInCacheForPdm,highlightStart:v.key===firstDateInCacheForPdm}"
                                 [attr.id]="v.key"
                                 style="font-size: 14px" *ngFor="let v of columnDataMap|keyvalue;let i=index">
                                <div fxFlex="150px" class="font-weight-bold">
                                    <span fxFlex="30px">{{ i + 1 }}:</span>
                                    <strong class="color-blue" style="font-weight: 600"> {{ v.key }}</strong>
                                </div>
                                <div fxFlex="150px" class="numeric" [title]="v.value" style="font-size: 13px">
                                    {{ v.value|number }}
                                </div>
                                <div fxFlex fxLayout="row">
                                    <button mat-raised-button class="btn-xs-25 m-4" (click)="setRange('start',v.key)">Début ici</button>
                                    <button mat-raised-button class="btn-xs-25 m-4" (click)="setRange('end',v.key)">Fin ici</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!--  but assign to regfield   -->
                <div fxLayout="row" fxFlex="90px" class="border-top pb-8" *ngIf="(reg&&regField)">
                    <button mat-raised-button color="primary" (click)="assignToRegField()" class="m-8 full-width">Assign to Reg-Field</button>
                    <button mat-raised-button color="primary" (click)="assignToRegField(-1)" class="m-8 full-width">Assign (-1) reg</button>
                </div>

            </div>
        </div>
    `,
    styleUrls: ['./shared.component.scss'],
    animations: egretAnimations
})
export class XlsMapperComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input('filter') filter: string;
    @Input('reg') reg: PdmRegDef;
    @Input('regField') regField: string;
    @Input('pdmUid') pdmUid: string;
    @Output('cb') cb: EventEmitter<any> = new EventEmitter<any>();

    @ViewChildren('extractedRow') extractedRows: QueryList<any>;
    @ViewChild('xlsParent', {static: false}) xlsParent: ElementRef<HTMLDivElement>;
    @ViewChild('donnees', {static: false}) donnees: ElementRef<HTMLDivElement>;
    @ViewChild('etats', {static: false}) etats: ElementRef<HTMLDivElement>;

    public list_statmens = [];

    statmensSummaryObj: any;
    statmensFullObj: any;
    itemCount = 0;
    progress = 0;

    htmlDonnees: string;
    htmlDonneesDom: any;
    htmlEtats: string;
    domObserver: MutationObserver;

    regFieldXlsMappingConfig: RegFieldXlsMappingConfigItem = new RegFieldXlsMappingConfigItem();
    columnDataMap: Map<string, number> = new Map<string, number>();

    firstDateInCacheForPdm = "";
    endDateInCacheForPdm = "";
    public files: NgxFileDropEntry[] = [];

    constructor(public myapp: AppService, public api: ApiService,
                public overlay: Overlay, public site: SiteService,
                public viewContainerRef: ViewContainerRef,
                public layout: LayoutService, public sanitizer: DomSanitizer) {
        super();
        this.overlay = overlay;
        this.viewContainerRef = viewContainerRef;
    }

    assignToRegField(fact: number = 1) {
        if (fact === -1) {
            this.columnDataMap.forEach((val, date) => {
                this.columnDataMap.set(date, val * -1);
            });
        }
        if (!this.site.selectedPdm.date_start) {
            this.myapp.showError("La date d'installation du PDM n'est pas fournie. Vous devez la mentionner avant d'importer.");
        } else {
            this.cb.emit({data: this.columnDataMap, mapConfig: this.regFieldXlsMappingConfig});
        }
    }

    refreshList($ev, val) {
        const key = val || ($ev ? $ev.target.value : '');
        this.myapp.spinner.show('xls', {fullScreen: false});
        this.api.loadStatmens(this.site.clientSite.statmens_prefix).subscribe(res => {
            this.list_statmens = (res.body);
            console.log("loadStatmens", res)
            if (this.lastXlsSelected) {
                this.selectStatmens(this.lastXlsSelected);
            } else this.myapp.spinner.hide('xls');
        });
    }

    get lastXlsSelected() {
        const lastPdmUidEdited = localStorage.getItem("pdmToEdit");
        if (lastPdmUidEdited === this.pdmUid) {
            const pdmXlsLastSelected = localStorage.getItem("pdmXlsLastSelected");
            if (lastPdmUidEdited !== undefined) return pdmXlsLastSelected;
        }
        return null;
    }

    selectStatmens(fileName) {
        console.log('selectStatmens', fileName, ">>>>>" + this.pdmUid);
        this.myapp.spinner.show('xls', {fullScreen: false});
        this.htmlEtats = null;
        this.htmlDonnees = null;
        this.statmensSummaryObj = this.getItemFromArrayByFieldVal(this.list_statmens, 'file_name', fileName);
        if (!this.statmensSummaryObj) {
            console.error("StatMens Not found in filtered list: was seeking for: " + fileName);
            this.myapp.spinner.hide('xls');
            return;
        }
        localStorage.setItem("pdmToEdit", this.pdmUid);
        localStorage.setItem("pdmXlsLastSelected", this.statmensSummaryObj.file_name);
        // retrieve last row to scroll
        this.endDateInCacheForPdm = localStorage.getItem("cacheLastRow_" + this.pdmUid);
        this.firstDateInCacheForPdm = localStorage.getItem("cacheFirstRow_" + this.pdmUid);
        let loaded = 0;
        this.regFieldXlsMappingConfig = new RegFieldXlsMappingConfigItem();
        this.regFieldXlsMappingConfig.xls_file = this.statmensSummaryObj.file_name;
        this.regFieldXlsMappingConfig.targetField = this.regField;
        this.site.api.getHtmlFromXls(this.statmensSummaryObj.file_name, "ETATS").subscribe(res => {
            this.htmlEtats = res.body;
            if (res.status === 0) this.myapp.toastr.error("Fichier introuvable: " + this.statmensSummaryObj.file_name, "Error", {timeOut: 2000});
            loaded++;
            if (loaded === 2)
                this.myapp.spinner.hide('xls');
        });
        this.site.api.getHtmlFromXls(this.statmensSummaryObj.file_name, "DONNEES").subscribe(res => {
            this.htmlDonnees = res.body;
            if (res.status === 0) this.myapp.toastr.error("Fichier introuvable: " + this.statmensSummaryObj.file_name, "Error", {timeOut: 2000});
            loaded++;
            if (loaded === 2)
                this.myapp.spinner.hide('xls');
        });
    }

    resetAttr() {
        this.donnees.nativeElement.querySelectorAll('.active').forEach(item => {
            item.classList.remove('active');
        });

        this.donnees.nativeElement.querySelectorAll('.mapped').forEach(item => {
            item.classList.remove('mapped');
        });
        this.etats.nativeElement.querySelectorAll('.active').forEach(item => {
            item.classList.remove('active');
        });

        this.etats.nativeElement.querySelectorAll('.mapped').forEach(item => {
            item.classList.remove('mapped');
        });
    }

    setRange(type: string, date: string) {
        console.log("setRange-" + type, date);
        const dt = new Date(date);
        if (type === "end") {
            localStorage.setItem("cacheLastRow_" + this.pdmUid, date);
        }
        if (type === "start") {
            localStorage.setItem("cacheFirstRow_" + this.pdmUid, date);
        }
        this.columnDataMap.forEach((v, k) => {
            const rowDate = new Date(k);
            if (type === "start") {
                this.regFieldXlsMappingConfig.rowDateStart = date;
                if (rowDate.getTime() < dt.getTime()) this.columnDataMap.delete(k);
            }
            if (type === "end") {
                this.regFieldXlsMappingConfig.rowDateEnd = date;
                if (rowDate.getTime() > dt.getTime()) this.columnDataMap.delete(k);
            }
        });
    }

    scrollToLatest() {
        this.extractedRows.forEach(item => {
            const div = item.nativeElement as HTMLDivElement;
            if (item.nativeElement.id === this.endDateInCacheForPdm) {
                div.parentElement.parentElement.scrollTop = item.nativeElement.offsetTop - 320;
            }
        });
    }

    setListener(nativeEl, panelName: string) {

        nativeEl.addEventListener('mouseover', (e) => {
            const col = e.target.getAttribute('aria-colindex');
            const rowIndex = e.target.parentElement.rowIndex;
            const date = e.target.getAttribute('aria-valuenow');
            const table = e.target.closest('table') as HTMLTableElement;
            const content = e.target.innerText;


            if (table && date && col && col > 1 && content.length > 0) {
                // console.log(e.target);
                for (let r = 0; r < table.rows.length; r++) {
                    const row: HTMLTableRowElement = table.rows.item(r);
                    if (!row.cells.item(col).classList.contains('mapped')) {
                        row.cells.item(col).classList.add('highlight');
                    }
                }
            }
        });


        nativeEl.addEventListener('mouseout', (e) => {
            nativeEl.querySelectorAll('.highlight').forEach(item => {
                item.classList.remove('highlight');
            });
        });


        nativeEl.addEventListener('click', (e) => {
            const cell: HTMLTableCellElement = (e.target as HTMLTableCellElement);
            const rowIndex = (cell.parentElement as HTMLTableRowElement).rowIndex;
            const date = cell.getAttribute('aria-valuenow');
            const cellIndex = cell.cellIndex;
            const parentTable = cell.closest('.html-holder') as HTMLDivElement;
            console.log("parentTable-" + this.regField, parentTable);
            this.resetAttr();
            this.regFieldXlsMappingConfig.reset();
            this.regFieldXlsMappingConfig.colIndex = cellIndex;
            this.regFieldXlsMappingConfig.panelName = parentTable ? parentTable.id : "none";
            this.columnDataMap.clear();
            nativeEl.querySelectorAll('tr').forEach(row => {
                const rowElm = row as HTMLTableRowElement;
                try {
                    if (rowElm.rowIndex === 2 && rowElm.cells && rowElm.cells.length > 0 && rowElm.cells[cellIndex])
                        this.regFieldXlsMappingConfig.colUnit = rowElm.cells[cellIndex].innerText;
                    if (rowElm.rowIndex === 3 && rowElm.cells && rowElm.cells.length > 0 && rowElm.cells[cellIndex])
                        this.regFieldXlsMappingConfig.colLabel = rowElm.cells[cellIndex].innerText;
                    let date = rowElm.getAttribute('aria-details');
                    if (!date) return;

                    row.querySelectorAll('td').forEach(td => {
                        const tdCell = td as HTMLTableCellElement;

                        if (tdCell.cellIndex === cellIndex && date.length === 10) {
                            const cellVal = parseFloat(tdCell.getAttribute('alt'));
                            //if (!isNaN(cellVal))
                            {
                                // console.log("Cell", date, cellVal);
                                if (this.regFieldXlsMappingConfig.rowIndexStart === 0) {
                                    this.regFieldXlsMappingConfig.rowIndexStart = rowElm.rowIndex;
                                    this.regFieldXlsMappingConfig.rowDateStart = date;
                                }
                                this.regFieldXlsMappingConfig.initCount++;
                                this.columnDataMap.set(date, cellVal);
                                tdCell.classList.add("mapped");
                            }
                        }
                    });
                } catch (e) {
                    console.log("Err", cellIndex, cell);
                    console.error(e);
                }


            });
            const dis = this;
            setTimeout(() => {
                dis.scrollToLatest();
            }, 500);


        });
    }

    public dropped(files: NgxFileDropEntry[]) {
        this.files = files;
        for (const droppedFile of files) {
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    // Here you can access the real file
                    console.log("Dropped file:", droppedFile.relativePath, file);
                    // You could upload it like this:
                    const formData = new FormData();
                    formData.append('logo', file);
                    // Headers
                    const headers = new HttpHeaders({
                        'enctype': 'multipart/form-data'
                    });

                    this.site.http.post('https://api.optimigration.ch/endpoint/receive', formData,
                        {headers: headers, responseType: 'blob'})
                        .subscribe(data => {
                            console.log("Data", data);
                            this.refreshList(null, this.filter);
                        });


                });
            } else {
                // It was a directory (empty directories are added, otherwise only files)
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
                console.log(droppedFile.relativePath, fileEntry);
            }
        }
    }

    public fileOver(event) {
        //console.log(event);
    }

    public fileLeave(event) {
        // console.log(event);
    }


    ngOnInit(): void {
        this.refreshList(null, this.filter);

        this.domObserver = new MutationObserver(list => {
            list.forEach(item => {
                if (item.target['id'] === "DONNEES") this.setListener(this.donnees.nativeElement, 'DONNEES');
                if (item.target['id'] === "ETATS") this.setListener(this.etats.nativeElement, 'ETATS');
                // console.log("Mutation", item.target['id'], item);
            });

        });
    }

    ngAfterViewInit() {
        this.domObserver.observe(this.xlsParent.nativeElement, {attributes: false, childList: true, subtree: true});
    }

    ngOnDestroy() {
    }
}

import {VisualTypes} from './constants';
import BarAdapter from './BarAdapter';
import CurveAdapter from './CurveAdapter';
import PieAdapter from './PieAdapter/PieAdapter';
import SignatureAdapter from './SignatureAdapter';

export {BarAdapter, CurveAdapter, SignatureAdapter};

export function adaptVisual(visual) {
    const data = visual.data || []
    const layout = visual.layout || {};
    const {type} = visual?.meta;

    switch (type) {
        case VisualTypes.SIGNATURE:
            return {
                ...visual,
                ...SignatureAdapter.reduce(data, layout),
            }
        case VisualTypes.BAR:
            return {
                ...visual,
                ...BarAdapter.reduce(data, layout),
            }
        case VisualTypes.CURVE:
            return {
                ...visual,
                ...CurveAdapter.reduce(data, layout),
            }
        case VisualTypes.PIE:
            return {
                ...visual,
                ...PieAdapter.reduce(data, layout),
            }
        default:
            return visual
    }
}

import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {Observable, of} from "rxjs";
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AngularFireAuthGuard} from "@angular/fire/compat/auth-guard";
import {AppService} from "../services/app.service";

@Injectable()
export class AuthGuard extends AngularFireAuthGuard {

    constructor(router: Router, auth: AngularFireAuth, public myapp: AppService) {
        super(router, auth);
    }
}

<div class="flex-container" fxLayout="column">
    <div fxLayout="row">
        <div fxFlex="400px">
            <mat-card class="p-0">
                <h3 mat-subheader>Points de mesure METEO</h3>

                <mat-list *ngIf="meteoFtpMetas">
                    <ng-container *ngFor="let ds of meteoFtpMetas">
                        <mat-list-item [ngClass]="{active:selectedDs&&ds===selectedDs}"
                                       (click)="selectMeteoDS(ds)" class="clickable">
                            <mat-icon mat-list-icon>thermostat</mat-icon>
                            <p mat-line>
                                {{ds}}
                            </p>
                        </mat-list-item>
                    </ng-container>

                </mat-list>
            </mat-card>
        </div>

        <div fxFlex>
            <mat-card class="p-0" *ngIf="selectedDs">
                <div fxLayout="row" class="numeric p-4">
                    <div fxFlex="50">
                        <div fxLayout="row" class="numeric p-4 border-bottom" *ngFor="let f of selectedDs|keyvalue">
                            <div fxFlex="150px">{{f.key}}</div>
                            <div>{{f.value}}</div>
                        </div>
                    </div>
                    <div fxFlex="50">
                        <div *ngIf="selectedDsData">
                            <div fxLayout="row" class="numeric p-4 border-bottom" *ngFor="let f of selectedDsData|keyvalue">
                                <div fxFlex="150px">{{f.key}}</div>
                                <div>{{f.value}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>

                <div class="p-8">
                    <button mat-raised-button (click)="updateMeteoSpot()">
                        Mettre à jour
                    </button>
                    <button mat-raised-button (click)="updateMeteoSpot()">
                        Synchroniser
                    </button>
                </div>
            </mat-card>

        </div>

    </div>


</div>

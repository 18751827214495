import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {AppService} from "../../shared/services/app.service";
import {SiteService} from "../../shared/services/site.service";
import moment, {Moment} from "moment";
import {AppConfirmService} from "../../shared/services/app-confirm/app-confirm.service";
import {Plotly} from "angular-plotly.js/lib/plotly.interface";
import {plotData, plotLayout} from "./bmens-meter-plots-config";
import {H} from "../../shared/helpers/H";
import {BmensData, BmensItem, BmensMeter, StatMensXlsFile} from "../../shared/models/Bmens.model";
import {EnergyStatsSubMeters} from 'app/shared/models/EnergyStats.model';
import {Subscription} from "rxjs";
import {NgxFileDropEntry} from 'ngx-file-drop';
import {HttpHeaders} from "@angular/common/http";
import {ClientSite} from "../../shared/models/models";

@Component({
    selector: 'app-bmens-meters',
    template: `
        <mat-card class="m-4 p-4 buttons-panel" fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <ng-container *ngFor="let gr of site.clientSite.bmens_groups">
                    <button mat-raised-button class="m-4 btn-xs-25" [title]="gr.uid"
                            [class.active]="selectedGroupUid===gr.uid"
                            (click)="selectGroup(gr.uid,gr.label)">
                        {{ gr.label }}
                    </button>
                </ng-container>
                <span fxFlex></span>
                <button mat-raised-button class="m-4 btn-xs-25" color="primary"
                        (click)="selectGroup(null,null)">
                    Edition des groupes
                </button>
            </div>
        </mat-card>

        <div fxLayout="row">
            <mat-card class="m-4 p-4" fxFlex="400px">
                <div class="bmens-meter-bloc">

                    <h3>Compteurs du bilan mensuel <b class="color-blue">[{{ this.selectedGroupLabel }}]</b></h3>
                    <div fxLayout="column">
                        <ng-container *ngFor="let meter of bmensMeters">
                            <div [ngClass]="{active:selectedMeter&&meter.uid===selectedMeter.uid}" *ngIf="meter.uid_group===selectedGroupUid"
                                 class="meter-row p-8 pl-0 pr-0 pb-0 {{meter.is_active?'active':'disabled'}}" fxLayout="row"
                                 fxLayoutAlign="space-between center">

                                <div class="font-weight-bold pl-4" fxFlex="30px" (click)="selectMeter(meter)">
                                    <mat-icon *ngIf="!(selectedMeter&&meter.uid===selectedMeter.uid)" inline class="cursor-pointer">
                                        radio_button_unchecked
                                    </mat-icon>
                                    <mat-icon *ngIf="selectedMeter&&meter.uid===selectedMeter.uid" inline class="cursor-pointer">radio_button_checked
                                    </mat-icon>
                                </div>
                                <div fxFlex (click)="selectMeter(meter)" class="cursor-pointer">
                                    <strong>{{ meter.label }}</strong> ({{ meter.energy_unit }})
                                </div>
                                <span class="meter-unit cursor-pointer" (click)="selectMeter(meter)">
                                    <mat-icon [matTooltip]="'SUIVI'" *ngIf="meter.is_in_suivi" inline class="color-blue w20">assessment</mat-icon>
                                    <mat-icon [matTooltip]="'BMENS'" *ngIf="meter.is_in_bmens" inline class="color-blue w20">monitoring</mat-icon>
                                    <mat-icon [matTooltip]="'Désactivé'" *ngIf="!meter.is_active" inline
                                              class="color-blue w20">settings_power</mat-icon>
                                    <mat-icon [matTooltip]="'TARIF'" *ngIf="meter.is_tarif" inline class="color-blue w20">attach_money</mat-icon>
                                    <mat-icon [matTooltip]="'TOTAL'" *ngIf="meter.is_total" inline class="color-blue w20">movie_creation</mat-icon>
                                    <mat-icon [matTooltip]="'PAS DE FLUIDE'" *ngIf="!meter.fluid">warning</mat-icon>
                                </span>
                                <div class="meter-type"> [{{ meter.type }}]</div>
                                <div fxFlex="35px" fxLayout="row" fxLayoutAlign="end center">
                                    <button *ngIf="selectedMeter&&!selectedSubMeterGroupAndLabel&&linkInGroup===meter.uid_group&&meter.releve_count>selectedMeter.releve_count"
                                            (click)="linkTo(meter)" mat-raised-button
                                            class="btn-xs-25 mr-4">Lier
                                    </button>
                                    <button mat-raised-button class="btn-xs-25 mr-4"
                                            *ngIf="selectedSubMeterGroupAndLabel"
                                            (click)="selectMeter(meter)">Comparer
                                    </button>
                                    <mat-chip fxFlex="33px">{{ meter.releve_count }}</mat-chip>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>


                <div class="bmens-meter-bloc">
                    <h3>Chercher par UID compteur</h3>

                    <div>
                        <input type="text" [(ngModel)]="meeterSeekByUID"
                               class="bloc-search-input"
                               (change)="selectMeterByUID()" (keyup)="selectMeterByUID()"></div>
                </div>
            </mat-card>

            <div fxFlex fxLayout="column">
                <div fxLayout="row">
                    <mat-card class="m-4 p-4" fxFlex="550px">
                        <ng-container *ngIf="selectedSubMeterGroupAndLabel">
                            <div class="bmens-meter-bloc">
                                <h3>Valeurs des sous-compteurs des suivis itératifs</h3>
                                <div class="p-4 border-bottom" fxLayout="row"
                                     *ngFor="let item of subMeters.get(selectedSubMeterGroupAndLabel)">
                                    <span class="font-weight-bold" fxFlex="100px">{{ item.year }}</span>
                                    <span class="p-4" fxFlex="100px">{{ item.amount }}</span>
                                    <span class="p-4" fxFlex="100px">{{ item.cost }} Fr.</span>
                                    <span>{{ item.notes }}</span>
                                </div>
                            </div>

                            <div *ngIf="selectedMeter&&selectedMeterData&&selectedMeterData.length"
                                 class="bmens-meter-bloc">
                                <h3>Valeurs saisies au 12e mois dans le bilan mensuel</h3>
                                <ng-container *ngFor="let releve of selectedMeterData">
                                    <div class="p-4 border-bottom" *ngIf="releve.month===12" fxLayout="row">
                                        <span class="font-weight-bold" fxFlex="100px">{{ releve.month }}/{{ releve.year }}</span>
                                        <span class="p-4" fxFlex="100px">{{ releve.rows_cache.energy_usage|number }}</span>
                                        <span class="p-4" fxFlex>{{ releve.rows_cache.energy_cost|number }}</span>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="selectedMeter&&!selectedSubMeterGroupAndLabel">
                            <!-- METER FORM -->
                            <div class="bmens-meter-bloc">
                                <h3>Compteur [{{ selectedMeter.label }}]</h3>
                                <table class="bmens-meter-table">
                                    <tr>
                                        <td class="col1">Libellé</td>
                                        <td>
                                            <div fxLayout="row" fxLayoutAlign="none center">
                                                <input matInput [(ngModel)]="selectedMeter.label" type="text" placeholder="Libellé">
                                                <div class="inline-label"> Ordre</div>
                                                <input style="width: 50px" matInput [(ngModel)]="selectedMeter.disp_order" type="text"
                                                       placeholder="Disp Order">

                                                <mat-checkbox [(ngModel)]="selectedMeter.is_active">Actif</mat-checkbox>

                                            </div>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="col1">Unité conso/tarif/coût</td>
                                        <td>
                                            <div fxLayout="row">
                                                <mat-select class="select-group" [(ngModel)]="selectedMeter.energy_unit"
                                                            placeholder="Unité consommation">
                                                    <mat-optgroup label="Unité consommation">
                                                        <mat-option [value]="'m³'">m³</mat-option>
                                                        <mat-option [value]="'KWh'">KWh</mat-option>
                                                        <mat-option [value]="'MWh'">MWh</mat-option>
                                                        <mat-option [value]="'t'">t</mat-option>
                                                        <mat-option [value]="'litres'">ℓ</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>

                                                <mat-select class="select-group" [(ngModel)]="selectedMeter.tariff_unit" placeholder="Unité tarif">
                                                    <mat-optgroup label="Unité tarif">
                                                        <mat-option [value]="'c./m³'">c./m³</mat-option>
                                                        <mat-option [value]="'c./kWh'">c./kWh</mat-option>
                                                        <mat-option [value]="'Fr./MWh'">Fr./MWh</mat-option>
                                                        <mat-option [value]="'Fr./m³'">Fr./m³</mat-option>
                                                        <mat-option [value]="'Fr./t'">Fr./t</mat-option>
                                                        <mat-option [value]="'Fr./l'">Fr./l</mat-option><!--Fr./ℓ -->
                                                        <mat-option [value]="'c./litres'">c./litres</mat-option>
                                                        <mat-option [value]="'Fr./100l'">Fr./100l</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>
                                                <mat-select class="select-group" [(ngModel)]="selectedMeter.cost_unit" placeholder="Unité coût">
                                                    <mat-optgroup label="Unité coût">
                                                        <mat-option [value]="'Fr.'">Fr. TTC</mat-option>
                                                        <mat-option [value]="'Fr. HTVA'">Fr. HTVA</mat-option>
                                                    </mat-optgroup>
                                                </mat-select>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="col1">Groupe</td>
                                        <td>
                                            <mat-select [(ngModel)]="selectedMeter.uid_group" class="select-group">
                                                <mat-option *ngFor="let gr of site.clientSite.bmens_groups"
                                                            [value]="gr.uid" ngDefaultControl>{{ gr.label }}
                                                </mat-option>
                                            </mat-select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="col1">Agent énergetique</td>
                                        <td>
                                            <mat-select [(ngModel)]="selectedMeter.agent" class="select-group" placeholder="Saisir l'agent">
                                                <mat-option [value]="agent.key" *ngFor="let agent of myapp.k.EnergyAgents|keyvalue">
                                                    {{ myapp.k.EnergyAgentsDisplayNames[agent.key] }}
                                                </mat-option>
                                            </mat-select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="col1">Fichier StatMense</td>
                                        <td>
                                            <mat-select [(ngModel)]="selectedMeter.statmens_file" class="select-group"
                                                        placeholder="Choisir le fichier">
                                                <mat-option [value]="xls.id" *ngFor="let xls of list_statmens">
                                                    {{ xls.file_name }}
                                                </mat-option>
                                            </mat-select>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </ng-container>

                    </mat-card>

                    <mat-card class="m-4 p-4" fxFlex>
                        <div class="bmens-meter-bloc" *ngIf="selectedMeter&&!selectedSubMeterGroupAndLabel">

                            <div class="form-bloc-title">
                                Configuration
                            </div>
                            <div class="bloc-config">
                                <div class="p-0" fxLayout="row" fxLayoutAlign="space-between center">
                                    <mat-checkbox [(ngModel)]="selectedMeter.is_total">is_total</mat-checkbox>
                                    <mat-checkbox [(ngModel)]="selectedMeter.is_entrant">is_entrant</mat-checkbox>
                                    <!-- suprimé pr le moment 
                                    <mat-checkbox [(ngModel)]="selectedMeter.is_tarif">is_tarif</mat-checkbox>
                                    -->
                                    <mat-checkbox [(ngModel)]="selectedMeter.is_in_bmens">is_in_bmens</mat-checkbox>
                                    <mat-checkbox [(ngModel)]="selectedMeter.is_in_suivi">is_in_suivi</mat-checkbox>
                                </div>
                            </div>

                            <div class="mt-4 mb-4">
                                <mat-button-toggle-group fxLayout="row" class="mt-4" fxLayoutAlign="start center">
                                    <span fxFlex="160px" class="form-bloc-title">Fluide</span>
                                    <mat-button-toggle (click)="selectedMeter.fluid=fl"
                                                       [checked]="selectedMeter.fluid===fl"
                                                       *ngFor="let fl of myapp.k.fluids">{{ fl }}
                                    </mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                            <div class="mb-4">
                                <mat-button-toggle-group fxLayout="row" class="mt-4" fxLayoutAlign="start center">
                                    <span fxFlex="160px" class="form-bloc-title">Type compteur</span>
                                    <mat-button-toggle (click)="selectedMeter.type='ACHAT'" [checked]="selectedMeter.type==='ACHAT'">ACHAT
                                    </mat-button-toggle>
                                    <mat-button-toggle (click)="selectedMeter.type='PRODUCTION'" [checked]="selectedMeter.type==='PRODUCTION'">
                                        PRODUCTION
                                    </mat-button-toggle>
                                    <mat-button-toggle (click)="selectedMeter.type='CONSOMMATION'" [checked]="selectedMeter.type==='CONSOMMATION'">
                                        CONSOMMATION
                                    </mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>

                            <div class="mb-4">
                                <mat-button-toggle-group fxLayout="row" class="mt-4" fxLayoutAlign="start center">
                                    <span fxFlex="160px" class="form-bloc-title">Type conso</span>
                                    <mat-button-toggle (click)="selectedMeter.type_var='ENERGY'" [checked]="selectedMeter.type_var==='ENERGY'">
                                        ENERGY
                                    </mat-button-toggle>
                                    <mat-button-toggle (click)="selectedMeter.type_var='INDICE'" [checked]="selectedMeter.type_var==='INDICE'">
                                        INDICE
                                    </mat-button-toggle>
                                    <mat-button-toggle (click)="selectedMeter.type_var='VAREXP'" [checked]="selectedMeter.type_var==='VAREXP'">
                                        VAREXP
                                    </mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                        </div>
                        <div class="bmens-meter-bloc" *ngIf="selectedGroupUid&&!selectedMeter">
                            <h3>Création compteur</h3>
                            <div>
                                <button (click)="createMeter()">Créer</button>
                            </div>
                        </div>
                        <div class="bmens-meter-bloc" *ngIf="!selectedGroupUid">
                            <h2>Edition des groupes</h2>
                            <div fxLayout="column">
                                <div fxLayout="row" class="group-form-row header" fxLayoutAlign="space-between center">
                                    <div fxFlex="30px">
                                        Enfants
                                    </div>
                                    <div fxFlex="130px" class="p-4">
                                        Libellé
                                    </div>
                                    <div fxFlex="30px">
                                        Ordre
                                    </div>
                                    <div fxFlex="20px">
                                        Total
                                    </div>
                                    <div fxFlex="70px">
                                        Type
                                    </div>
                                    <div fxFlex="25">
                                        Note
                                    </div>
                                </div>
                                <div fxLayout="row" class="group-form-row" fxLayoutAlign="space-between center"
                                     *ngFor="let gr of site.clientSite.bmens_groups">


                                    <div fxFlex="30px" fxLayoutAlign="space-evenly center" fxLayout="row">
                                        <ng-container *ngIf="getGroupMetersCount(gr.uid)===0">
                                            <mat-icon class="cursor-pointer" inline (click)="deleteGroup(gr.uid)">delete</mat-icon>
                                        </ng-container>
                                        <ng-container *ngIf="getGroupMetersCount(gr.uid) as count">
                                            <span>({{ count }})</span>
                                        </ng-container>
                                    </div>
                                    <div fxFlex="130px">
                                        <input matInput [(ngModel)]="gr.label" type="text" placeholder="label">
                                    </div>
                                    <div fxFlex="30px" class="text-center">
                                        <input matInput [(ngModel)]="gr.disp_order" type="number" placeholder="Ordre">
                                    </div>
                                    <div fxFlex="20px" class="text-center">
                                        <mat-checkbox [(ngModel)]="gr.is_total"></mat-checkbox>
                                    </div>
                                    <div fxFlex="70px">
                                        <mat-select [(ngModel)]="gr.type">
                                            <mat-option [value]="'FLUID'">Fluide</mat-option>
                                            <mat-option [value]="'PLACE'">Lieu</mat-option>
                                            <mat-option [value]="'VAREXP'">Var. Exp.</mat-option>
                                        </mat-select>
                                    </div>
                                    <div fxFlex="25">
                                <textarea matInput [(ngModel)]="gr.note" type="text" placeholder="Note">
                                </textarea>
                                    </div>
                                </div>
                                <div fxLayout="row" class="group-form-row footer pt-4 pb-4 ">
                                    <button (click)="addGroup()" mat-raised-button color="default" class="btn-xs-25 pl-4">
                                        Nouveau
                                    </button>
                                    <span fxFlex></span>
                                    <button (click)="saveGroupsInSite()" mat-raised-button color="primary" class="btn-xs-25">
                                        Sauvegarder les groupes
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="bmens-meter-bloc" *ngIf="selectedYearForReleves&&showDecember">
                            <bloc-usage-for-year [year]="selectedYearForReleves" [forceUpdate]="selectedYearForReleves"></bloc-usage-for-year>
                        </div>
                        <div class="bmens-meter-bloc" *ngIf="selectedMeterDataReleve&&!showDecember">
                            <h3>Statmens du relevé {{ myapp.k.months[selectedMeterDataReleve.month - 1] }}/{{ selectedMeterDataReleve.year }}</h3>

                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <ngx-file-drop dropZoneLabel="Drop files here" fxFlex
                                               (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
                                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                                        <button type="button" mat-raised-button (click)="openFileSelector()">Parcourir</button>
                                    </ng-template>
                                </ngx-file-drop>
                            </div>
                        </div>
                    </mat-card>
                </div>


                <div class="feeter-row" fxLayout="row" fxLayoutAlign="space-between center"
                     *ngIf="selectedMeter&&!selectedSubMeterGroupAndLabel">
                    <button class="btn-xs-25" mat-raised-button
                            (click)="linkInGroup=selectedMeter.uid_group">
                        Combiner avec
                    </button>
                    <div fxFlex></div>
                    <button class="btn-xs-25 mr-4" mat-raised-button color="accent"
                            (click)="saveMeter(true)">
                        Cloner
                    </button>
                    <button class="btn-xs-25" mat-raised-button color="primary"
                            (click)="saveMeter()">
                        Sauvegarder
                    </button>
                </div>
            </div>

        </div>

        <!-- RELEVES -->
        <mat-card *ngIf="selectedMeter" class="m-4 p-4" style="min-height: 445px">
            <div fxLayout="row" class="releve-bloc-header" fxLayoutAlign="space-between center">
                <button (click)="selectedYearForReleves=y" mat-raised-button class="btn-xs-25 mr-4"
                        [class.active]="selectedYearForReleves==y"
                        *ngFor="let y of suggested_year">{{ y }}
                </button>
                <span fxFlex></span>
                <mat-checkbox [(ngModel)]="showDecember">Only Déc</mat-checkbox>
            </div>

            <table class="bmens-releve-table">

                <tr class="head">
                    <td class="col-date"> Date</td>
                    <td class="col-select">Année de réf</td>

                    <td class="col-field-with-unit"> Conso<br><span>({{ selectedMeter.energy_unit }})</span></td>

                    <td class="col-field-with-unit"> Coût<br><span>({{ selectedMeter.cost_unit }})</span></td>

                    <td class="col-field-with-unit"> Tarif<br><span>({{ selectedMeter.tariff_unit }})</span></td>
                    <!-- FP CEGES -->
                    <td class="col-december" *ngIf="showDecember">
                        FP
                    </td>
                    <td class="col-december" *ngIf="showDecember">
                        CEGES <span>(tCO2eq.)</span>
                    </td>


                    <td class="col-derniere">
                        Évol. conso.<br> corrélée
                    </td>
                    <td class="col-derniere">
                        Évol. conso.<br> corrélée %
                    </td>
                    <td class="col-derniere">
                        Évol corr.<br> en Fr.
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>

                </tr>

                <ng-container *ngFor="let m of myapp.k.months;let i=index;">
                    <ng-container *ngFor="let rel of selectedMeterData">
                        <ng-container
                                *ngIf="(!showDecember&&rel.year===selectedYearForReleves&&rel.month===(i+1))||(i===11&&showDecember&&rel.month===12)"
                                class="releve-table">

                            <tr [class.selected]="evalShow(rel)">
                                <td class="col-date">
                                    {{ rel.month }} {{ rel.year }}
                                </td>
                                <td class="col-select">
                                    <mat-select [(ngModel)]="rel.year_ref">
                                        <mat-option *ngFor="let y of years_ref" [value]="y">{{ y }}</mat-option>
                                    </mat-select>

                                </td>

                                <td class="col-field-with-unit">
                                    <input #smartCell matInput [(ngModel)]="rel.rows_cache.energy_usage"
                                           [attr.value]="rel.uid" [attr.field]="'energy_usage'"
                                           type="number" placeholder="Consommation" (dblclick)="calcThis($event.target,rel)">
                                </td>

                                <td class="col-field-with-unit">
                                    <input #smartCell matInput [(ngModel)]="rel.rows_cache.energy_cost"
                                           [attr.value]="rel.uid" [attr.field]="'energy_cost'"
                                           type="number" placeholder="Coût" (dblclick)="calcThis($event.target,rel)">
                                </td>

                                <td class="col-field-with-unit">
                                    <input #smartCell matInput [(ngModel)]="rel.rows_cache.tariff"
                                           [attr.value]="rel.uid" [attr.field]="'tariff'"
                                           type="number" placeholder="Tarif" (dblclick)="calcThis($event.target,rel)">
                                </td>

                                <!-- FP CEGES -->
                                <td class="col-december" *ngIf="showDecember">
                                    <input matInput [(ngModel)]="rel.rows_cache.fp" type="number" placeholder="FP">
                                </td>

                                <td class="col-december" *ngIf="showDecember">
                                    <input matInput [(ngModel)]="rel.rows_cache.ceges" type="number" placeholder="CEGES">
                                </td>

                                <td class="col-derniere">
                                    <input matInput [(ngModel)]="rel.rows_cache.economy_evol_value" type="number">
                                </td>
                                <td class="col-derniere">
                                    <input matInput [(ngModel)]="rel.rows_cache.economy_evol_percent" type="number">
                                </td>
                                <td class="col-derniere">
                                    <input matInput [(ngModel)]="rel.rows_cache.cost_corr_evol" type="number">
                                </td>

                                <td></td>
                                <td class="col-button">
                                    <div fxLayout="row">
                                        <button mat-raised-button (click)="saveReleve(rel)" class="btn-xs-20 mr-4" color="primary">
                                            <mat-icon inline>save</mat-icon>
                                        </button>
                                        <button mat-raised-button (click)="selectReleve(rel)" class="btn-xs-20 mr-4">
                                            Select
                                        </button>
                                        <button mat-raised-button (click)="deleteReleve(rel)" class="btn-xs-20" color="accent">
                                            <mat-icon inline>remove</mat-icon>
                                        </button>
                                        <button mat-raised-button (click)="openReport(rel)" class="btn-xs-20 mr-4">
                                            <mat-icon inline>open_in_new</mat-icon>
                                        </button>
                                    </div>

                                </td>
                                <td class="col-icon">
                                    <mat-icon *ngIf="rel.rows_cache.file&&rel.rows_cache.file.length"
                                              [title]="rel.rows_cache.file_ts * 1000|amTimeAgo"
                                              (click)="site.openBmensAttachment(rel.rows_cache)"
                                              inline style="font-size: 25px;position: absolute;cursor: pointer"> picture_as_pdf
                                    </mat-icon>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </ng-container>

            </table>

            <!--ADD RELEVE -->
            <div class="add-releve-row" fxLayout="row" fxLayoutAlign="none center">
                <div fxFlex><h4>Ajouter un relevé: </h4></div>
                <mat-select fxFlex="200px" #monthForReleveToAdd placeholder="CHOIX DU MOIS">
                    <mat-option *ngFor="let m of myapp.k.months;let i=index;" [value]="i+1">{{ i + 1 }}:{{ m }}</mat-option>
                </mat-select>
                <span fxFlex="20px"></span>
                <mat-select fxFlex="100px" #yearRefForReleveToAdd placeholder="Année de ref">
                    <mat-option *ngFor="let y of years_ref" [value]="y">{{ y }}</mat-option>
                </mat-select>
                <div class="m-4">
                    <button mat-raised-button (click)="addReleve(monthForReleveToAdd.value,yearRefForReleveToAdd.value)" class="btn-xs-25"
                            color="accent">
                        <mat-icon inline>add</mat-icon>
                    </button>
                </div>
            </div>
        </mat-card>

        <!-- PLOT BLOC -->
        <mat-card *ngIf="selectedMeter" class="m-4 p-4">
            <plotly-plot #plotBmens class="plotly-plot"
                         [updateOnDataChange]="true"
                         [updateOnLayoutChange]="true"
                         [divId]="'plotBmens'"
                         [data]="plotData"
                         [layout]="plotLayout"
                         [config]="myapp.k.defaultConfig">
            </plotly-plot>
        </mat-card>

    `,
    styleUrls: ['./site-home.component.scss']
})
export class BmensMetersComponent implements OnInit {
    @ViewChildren('smartCell') smartCells: QueryList<any>;
    public files: NgxFileDropEntry[] = [];
    showDecember = false;
    balanceSheetsItems: BmensItem[] = [];
    latestBmensForYear: BmensItem;
    bmensMeters: BmensMeter[] = [];
    list_statmens: StatMensXlsFile[] = [];

    selectedYearForReleves: number;
    selectedMeter: BmensMeter;// BmensMeter
    selectedSubMeterGroupAndLabel: string;//submeters are meters that are defined in the energystats
    selectedGroupUid: string = null;
    selectedGroupLabel: string = null;
    selectedMeterData: BmensData[] = [];//all releve of selected meter
    selectedMeterDataReleve: BmensData = null;//selected releve
    selectedMeterDataAvailableYears: number[] = [];
    selectedMeterDataMap: Map<string, BmensData[]> = new Map<string, BmensData[]>();
    syncedSubmetersMap: Map<string, number> = new Map<string, number>();

    suggested_year: number[] = [];// for drop down form of releve, populated on select BmensData
    years_ref: number[] = [];// for drop down form of releve, populated on select BmensData
    groups: Map<string, string> = new Map<string, string>();
    groupsLabels: string[] = [];// previous line map values

    meeterSeekByUID = '';
    linkInGroup = '';
    plotLayout = plotLayout as Plotly.Layout;
    plotData = plotData;
    firstReleveDate = moment().add(10, "year");
    lastReleveDate = moment().subtract(10, 'years');

    selectedEnergyStatsYearData: any = null;// <--- for legacy admin submeter

    constructor(public myapp: AppService,
                public confirmService: AppConfirmService, public site: SiteService) {
    }

    evalShow(rel: BmensData) {
        return this.showDecember && rel.year === this.selectedYearForReleves
            || (this.selectedMeterDataReleve && rel.releve_key === this.selectedMeterDataReleve.releve_key);
    }

    test() {
        // console.log("this.groupFromSubMeters", this.groupFromSubMeters)
    }

    ngOnInit() {
        moment().locale('fr');
        this.selectedYearForReleves = moment().year();
        this.site.siteLoadingStatus.subscribe(eventName => {
            if (eventName === SiteService.MAX_SITE_LOADING_STATUS) {
                // this.loadData();

                this.getMeters();
                // this.scanSubmetersFromEnergyStats();

                this.years_ref = [];
                this.suggested_year = [];
                for (let i = 0; i < 15; i++) {
                    this.suggested_year.push(moment().year() + 1 - i);
                    this.years_ref.push(moment().year() + 1 - i);
                }

                this.suggested_year.sort();
                this.years_ref.sort();
                if (this.site.clientSite.bmens_groups)
                    this.site.clientSite.bmens_groups = this.site.clientSite.bmens_groups
                        .sort((a, b) => {
                            return a.disp_order - b.disp_order;
                        });
            }
        });
        this.loadStatMensFiles();
    }

    getMeters() {
        this.myapp.loader.open("Reception des compteurs créés");
        this.site.api.getBmensMeters(this.site.uid).subscribe(resp => {
            this.myapp.loader.close();
            this.bmensMeters = [];
            this.groupsLabels = [];
            this.groups = new Map<string, string>();
            if (resp.body && resp.body.length > 0) {
                //this.myapp.showMessage("Compteurs Reçus: ");
                resp.body.forEach(it => {
                    const meter = new BmensMeter(it);
                    if (meter.log_submeter_sync) {
                        Object.keys(meter.log_submeter_sync).forEach(uid => {
                            this.syncedSubmetersMap.set(uid, meter.log_submeter_sync[uid]);
                        });
                    }
                    if (moment(meter.releve_first).unix() < this.firstReleveDate.unix())
                        this.firstReleveDate = moment(meter.releve_first);
                    if (moment(meter.releve_last).unix() > this.lastReleveDate.unix())
                        this.lastReleveDate = moment(meter.releve_last);
                    this.groups.set(meter.uid_group, meter.group_label);
                    this.groupsLabels.push(meter.group_label);
                    this.bmensMeters.push(meter);
                });
                const metersCount = Number(resp.body.length);
                this.myapp.showMessage(metersCount + " compteurs chargés");
            } else {
                this.myapp.showMessage("Aucun compteur");
            }
        });

        //this.selectedEnergyStatsYearData = this.site.clientSite.old_energy_stats_map.get(year);
    }

    getMeterData() {
        if (!this.selectedMeter) return;
        this.selectedMeterDataAvailableYears = [];
        this.site.api.getMetersData(this.selectedMeter.uid).subscribe(resp => {
            //console.log("meterdata", resp);
            this.selectedMeterData = [];
            this.selectedMeterDataMap = new Map<string, BmensData[]>();
            resp.body.forEach(item => {
                const meterData = new BmensData(item);
                if (!this.selectedMeterDataAvailableYears.includes(meterData.year))
                    this.selectedMeterDataAvailableYears.push(meterData.year);
                this.selectedMeterData.push(meterData);
                if (this.selectedMeterDataReleve && meterData.uid === this.selectedMeterDataReleve.uid)
                    this.selectReleve(meterData);

                if (!this.selectedMeterDataMap.has(meterData.releve_key.toString()))
                    this.selectedMeterDataMap.set(meterData.releve_key.toString(), []);
                this.selectedMeterDataMap.get(meterData.releve_key.toString()).push(meterData);
            });
            this.selectedMeterData = this.selectedMeterData.sort((a, b) => {
                return a.releve_key - b.releve_key;
            })
            this.selectedMeterDataAvailableYears.sort();
            if (this.selectedMeterData.length) {
                this.selectedMeter.releve_count = this.selectedMeterData.length;
                this.renderPlot();
            }
        });
    }

    deleteGroup(groupId: string) {
        this.myapp.confirm.confirm({
            title: "Demande de confirmation",
            message: "Voulez vous supprimer ce groupe?"
        }).subscribe(isOk => {
            if (isOk) {
                this.site.clientSite.bmens_groups = this.site.clientSite.bmens_groups.filter(gr => gr.uid !== groupId);
                this.saveGroupsInSite();
            }
        });
    }

    getGroupMetersCount(groupId: string): number {
        let retval = 0;
        if (this.bmensMeters && Array.isArray(this.bmensMeters)) {
            retval = this.bmensMeters.filter(mt => mt.uid_group === groupId).length;
        }
        return retval;
    }

    selectGroup(groupId: string, groupLabel: string) {
        this.linkInGroup = '';
        this.selectedGroupUid = groupId;
        this.selectedGroupLabel = groupLabel;
        this.selectedMeter = null;
        this.selectedMeterDataReleve = null;
        this.selectedMeterData = [];
        this.loadStatMensFiles();
    }

    selectMeterByUID() {
        console.log('selectMeterByUID()', this.meeterSeekByUID);
        const foundMeter = this.bmensMeters.find(it => it.uid === this.meeterSeekByUID);
        if (foundMeter) {
            this.selectGroup(foundMeter.uid_group, foundMeter.group_label);
            this.selectMeter(foundMeter);
        }
    }

    selectMeter(meter: BmensMeter) {
        this.selectedMeter = meter;
        this.selectedMeterData = [];
        this.selectedMeterDataReleve = null;
        this.getMeterData();
    }

    saveMeter(clone = false) {
        let dataToSave = JSON.parse(JSON.stringify(this.selectedMeter));
        console.log("saveBefore", dataToSave);
        if (clone) {
            dataToSave['uid'] = H.randomStr(30);
            dataToSave['label'] = "Clone du " + dataToSave['label'];
        }
        Object.keys(dataToSave).forEach(k => {
            if (k.includes('is_') && dataToSave[k]) dataToSave[k] = 1;
            if (k.includes('is_') && dataToSave[k]) dataToSave[k] = 1;
            if (k.includes('is_') && !dataToSave[k]) dataToSave[k] = 0;
        });
        console.log("saveAfter", dataToSave);
        this.myapp.loader.open("Saving meter");
        this.site.api.saveBmensMeters(dataToSave).subscribe(resp => {
            const createdMeter = new BmensMeter(resp.body);
            this.selectedYearForReleves = null;
            this.selectGroup(this.selectedMeter.uid_group, this.selectedMeter.group_label);
            this.selectMeter(createdMeter);
            this.getMeters();
            this.myapp.loader.close();
        });
    }

    createMeter() {
        //this.selectedGroupUid = groupId;
        const newMeter = new BmensMeter({});
        newMeter.uid_group = this.selectedGroupUid;
        newMeter.uid_site = this.site.uid;
        newMeter.uid = H.randomStr(30);
        newMeter.uid_clear = newMeter.uid;
        newMeter.group_label = this.selectedGroupLabel;
        newMeter.label = 'Nv compteur';
        newMeter.type = 'ENERGY';
        newMeter.fluid = 'ELEC';
        newMeter.energy_unit = 'MWh';
        newMeter.energy_unit = 'MWh';
        newMeter.is_in_bmens = 1;
        this.myapp.loader.open("Création compteur");
        this.site.api.saveBmensMeters(newMeter).subscribe(resp => {
            const createdMeter = new BmensMeter(resp.body);
            this.selectedMeter = createdMeter;
            this.selectedYearForReleves = null;
            this.selectGroup(this.selectedMeter.uid_group, this.selectedMeter.group_label);
            this.selectMeter(createdMeter);
            this.getMeters();
            this.myapp.loader.close();
        });

    }

    addGroup() {
        const newGroup = {
            uid: H.randomStr(30), label: 'New group', is_total: 0, disp_order: 1, note: '', type: 'FLUID'
        };
        if (!this.site.clientSite.bmens_groups) this.site.clientSite.bmens_groups = [];
        this.site.clientSite.bmens_groups.push(newGroup);
        this.saveGroupsInSite();
    }

    saveGroupsInSite() {
        const dataToSend = {};
        dataToSend['bmens_groups'] = this.site.clientSite.bmens_groups;

        console.log('saveSite', dataToSend);
        //****
        this.site.api.updateSite(dataToSend, this.site.clientSite.uid).subscribe(resp => {
            if (resp.status === 1) {
                const updatedSite = new ClientSite(resp.body);
                this.selectedYearForReleves = null;
                if (this.selectedMeter)
                    this.selectGroup(this.selectedMeter.uid_group, this.selectedMeter.group_label);
                this.site.selectSite(updatedSite);
                console.log('savedSite - Updated', resp.body, this.site.clientSite);
                this.myapp.toastr.success("Données sauvegardées", "Succès", {timeOut: 2000});

                this.myapp.getSites(true);
            } else
                console.error('updateSite status 0', resp);
        });
    }

    loadStatMensFiles() {
        this.list_statmens = [];
        this.site.api.loadStatmens(this.site.clientSite.statmens_prefix)
            .subscribe(res => {
                res.body.forEach(it => {
                    const xlsFileItem = new StatMensXlsFile(it);
                    this.list_statmens.push(xlsFileItem);
                });
                console.log('loadStatMensFiles', this.list_statmens);
            });
    }

    addReleve(monthIndex: number, yearRef: number) {
        if (!monthIndex) {
            this.myapp.showError("Selectionner le mois du relevé.");
            return;
        }
        if (!yearRef) {
            this.myapp.showError("Selectionner une année de référence.");
            return;
        }
        const obj = {
            month: monthIndex,
            year: this.selectedYearForReleves,
            year_ref: Number(yearRef),
            uid_site: this.site.uid,
            uid_meter: this.selectedMeter.uid,
            rows_cache: {},
            data: {},
            ts_update: 0,
            ts_insert: H.unixTs()
        };
        console.log("addReleve", obj, yearRef);
        this.saveReleve(new BmensData(obj));
    }

    saveReleve(releve: BmensData = null) {
        if (releve)
            this.selectedMeterDataReleve = releve;
        this.myapp.loader.open("Sauvegarde du relevé");
        if (!this.selectedMeterDataReleve.uid) {
            const key = Number(this.selectedYearForReleves + this.myapp.k.monthsIndexToNumString[this.selectedMeterDataReleve.month - 1]);
            this.selectedMeterDataReleve.uid = this.selectedMeter.uid + '.' + key + '.' + this.selectedMeterDataReleve.year_ref;
        }
        this.selectedMeterDataReleve = new BmensData(this.selectedMeterDataReleve);//<-- recalc releve_date and key
        this.selectedYearForReleves = Number(this.selectedMeterDataReleve.year);
        this.site.api.saveMetersData(this.selectedMeterDataReleve).subscribe(resp => {
            this.myapp.loader.close();
            if (resp.status == 1) {
                this.myapp.showMessage("Relevé sauvegardé");
                this.selectMeter(this.selectedMeter);
            } else this.myapp.showError("Erreur de création de relevé.");
        });
    }

    selectReleve(releve: BmensData) {
        this.selectedMeterDataReleve = releve;
        console.log('selectReleve()', releve);
        this.suggested_year.sort();
    }

    deleteReleve(releve: BmensData) {
        console.log('deleteReleve()', releve);
        this.myapp.confirm.confirm(
            {
                title: 'Êtes-vous sûrs de vouloir supprimer ce relevé?',
                message: "Date de relevé: " + releve.releve_date
            })
            .subscribe(isOk => {
                if (isOk) {
                    this.site.api.deleteMetersData(releve).subscribe(resp => {
                        this.myapp.loader.close();
                        if (resp.status == 1) {
                            this.myapp.showMessage("Relevé supprimé");
                            this.selectMeter(this.selectedMeter);
                        } else this.myapp.showError("Erreur de Supréssion.");
                    });
                }
            });

    }

    calcThis(target: any, releve: BmensData) {
        const currentInput = target as HTMLInputElement;
        const currReleveUID = currentInput.getAttribute('value');
        const inputField = currentInput.getAttribute('field');
        //  tariff energy_cost energy_usage
        const cellsToEdit = {};
        if (this.smartCells) {
            this.smartCells.forEach(cell => {
                const cellEl = cell.nativeElement as HTMLInputElement;
                const relID = cellEl.getAttribute('value');
                const field = cellEl.getAttribute('field');
                if (relID === currReleveUID) {
                    cellsToEdit[field] = cellEl;
                }
            });
        }
        let multFac = 1;
        if (!this.selectedMeter.tariff_unit) return;
        if (!this.selectedMeter.energy_unit) return;
        const firstLetterTariff = this.selectedMeter.tariff_unit.substring(0, 1).toLowerCase();
        const firstLetterCons = this.selectedMeter.energy_unit.substring(0, 2).toLowerCase();
        //if (firstLetterTariff === 'c') multFac *= 100;


        if (inputField === 'tariff') {
            if (firstLetterCons === 'mw') multFac = 1 / 1000;
            if (firstLetterTariff === 'c') multFac = multFac * 100;
            if (cellsToEdit['energy_cost'].value && Number(cellsToEdit['energy_usage'].value) !== 0) {// verify division by zero
                cellsToEdit['tariff'].value = (multFac * Number(cellsToEdit['energy_cost'].value) / Number(cellsToEdit['energy_usage'].value)).toFixed(2);
                releve.rows_cache.tariff = Number(cellsToEdit['tariff'].value);
            }
        }
        if (inputField === 'energy_cost') {
            if (firstLetterCons === 'mw') multFac = 1 * 1000;
            if (firstLetterTariff === 'c') multFac = multFac / 100;
            if (cellsToEdit['energy_usage'].value && cellsToEdit['tariff'].value) {
                cellsToEdit['energy_cost'].value = (multFac * Number(cellsToEdit['energy_usage'].value) * Number(cellsToEdit['tariff'].value)).toFixed(2);
                releve.rows_cache.energy_cost = Number(cellsToEdit['energy_cost'].value);
            }

        }
        if (inputField === 'energy_usage') {
            if (firstLetterCons === 'mw') multFac = 1 / 1000;
            if (firstLetterTariff === 'c') multFac = multFac * 100;
            if (cellsToEdit['energy_cost'].value && Number(cellsToEdit['tariff'].value) !== 0) {// verify division by zero
                cellsToEdit['energy_usage'].value = (multFac * Number(cellsToEdit['energy_cost'].value) / Number(cellsToEdit['tariff'].value)).toFixed(2);
                releve.rows_cache.energy_usage = Number(cellsToEdit['energy_usage'].value);
            }
        }

        console.log("calcThis", firstLetterTariff, firstLetterCons, multFac);
        //cellsToEdit['tariff'].value = H.randomInt(1, 999);
    }

    linkTo(meter: BmensMeter) {
        this.linkInGroup = '';
        if (!this.selectedMeter) return;
        let highestCountMeter: BmensMeter = null;
        let meterToRename: BmensMeter = null;
        if (meter.releve_count > this.selectedMeter.releve_count) {
            highestCountMeter = meter;
            meterToRename = this.selectedMeter;
        } else {
            highestCountMeter = this.selectedMeter;
            meterToRename = meter;
        }
        this.confirmService.confirm(
            {
                title: 'ACTION IRREVERSIBLE: Etes vous sûrs?',
                message: "Tout les relevés associés au compteur ["
                    + meterToRename.label
                    + "] seront associés à [" + highestCountMeter.label + "] "
            })
            .subscribe((res) => {
                if (!res) {
                    return;
                }
                this._linkTo(highestCountMeter, meterToRename);
            });
    }

    _linkTo(highestCountMeter: BmensMeter, meterToRename: BmensMeter) {
        this.site.api.mergeBmensMeters(meterToRename.uid, highestCountMeter.uid).subscribe(resp => {
            console.log("_linkTo Resp ", resp);
            this.selectMeter(highestCountMeter);
            this.getMeters();
        });
    }

    renderPlot() {
        this.plotData[0].x = [];
        this.plotData[0].marker.color = [];//"#2196F3"
        this.plotData[0].y = [];
        this.plotLayout.xaxis.tickvals = [];
        this.plotLayout.xaxis.ticktext = [];

        this.plotLayout = plotLayout as Plotly.Layout;
        let currMoment = this.firstReleveDate.clone();
        let index = 0;
        //console.log("renderPlot", this.firstReleveDate.format('YYYY-MM-DD'), this.lastReleveDate.format('YYYY-MM-DD'));
        do {
            const key = currMoment.year() + currMoment.format('MM');
            //console.log("Moment Key: ", currMoment.toDate(), key);

            if (this.selectedMeterDataMap.has(key)) {
                if (currMoment.month() === 11) {
                    this.plotData[0].marker.color.push("#0081ff");
                } else {
                    this.plotData[0].marker.color.push("#333333");
                }
                this.plotData[0].y.push(this.selectedMeterDataMap.get(key)[0].rows_cache['energy_usage']);
            } else {
                this.plotData[0].y.push(0);
                this.plotData[0].marker.color.push("#F44336");
            }

            this.plotData[0].x.push(index);
            this.plotLayout.xaxis.tickvals.push(index);
            this.plotLayout.xaxis.ticktext.push(key);

            currMoment = currMoment.add(1, 'month');
            index++;
        } while (currMoment.unix() < this.lastReleveDate.unix())
        // console.log("Layout:", this.plotLayout, this.plotData);
    }

    public dropped(files: NgxFileDropEntry[]) {
        this.files = files;
        for (const droppedFile of files) {
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    // Here you can access the real file
                    console.log('Dropped file:', droppedFile.relativePath, file);
                    // You could upload it like this:
                    const formData = new FormData();
                    formData.append('type', 'bmensdata');
                    formData.append('bmens_data_id', this.selectedMeterDataReleve.uid);
                    formData.append('logo', file);
                    // Headers
                    const headers = new HttpHeaders({
                        'enctype': 'multipart/form-data'
                    });

                    this.site.api.http.post(
                        this.site.api.getLink('/receive?filename=' + this.selectedMeterDataReleve.uid), formData,
                        {headers: headers})
                        .subscribe(data => {
                            if (data['status'] == 1) {
                                this.getMeterData();
                                this.myapp.showMessage("Fichier mit à jour")
                            } else {
                                this.myapp.showError("Erreur d'upload. " + data['error']);
                                console.log("Erreur d'upload: ", data);
                            }
                        });
                });
            } else {
                // It was a directory (empty directories are added, otherwise only files)
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
                console.log(droppedFile.relativePath, fileEntry);
            }
        }
    }

    public fileOver(event) {
        //console.log(event);
    }

    public fileLeave(event) {
        // console.log(event);
    }

    openReport(releve: BmensData = null) {
        if (releve)
            this.selectedMeterDataReleve = releve;
        this.site.router.navigate(['/bmens/' + this.site.clientSite.ref
        + "/" + this.selectedMeterDataReleve.releve_key]);

    }

    getYearMonthKey(year, monthIndex) {
        let month = monthIndex + 1;
        if (month < 10) month = '0' + month;
        else month = '' + month.toString();
        return year + month;
    }
}

/*
linkSubMeterToMeter() {
    console.log("linkSubMeterToMeter()", this.selectedMeter, this.selectedSubMeterGroupAndLabel);
    if (!this.selectedMeter.log_submeter_sync) this.selectedMeter.log_submeter_sync = {};
    let note = "";
    this.subMeters.get(this.selectedSubMeterGroupAndLabel).forEach(submeter => {
        if (note === '' && submeter.notes !== '') note = submeter.notes;
        this.selectedMeter.log_submeter_sync[submeter.uid] = H.unixTs();//store in meter the submeters associated to it
    });
    this.selectedMeter.note = note;
    this.selectedMeter.is_in_suivi = 1;
    this.saveMeter();
}*/
//SELECT *,count(*) as c FROM `tb_bmens_items` WHERE `uid_site` LIKE '5b8fd9924a54b500045f5a2c' GROUP BY date ORDER BY `c` DESC

/*
scanSubmetersFromEnergyStats() {
    this.subMeters = new Map<string, EnergyStatsSubMeters[]>();
    this.groupFromSubMeters = [];
    if (this.site.clientSite.old_energy_stats_map) {
        this.site.clientSite.old_energy_stats_map.forEach((energyStats, key) => {
            if (energyStats.subMeters)
                energyStats.subMeters.forEach(meter => {
                    const subMeter = new EnergyStatsSubMeters(meter);
                    this.groupFromSubMeters.push(subMeter.category);
                    if (!this.subMeters.has(subMeter.category + subMeter.name))
                        this.subMeters.set(subMeter.category + subMeter.name, []);
                    this.subMeters.get(subMeter.category + subMeter.name).push(subMeter);
                });
        });
    }
    this.groupFromSubMeters = this.groupFromSubMeters.filter(
        (item, pos, self) => {
            return self.indexOf(item) === pos;
        });

}*/

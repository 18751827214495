<footer class="main-footer">
    <div class="container-dynamic d-flex">
        <a style="margin-right: 15px" mat-raised-button
           color="accent"
           href="http://enerplan.ch">Enerplan SA</a>

        Optimise Web 2.0

        <span class="m-auto"></span>
    </div>
</footer>

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppService} from "../../../shared/services/app.service";
import {SiteService} from "../../../shared/services/site.service";
import {H} from "../../../shared/helpers/H";
import {globalEconGroupedBarsLayout} from "./plots";
import {interval} from "rxjs";
import Plotly from 'plotly.js-dist-min'
import {Action, ActionEconomyPerAgent} from "../../../shared/models/Action.model";
import {K} from "../../../shared/models/K";
import {PlotlyService} from "angular-plotly.js";
import {SiteTariffConfig, SiteTariffData} from "../../../shared/models/models";


@Component({
    selector: 'monitor-econ',
    template: `
        <div fxLayout="column" class="full-width h100 h-100">
            <div fxLayout="row" class="h-100">
                <div fxLayout="column" fxFlex="900px" fxLayoutAlign="space-between none">
                    <mat-card class="p-0 m-4">
                        <div id="plot"></div>
                    </mat-card>
                </div>
                <div fxLayout="column" fxFlex="1200px" fxLayoutAlign="space-between none">
                    <mat-card class="p-0 m-4">
                        <div fxLayout="row" class="p-8">
                            <button mat-raised-button class="btn-xs-25 mr-4" *ngFor="let y of availableYears" (click)="filterEconomiesPerYear(y)">
                                {{ y }}
                            </button>
                        </div>
                        <div fxLayout="row" class="p-8">
                            <button mat-raised-button class="btn-xs-25 mr-4" *ngFor="let ag of agents" (click)="filterEconomiesPerAgent(ag)">
                                {{ ag }}
                            </button>
                        </div>
                        <div class="all-economies-table-holder">
                            <table class="all-economies-table">
                                <tr class="head">
                                    <td>i</td>
                                    <td class="title">Batiment</td>
                                    <td>APE: Num (année)</td>
                                    <td>Fluide | Agent</td>
                                    <td>Libellé</td>
                                    <td>
                                        Tarif
                                    </td>
                                    <td>
                                        Economie Energie
                                    </td>
                                    <td>
                                        Economie Frs
                                    </td>
                                    <td>
                                        Co2
                                    </td>

                                </tr>

                                <tr *ngFor="let econ of economiesToShow" (click)="myapp.showConsole(econ)">
                                    <td>{{ econ.index }}</td>
                                    <td class="title">{{ econ.ref }}</td>
                                    <td>APE: {{ econ.ape }} ({{ econ.year }})</td>
                                    <td>{{ econ.fluid }} | {{ econ.agent }}</td>
                                    <td>{{ econ.label }}</td>
                                    <td>
                                        {{ econ.price }}
                                    </td>
                                    <td>
                                        {{ econ.amount.value|number }}
                                        {{ econ.amount.unit }}
                                    </td>
                                    <td>
                                        {{ econ.econ_chf|number }}
                                    </td>
                                    <td>
                                        {{ econ.emitted|number }}
                                    </td>

                                </tr>
                            </table>
                        </div>
                    </mat-card>
                </div>

            </div>

        </div>
    `,
    styleUrls: ['./manager.component.scss']
})
export class MonitorEconComponent implements OnInit, OnDestroy {
    protected readonly globalEconGroupedBarsLayout = globalEconGroupedBarsLayout;
    allActions: Action[] = [];
    years: number[] = [];
    statsMap: Map<string, number> = new Map<string, number>();
    agents: any [] = [];
    allEconomies: any [] = [];
    economiesToShow: any [] = [];
    totEcon = 0;
    totCo2 = 0;
    baseBarTrace = {
        x: [],
        y: [],
        name: 'Chaleur',
        type: 'bar',
        barmode: 'group',
        yaxis: 'y',
    };
    availableYears = [];

    constructor(
        public myapp: AppService,
        public site: SiteService,
        public plotlyServ: PlotlyService
    ) {

    }

    ngOnInit(): void {
        if (this.myapp.user) {
            this.preRun();
        } else {
            this.myapp.appInitStatus.subscribe(step => {
                if (step === 1)
                    this.preRun();
            });
        }
    }

    preRun() {
        if (this.myapp.user.isAdmin)
            this.run();
        else {
            this.myapp.showError("Accès reservé aux administrateurs");
            setTimeout(() => {
                window.history.back();
            }, 4000);
        }
    }

    run() {
        this.myapp.storeCurrentRoute();
        this.site.api.getAllActions(this.myapp.user.email).subscribe(resp => {
            if (Array.isArray(resp.body)) {
                this.allActions = resp.body.map(it => new Action(it));
            }
            this.scanActions();
        });
    }

    scanActions() {
        let counter = 1;
        const tracesHolder = {};
        this.allActions.forEach(act => {
            act.economies.forEach(econ => {
                const econObj = new ActionEconomyPerAgent(econ);
                const agent = econ.agent !== 'NOAGENT' ? econ.agent : 'WATER';
                this.agents.push(agent)
                // const tariffKeyForDueYear = act.yearDue + '-' + econ.uid_tariff;
                const tariffKeyForRefYear = act._tariff_data['year_ref'] + '-' + econ.uid_tariff;

                // const key = act.yearDue + "_" + econ.fluid;
                const key = act.yearDue + "_" + agent;
                const econObjectWithTarif = {...econ};
                const tariffObj = new SiteTariffConfig(act._tariff_data['tariff_config'][econ.uid_tariff]);
                let tariffDataObj: SiteTariffData = null;
                if (act._tariff_data['energy_usage'][tariffKeyForRefYear])
                    tariffDataObj = new SiteTariffData(act._tariff_data['energy_usage'][tariffKeyForRefYear]);

                econObjectWithTarif['index'] = counter;
                econObjectWithTarif['ref'] = act._tariff_data['ref'];
                econObjectWithTarif['ape'] = act.num;
                econObjectWithTarif['year'] = act.yearDue;
                econObjectWithTarif['tariffObj'] = tariffObj;
                econObjectWithTarif['fluid'] = tariffObj.fluid;
                econObjectWithTarif['agent'] = tariffObj.agent;
                econObjectWithTarif['amount'] = econObj.renderAmount(econObj.econEstimated);
                if (tariffDataObj) {
                    econObjectWithTarif['econ_chf'] = econObj.economyInChf(tariffObj, tariffDataObj);
                    econObjectWithTarif['price'] = tariffDataObj.priceAdapted;
                    econObjectWithTarif['emitted'] = tariffDataObj.getCegesEmittedForInput(econObj.econEstimated);
                }


                this.allEconomies.push(econObjectWithTarif);
                /// TODO: ask Rija why
                if (econ.econEstimated > 0)
                    H.incrStatMap(this.statsMap, key, econ.econEstimated);
                counter++;
            });
        });
        console.log("this.allEconomies", this.allEconomies);
        this.statsMap = new Map([...this.statsMap.entries()].sort());
        this.statsMap.forEach((v, k) => {
            const agent = k.split(/_(.*)/s)[1];
            const year = Number(k.split(/_(.*)/s)[0]);
            this.availableYears.push(year)
            if (!tracesHolder[agent]) {
                const trace = JSON.parse(JSON.stringify(this.baseBarTrace));
                trace.name = agent;
                if (agent === "WATER" || agent === "EAU") {
                    trace.yaxis = 'y2';
                    trace.type = 'scatter';
                } else {
                    trace.yaxis = 'y';
                }
                tracesHolder[agent] = trace;
            }
            let correctedValue = v;
            if (agent !== "WATER" && agent !== "EAU") {
                correctedValue = correctedValue / 1e6;
            }
            const lastElement = Number(tracesHolder[agent].y.slice(-1));/// TODO: enh,add values for all years
            tracesHolder[agent].x.push(year);
            tracesHolder[agent].y.push(lastElement + correctedValue);

        });
        this.availableYears = H.arrayUnique(this.availableYears).sort()

        Plotly.newPlot('plot', Object.values(tracesHolder), this.globalEconGroupedBarsLayout, this.myapp.k.defaultConfig);


        console.log("this.statsMap", this.statsMap, tracesHolder);
        this.agents = H.arrayUnique(this.agents).sort()
    }

    filterEconomiesPerYear(year: number) {
        console.log("filterEconomies", year)
        this.economiesToShow = this.allEconomies.filter(econ => econ.year === year);
    }

    filterEconomiesPerAgent(agent: number) {
        console.log("filterEconomiesPerAgentr", agent)
        this.economiesToShow = this.allEconomies.filter(econ => econ.agent === agent);
    }

    ngOnDestroy() {
    }

}

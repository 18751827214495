import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewChild}
    from "@angular/core";
import {SiteService} from "../../shared/services/site.service";
import {H} from "../../shared/helpers/H";
import {AnnotationService} from "./annot.service";
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
import {take} from "rxjs/operators";
import {ReportAnnotation, ReportAnnotationPoint} from "../../shared/models/models";
import {AppService} from "../../shared/services/app.service";

@Component({
    selector: 'annot-editor',
    template: `
        <div class="proto-hebdo-annot-editor">
            <div class="p-0" fxLayout="column" fxLayoutAlign="" *ngIf="annotation">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-form-field class="" fxFlex>
                        <mat-label>{{textAreaLabel}}</mat-label>
                        <textarea matInput #autosize cdkTextareaAutosize="true" [cdkAutosizeMinRows]="0"
                                  [(ngModel)]="annotation['initial_text']"
                                  style="height: unset;min-height: unset;padding: 4px 0!important;"
                                  (focusin)="focusTextArea($event)"
                                  (focusout)="focusTextArea($event)"
                                  (keyup)="annotServ.annotationUpdate(annotation,$event);tick();"
                                  placeholder="Texte  ici..."></textarea>

                    </mat-form-field>
                    <mat-checkbox class="mr-4" [(ngModel)]="annotation.is_global" (change)="tick()">
                        <small class="text-muted">Globale</small>
                    </mat-checkbox>
                    <mat-icon class="cursor-pointer" (click)="delete()">delete_outline</mat-icon>
                </div>
            </div>
        </div>
    `,

    styleUrls: ['./proto-hebdo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush // required to make ngOnChange run on forceUpdate
})
export class AnnotEditorComponent implements AfterViewInit, OnInit, OnChanges {
    @ViewChild("autosize") autosize: CdkTextareaAutosize;
    @Input('type') type: string;
    @Input('annotationUID') annotationUID: any;
    @Input() forceUpdate: number;
    @Output('cb') cb: EventEmitter<any> = new EventEmitter<any>();
    annotation: ReportAnnotation = null;

    get textAreaLabel() {
        if (this.annotation && this.annotation.point_cache)
            return "Annotez ce point" + " x: " + this.annotation.x + (this.annotation.point_cache.text ? ': ' + this.annotation.point_cache.text : '');
        else
            return "Annotez ce point" + " x: " + this.annotation.x;
    }

    constructor(public site: SiteService, public myapp: AppService, public annotServ: AnnotationService, public ngZone: NgZone) {
    }

    ngOnInit() {
        //this.bootstrap();
        console.log("AnnotEditorComponent:INIT", this.annotServ.annotationsMapForSelectedComment, this.annotation);
        // this.triggerResize();
    }

    bootstrap() {
        if (this.type === 'creation')
            this.annotation = this.annotServ.annotationsMapForNewComment.get(this.annotationUID);
        if (this.type === 'selectedComment')
            this.annotation = this.annotServ.annotationsMapForSelectedComment.get(this.annotationUID);
        if (!this.annotation.initial_text) this.annotation.initial_text = this.annotation.text;
        if (!this.annotation.priority) this.annotation.priority = 'NORMAL';
    }

    delete() {
        this.myapp.confirm.confirm({title: "Attention!", message: "Cette action est irreversible."}).subscribe(res => {
            if (res) this.cb.next({ann: this.annotation, uid: this.annotationUID, act: 'delete'});
        });
    }

    tick() {
        this.annotation.ts = H.unixTs();
        this.cb.next({ann: this.annotation, uid: this.annotationUID, act: 'update'});
    }

    focusTextArea($ev) {
        console.log("focusTextArea", this.annotation.visual_key, $ev.type);
        this.cb.next({focusType: $ev.type, visual_key: this.annotation.visual_key, act: 'highlightPlot'});
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log("AnnotEditorComponent:ngOnChanges", changes);
        this.bootstrap();
    }

    ngAfterViewInit(): void {
    }

    triggerResize() {
        // Wait for changes to be applied, then trigger textarea resize.
        this.ngZone.onStable.pipe(take(1)).subscribe(() => {
            console.log("triggerResize()", this.autosize);
            this.autosize.resizeToFitContent(true);
        });
    }
}

import _isEmpty from 'lodash/isEmpty'
import _range from 'lodash/range'
import moment from 'moment'
import {linearRegression, linearRegressionLine} from 'simple-statistics'
import {ERROR_VALUES} from './constants'
import * as d3 from 'd3';

export function empty(values) {
    return _isEmpty(values)
        || values.every(v => v === null || typeof v === 'undefined')
}

/**
 * Find all x values against all series
 * @param data an array of series
 * @param serieFilter a filter function for series
 * @return {Array}
 */
export function findXValues(data, serieFilter = () => true) {
    return [].concat([],
        ...data
            .filter(serieFilter)
            .map(({x}) => x),
    ).filter(v => v !== null)
}

/**
 * Find all y values against all series
 * @param data an array of series
 * @param serieFilter a filter function for series
 * @return {Array}
 */
export function findYValues(data, serieFilter = () => true) {
    return [].concat([],
        ...data
            .filter(serieFilter)
            .map(({y}) => y),
    ).filter(v => v !== null)
}

export function findStackedYValues(data) {
    const seriesInFirstAxis = data.filter(serie => serie.type === 'bar' && serie.yaxis !== 'y2')
    if (seriesInFirstAxis.length) {
        // 2D array contains y values for each series
        const series = seriesInFirstAxis.map(serie => serie.y)

        // calculate max y length
        const maxLength = d3.max(series.map(values => values.length))

        // sum all values by index
        return _range(maxLength).map(i => {
            return series.reduce((acc, values) => acc + values[i] || 0, 0)
        })
    }
    return []
}

export function getMin(currentMin, mode) {
    const min = currentMin
    switch (mode) {
        case 'tozero': {
            // set 0 if the lowest value is greater than 0
            // however ymin can be < 0
            return Math.min(0, min)
        }
        // always zero
        case 'nonnegative': {
            return 0;
        }
        default: {
            // nothing to do
            return min
        }
    }
}

export function rangeFromValues(values, mode) {
    const min = d3.min(values)
    const max = d3.max(values)
    // choose ymin value
    return [getMin(min, mode), max]
}

export function configureTicks({values, range, nticks = 10, tickmode, padding = 0}) {
    let ymin
    let ymax
    let pad = padding

    if (!empty(values)) {
        // configure range from an array of values
        ymin = d3.min(values)
        ymax = d3.max(values)
    } else if (!empty(range)) {
        // configure range from a raw range
        [ymin, ymax] = range
    } else {
        // default range
        pad = 0
        ymin = 0
        ymax = 1
    }

    // prevent range[0,0]
    if (ymin === ymax && ymin === 0) {
        ymin = 0
        ymax = 1
    }

    // adapt minimum value according to tickmode
    ymin = getMin(ymin, tickmode)

    // use d3.js to create an array of ticks
    const ticks = d3.scaleLinear()
        .domain([ymin, ymax])
        .nice(nticks)
        .ticks(nticks)

    const step = ticks[1] - ticks[0] // step between ticks
    const start = ticks[0]
    let end = ticks.slice(-1)[0]

    // grow the range to have enough distance
    // between the maximum value and plot borders
    const dist = (end - ymax) / step
    if (dist < pad) {
        end += step
    }

    // set of plotly configurations
    return {
        range: [start, end],
        tickmode: 'linear',
        tick0: start,
        dtick: step,
    }
}

export function nice(number) {
    return d3.scaleLinear()
        .domain([0, number]).nice(1).ticks(1)[1]
}

export function configureTicksFromY1(config, range2) {
    const {range: range1, dtick: dtick1} = config
    // target number of ticks
    const nticks = (range1[1] - range1[0]) / dtick1
    const max = (range2[1] - range2[0])
    const step = nice(max / nticks)
    const start = range2[0]
    const newMax = start + (step * nticks)
    return {
        range: [start, newMax],
        tick0: start,
        dtick: step,
        tickmode: 'linear',
    }
}

export function removeErrorValues(values) {
    return (values || [])
        .map(value =>
            ERROR_VALUES.includes(value) ? null : value,
        )
}

/**
 * Remove empty traces and filter error values
 */
export function cleanSeries(series = [], xProp = 'x', yProp = 'y') {
    return series
        .map(serie => {
            // TODO: issue #15
            const y = removeErrorValues(serie[yProp])
            const xEmpty = empty(serie[xProp])
            const yEmpty = empty(y)
            if (xEmpty || yEmpty) {
                return false
            }
            return {...serie, [yProp]: y}
        })
        .filter(Boolean)
}

export function toDates(dateStamps) {
    return (dateStamps || []).map(time => {
        return moment(!time.match(/Z$/) ? `${time}Z` : time).toISOString()
    })
}

/**
 * Calculate a linear regression using the least sum of squares
 * @param data {{ x: Array<Number>, y: Array<Number> }} input trace data
 * @return {{x: *, y: *}} linear regression trace data
 */
export function bestFit(data) {
    // construct array of pairs (x, y)
    const {x = [], y = []} = data || {}
    const pairs = x.map((v, i) => [v, y[i]])
    // get the linear regression function
    const func = linearRegressionLine(linearRegression(pairs))
    const range = d3.extent(x)
    return {
        x: range,
        y: range.map(func),
    }
}

/**
 * Calculate the week of year for a date.
 * This fixes an issue when the date is a monday and we want the display
 * the previous week number.
 * @param date
 * @return {number}
 */
// codap 22 check

export function enerplanWeek(date) {
    return moment(date).subtract(1, 'day').isoWeek()
}

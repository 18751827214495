<div class="page-wrap h-fulldefault-bg">
  <div class="app-error">
    <div class="fix">
      <mat-icon class="error-icon" color="warn">warning</mat-icon>
      <div class="error-text">
        <h1 class="error-title">500</h1>
        <div class="error-subtitle">Server Error!</div>
      </div>
    </div>
    
    <div class="error-actions">
      <button 
      mat-raised-button 
      color="primary"
      class="mb-16 mr-8"
      [routerLink]="['/']">Back to Dashboard</button>
      <button 
      mat-raised-button 
      color="warn">Report this Problem</button>
    </div>
  </div>
</div>

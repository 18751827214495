import {Component, Input, OnInit} from "@angular/core";

@Component({
    selector: 'icon-fluid',
    template: `
        <mat-icon *ngIf="fluid==='EAU'" mat-list-icon>water_drop</mat-icon>
        <mat-icon *ngIf="fluid==='WATER'" mat-list-icon>water_drop</mat-icon>
        <mat-icon class="text-blue" *ngIf="fluid==='ELEC'" mat-list-icon>bolt</mat-icon>
        <mat-icon class="text-blue" *ngIf="fluid==='ELECTRICITY'" mat-list-icon>bolt</mat-icon>
        <mat-icon class="text-red" *ngIf="fluid==='CHALEUR'" mat-list-icon>whatshot</mat-icon>
        <mat-icon *ngIf="fluid==='FROID'" mat-list-icon>ac_unit</mat-icon>
    `
})
export class IconFluidComponent implements OnInit {
    @Input('fluid') fluid: string;


    constructor() {
    }

    ngOnInit() {

    }
}

<mat-card>
    <mat-card-title class="color-blue" *ngIf="site.currentSiteEvent"
                    fxLayout="row" fxLayoutAlign="space-between center">
        <div class="p-4" fxFlex="">
            <h2>
                <span *ngIf="!site.currentSiteEvent.uid">Partager le rapport</span>
                <span *ngIf="site.currentSiteEvent.uid">Archive de partage</span>
                <span *ngIf="site.currentSiteEvent.related_data&&site.currentSiteEvent.wat==='share-proto-hebdo'">
                    {{site.currentSiteEvent.related_data.reportDate}}
                </span>
            </h2>
        </div>
        <div class="ml-16 text-small">
            <span class="font-weight-bold color-blue mr-16">Type:</span>
            <span>[{{site.currentSiteEvent.wat}}]</span>
        </div>
        <div class="ml-16 text-small">

        </div>
        <div class="ml-16 text-small">
            <button mat-raised-button class="btn-xs-25"
                    (click)="dismiss()">Fermer
            </button>
        </div>
    </mat-card-title>

    <mat-card-content fxLayout="row" style="width: 1200px; height: 600px" class="m-0">
        <mat-card fxFlex="70" class="form-bloc m-0 p-4 mr-8">
            <mat-form-field class="full-width">
                <input matInput placeholder="Titre" [(ngModel)]="site.currentSiteEvent.subject"/>
            </mat-form-field>
            <mat-form-field class="full-width">
                <textarea matInput placeholder="Message"
                          [ngClass]="{'input-hidden':isArchive}"
                          [(ngModel)]="site.currentSiteEvent.body"></textarea>
                <span *ngIf="site.currentSiteEvent.uid" [innerHTML]="site.currentSiteEvent.body|nl2br"></span>
            </mat-form-field>
        </mat-card>
        <mat-card class="m-0 p-4" fxFlex fxLayout="column">
            <div class="but-global-select-panel" *ngIf="!isArchive">
                <button mat-raised-button class="btn-xs-25 m-4" (click)="select('all')">Tout sélectionner</button>
                <button mat-raised-button class="btn-xs-25 m-4" (click)="select('none')">Tout désélectionner</button>
            </div>
            <div class="users-scroller">
                <div class="avatar-holder" *ngFor="let u of site.responsibles">

                    <div fxLayout="row" fxLayoutAlign="space-between center"
                         (click)="addUser(u)" class="p-4 item">
                        <ngx-avatar class="cursor-pointer" [size]="35" [name]="u.first_name+' '+u.last_name">
                        </ngx-avatar>
                        <div class="pl-8">
                            <b>{{u.first_name + ' ' + u.last_name}}</b><br>
                            <span>{{u.email}}</span>
                        </div>

                        <span fxFlex></span>
                        <mat-icon *ngIf="site.currentSiteEvent.target&&site.currentSiteEvent.target.includes(u.email)">
                            check
                        </mat-icon>
                    </div>
                </div>
            </div>
        </mat-card>

    </mat-card-content>
    <mat-card-footer>
        <div class="p-16">
            <div fxLayout="row" *ngIf="!site.currentSiteEvent.uid">
                <div fxFlex></div>
                <div>
                    <button
                            [disabled]="!site.currentSiteEvent.target||!site.currentSiteEvent.target.length"
                            mat-raised-button color="primary" (click)="send()">Envoyer
                    </button>
                </div>
            </div>
        </div>

    </mat-card-footer>

</mat-card>

import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AppService} from "../../shared/services/app.service";
import {ApiService} from "../../shared/services/api.service";
import {SiteService} from "../../shared/services/site.service";
import {LayoutService} from "../../shared/services/core/layout.service";
import {DomSanitizer} from "@angular/platform-browser";
import {DatePipe} from "@angular/common";
import moment from "moment";
import {Action, ActionAssignee, ActionEconomyPerAgent} from 'app/shared/models/Action.model';
import {SiteTariffConfig, User} from 'app/shared/models/models';
import {EnergyAgent} from "../../shared/models/EnergyStats.model";

@Component({
    selector: 'action-economy',
    template: `
        <mat-card class="p-0 m-4" (click)="myapp.showConsole(econ)">

            <!-- IF MISSING PARENT TARIFF -->
            <div fxLayout="row" fxLayoutAlign="space-between center"
                 class="economy-estimated-row error" *ngIf="!tariffObj">
                <div fxFlex="40px">
                    <icon-fluid fxFlex="30px" [fluid]="econ.agent">
                    </icon-fluid>
                </div>
                <label fxFlex="" class="font-weight-bold"> {{ econ.label }} </label>
                <div fxFlex="200px" fxLayout="column" class=" ">
                    <div>
                        <mat-icon mat-list-icon inline>warning</mat-icon>
                        Economie Orpheline
                    </div>

                    <mat-select placeholder="Choix tarif parent" class="select-unit"
                                (selectionChange)="switchParentTariff($event.value)">
                        <mat-option [value]="t" *ngFor="let t of site.clientSite.tariff_config">
                            {{ t.label }}
                        </mat-option>
                    </mat-select>
                </div>
                <div fxFlex="120px" fxLayout="row" fxLayoutAlign="space-around center"
                     class="economy-input-legacy">
                    <strong>{{ econ.econEstimated }}</strong>
                </div>

                <div fxFlex="40px" class="economy-unit">
                    <span>{{ econ.unitStr }}</span>
                </div>
                <div class="year-right">
                    {{ site.clientSite.year_tariff_default }}
                </div>
            </div>

            <!-- IF PARENT TARIFF EXISTS-->
            <div fxLayout="row" fxLayoutAlign="space-between center" class="economy-estimated-row" *ngIf="tariffObj">
                <div fxFlex="40px">
                    <icon-fluid fxFlex="30px" [fluid]="tariffObj.fluid">
                    </icon-fluid>
                </div>
                <label fxFlex="" class="font-weight-bold"> {{ tariffObj.label }} </label>

                <div fxFlex="100px" class="text-right">
                    <span class="tariff">{{ site.getTariffValueForYear(econ.uid_tariff)|number }}
                    </span>
                    <span class="tariff"> {{ tariffObj.priceUnitStr }}</span>
                </div>
                <div fxFlex="120px" fxLayout="row" fxLayoutAlign="space-around center"
                     class="economy-input-legacy">
                    <input matInput [ngModel]="amount('estimated')"
                           [readonly]="act.isCompleted"
                           (keyup)="enterEconomyValue($event,'estimated')"
                           type="number" placeholder="Econ estimée">
                </div>

                <div fxFlex="40px" class="economy-unit">
                    <span>{{ tariffObj.renderAmount(34).unit }}</span>
                </div>
                <div class="year-right">
                    {{ site.clientSite.year_tariff_default }}
                </div>
            </div>

            <!-- real econ per year since termination -->
            <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="tariffObj">
                <div fxFlex="40px">

                </div>
                <div fxLayout="column" *ngIf="tariffValueForYears">
                    <div fxLayout="row" fxLayoutAlign="space-between center" (click)="myapp.showConsole(years,site.agentMetricToTarifsMap)"
                         *ngFor="let kv of tariffValueForYears|keyvalue" class="economy-year-row">

                        <div fxFlex="100px" class="text-right">
                            <span class="tariff">{{ kv.value|number }} </span>
                            <span class="tariff"> {{ kv.value.unit }}</span>
                        </div>

                        <div fxFlex="120px" fxLayout="row" fxLayoutAlign="space-around center"
                             class="economy-input-legacy">
                            <input matInput [ngModel]="amount('real',kv.key)" (keyup)="enterEconomyValue($event,'real',kv.key)"
                                   pattern="[0-9]+([\\.,][0-9]+)?" step="0.01"

                                   type="number" placeholder="Econ {{kv.key}}">
                        </div>

                        <div fxFlex="40px" class="economy-unit">
                            <span> {{ tariffObj.renderAmount(34).unit }}</span>
                        </div>

                        <div class="year-right">
                            {{ kv.key }}
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    `,
    styleUrls: ['./actions-subs.component.scss']
})
export class ActionEconItemComponent implements OnInit, OnChanges {
    @Input('econ') econ: ActionEconomyPerAgent;
    @Input('yearTerminated') yearTerminated: number;
    @Input('forceUpdate') forceUpdate: number;
    @Output('cb') cb: EventEmitter<any> = new EventEmitter<any>();
    tariffValueForYears = {}

    get act(): Action {
        return this.site.selectedAction;
    }

    get tariffObj() {
        return this.site.getTariffObj(this.econ.uid_tariff)
    }

    amount(type: string, year: number = 0) {
        let val = 0;
        if (type === 'real') {
            val = this.econ.econRealPerYear[year];
        }
        if (type === 'estimated') {
            val = this.econ.econEstimated;
        }
        return this.tariffObj.renderAmount(val).value;
    }

    constructor(public myapp: AppService, public site: SiteService,) {
    }

    ngOnInit(): void {
        this.site.tariffValueForYears.forEach((v, k) => {
            const chunks = k.split('-');
            const year = Number(chunks[0]);
            const tarifUID = chunks[1];
            if (this.tariffObj && tarifUID === this.tariffObj.uid)
                if (year > this.yearTerminated)
                    this.tariffValueForYears[year] = v;
            // console.log("tariffValueForYears", year, tarifUID, v, chunks);
        });
    }

    switchParentTariff(tariffObj: SiteTariffConfig) {
        console.log('switchParentTariff()', tariffObj, this.econ);
        this.myapp.confirm
            .confirm({
                title: "Cette action est irreversible !!",
                message: 'Ne pas oublier de sauvegarder'
            })
            .subscribe(ok => {
                if (ok) {
                    this.econ.fluid = tariffObj.fluid;
                    this.econ.agent = tariffObj.agent;
                    this.econ.label = tariffObj.label;
                    this.econ.uid_tariff = tariffObj.uid;
                    this.econ.unit = tariffObj.unit;
                    delete this.econ.clean_label;
                    delete this.econ.agentMetric;
                    delete this.econ.usageUID;
                    this.cb.emit(true);
                }
            });

    }

    deleteEconomy(econ: ActionEconomyPerAgent) {
        //const econToDelete = this.act.economies.filter(it => it.uid !== econ.uid);
        this.site.selectedAction.economies = this.act.economies.filter(it => it.uid !== econ.uid);
        console.log('deleteEconomy()', this.site.selectedAction.economies);
    }

    enterEconomyValue($event, econType, year = 0) {
        year = Number(year)
        setTimeout(() => {
            $event.target.value = Number($event.target.valueAsNumber);
            console.log("enterEconomyValueLegacy", $event.target.valueAsNumber, this.econ);

            if (econType === 'real') {
                if (!this.econ.econRealPerYear) this.econ.econRealPerYear = {};
                this.econ.econRealPerYear[year.toString()] = this.econ.calcAmountToStore($event.target.valueAsNumber);
            }
            if (econType === 'estimated') {
                this.econ.econEstimated = this.econ.calcAmountToStore($event.target.valueAsNumber);
            }

            this.cb.emit(true);
        }, 1000);

    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.econ.econRealPerYear) this.econ.econRealPerYear = {};
    }
}

<div class="flex-container" fxLayout="column" #plotHolder>

    <div fxLayout="column" class="toolbar-top" style="height: auto" [@animate]="{ value: '*', params: { y: '-190px', delay: '300ms' } }">
        <h2 fxFlex class="m-8" (dblclick)="sortGroupsDisplay()">
            Bilan des économies et pertes énergétiques et financières
        </h2>
        <div fxLayout="row" fxFlexAlign="space-between end" class="bmens-top-bar">
            <div class="years-holder" fxLayout="row" fxLayoutAlign="center" *ngIf="availableYears&&availableYears.length">
                <span [routerLink]="['/bmens/'+site.clientSite.uid+'/'+y+ '01']"
                      [ngClass]="{active:y===selectedYear}" class="btn-year"
                      *ngFor="let y of availableYears">{{ y }}</span>
            </div>


            <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                <div class="selected-bmens-head">
                    {{ selectedMonthStr }} {{ selectedYear }}
                </div>
                <div class="ref-year-div">
                    <span class="ref-head">Ref:</span>
                    <span *ngFor="let ref of availableRefYears.get(selectedYear)">{{ ref }}</span>
                </div>
                <div class="">
                    <button mat-raised-button color="accent" class="btn-xs-25" (click)="printAnnualReport()">
                        <mat-icon inline>print</mat-icon>
                        Imprimer
                    </button>
                </div>
            </div>
            <div fxLayout="row" *ngIf="selectedYear" style="height: 40px;vertical-align: bottom;margin-top: 8px;"
                 [@animate]="{ value: '*', params: { y: '90px', delay: '100ms' } }">

                <div *ngFor="let m of myapp.k.months;let i=index;">
                    <ng-container *ngIf="selectedMeterDataMap.get(selectedYear+myapp.k.monthsIndexToNumString[i]) as dt">
                        <button mat-button class="btn-tab" [ngClass]="{active:selectedMonthIndex===i}"
                                [routerLink]="['/bmens/'+site.clientSite.uid+'/'+getYearMonthKey(selectedYear,i)]"
                                (click)="selectMonth(i)">
                            <span strEnh="ucfirst">{{ myapp.k.months[i].trim() }}</span>
                        </button>
                    </ng-container>
                </div>

                <div fxFlex="60px" class="m-8">
                    <button mat-button
                            *ngIf="bmensIfExists&&myapp.getIfUserCanDoForRoute('BMENS','SHARE')"
                            (click)="initShareEvent(); myapp.openBS_Mailer()">
                        <mat-icon>share</mat-icon>
                    </button>
                </div>

            </div>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between stretch" class="page-body">
        <div *ngIf="!selectedKeyDataToMeterMap||!selectedKeyDataToMeterMap.size">
            <mat-card>
                <h3 class="">
                    <mat-icon inline>warning</mat-icon>
                    Pas de données pour le mois
                    {{ selectedMonthStr }} {{ selectedYear }}
                </h3>
            </mat-card>
        </div>


        <ng-container *ngIf="myapp.user&&selectedKeyDataToMeterMap&&selectedKeyDataToMeterMap.size">

            <div fxFlex="1030px" fxLayout="column" class="bloc">
                <div class="bmens-intro" #bloc>
                    <div [hidden]="!startPrint">
                        <h1>
                            Bilan des économies et pertes énergétiques et financières {{ selectedMonthStr }}.{{ selectedYear }}
                            <span> Réf:</span>
                            <span class="ref-year-span" *ngFor="let ref of availableRefYears.get(selectedYear)">{{ ref }}</span>
                        </h1>
                        <table>
                            <tr>
                                <td class="col1">
                                    Site: <b>{{ site.clientSite.name }}</b><br>
                                    {{ site.clientSite.address }}, {{ site.clientSite.npaLoc }}<br>
                                </td>
                                <td>
                                    <img src="logosrc" height="60px"/>
                                </td>
                            </tr>
                        </table>

                        <div>
                            <b>{{ site.clientSite.notes }}</b><br>
                            <div class="date">Généré le {{ myapp.nowLongStr }}</div>
                        </div>
                    </div>
                </div>

                <div #bloc>
                    <mat-card class="p-8">
                        <bmens-comment key="pageComment" (cb)="loadComments()" *ngIf="yearMonthKey"
                                       [yearMonthKey]="yearMonthKey"
                                       [groups]="groups" [markdownNote]="markdownNote"
                                       [commentEditMode]="commentEditMode"></bmens-comment>
                    </mat-card>
                </div>

                <ng-container *ngFor="let group of site.clientSite.bmens_groups">
                    <div #bloc>
                        <mat-card class="p-0" *ngIf="metersCountByGroup[group.uid]">
                            <h2 class="p-4 m-4">{{ group.label }}</h2>
                            <table class="table-bmens">
                                <ng-template [ngTemplateOutlet]="tableTrHead"></ng-template>

                                <ng-container *ngFor="let meter of bmensMeters">
                                    <ng-container *ngIf="meter.uid_group===group.uid&&selectedKeyDataToMeterMap.get(meter.uid)">
                                        <ng-container *ngIf="selectedKeyDataToMeterMap.get(meter.uid) as releves">
                                            <tr *ngFor="let releve of releves;let i=index;"
                                                [ngClass]="{active:selectedMeter&&meter.uid===selectedMeter.uid}">
                                                <td class="col1 color-blue" *ngIf="i===0" [rowSpan]="releves.length">
                                                    {{ meter.label }}
                                                </td>
                                                <td class="col-pdf">
                                                    <mat-icon *ngIf="releve.rows_cache.file&&releve.rows_cache.file.length"
                                                              (click)="site.openBmensAttachment(releve.rows_cache)"
                                                              inline style="font-size: 25px">
                                                        picture_as_pdf
                                                    </mat-icon>
                                                </td>
                                                <td class="col-evol">
                                                    <span class="p-0" *ngIf="releve.rows_cache.economy_evol_value>0">+</span>
                                                    <span>{{ releve.rows_cache.economy_evol_value }}</span>
                                                    <span class="unit">{{ meter.energy_unit }}</span>
                                                    <ng-container *ngIf="releve.rows_cache.economy_evol_percent">
                                                        <span style="width: 30px">{{ releve.rows_cache.economy_evol_percent }}</span>
                                                        <span>%</span>
                                                    </ng-container>
                                                </td>
                                                <td class="col-evol-fr">
                                                    <span>{{ releve.rows_cache.cost_corr_evol|number:'1.0' }}</span>
                                                    <span *ngIf="releve.rows_cache.cost_corr_evol" class="unit">
                                                    {{ meter.cost_unit }}
                                                </span>
                                                </td>
                                                <td class="col-cumul" class="col-cumul" title="energy_usage and unit">
                                                    <span>{{ releve.rows_cache.energy_usage|number:'1.0' }}</span>
                                                    <span class="unit">{{ meter.energy_unit }}</span>
                                                </td>
                                                <td class="col-cumul-fr">
                                                    <span>{{ releve.rows_cache.energy_cost|number:'1.0' }}</span>
                                                    <span *ngIf="releve.rows_cache.energy_cost" class="unit">Fr.</span>
                                                </td>
                                                <td class="col-tariff" title="tariff">
                                                    <span>{{ releve.rows_cache.tariff|number:'1.1-2' }}</span>
                                                    <span *ngIf="releve.rows_cache.tariff" class="unit">
                                                    {{ meter.tariff_unit }}
                                                </span>
                                                </td>
                                                <td class="col-ref">
                                                    <span class="col-ref-year">{{ releve.year_ref }}</span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>

                                <!-- total row -->
                                <ng-container class="row-total" *ngFor="let refYear of availableRefYears.get(selectedYear)">
                                    <tr class="row-total" *ngIf="rowsGroupedTotalMap.get(group.uid+'|'+refYear) as totEntry">
                                        <td class="col1 color-blue text-right">
                                            Total :
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <span class="p-0" *ngIf="totEntry.cost_corr_evol>0">+</span>
                                            <span>{{ totEntry.cost_corr_evol|number:'1.0' }}</span>
                                            <span *ngIf="totEntry.cost_corr_evol" class="unit">Fr. HTVA</span>
                                        </td>

                                        <td title="energy_usage and unit"></td>
                                        <td>
                                            <span>{{ totEntry.energy_cost|number:'1.0' }}</span>
                                            <span *ngIf="totEntry.energy_cost" class="unit">Fr.</span>
                                        </td>
                                        <td title="tariff"></td>
                                        <td class="col-ref-year p-8">{{ totEntry.year }}</td>
                                    </tr>
                                </ng-container>
                            </table>
                            <bmens-comment [key]="group.uid" (cb)="loadComments()"
                                           [yearMonthKey]="yearMonthKey"
                                           [groups]="groups" [markdownNote]="markdownNote"
                                           [commentEditMode]="commentEditMode"></bmens-comment>
                        </mat-card>
                    </div>

                </ng-container>
            </div>

            <div fxFlex fxLayout="column">
                <mat-card class="p-8" *ngIf="selectedMeter">
                    <div class="mb-32" fxLayout="column" fxLayoutAlign="start stretch">
                        <h2>Récap graphique de l'année <b>{{ selectedYear }}</b></h2>
                        <plotly-plot #plotUsage class="plotly-plot"
                                     [data]="plotUsageData"
                                     [layout]="plotUsageLayout"
                                     [config]="myapp.k.defaultConfig">
                        </plotly-plot>
                        <plotly-plot #plotCost class="plotly-plot"
                                     [data]="plotCostData"
                                     [layout]="plotCostLayout"
                                     [config]="myapp.k.defaultConfig">
                        </plotly-plot>
                        <div fxFlex></div>
                    </div>
                </mat-card>
            </div>
        </ng-container>
    </div>
</div>
<ng-template #tableTrHead>
    <tr class="head">
        <td class="col1">
            <span>Catégorie</span>
        </td>
        <td class="col-pdf">
            <span>PDF</span>
        </td>
        <td class="col-evol">
            <span>Évol. conso. corrélée</span>
        </td>
        <td class="col-evol-fr">
            <span>Évol corr. en Fr.</span>
        </td>
        <td class="col-cumul">
            <span>Cumul</span>
        </td>
        <td class="col-cumul-fr">
            <span>Coûts cumulés</span>
        </td>
        <td class="col-tariff">
            <span>Tarif moy.</span>
        </td>
        <td class="col-ref">
            <span>Réf.</span>
        </td>
    </tr>
</ng-template>

import {Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren, ViewContainerRef} from '@angular/core';
import {BehaviorSubject, forkJoin, concat, Observable, Subject, Subscription} from 'rxjs';
import moment from "moment";
import {AppService} from "../../../shared/services/app.service";
import {ApiService} from "../../../shared/services/api.service";
import {Overlay} from "@angular/cdk/overlay";
import {SiteService} from "../../../shared/services/site.service";
import {PlotlyService} from "angular-plotly.js";
import {LayoutService} from "../../../shared/services/core/layout.service";
import {DomSanitizer} from "@angular/platform-browser";
import {H} from "../../../shared/helpers/H";
import {egretAnimations} from "../../../shared/animations/egret-animations";
import {Plotly} from "angular-plotly.js/lib/plotly.interface";
import {plotCostLayout, plotCostsData, plotUsageData, plotUsageLayout} from "./plots-config";
import {ObsWrapper} from "../../../shared/models/models";
import {BmensData, BmensItemRow, BmensMeter} from 'app/shared/models/Bmens.model';
import {ActivatedRoute} from "@angular/router";
import {SiteEvent} from "../../../shared/models/Notifs";
import {SuiviPageDeGardeComponent} from "../suivi/suivi-page-garde.component";

@Component({
    selector: 'app-bmens',
    templateUrl: './bmens.component.html',
    styleUrls: ['./bmens.component.scss'],
    animations: egretAnimations
})
export class BmensComponent implements OnInit {
    @ViewChildren('bloc') blocs: QueryList<ElementRef>;

    public routeSubscription: Subscription = null;

    urlRef: string;
    urlKey: string;
    siteLoadingStatusCache: number;

    groups: Map<string, string> = new Map<string, string>();
    groupsLabels: string[] = [];// previous line map values

    bmensMeters: BmensMeter[] = [];
    bmensMetersMap: Map<string, BmensMeter> = new Map<string, BmensMeter>();//for convenience, getById like

    metersData: BmensData[] = [];//all releve of site

    selectedMeter: BmensMeter;// BmensMeter
    selectedMeterDataReleve: BmensData = null;//selected releve
    selectedMeterDataMap: Map<string, BmensData> = new Map<string, BmensData>();
    selectedKeyDataToMeterMap: Map<string, BmensData[]> = new Map<string, BmensData[]>();

    availableYears: number[];
    availableRefYears: Map<number, number[]> = new Map<number, number[]>();
    selectedYear: number = 0;
    selectedMonthIndex: number = 0;
    yearMonthKey: string;

    rowsGroupedMap: Map<string, BmensItemRow[]> = new Map<string, BmensItemRow[]>();
    rowsGroupedTotalMap: Map<string, any> = new Map<string, any>();

    report_comments: any[] = [];
    commentEditMode: any = {'pageComment': false};
    markdownNote: any = {};

    plotUsageLayout = plotUsageLayout as Plotly.Layout;
    plotCostLayout = plotCostLayout as Plotly.Layout;
    plotUsageData = plotUsageData;
    plotCostData = plotCostsData;

    sortType = 0;
    metersCountByGroup = {};

    pdfLink: string = '';
    startPrint = false;

    constructor(public myapp: AppService, public api: ApiService,
                public overlay: Overlay, public site: SiteService,
                public viewContainerRef: ViewContainerRef,
                public plotlyServ: PlotlyService,
                private currentRoute: ActivatedRoute,
                public layout: LayoutService, public sanitizer: DomSanitizer) {
    }

    get bmensIfExists() {
        return this.selectedMeterDataMap.has(this.yearMonthKey);
    }

    get selectedMonthStr() {
        return H.ucfirst(this.myapp.k.months[this.selectedMonthIndex].trim());
    }

    ngOnInit(): void {
        moment().locale('fr');
        if (this.myapp.user)
            this.myapp.storeCurrentRoute();
        else
            this.myapp.appInitStatus.subscribe(status => {
                if (status === 1)
                    this.myapp.storeCurrentRoute();
            });

        this.availableRefYears = new Map<number, number[]>();
        this.availableYears = [];
        this.site.siteLoadingStatus.subscribe(loadingLevel => {
            this.siteLoadingStatusCache = loadingLevel;
            if (loadingLevel === SiteService.MAX_SITE_LOADING_STATUS) {
                this.sortGroupsDisplay();
                this.bootstrap();
            }
        });
        // this.selectedYear = moment().year();
        this.routeSubscription = this.currentRoute.params.subscribe(queryParams => {
            if (queryParams['site'] && queryParams['key']) {
                this.urlRef = queryParams['site'];
                this.urlKey = queryParams['key'];
                this.yearMonthKey = this.urlKey;
                const yearUrl = Number(this.yearMonthKey.slice(0, 4));
                const monthUrl = Number(this.yearMonthKey.slice(4)) - 1;
                if (yearUrl !== this.selectedYear) {
                    // console.log("routeSubscription", {yearUrl, monthUrl}, this.selectedYear, this.selectedMonthIndex);
                    this.selectYear(yearUrl, 'route');
                }
                if (monthUrl !== this.selectedMonthIndex)
                    this.selectMonth(monthUrl);

                // if (this.metersData.length)
                // this.selectedMonthStr();
                // console.log("queryParams:CHANGE:BMENS", queryParams, this.siteLoadingStatusCache, queryParams['site'], this.urlRef, this.urlKey,);
            }
        });
    }

    bootstrap() {
        if (this.urlRef && this.site.clientSite && this.site.clientSite.ref !== this.urlRef) {
            console.log("BMENS::bootstrap()", this.urlRef);
            // this.site.getSites(this.urlRef);
            // return;
        }
        concat(
            this.wrapObserver('getMeters'),
            this.wrapObserver('getMetersData'),
        ).subscribe(res => {
            if (res) {
                if (res.type === "getMeters") {
                    this.parseMeters(res.resp);
                }
                if (res.type === "getMetersData") {
                    this.metersData = [];
                    this.parseMetersData(res.resp);
                    this.initShareEvent();
                }
                console.log('bmens::bootstrap: Response', res);
            }
            if (this.metersData && this.metersData.length && this.bmensMeters && this.bmensMeters.length)
                this.selectYear(this.selectedYear, 'bootstrap');
        });
    }

    initEditMode() {
        if (!this.commentEditMode['pageComment'])
            this.commentEditMode['pageComment'] = false;
        if (!this.markdownNote['pageComment'])
            this.markdownNote['pageComment'] = false;

        this.groups.forEach((value, key) => {
            this.commentEditMode[key] = false;
        })
        console.log("initEditMode", this.commentEditMode);
    }

    wrapObserver(type: string): Observable<ObsWrapper> {
        let obs: Observable<any>;
        if (type === 'getMeters') {
            obs = this.site.api.getBmensMeters(this.site.uid);
        }
        if (type === 'getMetersData') {
            obs = this.site.api.getAllMetersData(this.site.uid);
        }
        return new Observable<ObsWrapper>(observer => {
            console.info('wrapObserver:::::', type);
            obs.subscribe(res => {
                observer.next({resp: res, type});
                observer.complete();
            });
        });
    }

    parseMeters(resp) {
        this.myapp.loader.open("Reception des compteurs créés");
        this.myapp.loader.close();
        this.bmensMeters = [];
        this.groupsLabels = [];

        this.groups = new Map<string, string>();
        if (resp && resp.body && resp.body.length > 0) {
            resp.body.forEach(it => {
                const meter = new BmensMeter(it);

                /// TODO: remove groupLabels since groups became global var stored on ClientSite.bmens_groups
                this.groups.set(meter.uid_group, meter.group_label);
                this.groupsLabels.push(meter.group_label);

                if (meter.is_in_bmens) {
                    this.bmensMeters.push(meter);
                    this.bmensMetersMap.set(meter.uid, meter);
                }

            });
            this.initEditMode();
            this.bmensMeters = this.bmensMeters.sort((a, b) => a.disp_order - b.disp_order)
            /*
            if (this.selectedYear)
                this.selectYear(this.selectedYear);
            else this.selectYear(this.availableYears[this.availableYears.length - 1]);
            */
        } else this.myapp.showMessage("Aucun compteur");
    }

    parseMetersData(resp) {
        this.availableRefYears = new Map<number, number[]>();
        this.availableYears = [];
        this.selectedMeterDataMap = new Map<string, BmensData>();
        if (!resp) {
            console.log('bmens::parseMetersData()', 'Pas de compteurs trouvés')
            this.myapp.showError('Pas de compteurs trouvés');
            return;
        }
        resp.body.forEach(item => {
            const meterData = new BmensData(item);
            if (!this.availableYears.includes(meterData.year))
                this.availableYears.push(meterData.year);
            if (!this.availableRefYears.has(meterData.year))
                this.availableRefYears.set(meterData.year, []);
            this.availableRefYears.get(meterData.year).push(meterData.year_ref);
            this.availableRefYears.get(meterData.year).sort();
            this.availableRefYears.set(meterData.year, H.arrayUnique(this.availableRefYears.get(meterData.year)));

            this.metersData.push(meterData);
            this.selectedMeterDataMap.set(meterData.releve_key.toString(), meterData);
        });
        this.availableYears.sort();
        console.log('bmens::parseMetersData():  this.availableYears', this.availableYears);
        if (!this.selectedYear && this.availableYears.length > 0)
            this.selectYear(this.availableYears[this.availableYears.length - 1]);
        /*
        if (this.selectedYear)
            this.selectYear(this.selectedYear);
        else this.selectYear(this.availableYears[this.availableYears.length - 1]);
        */
    }

    selectYear(y, source = '') {
        this.selectedYear = y;
        //console.log("selectYear", source, y, ' month', this.selectedMonthIndex);
        setTimeout(() => {
            if (this.selectedMonthIndex === 0)
                this.selectLastMonthOfYear();
            else
                this.selectMonth(this.selectedMonthIndex);
        }, 100);
    }

    selectLastMonthOfYear() {
        let highestIndex = -1;
        this.myapp.k.months.forEach((m, index) => {
            const has = this.selectedMeterDataMap.has(this.selectedYear + this.myapp.k.monthsIndexToNumString[index]);
            if (has) highestIndex = index;
        });
        //console.log("selectLastMonthOfYear", highestIndex, this.selectedMonthIndex);
        if (highestIndex !== -1)
            this.selectMonth(highestIndex);
    }

    selectMonth(monthIndex: number) {
        if (!this.site.clientSite) return;
        this.rowsGroupedTotalMap = new Map<string, any>();
        this.selectedMonthIndex = monthIndex;
        const month = Number(this.selectedMonthIndex) + 1;
        const prefix = month < 10 ? '0' : '';
        this.yearMonthKey = this.selectedYear + prefix + month;
        this.selectedKeyDataToMeterMap = new Map<string, BmensData[]>();
        this.metersData.forEach(dt => {
            if (dt.month === month && dt.year === this.selectedYear) {
                if (!this.selectedKeyDataToMeterMap.has(dt.uid_meter))
                    this.selectedKeyDataToMeterMap.set(dt.uid_meter, []);
                this.selectedKeyDataToMeterMap.get(dt.uid_meter).push(dt);
            }
        });

        // calc total by groups
        this.metersCountByGroup = {};
        if (!this.site.clientSite.bmens_groups) {
            this.myapp.showError("Groupes compteurs non définis !");
            return;
        }
        this.site.clientSite.bmens_groups.forEach(g => this.metersCountByGroup[g.uid] = 0);
        // reset group total
        this.groups.forEach((val, key) => {
            //this.rowsGroupedTotalMap.set(key, {energy_cost: 0, cost_corr_evol: 0});
        });

        // scan bmensMetersData and check meter_uid for each data and get group_uid, then increment total
        this.metersData.forEach(dt => {
            const parentMeter = this.bmensMetersMap.get(dt.uid_meter);
            if (dt.month === month && dt.year === this.selectedYear && parentMeter) {
                const currMeterGroup = this.bmensMetersMap.get(dt.uid_meter).uid_group;
                const currMeterGroupandYear = this.bmensMetersMap.get(dt.uid_meter).uid_group + '|' + dt.year_ref;
                if (this.bmensMetersMap.has(dt.uid_meter)) {
                    // counting groups meters count (detect empty ones)
                    this.metersCountByGroup[currMeterGroup] = this.metersCountByGroup[currMeterGroup] + 1;

                    // calc tot
                    if (!this.rowsGroupedTotalMap.has(currMeterGroupandYear))
                        this.rowsGroupedTotalMap.set(currMeterGroupandYear, {energy_cost: 0, cost_corr_evol: 0, year: dt.year_ref});

                    let acc = this.rowsGroupedTotalMap.get(currMeterGroupandYear)
                    acc.energy_cost = acc.energy_cost + dt.rows_cache.energy_cost;
                    acc.cost_corr_evol = acc.cost_corr_evol + dt.rows_cache.cost_corr_evol;
                    this.rowsGroupedTotalMap.set(currMeterGroupandYear, acc);
                }

            }
        });

        console.log(" this.rowsGroupedTotalMap", this.rowsGroupedTotalMap);

        // render plots
        if (this.selectedMeter)
            this.renderPlots();

        // load comments
        if (this.bmensIfExists)
            this.loadComments('selectMonth' + monthIndex);
    }

    selectMeter(meter: BmensMeter) {
        this.selectedMeter = meter;
        //this.metersData = [];
        this.selectedMeterDataReleve = null;
        this.renderPlots();
    }


    initShareEvent() {
        this.site.currentSiteEvent = new SiteEvent({});
        this.site.currentSiteEvent.site_name = this.site.clientSite.name;
        this.site.currentSiteEvent.uid_site = this.site.clientSite.uid;
        this.site.currentSiteEvent.subject = this.site.clientSite.name + ' - Bilan mensuel - ' + this.selectedMonthStr + ". " + this.selectedYear;
        this.site.currentSiteEvent.body = this.myapp.k.inMailerBmensText + this.myapp.user_display_name_short + " \n" +
            this.myapp.user.office;
        this.site.currentSiteEvent.target = [];
        this.site.currentSiteEvent.wat = 'share-bmens';
        this.site.currentSiteEvent.related_data = {
            link: this.myapp.router.url,
            report_key: this.yearMonthKey, site_id: this.site.uid
        };
    }

    loadComments(sourse = '') {
        this.markdownNote = {};
        console.log("loadComments", sourse);
        this.api.loadComments(this.site.uid, this.yearMonthKey, null)
            .subscribe(resp => {
                if (resp) {
                    this.report_comments = resp.body;
                    if (this.report_comments && this.report_comments.length)
                        this.report_comments.forEach(it => {
                            // console.log("this.report_comments.forEach", it.key_page, it.comment);
                            this.markdownNote[it.key_page] = it.comment ? it.comment : '';
                        });

                }
            }, error => {
                console.log("loadComments", error);
            });
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        if (this.commentEditMode) return;
        if (event.code === 'ArrowRight' || event.code === 'ArrowLeft') {
            if (event.code === 'ArrowRight') {
                this.selectedMonthIndex++;
                if (this.selectedMonthIndex > 11) {
                    this.selectedMonthIndex = 0;
                    this.selectedYear = this.selectedYear + 1;
                }
            }
            if (event.code === 'ArrowLeft') {
                this.selectedMonthIndex--;
                if (this.selectedMonthIndex < 0) {
                    this.selectedMonthIndex = 11;
                    this.selectedYear = this.selectedYear - 1;
                }
            }
            // console.log("handleKeyDown", this.selectedYear, this.selectedMonthIndex);
            this.site.router.navigate(['/bmens/' + this.site.clientSite.uid + "/" + this.getYearMonthKey(this.selectedYear, this.selectedMonthIndex)]);
            this.selectMonth(this.selectedMonthIndex);
        }

    }

    getYearMonthKey(year, monthIndex) {
        let month = monthIndex + 1;
        if (month < 10) month = '0' + month;
        else month = '' + month.toString();
        return year + month;
    }

    sortGroupsDisplay() {
        if (this.site.clientSite.bmens_groups === null) {
            console.error("this.site.clientSite.bmens_groups is NULL");
            return;
        }
        this.sortType++;
        if (this.sortType === 5) this.sortType = 1;
        this.site.clientSite.bmens_groups = this.site.clientSite.bmens_groups.sort((a, b) => {
            if (this.sortType === 1)
                return a.disp_order - b.disp_order;
            if (this.sortType === 2)
                return b.disp_order - a.disp_order;
            if (this.sortType === 3)
                return a.is_total - b.is_total;
            if (this.sortType === 4)
                return b.is_total - a.is_total;
        });
    }

    renderPlots() {
        if (!this.selectedMeter) {
            this.myapp.showError("Row non défini");
            return;
        }

        const points = this.metersData
            .filter(it => it.year === this.selectedYear && this.selectedMeter.uid === it.uid_meter)
            .map(it => {
                const month = moment(it.releve_date).month();
                const data = {...it.rows_cache, month};
                return data;
            })
        ;
        // console.log('points: ' + this.selectedYear, points);
        this.plotUsageData[0].x = [];
        this.plotUsageData[0].marker.color = [];//"#2196F3"
        this.plotCostData[0].x = [];
        this.plotCostData[0].marker.color = [];//""#F44336""
        this.plotUsageData[0].y = [];
        this.plotCostData[0].y = [];

        this.plotUsageLayout = plotUsageLayout as Plotly.Layout;
        this.plotCostLayout = plotCostLayout as Plotly.Layout;

        points.forEach((v, i) => {
            this.plotUsageLayout.xaxis.tickvals[i] = v.month;
            this.plotUsageLayout.xaxis.ticktext[i] = this.myapp.k.months[v.month];
            this.plotCostLayout.xaxis.tickvals[i] = v.month;
            this.plotCostLayout.xaxis.ticktext[i] = this.myapp.k.months[v.month];
            //this.plotCostLayout.xaxis.tickvals.push(Number(v.month));
            //this.plotCostLayout.xaxis.ticktext.push(this.myapp.k.months[i]);
            this.plotUsageData[0].x.push(v.month);
            if (v.month === this.selectedMonthIndex) {
                this.plotUsageData[0].marker.color.push("#ffff66");
                this.plotCostData[0].marker.color.push("#ffff66");
            } else {
                this.plotUsageData[0].marker.color.push("#2196F3");
                this.plotCostData[0].marker.color.push("#F44336");
            }
            this.plotCostData[0].x.push(v.month);
            this.plotUsageData[0].y.push(v.energy_usage);
            this.plotCostData[0].y.push(v.energy_cost);
            // console.log("points", v.month, v.energy_usage);
            //console.log("points", v.month, v.energy_usage, v);
        });
        this.plotUsageLayout.title = "Consommation cumulés: " + this.selectedMeter.group_label
            + " | " + this.selectedMeter.label;
        this.plotUsageLayout.width = 600;
        this.plotUsageLayout.height = 300;
        this.plotCostLayout.title = "Coûts cumulés: " + this.selectedMeter.group_label + " | " + this.selectedMeter.label;
        this.plotCostLayout.width = 600;
        this.plotCostLayout.height = 300;
        this.plotUsageLayout.datarevision++;
        this.plotCostLayout.datarevision++;
    }

    /*
    PRINT
     */
    printAnnualReport() {
        this.startPrint = true;
        setTimeout(() => {
            this.getBlocsHtml();
        }, 500);
    }

    getBlocsHtml() {

        //const coverRawHtml = this.cover.html.nativeElement as HTMLElement;
        //const coverRawHtmlCleaned = this.visit_nodes_recursive(this.callback, coverRawHtml);
        const blocsRawHtml = [];

        this.blocs.forEach(elm => {
            const el = elm.nativeElement as HTMLElement;
            // console.log("Native element", el);
            const cleanedEl = this.visit_nodes_recursive(this.callback, el);
            blocsRawHtml.push(cleanedEl.outerHTML);
            // console.log("Native element", cleanedEl.outerHTML);
        });
        //const b64Cover = ApiService.bs64FromJsonStr((coverRawHtmlCleaned.outerHTML));
        const b64Html = ApiService.bs64FromJsonStr(JSON.stringify(blocsRawHtml));
        //console.log("plotImages 64", b64Images);
        this.site.api.printSuivi('', b64Html, '', this.yearMonthKey, 'bmens')
            .subscribe(resp => {
                this.pdfLink = this.site.api.getLink(resp.url + '&type=bmens', true);
                console.log("respPrint", resp);
                console.log(" this.pdfLink ", this.pdfLink);
                H.openUrlNewTab(this.pdfLink);
                this.startPrint = false;
            });
    }

    private visit_nodes_recursive(callback, node, depth = 0) {
        if (!node) return;
        this.callback(null, node);
        if (node.children && node.children.length > 0) {
            Array.from(node.children).forEach(child => {
                this.visit_nodes_recursive(this.callback, child, depth + 1);
            });
        }
        return node;
    }

    private callback(arg, node: HTMLElement) {
        if (node && node.hasAttributes() && false) {
            // console.log("callback", node.tagName, node.className);
            node.classList.remove('ng-star-inserted');
            if (node.tagName.includes('suivi'))
                node.setAttribute("style", 'display:block');
            else
                node.removeAttribute('style');
            node.getAttributeNames().forEach(attr => {
                const attrVal = node.getAttribute(attr);
                if (attrVal === undefined || attrVal === '') node.removeAttribute(attr);
                if (attr.includes('ng') || attr.includes('fx')) node.removeAttribute(attr);
            })
        }
    }
}

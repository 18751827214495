import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AppService} from "../../../shared/services/app.service";
import {SiteService} from "../../../shared/services/site.service";
import {Optimise} from "../../../shared/helpers/Optimise";
import moment from "moment";
import {K} from "../../../shared/models/K";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {ReportSuivi} from "../../../shared/models/Suivi.model";
import {Action} from "../../../shared/models/Action.model";

@Component({
    selector: 'suivi-goals',
    template: `
        <div class="component-holder">
            <mat-card class="m-0 mb-8 p-8 mt-4" fxLayout="row" fxLayoutAlign="space-between center">
                Objectif sur 10 ans:
                <b class="color-blue">{{reportRef.goal}} %</b>
                <div fxFlex></div>
                <div class="p-4 noprint" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="canEdit">
                    <input #yearToAdd type="number" class="goal-input"
                           [(ngModel)]="reportRef.goal" placeholder="Objectif"/>
                    <button mat-raised-button class="btn-xs-25"
                            [disabled]="!myapp.isAdmin" (click)="saveSuivi()">Sauvegarder
                    </button>
                </div>
            </mat-card>
            <h2>Objectifs</h2>
            <table class="table-actions">
                <tr class="table-actions-head">
                    <td *ngFor="let col of colsGoals">
                        <span>{{goalsCols[col]}}</span>
                    </td>
                </tr>
                <tr class="table-actions-row " *ngFor="let g of goals">
                    <td *ngFor="let col of colsGoals">
                        <ng-container *ngIf="col==='index'">
                            {{g[col]}}
                        </ng-container>
                        <ng-container *ngIf="col!=='index' ">
                            {{g[col]|number:'1.1-1'}} %
                        </ng-container>

                    </td>
                </tr>
            </table>
        </div>
    `,
    styleUrls: ['./suivi.component.scss']
})
export class SuiviGoalsComponent implements OnInit, OnChanges {
    @Input('reportRef') reportRef: ReportSuivi;
    @Input('report') report: ReportSuivi;
    @Input('year') year: number;//force update
    @Input('goals') goals: any[];

    actions: Action[] = [];

    sortBy: string = '';
    sortDirection = 0;

    goalsCols = {
        index: "Année",
        goal_theorical: "Objectifs théoriques",
        goal_minimum: "Objectifs minimaux",
        efficiency_predicted: 'Efficacité prévisionnelle évolutive',
        efficiency_real: "Efficacité réelle"
    };
    log = [];

    constructor(public myapp: AppService, public site: SiteService) {
    }

    get canEdit() {
        return this.myapp.getIfUserCanDo('EDIT');
    }

    get colsGoals() {
        return Object.keys(this.goalsCols);
    }

    ngOnInit() {
        this.site.siteSpecificEventTriggerer.subscribe(eventName => {
            if (eventName === SiteService.PDMS_CONFIG_RELOADED) {
                this.bootstrap();
            }
        });
    }

    // check commit  with message 'VERSION MIGRATION' to see version of this component related to Pdm
    bootstrap() {
        this.actions = this.site.actions.filter(act => {
            // console.log("Filter", act, act.status, act.year, act.status === "COMPLETED", act.year === this.report.year);
            return moment(
                    act.ts_completed * 1000).year() === this.report.year
                && act.gc
                && (act.status === "COMPLETED" || act.status === "REFUSED")
                ; //act.status === "COMPLETED" && //act.year === this.report.year  &&
        });
        this.sort('num');

    }

    changeTab($event: MatTabChangeEvent) {
        console.log("change tab", $event);
        this.sortBy = '';
        this.sortDirection = 0;
    }

    sort(key) {
        this.sortDirection++;
        this.sortBy = key;
        if (this.sortDirection === 2) this.sortDirection = -1;
        this.actions = Optimise.sortArrayByKeyVal(this.actions, key);
        if (this.sortDirection === -1) this.actions = this.actions.reverse();
    }

    saveSuivi() {
        const dataToDave = {};
        dataToDave['goal'] = this.reportRef.goal;
        this.site.api.saveSuivi(this.reportRef, dataToDave)
            .subscribe(resp => {
                if (resp.status == 1) {
                    this.myapp.showMessage("Convention modifiée");
                }
                console.log("saveSuivi ", resp);
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.bootstrap();
    }

}

import {H} from "../helpers/H";
import moment from "moment/moment";
import {K} from "./K";
import {Moment} from "moment";
import {Metric} from "./EnergyStats.model";


export class StatMensXlsFile {
    id: string;
    file_name: string;
    mandant_nom: string;
    num: number;
    agent: string;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
    }
}

export class BmensGroup {
    label: string;
    uid: string;
    type: string;
    note: string;
    disp_order: number;
    is_total: number;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
    }
}

export class BmensMeter {
    uid: string;
    uid_clear: string;
    uid_site: string;
    uid_group: string;
    group_label: string;
    label: string;
    type: string;
    type_var: string;

    agent: string;
    fluid: string;
    energy_unit: string;
    cost_unit: string;
    tariff_unit: string;

    lieu: string;
    statmens_file = '';

    disp_order: number;
    is_active: number;
    is_entrant: number;
    is_tarif: number;
    is_total: number;
    is_in_suivi: number;
    is_in_bmens: number;

    releve_first: string;
    releve_last: string;
    releve_count: number;

    note: string; // from submeter initially, would be later used for something else
    log_submeter_sync: any; // keep submeters uid

    ts_insert: number;
    ts_update: number;
    _site_name: string;// Joined on backend
    _site_ref: string;// Joined on backend

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
    }

    /// TODO: fix this, no need to retun raw energy unit
    get energyUnit() {
        const unitStr = Metric.unitShort[this.energy_unit];
        return unitStr ? unitStr : this.energy_unit;
    }

}

export class BmensDataRowsCache {
    label: string;
    part: number;
    ceges: number;
    fp: number;
    energy_usage: number;
    file: string;
    uid: string;
    group: string;
    tariff_unit: string;//	"c./kWh"
    tariff: number;
    energy_cost: number;
    energy_cost_unit: string;//	"Fr."
    energy_usage_unit: string;//"MWh"
    economy_evol_value: number;
    economy_evol_percent: number;
    cost_corr_evol: number;
    date_str: string;//	"09.2020 / 2019"

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
    }
}

export class BmensData {
    data: any;
    merge_conf: any;// store merge trace
    month: number;
    releve_date: string;
    releve_date_short: string;
    releve_key: number;
    rows_cache: BmensDataRowsCache;
    ts_insert: number;
    ts_update: number;
    uid: string;
    uid_meter: string;
    uid_site: string;
    year: number;
    year_ref: number;
    _mom: Moment;
    _mom_create: Moment;
    _evol: number;//populated in suivi for evol table
    _meterObj: BmensMeter;// populated by other query in untime

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
        this.rows_cache = new BmensDataRowsCache(this.rows_cache);
        // calced based on year and month
        this.releve_key = Number(this.year + K.monthsIndexToNumString[this.month - 1]);
        this.releve_date = this.year + '-' + K.monthsIndexToNumString[this.month - 1] + '-01';
        this._mom = moment(this.releve_date, 'YYYY-MM-DD');
        this._mom_create = moment.unix(this.ts_insert);
        this.releve_date_short = this._mom.format('MMM YYYY')
    }

    get dateStr() {
        return this._mom.format('MMM YYYY');
    }

    get dateCreateStr() {
        return this._mom_create.format('ddd DD MMM HH:mm');
    }
}

export class BmensItemGroup {
    label: string;
    id: string;
    categ: string;
    dispOrder: number;
    showTotal: boolean;
    showPartColumn: boolean;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
        if (this.showTotal === undefined)
            this.showTotal = true;
        if (this.showPartColumn === undefined)
            this.showPartColumn = true;
        if (this.categ === undefined)
            this.categ = 'energy';
    }
}

export class BmensItemRow {
    cost_corr_evol: number;
    date_str: string;
    economy_evol_percent: number;
    economy_evol_value: number;
    energy_cost: number;
    energy_cost_unit: string;
    energy_usage: number;
    energy_usage_unit: string;

    file: string;
    file_ts: number;
    file_original: number;

    group: string;
    label: string;
    part: string;
    tariff: number;
    tariff_unit: string;
    show_bmens = true;
    show_suivi = true;
    uid: string;

    constructor(dynProps: any = {}) {
        this.uid = H.randomStr(16);
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
        if (!dynProps['show_bmens']) this.show_bmens = true;
        if (!dynProps['show_suivi']) this.show_suivi = true;
    }
}

export class BmensItem {
    uid: string;
    uid_site: string;
    date: string;
    year: number;
    year_ref: number;
    rows: BmensItemRow[];
    _rows: string;
    cols: any[];
    group_config: BmensItemGroup[];
    notes: string;
    hidden_rows: any;
    ts_updated: number;
    ts_created: number;
    monthIndex: number;
    dateStrShort: string;
    justInserted = false;

    constructor(dynProps: any = {}) {
        this.ts_created = H.unixTs();
        this.ts_updated = 0;
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
        if (this.rows && this.rows.length > 0)
            this.rows = this.rows.map(it => new BmensItemRow(it));

        if (this.group_config && this.group_config.length > 0)
            this.group_config = this.group_config.map(it => new BmensItemGroup(it));

        this.changeDate(this.date);
    }

    changeDate(date) {
        this.date = date;
        const momObj = this.mom;
        if (this.rows)
            this.rows = this.rows.map(row => {
                row.date_str = momObj.format("MM.YYYY") + " / " + this.year_ref;
                return row;
            });
        this.dateStrShort = momObj.format('MMM Y');
        this.monthIndex = momObj.month();
    }

    get mom() {
        return moment(this.date, "YYYY-MM-DD");
    }
}

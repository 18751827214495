export const COLORS = [
    '#1069C9',
    '#969696',
    '#3ECCCB',
    '#C0C0C0',
    '#9ACA27',
    '#FECB9C',
    '#3BFB42',
    '#3D9768',
]
export const COLORS_CURVE = [
    '#FF0226',
    '#00FF33',
    '#001AFA',
    '#FDCA42',
    '#666895',
    '#953317',
]
export const SYMBOLES = ['circle', 'diamond', 'star-triangle-up']
export const SYMBOLES_BAR = ['circle', 'star-triangle-up', 'square']
export const ERROR_VALUES = [
    -2146826281,
    -2146826265,
    -2146826246,
    -2146826273,
    -2146826259,
    -2146826252,
]

export const VisualTypes = {
    SIGNATURE: 'SIGNATURE',
    BAR: 'BAR',
    CURVE: 'CURVE',
    TABLE: 'TABLE',
    PIE: 'PIE',
}

export const gridColor = '#808080'
export const fontColor = '#000000'

const ticklen = 3
const bodyStyle = {
    size: 12,
    color: fontColor,
}

const captionStyle = {
    size: 10,
    color: fontColor,
}

export const defaultLayout = ({data, layout}) => {
    const dataLength = data.length
    return {
        paper_bgcolor: '#fff',
        font: {
            family: 'Roboto, sans-serif',
            size: 10,
            color: fontColor,
        },
        xaxis: {
            color: gridColor,
            titlefont: {...captionStyle},
            tickfont: {...captionStyle},
            gridcolor: gridColor,
            ticklen,
            tickcolor: gridColor,
            ticks: 'outside',
            showline: true,
            mirror: true,
            separatethousands: true,
        },
        yaxis: {
            color: gridColor,
            titlefont: {...captionStyle},
            tickfont: {...captionStyle},
            gridcolor: gridColor,
            ticklen,
            tickcolor: gridColor,
            ticks: 'outside',
            hoverformat: '.2r',
            showline: true,
            mirror: true,
            separatethousands: true,
        },
        legend: {
            x: 0.5,
            bgcolor: 'transparent',
            y: 1.005,
            xanchor: 'center',
            yanchor: 'bottom',
            tracegroupgap: 0,
            orientation: 'h',
            traceorder: 'normal',
        },
        autosize: false,
        showlegend: dataLength > 1,
        separators: ".'",
        margin: {
            pad: 0,
            t: 20,
            b: 35,
            r: 45,
            l: 45,
        },
    }
}

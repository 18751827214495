import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {AppService} from "../app.service";

interface IMenuItem {
    type: string; // Possible values: link/dropDown/icon/separator/extLink
    name?: string; // Used as display text for item and title for separator type
    state?: string; // Router state
    icon?: string; // Material icon name
    svgIcon?: string; // UI Lib icon name
    tooltip?: string; // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
}

interface IChildItem {
    type?: string;
    name: string; // Display text
    state?: string; // Router state
    icon?: string; // Material icon name
    svgIcon?: string; // UI Lib icon name
    sub?: IChildItem[];
}

interface IBadge {
    color: string; // primary/accent/warn/hex color codes(#fff000)
    value: string; // Display text
}

@Injectable()
export class NavigationService {
    iconMenu: IMenuItem[] = [
        {
            name: "Editeur PDM",
            type: "link",
            icon: "edit",
            state: "pdm-editor",
        },
        {
            name: "StatMens config.",
            type: "link",
            icon: "person",
            state: "statmens-conf",
        },
        /*
        {
            name: "Module de saisie",
            type: "link",
            icon: "keyboard_hide",
            state: "saisie",
        },
        {
            name: "Données globales",
            type: "link",
            icon: "legend_toggle",
            state: "global-data",
        },
        {
            name: "Migration finale",
            type: "link",
            icon: "sync",
            state: "migration",
        },*/
        {
            name: "Supervision",
            type: "link",
            icon: "person",
            state: "sessions-monitor",
        },
        {
            name: "Monitoring Hebdo",
            type: "link",
            icon: "person",
            state: "proto-monitor",
        },
        {
            name: "Monitoring ECO",
            type: "link",
            icon: "person",
            state: "monitor-econ",
        },
        {
            name: "Monitoring MENS",
            type: "link",
            icon: "person",
            state: "monitor-bmens",
        },
        {
            name: "Utilisateurs",
            type: "link",
            icon: "person",
            state: "users",
        },
        {
            name: "Gestion clients",
            type: "link",
            icon: "supervised_user_circle",
            state: "clients",
        },
    ];

    // Icon menu TITLE at the very top of navigation.
    // This title will appear if any icon type item is present in menu.
    iconTypeMenuTitle = "Frequently Accessed";
    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();
    filteredIconMenu: IMenuItem[] = [];

    constructor(public myapp: AppService) {
        this.myapp.appInitStatus.subscribe(state => {
            if (this.myapp.user) {

                const filteredIconMenu = this.myapp.user.filterNavLinks(this.iconMenu);
                this.menuItems.next(filteredIconMenu);
            }
            if (state === 1) {
            }
            //    console.log("NavigationService::appInitStatus", state, this.iconMenu);
        });
    }

    // Customizer component uses this method to change menu.
    // You can remove this method and customizer component.
    // Or you can customize this method to supply different menu for
    // different user type.
    publishNavigationChange(menuType: string) {
        console.log("NavigationService::publishNavigationChange", menuType, this.filteredIconMenu);
        switch (menuType) {
            case "separator-menu":
                this.menuItems.next([]);
                break;
            case "icon-menu":
                this.menuItems.next(this.filteredIconMenu);
                break;
            default:
                this.menuItems.next([]);
        }
    }
}

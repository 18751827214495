import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AppService} from "../../shared/services/app.service";
import {ApiService} from "../../shared/services/api.service";
import {SiteService} from "../../shared/services/site.service";
import {LayoutService} from "../../shared/services/core/layout.service";
import {DomSanitizer} from "@angular/platform-browser";
import {DatePipe} from "@angular/common";
import moment from "moment";
import {Action, ActionAssignee} from 'app/shared/models/Action.model';
import {User} from 'app/shared/models/models';

@Component({
    selector: '[action-row]',
    template: `
        <ng-container>
            <td class="cell-checkbox">
                <mat-checkbox [(ngModel)]="checked" (change)="actionCheck()"></mat-checkbox>
            </td>
            <td class="cell-action-num">
                <span class="cursor-pointer" (click)="openAction(action)">{{ action.num }}</span>
            </td>
            <td class="cell-title cursor-pointer" (click)="openAction(action)">
                <div class="action-title-cell">
                    <div class="title color-bl ue">{{ action.title }}

                        <strong *ngIf="action.attachments&&action.attachments.length">
                            <mat-icon inline> attach_file</mat-icon>
                            {{ action.attachments.length }}
                        </strong>
                    </div>
                    <div *ngIf="action['description']">
                        <mat-icon [matTooltip]="'Grand consommateur'" class="color-blue" inline *ngIf="action.gc">corporate_fare</mat-icon>
                        <span *ngIf="action.comments_count>0">
                            <mat-icon class="color-blue" inline> reply</mat-icon>
                        </span>


                        <span class="excerpt" [innerHTML]="action['description'] |excerpt:80"></span>
                    </div>
                </div>
            </td>
            <td class="cell-priority">
                <mat-icon mat-sm-chip [style]="{color:myapp.k.PriorityColor[action.priority]}">
                    flag_circle
                </mat-icon>
                {{ myapp.k.PriorityDisplayName[action.priority] }}
            </td>
            <td class="cell-priority cell-status">
                <mat-icon class="text-green"> {{ myapp.k.StatusIcon[action.status] }}</mat-icon>
                {{ myapp.k.StatusDisplayName[action.status] }}
            </td>
            <td class="cell-economy"
                (click)="openAction(action)"
                [matTooltip]="action._warning_missing_tarif?'Économie NON valirisé - VEUILLEZ CORRIGER':''"
                [class.error]="action._warning_missing_tarif">
                <ng-container *ngIf="action.economies_total&&action.economies_total>0">
                    <span>{{ action.economies_total|number:"1.0-0" }} </span>
                    <span class="unit"> Fr.</span>
                </ng-container>
                <span *ngIf="!action.economies_total">0 Fr.</span>
            </td>
            <td *ngFor="let fl of availableFluids" class="cell-fluid fluid-{{fl}}">
                <span class="val" *ngIf="econsByFluid[fl]">{{ econsByFluid[fl] }} </span>
                <span class="unit" *ngIf="unitsByFluid[fl]"> {{ unitsByFluid[fl] }}</span>
            </td>
            <td></td>
            <td class="cell-date">{{ action.dateDue }}</td>


            <ng-container *ngFor="let extraCol of extracolsMap|keyvalue">
                <td *ngIf="extraCol.value===1" class="cell-date">
                    <ng-container *ngIf="extraCol.key!=='assignees'">
                        <span *ngIf="getVal(extraCol.key).type==='bool'">
                            <mat-icon *ngIf="getVal(extraCol.key).value" inline>check</mat-icon>
                        </span>
                        <span *ngIf="getVal(extraCol.key).type==='number'">{{ getVal(extraCol.key).value|number:'1.0-0' }}{{ getVal(extraCol.key).unit }}</span>
                        <span *ngIf="getVal(extraCol.key).type==='string'">{{ getVal(extraCol.key).value }}{{ getVal(extraCol.key).unit }}</span>
                    </ng-container>
                    <ng-container *ngIf="extraCol.key==='assignees'">
                        <div fxLayout="row">
                            <ng-container *ngFor="let assignee of action.assignees">
                                <ngx-avatar *ngIf="getAssigneesUser(assignee) as u"
                                            class="cursor-pointer assignee-avatar"
                                            [title]="'NAME:'+u.first_name+'-'+u.last_name"
                                            [name]="u.first_name+' '+u.last_name"
                                            [size]="40">
                                </ngx-avatar>

                                <ngx-avatar *ngIf="!getAssigneesUser(assignee)"
                                            class="cursor-pointer assignee-avatar"
                                            [title]="assignee.assignee"
                                            [matTooltip]="'Assigné ayant quitté'"
                                            [name]="'- -'"
                                            (click)="getNotFoundAssigneeInfos(assignee)"
                                            [size]="40">
                                </ngx-avatar>
                            </ng-container>


                        </div>

                    </ng-container>
                </td>
            </ng-container>
        </ng-container>
    `,
    styleUrls: ['./actions-subs.component.scss']
})
export class ActionRowComponent implements OnInit, OnChanges {
    @Input('action') action: Action;
    @Input('availableFluids') availableFluids: string[];
    @Input('forceUpdate') forceUpdate: number;
    @Input('extracolsMap') extracolsMap: Map<string, number> = new Map<string, number>();
    @Output('cb') cb: EventEmitter<any> = new EventEmitter<any>();

    private _datePipe: DatePipe;
    public checked = false;
    unitsMap = {
        payback: 'ans',
        ts_due: '',
        ts_proposed: '',
        ts_completed: '',
        total: '',
        investment: "Fr.",
        investment_real: "Fr.",
    };
    assigneesFound: Map<string, ActionAssignee> = new Map<string, ActionAssignee>();
    assigneesNotFound: Map<string, ActionAssignee> = new Map<string, ActionAssignee>();
    econsByFluid = {}
    unitsByFluid = {}

    constructor(public myapp: AppService,
                public api: ApiService,
                public site: SiteService,
                private cdRef: ChangeDetectorRef,
                public layout: LayoutService, public sanitizer: DomSanitizer) {
        this._datePipe = new DatePipe("en");
    }

    ngOnInit(): void {
        this.econsByFluid = this.action.getEconomiesByFluid(this.site).economies
        this.unitsByFluid = this.action.getEconomiesByFluid(this.site).unitsPerFluid
    }

    actionCheck() {
        this.cb.emit({act: 'updateCheck', checked: this.checked});
    }


    getVal(key: string): { type, value, unit } {
        const v = this.action[key];
        if (key === 'is_ref') {
            const vv = this.action.is_ref ? true : false;
            return {value: vv, type: 'bool', unit: ''};
        }
        if (!v) return {value: "N.C.", type: 'string', unit: this.unitsMap[key]};
        if (key.includes('ts_')) {
            if (v) {
                return {value: moment.unix(v).format("MMM YYYY"), type: 'string', unit: this.unitsMap[key]};
            }
        }
        if (!isNaN(v))
            return {value: v, type: 'number', unit: this.unitsMap[key]};
        else return {value: v.toFixed(2), type: 'string', unit: this.unitsMap[key]};
    }

    openAction(action) {
        this.cb.emit({act: 'open'});
    }

    getAssigneesUser(assignee: ActionAssignee): User {
        const userOfAssignee = this.site.responsiblesMapToOldUid.get(assignee.assignee);
        if (userOfAssignee) {
            assignee._user = userOfAssignee;
            this.assigneesFound.set(assignee.assignee, assignee);
        } else
            this.assigneesNotFound.set(assignee.assignee, assignee);
        return userOfAssignee;
    }

    getNotFoundAssigneeInfos(assignee: ActionAssignee) {
        console.log("getNotFoundAssigneeInfos", this.assigneesNotFound, this.assigneesFound);
        return this.myapp.db.collection<any>('users', ref =>
            ref.where('uid_old', '==', assignee.assignee)
        ).valueChanges()
            .subscribe(user => {
                if (user.length > 0) {
                    const userObj = new User(user[0]);
                    this.myapp.confirm.confirm(
                        {
                            okButton: "Désassigner",
                            title: "Utilisateur ayant quitté",
                            message: "<b>Nom:</b> " + userObj.first_name + " " + userObj.last_name
                                + "<br><b>Email: </b>" + userObj.email
                        })
                        .subscribe(resp => {
                            console.log("Confirm", resp);
                        });
                }
                console.log("getNotFoundAssigneeInfos", 'users/' + assignee.assignee, user);
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        //
    }
}

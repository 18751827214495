import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";

import {SiteService} from "../../shared/services/site.service";
import {
    Plot
} from "../../shared/models/models";
import {BaseComponent} from "../../shared/BaseComponent";
import {AppService} from "../../shared/services/app.service";
import {NodeHtmlMarkdown} from "node-html-markdown";
import {H} from "../../shared/helpers/H";
import {ReportSuivi} from "../../shared/models/Suivi.model";

@Component({
    selector: 'mark-down',
    template: `
        <div class="component-holder mb-32">
            <h3 class="color-blue" (dblclick)="log()" fxLayout="row" fxLayoutAlign="space-between center">
                <span>{{blocHeading}}</span>
                <span fxFlex></span>

                <button (click)="editMode=true" *ngIf="!editMode" mat-icon-button>
                    <mat-icon>edit</mat-icon>
                </button>
                <button (click)="editMode=false" *ngIf="editMode" mat-icon-button>
                    <mat-icon>cancel</mat-icon>
                </button>
                <ng-container *ngIf="initialMD5!==actualMD5">
                    <button (click)="save()" mat-icon-button>
                        <mat-icon>save</mat-icon>
                    </button>

                </ng-container>

            </h3>
            <!--
            <div class="from-markdown" [innerHTML]="convertedHtml"></div>
            {{legacyMD5}}<br>
            {{initialMD5}}<br>
            {{actualMD5}}<br>
            -->
            <div class="bloc-markdown">
                <span *ngIf="mdToShow===''&&!editMode" class="text-muted">Pas de commentaire</span>
                <markdown *ngIf="!editMode" [data]="mdToShow"></markdown>
                <textarea *ngIf="editMode" [(ngModel)]="mdToShow" (keyup)="editMarkDown()"></textarea>
            </div>
        </div>
    `,

    styleUrls: ['subs.scss']
})
export class MarkDownComponent extends BaseComponent implements AfterViewInit, OnInit, OnChanges {
    @Input('report') report: ReportSuivi;
    @Input('content') content: any;
    @Input('field') field: string;
    @Input('tariffUID') tariffUID: string;

    editMode = false;

    blocsTypes = [];
    convertedHtml = "";
    convertedMD = "";
    mapFieldToTitle = {
        notes: "Textes explicatifs",
        note_factors: "Remarques",
        note_goals: "Atteinte des objectifs",
        note_previsions: "Prévisions pour l'année à venir",
        note_updates: "Modifications prévues ou effectives du site",
    };
    mapFieldToMDField = {
        notes: "notes_md",
        note_factors: "notes_factors_md",
        note_goals: "notes_goals_md",
        note_previsions: "notes_previsions_md",
        note_updates: "notes_updates_md",
        notes_tariffs: "notes_tariffs",
    };
    fieldToUpdate = '';

    legacyMD5 = "";
    initialMD5 = "";
    actualMD5 = "";

    get blocHeading() {
        if (this.field === 'notes_tariffs') {
            const tariffObj = this.site.clientSite.tariff_config_map.get(this.tariffUID);
            if (tariffObj) return "Notes sur: " + tariffObj.label;
            else return "Tarif introuvable";
        } else {
            return this.mapFieldToTitle[this.field];
        }
    }

    get mdToShow() {
        if (this.field === 'notes_tariffs') {
            if (!this.report.notes_tariffs) this.report.notes_tariffs = {};
            if (!this.report.notes_tariffs[this.tariffUID]) this.report.notes_tariffs[this.tariffUID] = '';
            return this.report.notes_tariffs[this.tariffUID];
        } else {
            return this.report[this.fieldToUpdate];
        }
    }

    set mdToShow(v) {
        if (this.field === 'notes_tariffs') {
            if (!this.report.notes_tariffs) this.report.notes_tariffs = {};
            this.report.notes_tariffs[this.tariffUID] = v;
        } else {
            this.report[this.fieldToUpdate] = v;
        }
    }

    constructor(public myapp: AppService, public site: SiteService,) {
        super();
    }

    ngOnInit() {
    }

    bootstrap() {
        this.fieldToUpdate = this.mapFieldToMDField[this.field];
        this.convertedHtml = "";
        if (this.field !== 'notes_tariffs') {
            if (this.content && this.content['document'])
                this.proccessNodes(this.content['document'].nodes, 0, this.field);
            this.blocsTypes = [...new Set(this.blocsTypes)];

            const nhm = new NodeHtmlMarkdown({maxConsecutiveNewlines: 2});
            this.convertedMD = nhm.translate(this.convertedHtml);

            // convert old html structure from optimise 1 and save converted
            if (!this.report[this.fieldToUpdate]) {
                this.report[this.fieldToUpdate] = this.convertedMD;
                //  this.save();
            }
        } else {
            this.convertedMD = this.mdToShow;
        }

        this.legacyMD5 = H.getMd5(this.convertedMD);
        this.initialMD5 = H.getMd5(this.mdToShow);
        this.actualMD5 = H.getMd5(this.mdToShow);
        // console.log("Mark-down", this.convertedHtml, "%%%%%%%%%%%%%", this.convertedMD);


    }

    save() {
        const dataToDave = {};
        dataToDave[this.fieldToUpdate] = this.report[this.fieldToUpdate];
        this.site.api.saveSuivi(this.report, dataToDave)
            .subscribe(resp => {
                if (resp.status == 1) {
                    this.myapp.showMessage("Note sauvegardée");
                    this.editMode = false;
                }
                console.log("Report ", this.report.year, this.report.isRef, this.fieldToUpdate, resp);
            });
    }

    editMarkDown() {
        this.actualMD5 = H.getMd5(this.report[this.fieldToUpdate]);
        console.log("editMarkDown", this.actualMD5);
    }

    log() {
        console.log("this.content['document'].nodes",
            this.content['document'].nodes,
            JSON.stringify(this.convertedHtml, null, 1));
    }

    proccessNodes(entryNodes: any[], level, field = '', url = '') {
        entryNodes.forEach(node => {
            const {nodes, data, object, type, isVoid, leaves} = node;
            this.blocsTypes.push(object + "-" + type);

            if (object === 'text') {
                this.proccessLeaves(leaves);
                return;
            }
            this.convertedHtml += this.getTagFromNode(node, false);
            this.proccessNodes(nodes, level + 1);
            this.convertedHtml += this.getTagFromNode(node, true);
        });
    }

    proccessLeaves(leaves: any) {
        leaves.forEach(leaf => {
            const {text, marks, object} = leaf;

            let cleanedText = text.trim()
                .replaceAll(/(\r\n|\n|\r)/gm, "")
                .replaceAll(/[\n\r]+/g, "");
            // console.log("--", text, cleanedText, JSON.stringify(text));
            let withMarks = " ";
            if (marks.length) {
                marks.forEach(m => {
                    withMarks += m.type + "|";
                });
            }
            if (cleanedText === '') cleanedText += '<br>';
            this.convertedHtml += cleanedText;
        });
    }

    getTagFromNode(node, isClosing = false) {
        if (!node.type) return;
        let tag = '';
        if (node.type === "h1") tag = 'h4';
        if (node.type === "h2") tag = 'h5';
        if (node.type === "h3") tag = 'h6';
        if (node.type === "paragraph") tag = 'p';
        if (node.type === "list_item") tag = 'li';
        if (node.type === "ul_list") tag = 'ul';
        if (isClosing) return `</${tag}>`;
        else return `<${tag}>`;
    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log("StatmensTableComponent::ngOnChanges---------- ", changes, this.site.seriesData);
        this.bootstrap();
    }

    ngAfterViewInit(): void {

    }

}

/*
0: "block-h1"
1: "block-h2"
2: "block-h3"
3: "block-list_item"
4: "block-paragraph"
5: "block-ul_list"
6: "text-undefined"


 */

import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import * as Highcharts from 'highcharts';
import PatternFill from "highcharts/modules/pattern-fill";
import Exporting from "highcharts/modules/exporting";
import Annotations from "highcharts/modules/annotations";
import Debugger from "highcharts/modules/debugger";

if (typeof Highcharts === 'object') {
    PatternFill(Highcharts);
    Exporting(Highcharts);
    Annotations(Highcharts);
    Debugger(Highcharts);
}
import {H} from "../../shared/helpers/H";
import {SiteService} from "../../shared/services/site.service";
import {Plot, PlotUnit} from "../../shared/models/models";
import {BaseComponent} from "../../shared/BaseComponent";
import {AppService} from "../../shared/services/app.service";
import {AxisPlotLinesOptions, Chart} from "highcharts";
import {K} from "../../shared/models/K";
import {OptimiseChart} from "../../shared/helpers/OptimiseChart";
import {BehaviorSubject, from, Observable, Subscription} from "rxjs";
import {Optimise} from "../../shared/helpers/Optimise";
import {PdmYearConfig, PdmYearModel, PdmYearSignature} from "../../shared/models/PdmYearConfig";
import {concatMap} from "rxjs/operators";
import {Pdm} from "../../shared/models/Pdm";

@Component({
    selector: 'statmens-cassure',
    template: `
        <mat-card class="bloc-statmens-plot m-4 p-4" fxLayout="column">
            <div class="bloc-statmens-plot-title" (dblclick)="isDebugMode=true">
                <mat-select placeholder="Unité Y" class="select-unit"
                            *ngIf="!plot.isCost()"
                            [value]="plot.plotUnit.unit_plot"
                            (selectionChange)="setUnitFactor($event.value)" style="float: left">
                    <mat-option value="K">Kilo</mat-option>
                    <mat-option value="M">Mega</mat-option>
                    <mat-option value="G">Giga</mat-option>
                </mat-select>
                <span>Recherche de cassure</span>
                <div class="last-releve-date">
                    Dernier relevé: <span>{{ lastReleve }}</span>
                </div>
            </div>
            <div class="bloc-statmens-plot-subtitle  " [innerHTML]="subTitle"></div>

            <table *ngIf="pointsForTable&&showTable">
                <tr *ngFor="let p of pointsForTable">
                    <td>{{ p.date }}</td>
                    <td>{{ p.x|number }}</td>
                    <td>{{ p.y|number }}</td>
                </tr>
            </table>

            <div class="full-width" fxLayout="row">
                <highcharts-chart
                        [Highcharts]="Highcharts"
                        [runOutsideAngular]="true"
                        [options]="chartOptions"
                        [(update)]="updateFlag"
                        (chartInstance)="onChartInstance($event)"
                        style="width: 100%; height: 520px; display: block;margin: 5px">
                </highcharts-chart>
            </div>

            <div class="p-8 m-4 " *ngIf="tempSignature">
                <h4> Configuration cassure année: {{ site.year }}</h4>
                <div class="config-panel p-8">
                    <div fxLayout="row">
                        <h6 class="title text-muted">
                            <span>Cassure: <strong class="color-blue mr-4">{{ tempSignature.cassure|number }}</strong> </span>
                            <button mat-raised-button color="primary" class="btn-xs-25 mr-4" (click)="updateSignature(true)">Sauvegarder</button>
                        </h6>
                        <div fxFlex></div>
                        <mat-radio-group fxLayout="row" fxFlex="50" [(ngModel)]="tempSignature.flat" (change)="updateSignature()">
                            <div fxFlex="">Plat</div>
                            <mat-radio-button class="mr-16" [value]="'LEFT'"> Horiz. Gauche</mat-radio-button>
                            <mat-radio-button class="mr-16" [value]="'RIGHT'"> Horiz. Droite</mat-radio-button>
                            <mat-radio-button class="mr-16" [value]="'NONE'"> Normal</mat-radio-button>

                        </mat-radio-group>
                    </div>

                    <div fxLayout="column">
                        <div fxLayout="row" class="border-top">
                            <div fxFlex="50" class="p-4">
                                <mat-checkbox (change)="updateSignature()" class="mr-16" [(ngModel)]="tempSignature.sd">Saut discret</mat-checkbox>
                                <mat-checkbox (change)="updateSignature()" [(ngModel)]="tempSignature.isManual">Saisie manuelle</mat-checkbox>

                                <div class="p-4" *ngIf="tempSignature.isManual">
                                    <mat-form-field appearance="legacy" fxFlex="300px">
                                        <input matInput placeholder="Cassure manuelle" type="number" step="0.1"
                                               [(ngModel)]="tempSignature.cassure"
                                               (keyup.enter)="updateSignature()">
                                    </mat-form-field>
                                </div>
                            </div>


                            <div fxFlex></div>
                            <mat-radio-group fxLayout="row" fxFlex="50" [(ngModel)]="tempSignature.type" (change)="updateSignature()">
                                <div fxFlex="">Type</div>
                                <mat-radio-button class="mr-16" [value]="'CHAUD'"> Chaud</mat-radio-button>
                                <mat-radio-button class="mr-16" [value]="'FROID'"> Froid</mat-radio-button>
                                <mat-radio-button class="mr-16" [value]="'MIXTE'"> Mixte</mat-radio-button>

                            </mat-radio-group>
                        </div>
                    </div>

                </div>
            </div>
        </mat-card>
    `,

    styleUrls: ['../../shared/components/shared-styles/shared.scss']
})
export class StatmensCassureComponent extends BaseComponent implements AfterViewInit, OnInit, OnChanges {
    @Input('plot') plot: Plot;
    @Input('pdm') dataPdm: Pdm;

    showTable = false;
    isDebugMode = false;
    subTitle = "";

    chart: Chart;
    errors: any[] = [];
    Highcharts: typeof Highcharts = Highcharts;
    public chartOptions: Highcharts.Options = {...K.chartOptions};
    updateFlag = false;
    signature: any; // for SIGNATURE
    cassure = 0;    // for SIGNATURE

    pointsForSignatureCalc = [];
    optimiseChart: OptimiseChart;
    renderingCount = 0;
    lastReleve: string;
    public dataChangeListener: BehaviorSubject<number>;
    public tempSignature: PdmYearSignature = new PdmYearSignature({});
    public tempModel: PdmYearModel = new PdmYearModel({});
    public tempModelVars: any = {};
    public rawDataFromManager: any = null;

    subsStatMensConfig: Subscription;
    pointsForTable = [];

    constructor(public myapp: AppService, public site: SiteService,) {
        super();
        this.dataChangeListener = new BehaviorSubject<number>(0);
    }

    bootstrap(debugCalledBy = "") {
        console.info('bootstrap: ' + debugCalledBy, this.plot, Highcharts.charts);
        this.errors = [];

        // prevalidate
        if (!this.site.refYear) {
            console.error('StatmensPlotComponent::bootstrap: yearsAndConfigsSetAndCHECKED ERROR: ', this.site.refYear);
            return;
        }
        this.initStatMensConfig();

        this.tempSignature = new PdmYearSignature({});
        this.optimiseChart = new OptimiseChart(
            this.plot,
            this.site.selectedPdm,
            this.site.selectedPdmConfigForRefYear,
            this.site.dataSummaryMap);

        this.optimiseChart.applyForCassure(this);
        // this.chartClear();
        if (this.chart)
            this.renderChart();

        if (this.site.year && this.site.dataSummaryMap.has(this.site.refYear.toString()))
            this.lastReleve = this.site.dataSummaryMap.get(this.site.refYear.toString()).last_date;

    }

    renderChart() {
        if (!this.dataPdm) {
            console.log('StatmensCassureComponent:renderChart', 'dataPdm EMPTY');
            return;
        }
        if (!this.chart) {
            console.log('StatmensCassureComponent:renderChart', 'chart null');
            return;
        }
        // prevalidate
        if (!this.site.refYear) {
            console.error('StatmensPlotComponent::bootstrap: yearsAndConfigsSetAndCHECKED ERROR: ', this.site.refYear);
            return;
        }

        if (this.site.selectedPdmConfigForRefYear && this.site.selectedPdmConfigForRefYear.signature_year) {
            //console.error("StatmensCassureComponent:renderChart:", this.site.selectedPdmConfigForYear);
            this.tempSignature = new PdmYearSignature(this.site.selectedPdmConfigForRefYear.signature_year);
        }

        this.tempSignature.calcObs().subscribe(done => {
            //console.log("StatmensCassureComponent:WILL DRAW:", done);
            this.drawSignature();
            this.drawMainPointsAndCassure();
        });
        this.drawMainPointsAndCassure();
    }

    initStatMensConfig() {
        if (this.site.selectedPdmConfigForRefYear) {
            this.plot.plotUnit = this.site.selectedPdmConfigForRefYear.getUnitForPlot(this.plot.getKey());
        }
        if (this.site.selectedPdmConfigForRefYear && this.site.selectedPdmConfigForRefYear.signature_year)
            this.tempSignature = new PdmYearSignature(this.site.selectedPdmConfigForRefYear.signature_year);
        else
            this.tempSignature = new PdmYearSignature({});
    }

    setUnitFactor(newValue) {
        const plotUnit = new PlotUnit(this.plot.getKey(), this.site.selectedPdm.fluid);
        plotUnit.setUnit(newValue);
        this.site.saveSelectedPdmConfig('units', {key: this.plot.getKey(), plotUnit}, true);
    }

    getXAxisLabel() {
        let retVal = "";

        let weatherStation = this.site.clientSite.weather;
        if (weatherStation.includes('.')) {
            const chunks = weatherStation.split(".");
            weatherStation = chunks[0];
        }

        retVal = "Température (" + weatherStation + ")";

        return retVal;
    }

    getSignaturePoints() {
        this.pointsForSignatureCalc = this.site.selectedPdm
            .getMensualData(this.site.year)
            .map((dataForMonth, i) => {
                const ptX = (dataForMonth.vars_meteo ? dataForMonth.vars_meteo['m1'] : -1) || 0;
                const ptY = 30 * dataForMonth.ve / dataForMonth.days * this.plot.getUnitFactor();
                return {x: ptX, y: ptY, category: ptX, custom: (i + 1), date: dataForMonth.date};
                // return {x: ptX, y: item.val };
            });
        this.pointsForSignatureCalc = Optimise.sortArrayByKeyVal(this.pointsForSignatureCalc, 'x');
        this.pointsForTable = Optimise.sortArrayByKeyVal(this.pointsForSignatureCalc, 'x');
        // console.log(" getSignaturePoints() :", this.pointsForSignatureCalc);
        this.tempSignature.points = this.pointsForSignatureCalc;
    }

    drawMainPointsAndCassure() {
        this.getSignaturePoints();
        this.chartUpdateSerie(0, this.pointsForSignatureCalc, 'Update Signature-cassure');
        if (!this.chart) return;
        this.tempSignature.cassure = Number(this.tempSignature.cassure);
        //console.log("drawMainPointsAndCassure:WILL DRAW:", this.tempSignature);
        const line = {
            id: 'cassure',
            label: 'Cassure:' + this.tempSignature.cassure.toFixed(2),
            dashStyle: 'longdashdot',
            color: '#FF0000',
            width: 1,
            value: this.tempSignature.cassure
        } as any;

        if (this.chart.xAxis && this.chart.xAxis.length > 0) {
            this.chart.xAxis[0].userOptions.tickInterval = undefined;
            this.chart.xAxis[0].userOptions.tickAmount = undefined;
            this.chart.xAxis[0].userOptions.tickPixelInterval = 80;
            this.chart.xAxis[0].removePlotLine('cassure');
            this.chart.xAxis[0].addPlotLine(line as AxisPlotLinesOptions);
        }
    }

    drawSignature() {
        this.getSignaturePoints();
        const serieLines = this.tempSignature.getPlotData();
        if (this.chart && this.chart.series && this.chart.series[1]) {
            this.chart.series[1].setData(serieLines, true);
        }
        console.log("drawSignature ", this.tempSignature);

    }

    updateSignature(save = false) {
        if (this.tempSignature.isManual) {
            this.tempSignature.cassure = Number(this.tempSignature.cassure);
        }

        this.tempSignature.calcObs().subscribe(done => {
            console.log("====updateSignature start:", this.tempSignature.cassure, this.tempSignature);
            this.drawMainPointsAndCassure();
            this.drawSignature();

            if (save) this.site.saveSelectedPdmConfig('signature', this.tempSignature, true);
        });
    }


    /*









     */

    chartUpdateSerie(index, data, debugInfos = "") {
        if (!this.chart) {
            return;
        }
        if (this.chart.series && this.chart.series[index]) {
            this.chart.xAxis[0].setCategories(undefined);
            this.chart.series[index].setData(data, false, false);
        } else {
            console.log("SERIE PAS TROUVöE", index, data);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log("ngOnChanges---------- ", changes);
        this.bootstrap('ngOnChanges');
    }

    raiseError(message, year: number, severity, type = 'data') {
        this.errors.push({message, severity, ts: H.unixTs(), year, type});
    }

    ngOnInit() {
        if (this.subsStatMensConfig) this.subsStatMensConfig.unsubscribe();
        this.subsStatMensConfig = this.site.siteSpecificEventTriggerer.subscribe(event => {
            //  if (event === SiteService.STATMENS_CONFIG_RELOADED) {
            if (event === SiteService.PDMS_DATA_POPULATED && this.site.refYear) {
                if (this.dataPdm && this.chart) {
                    this.renderChart();
                }
            }
        });
    }

    onChartInstance(chart) {
        this.chart = chart;
        this.renderChart();
    }

    // called from OptimiseChart
    public chartUpdate() {
        this.optimiseChart.setTitle();
        if (this.chart) this.chart.update(this.chartOptions, true, true);
    }

    ngAfterViewInit(): void {

    }
}

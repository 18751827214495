<div class="flex-container" fxLayout="row" *ngIf="myapp.user">
    <div fxFlex fxLayout="column" class="p-8">
        <ng-container *ngIf="myapp.isAdminOrSuperUser">
            <mat-form-field appearance="outline" fxFlex="85px">
                <mat-label>Recherche de batiment</mat-label>
                <input matInput placeholder="Nom site" [value]="initVal" (keyup)="refresh($event)">
                <mat-icon matSuffix>search</mat-icon>
                <mat-hint>Tapez n'importe quelle suite de lettres du nom</mat-hint>
            </mat-form-field>
            <mat-card class="m-0 p-8">
                <div fxFlex="" fxLayout="column">
                    <div class="site-list-scroller">
                        <div class="site-item" *ngFor="let site of clientsSites" (click)="selectDomainEvt(site)">
                            <span>{{ site.name }}</span>
                            <span class="client" *ngIf="site.client_name">[{{ site.client_name }}]</span>
                            <span class="text-muted">Réf: #{{ site.ref }}</span>
                        </div>
                    </div>

                </div>
            </mat-card>
        </ng-container>
    </div>

    <div fxFlex="20"
         [@animate]="{value:'*',params:{delay:'800ms',y:'50px'}}">
        <h3>Favoris</h3>
        <div class="p-4 mr-16" *ngIf="myapp.isAdminOrSuperUser">
            <ng-container *ngFor="let fav of myapp.user.favs">
                <ng-container *ngIf="myapp.getSiteWithId(fav) as siteObj  ">
                    <div fxLayout="row" class="  list-item-site" (click)="selectDomain(siteObj)">
                        <div class="w-40 h-40 mr-16  ">
                            <ngx-avatar [name]="siteObj.name" [size]="40"></ngx-avatar>
                        </div>
                        <div class="d-flex flex-column justify-content-center flex-grow-1 font-weight-bold">
                            {{ siteObj.name }}
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>

    </div>
    <div fxFlex="20" [@animate]="{value:'*',params:{delay:'500ms',y:'50px'}}">
        <h3>Responsabilités</h3>
        <div class="p-4 mr-16">
            <ng-container *ngFor="let role of myapp.user.site_access|keyvalue">
                <div fxLayout="row" class="list-item-site" (click)="selectDomain(siteObj)"
                     *ngIf="myapp.getSiteWithId(role.key) as siteObj">
                    <div class="w-40 h-40 mr-16  ">
                        <ngx-avatar [name]="siteObj.ref" [size]="40"></ngx-avatar>
                    </div>
                    <div class="d-flex flex-column justify-content-center flex-grow-1">
                        <b>{{ siteObj.name }}</b>
                        <small class="text-muted font-weight-bold color-blue">{{ role.value.responsabilityLabel }}</small>
                    </div>
                </div>
            </ng-container>
        </div>

        <div *ngIf="!myapp.user.site_access||myapp.user.site_access.size===0" class="error-bloc">
            Aucune résponsabilité définie ou erreur!

            <button (click)="myapp.sendErrorReport()">Envoyer rapport d'erreur</button>
        </div>

    </div>
</div>
<ng-container *ngIf="!myapp.user">
    <div class="error-bloc">
        Utilisateur non connecté

        <button (click)="myapp.sendErrorReport()">Envoyer rapport d'erreur</button>
    </div>
</ng-container>
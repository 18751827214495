import {Component, OnInit, ViewChild, TemplateRef, AfterViewInit, ChangeDetectorRef, ViewChildren, QueryList, Renderer2} from '@angular/core';
import {AppService} from "../../shared/services/app.service";
import {ApiService} from "../../shared/services/api.service";
import {LayoutService} from "../../shared/services/core/layout.service";
import {DomSanitizer} from "@angular/platform-browser";
import {DatePipe} from "@angular/common";
import {SiteService} from '../../shared/services/site.service';
import {egretAnimations} from "../../shared/animations/egret-animations";
import moment from "moment";
import {fromEvent, Observable, Subscription} from "rxjs";
import {H} from "../../shared/helpers/H";
import {Action, Chapter} from "../../shared/models/Action.model";

@Component({
    selector: 'app-actions',
    templateUrl: './actions.component.html',
    styleUrls: ['./actions.component.scss'],
    animations: egretAnimations
})
export class ActionsComponent implements AfterViewInit, OnInit {
    @ViewChildren('headcell') headcell: QueryList<any>;
    viewMode = "std";
    filteredActions: Action[] = [];
    selectedAction: Action = null;
    columnsWithSearch: string[] = [];

    private _datePipe: DatePipe;

    chaptersMap: Map<string, string> = new Map<string, string>();
    selectedChapter: Chapter;
    selectedActions: Map<string, Action> = new Map<string, Action>();

    proccessedPaybackNet = 0;
    proccessedPaybackBrut = 0;
    proccessedTotalEcon = 0;
    proccessedTotalInvest = 0;
    proccessedTotalInvestReal = 0;
    proccessedCount = 0;
    proccessedEconByTariff = {};
    proccessedEconByTariffUnit = {};
    proccessedAvailableFluids = [];

    yearsAll: number[] = [];
    yearsCompleted: number[] = [];
    yearsDue: number[] = [];
    yearsProposed: number[] = [];

    filtersMap: Map<string, any> = new Map<string, any>();
    filtersMultipleValue = ['status', 'priority', 'ts_proposed',
        'ts_due', 'ts_completed', 'key'];//'chapterId',
    sortBy: string = '';
    sortDir = 1;
    clickedElement: Subscription = new Subscription();
    countDraft = 0;
    countDelete = 0;
    countCommented = 0;
    countNoChapter = 0;

    extracolsMap: Map<string, number> = new Map<string, number>();
    extracolsLabel = {
        "is_ref": "Réf",
        "assignees": "Assignés",
        "chapterId": "Chapitres",
        "draft": "Bruouillon",
        "deleted": "Supprimés",
        "commented": "Actions commentées",
        "gc": "Grand consommateur",
        "key": "Mot clé",
        "onlyMyActions": "Actions assignées à moi",
        "priority": "Priorité",
        "status": "Statut",
        "investment": "Invest",
        "investment_real": "Invest brut",
        "payback": "Payback",
        "ts_last_updated": "Modifié",
        "ts_proposed": "Proposé",
        "ts_completed": "Terminé",
        "ts_due": "Délai",
        "ts_last_commented": "Dern. comment.",
    };

    constructor(public myapp: AppService,
                public api: ApiService,
                public site: SiteService,
                private cdRef: ChangeDetectorRef,
                private renderer: Renderer2,
                public layout: LayoutService, public sanitizer: DomSanitizer) {
        this._datePipe = new DatePipe("fr");
        moment().locale('fr');
        moment.updateLocale('fr', {
            relativeTime: {
                future: "dans %s",
                past: "il y a %s",
                s: "%d sec",
                m: "1 min",
                mm: "%d min",
                h: "1h",
                hh: "%dh",
                d: "1j",
                dd: "%dj",
                M: "1 mois",
                MM: "%d mois",
                y: "1 an",
                yy: "%d ans"
            }
        });
        this.extracolsMap = new Map<string, number>();
        this.extracolsMap.set("assignees", 1);
        this.extracolsMap.set("is_ref", 1);
        this.extracolsMap.set("investment", 0);
        this.extracolsMap.set("investment_real", 0);
        this.extracolsMap.set("payback", 0);
        this.extracolsMap.set("ts_last_updated", 0);
        this.extracolsMap.set("ts_proposed", 0);//proposed
        this.extracolsMap.set("ts_completed", 0);
        this.extracolsMap.set("ts_last_commented", 0);
        //  this.extracolsMap.set("ts_due", 0);
        this.site.siteSpecificEventTriggerer.subscribe(eventName => {
            console.log("site.siteSpecificEventTriggerer:", eventName);
            if (eventName === SiteService.ACTION_SAVED) {
                this.run();
            }
        })
    }

    get checkIsChapterDeletable(): boolean {
        let retVal = false;
        const isOnlyChapterFilterIsOn = this.filtersMap.size === 1 && this.filtersMap.has('chapterId');
        const isNoActions = this.filteredActions.length === 0;
        return isOnlyChapterFilterIsOn && isNoActions;
    }

    test() {
        this.cdRef.detectChanges();
    }

    ngOnInit(): void {
        if (this.myapp.user)
            this.myapp.storeCurrentRoute();
        else
            this.myapp.appInitStatus.subscribe(status => {
                if (status === 1)
                    this.myapp.storeCurrentRoute();
            });

        this.site.siteLoadingStatus.subscribe(status => {
            if (status === SiteService.MAX_SITE_LOADING_STATUS) {
                if (this.site.actions && this.site.actions.length)
                    this.run();
            }
        })
    }

    run(): void {
        this.cdRef.markForCheck();
        this.extractYears(this.site.actions);
        this.filterDatatable(null);

        if (Array.isArray(this.site.actions) && this.site.actions.length > 0) {
            this.columnsWithSearch = Object.keys(this.site.actions[0]);
            this.cdRef.detectChanges();
        }
    }

    initSortingEvents() {
        this.headcell.forEach(cell => {
            const cellEl = cell.nativeElement as HTMLTableCellElement;
            this.renderer.addClass(cellEl, 'cursor-pointer');
            const iconsCount = cellEl.querySelectorAll('.mat-icon').length;
            if (iconsCount) {
                const items = cellEl.querySelectorAll('.mat-icon');
                for (let i = 0; i < iconsCount; i++)
                    items.item(i).remove();
            }
            const dataField = cellEl.getAttribute('field');

            this.clickedElement = fromEvent(cellEl, 'click').subscribe(() => this.sort(dataField, cellEl));

        });
    }

    refreshHeader() {
        setTimeout(() => {
            this.initSortingEvents();
        }, 500);
    }

    sort(key: string, cell: HTMLTableCellElement) {
        this.headcell.forEach(headyCell => {
            const cellEl = headyCell.nativeElement as HTMLTableCellElement;
            const iconsCount = cellEl.querySelectorAll('.mat-icon').length;
            if (iconsCount) {
                const items = cellEl.querySelectorAll('.mat-icon');
                for (let i = 0; i < iconsCount; i++)
                    items.item(i).remove();
            }
        });

        if (this.sortBy === key) {
            this.sortDir++;
            if (this.sortDir === 2) this.sortDir = -1;
        } else {
            this.sortDir = 1;
            this.sortBy = key;
        }
        // cell.append('<mat-icon>user</mat-icon>');
        // cell.insertAdjacentHTML('beforeend', '<mat-icon class="mat-icon">arrow_drop_up</mat-icon>');
        if (this.sortDir !== 0) {
            const iconName = this.sortDir > 0 ? 'arrow_drop_up' : 'arrow_drop_down';
            const iconEl = this.renderer.createElement('mat-icon');
            const text = this.renderer.createText(iconName);
            this.renderer.addClass(iconEl, 'mat-icon');
            this.renderer.addClass(iconEl, 'material-icons');
            this.renderer.appendChild(iconEl, text);
            this.renderer.appendChild(cell, iconEl);
        }
        const numericalKey = ['status', 'priority', 'num', 'ts_due', 'ts_created', 'ts_completed', 'ts_last_commented', 'economies_total'];
        // console.log("sort", this.sortBy, numericalKey);
        this.filteredActions = this.filteredActions.sort((a, b) => {
            if (key.startsWith('fluids')) {
                const fl = key.split('_')[1];
                const aa = a._econsByFluid[fl] || 0;
                const bb = b._econsByFluid[fl] || 0;
                // console.log('sort', key, fl, a._econsByFluid);
                if (this.sortDir === 1) {
                    return aa - bb;
                }
                if (this.sortDir === -1) {
                    return bb - aa;
                }
            }
            if (numericalKey.includes(key)) {
                let aa = a[key] || 0;
                let bb = b[key] || 0;
                if (key === 'status') {
                    aa = this.myapp.k.StatusValue[aa];
                    bb = this.myapp.k.StatusValue[bb];
                }
                if (key === 'priority') {
                    aa = this.myapp.k.PriorityValue[aa];
                    bb = this.myapp.k.PriorityValue[bb];
                }
                if (this.sortDir === 1) {
                    return aa - bb;
                }
                if (this.sortDir === -1) {
                    return bb - aa;
                }
            } else {
                const aa = a[key] || '';
                const bb = b[key] || '';
                if (this.sortDir === 1) {
                    return aa.toString().localeCompare(bb.toString());
                }
                if (this.sortDir === -1) {
                    return bb.toString().localeCompare(aa.toString());
                }
            }

            return 0;
        });
    }

    selectAction(action) {
        console.log("action:", action);
        this.selectedAction = action;
        this.site.openAction(action);
        return true;
    }

    simulateDefaultTariff(year: number) {
        this.site.clientSite.year_tariff_default = year;
        this.preProcActions();
    }

    datePipe() {
        return {transform: (value) => value ? this._datePipe.transform(value * 1000, 'dd.MM.yyyy') : "NC"};
    }

    selectChapter(ch: Chapter) {
        this.selectedChapter = ch;
        this.chaptersMap.set(ch.uid, ch.title);//map of selected chapters
        this.filterBy('chapterId', ch.uid);
        this.filterDatatable(null);
    }

    extraColToggle($event, key: string) {
        $event.stopPropagation();
        if (this.extracolsMap.get(key) === 0)
            this.extracolsMap.set(key, 1);
        else
            this.extracolsMap.set(key, 0);
        setTimeout(() => {
            this.initSortingEvents();
        }, 1000);
    }

    filterGetIsActive(filter, value) {
        let retVal = false;
        if (this.filtersMap && this.filtersMap.has(filter)) {
            const storedValues = Array.from(this.filtersMap.get(filter));
            if (storedValues.includes(value)) retVal = true;
        }
        return retVal;
    }

    filterBy(type: string, val: any) {
        if (!this.filtersMap) this.filtersMap = new Map<string, any>();

        console.log("filterBy():SETTING FILTER", type, val, this.filtersMap);

        // manage differently filters that may have multiple AND values
        if (this.filtersMultipleValue.includes(type)) {
            if (!this.filtersMap.has(type))
                this.filtersMap.set(type, [val]);
            else {
                let storedValues = Array.from(this.filtersMap.get(type));
                if (storedValues.includes(val)) {
                    storedValues = H.arrayDelete(storedValues, val);
                } else {
                    storedValues.push(val);
                }
                if (storedValues.length)
                    this.filtersMap.set(type, storedValues);
                else this.filtersMap.delete(type);
            }
        } else {
            if (this.filtersMap.has(type)) {
                if (this.filtersMap.get(type) === val) {
                    this.filtersMap.delete(type);
                } else this.filtersMap.set(type, val);
            } else {
                this.filtersMap.set(type, val);
            }
        }

        this.filterDatatable(null);
    }

    filterDatatable(event) {
        if (event) {
            const key = event.target.value.toLowerCase().trim();
            if (key.length === 0)
                this.filtersMap.delete('key');
            else
                this.filtersMap.set('key', key);
        }
        this.filteredActions = this.site.actions;
        //this.filteredActions = this.site.actions  .filter(it => !it.removed)  .filter(it => !it.draft);

        this.filteredActions = this.filteredActions
            .filter(item => {
                if (this.filtersMap && this.filtersMap.has('key')) {
                    const searchKey = this.filtersMap.get('key');
                    for (let i = 0; i < this.columnsWithSearch.length; i++) {
                        let colValue = item[this.columnsWithSearch[i]];
                        if (!colValue) continue;
                        if (!!colValue && colValue.toString()
                            .toLowerCase()
                            .indexOf(searchKey) !== -1) {
                            return true;
                        }
                    }
                } else return true;
            })
            .filter(item => {
                if (this.filtersMap && this.filtersMap.has('onlyMyActions')) {
                    return item.getIsAssignedToUserId(this.myapp.user.uid_bdd);
                } else return true;
            })
            .filter(item => {
                if (this.filtersMap && this.filtersMap.has('chapterId')) {
                    return this.filtersMap.get('chapterId').includes(item.uid_chapter);
                } else return true;
            })
            .filter(item => {
                if (this.filtersMap && this.filtersMap.has('status')) {
                    return this.filtersMap.get('status').includes(item.status);
                } else return true;
            })
            .filter(item => {
                if (this.filtersMap && this.filtersMap.has('priority')) {
                    return this.filtersMap.get('priority').includes(item.priority);
                } else return true;
            })
            .filter(item => {
                if (this.filtersMap && this.filtersMap.has('ts_due')) {
                    const objMom = moment(item['ts_due'] * 1000);
                    const y = objMom.year();
                    return this.filtersMap.get('ts_due').includes(y);
                } else return true;
            })
            .filter(item => {
                if (this.filtersMap && this.filtersMap.has('ts_completed')) {
                    const objMom = moment(item['ts_completed'] * 1000);
                    const y = objMom.year();
                    return this.filtersMap.get('ts_completed').includes(y);
                } else return true;
            })
            .filter(item => {
                if (this.filtersMap && this.filtersMap.has('ts_proposed')) {
                    return this.filtersMap.get('ts_proposed').includes(item.yearProposed);
                } else return true;
            })
            .filter(item => {
                if (this.filtersMap && this.filtersMap.has('onlyPending')) {
                    return item.status !== 'REFUSED' && item.status !== 'COMPLETED';
                } else return true;
            })
            .filter(item => {
                if (this.filtersMap && this.filtersMap.has('gc')) {
                    return item.gc === 1;
                } else return true;
            })
            .filter(item => {
                if (this.filtersMap && this.filtersMap.has('deleted')) {
                    return item.is_deleted;
                } else return !item.is_deleted;
            })
            .filter(item => {
                if (this.filtersMap && this.filtersMap.has('draft')) {
                    return item.ts_published === 0;
                } else return true;
            })
            .filter(item => {
                if (this.filtersMap && this.filtersMap.has('noChapter')) {
                    return !item.uid_chapter && item.is_deleted === 0
                    // return item.uid_chapter === '' || item.uid_chapter === null;
                } else return true;
            })
            .filter(item => {
                if (this.filtersMap && this.filtersMap.has('commented')) {
                    this.extracolsMap.set("ts_last_commented", 1);
                    this.refreshHeader();
                    return !!item.ts_last_commented;
                } else return true;
            })

        this.selectedActions = new Map<string, Action>();//<-- reset selected actions map when activate any filter
        this.preProcActions();
    }

    resetFilter() {
        this.filtersMap = new Map<string, any>();
        this.filterDatatable(null);
    }

    cbFromRow(action: Action, arg: any) {
        const {act, checked} = arg;
        if (act === 'open') this.selectAction(action);
        if (act === 'updateCheck') {
            if (checked) this.selectedActions.set(action.uid, action);
            else this.selectedActions.delete(action.uid);
            this.preProcActions();
        }
        console.log("cbFromRow", arg, action, this.selectedActions, this.proccessedEconByTariff);
    }

    // gwenerate some stats on actions
    preProcActions() {
        // console.log("filterBy()", this.filtersMap, this.chaptersMap);
        if (this.selectedActions.size > 0)
            this.procActions(Array.from(this.selectedActions.values()));
        else this.procActions(this.filteredActions);
    }

    procActions(actions: Action[]) {
        this.proccessedCount = actions.length;
        this.proccessedPaybackNet = 0;
        this.proccessedPaybackBrut = 0;
        this.proccessedTotalEcon = 0;
        this.proccessedTotalInvest = 0;
        this.proccessedTotalInvestReal = 0;
        this.proccessedEconByTariff = {};

        actions.forEach(act => {
            this.proccessedTotalEcon += act.economies_total;
            this.proccessedTotalInvest += act.investment;
            this.proccessedTotalInvestReal += act.investment_real;
            act.economies.forEach(econ => {
                const tariffObj = this.site.getTariffObj(econ.uid_tariff);
                if (tariffObj) {
                    this.proccessedAvailableFluids.push(tariffObj.fluid);
                    if (this.proccessedEconByTariff[tariffObj.label] === undefined)
                        this.proccessedEconByTariff[tariffObj.label] = 0;
                    this.proccessedEconByTariff[tariffObj.label] += act.isCompleted ? econ.amount(tariffObj) : econ.amount(tariffObj);
                    this.proccessedEconByTariffUnit[tariffObj.label] = tariffObj.unitStr;
                } else {
                    console.error("Economie à tarif orphelin APE N°:" + act.num, econ.label, econ, act);
                }

            });
            this.proccessedAvailableFluids = H.arrayUnique(this.proccessedAvailableFluids).sort();
        });
        this.proccessedPaybackNet = this.proccessedTotalInvestReal / this.proccessedTotalEcon;
        this.proccessedPaybackBrut = this.proccessedTotalInvest / this.proccessedTotalEcon;
        // console.log("procActions()", this.proccessedEconByMetric);
        if (this.headcell)
            this.initSortingEvents()
    }

    // detect available years from all actions
    extractYears(actions) {
        this.yearsDue = [];
        this.yearsProposed = [];
        this.yearsCompleted = [];
        this.yearsAll = [];
        actions.forEach(act => {
            const objMomCompleted = moment(act.ts_completed * 1000);
            const objMomProposed = moment(act.ts_created * 1000);
            const objMomDue = moment(act.ts_due * 1000);

            if (objMomCompleted.year() > 1970 && !this.yearsCompleted.includes(objMomCompleted.year())) {
                this.yearsCompleted.push(objMomCompleted.year());
                this.yearsAll.push(objMomCompleted.year());
            }
            if (objMomDue.year() > 1970 && !this.yearsDue.includes(objMomDue.year())) {
                this.yearsDue.push(objMomDue.year());
                this.yearsAll.push(objMomDue.year());
            }
            if (objMomProposed.year() > 1970 && !this.yearsProposed.includes(objMomProposed.year())) {
                this.yearsProposed.push(objMomProposed.year());
                this.yearsAll.push(objMomProposed.year());
            }

        });
        this.yearsAll = H.arrayUnique(this.yearsAll);
        this.yearsAll = this.yearsAll.sort();
        this.yearsDue = this.yearsDue.sort();
        this.yearsProposed = this.yearsProposed.sort();
        this.yearsCompleted = this.yearsCompleted.sort();


        this.countDelete = this.site.actions.filter(it => it.is_deleted).length;
        this.countDraft = this.site.actions.filter(it => it.ts_published === 0).length;
        this.countCommented = this.site.actions.filter(it => it.ts_last_commented).length;
        this.countNoChapter = this.site.actions.filter(it => !it.uid_chapter && it.is_deleted === 0).length;

    }

    deleteSelectedChapter(chapterToDel: Chapter) {
        this.myapp.confirm.confirm({title: "Action irreversible", message: "Veuillez confirmer"}).subscribe(isOk => {
            if (isOk)
                this.myapp.api.deleteChapter(chapterToDel).subscribe(resp => {
                    if (resp.status === 1) {
                        this.myapp.showMessage("Chapitre: [" + chapterToDel.title + "] est supprimé avec succès. ");
                        new Observable((observer) => {
                            this.site.loadActions(observer);
                        }).subscribe(resp => {
                            this.myapp.showMessage("Actions et chapitres rechargés");
                        });
                    }
                });
        });

    }

    exportExcel() {
        const actionsIds = this.filteredActions.map(it => it.uid);
        console.log("exportExcel", this.filtersMap, this.filtersMultipleValue);
        this.site.api.printActionsToXls(actionsIds, this.site.uid).subscribe(resp => {
            console.log("exportExcel::resp", resp, actionsIds, this.filteredActions);
            if (resp.status == 1) {
                const xlsUrl = 'https://api.optimigration.ch/web/' + resp.filename;
                window.open(xlsUrl, '_blank');
            }
        });
    }

    printPreview() {
        if (this.viewMode === 'std') this.viewMode = 'print';
        else {
            this.viewMode = 'std';
            setTimeout(() => {
                this.initSortingEvents();
            }, 500);
        }
    }

    ngAfterViewInit() {
        this.layout.publishLayoutChange({sidebarStyle: 'closed'});
        this.initSortingEvents();
    }

    drop($ev) {
        console.log("DROP", $ev);
    }
}

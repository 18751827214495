import {Injectable} from '@angular/core';
import {getAuth, sendEmailVerification} from "firebase/auth";
//import * as firebase from 'firebase/app';
import {BehaviorSubject, EMPTY, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {User} from '../models/models';
import {Router} from "@angular/router";
import firebase from "firebase/compat/app";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Subscription} from "rxjs/internal/Subscription";
import * as Sentry from "@sentry/browser";
import {H} from "../helpers/H";

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public state: string;
    public fbUser: any;
    public user: User;
    public userId: string;
    public userEmail: string;
    public userDisplayName: string;
    public error: string;
    public authSubject: BehaviorSubject<string>;
    public userSubs: Subscription;
    public tracesLog: any[] = [];

    constructor(public afAuth: AngularFireAuth, private afs: AngularFirestore, public router: Router) {

        this.authSubject = new BehaviorSubject<string>('');
        firebase.auth().languageCode = 'fr';
        afs.firestore.settings({
            experimentalAutoDetectLongPolling: true, merge: true
        });
        // console.info('AuthService', "Constructor");
        this.addTrace('Auth service constructor');
        this.afAuth.authState.subscribe(u => {
            //console.log('this.afAuth.authState', u);
            this.fbUser = u;
            if (u !== null) {
                this.addTrace('this.afAuth.authState subs: u not null: ' + u.uid);
                this.userId = u.uid;
                this.loadUserFromFirestore(u);
            } else {
                this.addTrace('this.afAuth.authState subs: u IS NULL: ');
                this.user = null;
                this.userId = null;
                this.userEmail = null;
                this.userDisplayName = null;
                if (this.userSubs) {
                    this.userSubs.unsubscribe();
                    this.userSubs = null;
                }
                this.authSubject.next("notConnected");
            }
        });
    }

    loadUserFromFirestore(u) {
        //console.log('this.afAuth.loadUser()', 'LOADING USER', u, u.uid);
        this.userSubs = this.afs.collection('users')
            .doc<any>(u.uid)
            // .doc<any>("OUDm6B2FsvafhDSOT0yncPHpiQh1")
            .valueChanges()
            .subscribe(user => {
                if (user === null || user === undefined) {
                    this.addTrace('loadUserFromFirestore: user null or undefined ');
                } else {
                    this.addTrace('loadUserFromFirestore: user returned successfully ');
                }
                console.log("-------------------------loadUser:RESP", user, this.user);
                if (!this.user) {
                    this.user = user;
                    this.authSubject.next("connected");
                    this.addTrace('loadUserFromFirestore: authSubject-(CONNECTED) ');
                } else {
                    this.user = user;
                    this.authSubject.next("updated");
                    this.addTrace('loadUserFromFirestore: authSubject-next(UPDATED) ');
                }
            }, error => {
                console.log('this.afAuth.authState:error', error);
                this.addTrace('loadUserFromFirestore: ERROR: ' + error);
                this.reportError("Error with Firestore: " + error);
            });
    }

    doLogin(value) {
        return new Promise<any>((resolve, reject) => {
            firebase.auth().languageCode = 'fr';
            firebase.auth().signInWithEmailAndPassword(value.email, value.password)
                .catch(err => {
                    this.userEmail = value.email;
                    this.error = err;
                    this.addTrace('sign-in: ERROR: ' + err);
                    this.reportError("doLogin() " + this.userEmail + ' - ' + err);
                    // console.log("Error login", err);
                    this.authSubject.next("error");
                    reject(err);
                })
                .then(res => {
                    resolve(res);
                }, err => reject(err));
        });
    }

    doLogout() {
        return new Promise((resolve, reject) => {
            this.afAuth.signOut().then(r => {
                if (this.userSubs)
                    this.userSubs.unsubscribe();
                localStorage.removeItem('currentUser');
                localStorage.removeItem('EGRET_USER');
                localStorage.removeItem('email');
                resolve(r);
            }, err => reject(err));
        });
    }

    addTrace(wat: string) {
        this.tracesLog.push({ts: H.unixTs(), wat});
    }

    reportError(trace: string) {
        if (this.userId)
            trace = this.userId + ": " + trace;
        console.log(this.tracesLog);
        Sentry.captureException(new Error("ErrorLogin" + trace), {
            tags: {
                userId: this.userId,
                email: this.userEmail
                // fbUser: this.fbUser,
                // trace: this.tracesLog,
            },
        });
    }

}

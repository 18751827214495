<div class="app-admin-wrap" [dir]='layoutConf?.dir'>
    <!-- Main Container -->
    <mat-sidenav-container
            [dir]='layoutConf.dir'
            class="app-admin-container app-side-nav-container mat-drawer-transition sidebar-{{layoutConf?.sidebarColor}} topbar-{{layoutConf?.topbarColor}} footer-{{layoutConf?.footerColor}}"
            [ngClass]="adminContainerClasses">
        <mat-sidenav-content>
            <!-- SIDEBAR -->
            <!-- ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->
            <app-sidebar-side
                    *ngIf="layoutConf.navigationPos === 'side'"
                    (mouseenter)="sidebarMouseenter($event)"
                    (mouseleave)="sidebarMouseleave($event)"
            ></app-sidebar-side>

            <!-- App content -->
            <div class="main-content-wrap" id="main-content-wrap" [perfectScrollbar]="" [disabled]="layoutConf.topbarFixed || !layoutConf.perfectScrollbar">
                <!-- Header for side navigation layout -->
                <!-- ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->
                <app-header-side
                        *ngIf="layoutConf.navigationPos === 'side'">
                </app-header-side>

                <div class="rightside-content-hold" id="rightside-content-hold"
                     [perfectScrollbar]="scrollConfig"
                     [disabled]="!layoutConf.topbarFixed || !layoutConf.perfectScrollbar">
                    <!-- View Loader -->
                    <div class="view-loader" *ngIf="isModuleLoading" style="position:fixed;"
                         fxLayout="column" fxLayoutAlign="center center">
                        <div class="spinner">
                            <div class="double-bounce1 mat-bg-accent"></div>
                            <div class="double-bounce2 mat-bg-primary"></div>
                        </div>
                    </div>
                    <!-- Breadcrumb -->
                    <app-breadcrumb></app-breadcrumb>
                    <!-- View outlet -->
                    <div class="container-dynamic">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>

        </mat-sidenav-content>


    </mat-sidenav-container>
</div>
<div class="handle" *ngIf="!myapp.isDisplayedComponentAdmin">
    <button mat-fab color="primary"
            (click)="createAction()"
            *ngIf="site.clientSite&&myapp.getIfUserCanDoForRoute('ACTIONS','CREATE')">
        <mat-icon class="text-white">add_task</mat-icon>
    </button>
</div>

import {Injectable} from '@angular/core';
import {BehaviorSubject, EMPTY, Observable} from 'rxjs';
import {AppService} from "../../shared/services/app.service";
import {ApiService} from "../../shared/services/api.service";
import {Overlay} from "@angular/cdk/overlay";
import {SiteService} from "../../shared/services/site.service";
import moment from "moment/moment";
import {H} from "../../shared/helpers/H";
import {PlotlyComponent} from "angular-plotly.js";
import {Comment, DomainReportPage, ReportAnnotation, ReportAnnotationPoint} from "../../shared/models/models";
import {ProtoReportPage} from "../../shared/models/ProtoHebdo";

@Injectable({
    providedIn: 'root',
})
export class AnnotationService {
    public annotLoadingStatus: BehaviorSubject<number>;
    public triggerActionsObs: BehaviorSubject<any>;//open sideNav togle mode etc
    public sideNavMode = '';//<--- copy of protohebdo state
    public currentPage: ProtoReportPage;
    public report_comments: Comment[] = [];
    public report_comments_years: number[] = [];
    public commentsMap: Map<string, Comment> = new Map<string, Comment>();
    public commentCountPerYearMap: Map<string, string[]> = new Map<string, string[]>();

    public pagesKeyToPosition: Map<string, number> = new Map<string, number>();
    public pagesMapToPos: Map<string, ProtoReportPage> = new Map<string, ProtoReportPage>();
    public pagesMapToKey: Map<string, ProtoReportPage> = new Map<string, ProtoReportPage>();

    public listPagesPerYear = 0;
    public listPagesPerLevel = 0;

    public replyText = '';
    public commentType = 'pending';
    public annotationPriority = 'NORMAL';

    public selectedVisualKeyForAnnotation: string;
    public selectedPlotIndexForAnnotation = -1;
    public selectedPointForAnnotation: any;
    public selectedAnnotationUID: string;
    public selectedYear: number;
    public selectedComment: Comment;
    public newComment: Comment;

    public annotationSavingInProgress = false;
    public commentSavingInProgress = false;

    public visualKeyToPlotDataMap: Map<string, any> = new Map<string, any>();
    public visualKeyToPlotIndexMap: Map<string, number> = new Map<string, number>();
    public visualKeysToAnnotationsMap: Map<string, Map<string, ReportAnnotation>> = //data holder for plotly
        new Map<string, Map<string, ReportAnnotation>>();// each visual key mapped to the map of uidAnnot=>PlotlyData
    public indexToVisualKeyMap: Map<number, string> = new Map<number, string>();

    public annotationsMapForNewComment: Map<string, ReportAnnotation> = new Map<string, ReportAnnotation>(); // data holder for annot editor
    public annotationsMapForSelectedComment: Map<string, ReportAnnotation> = new Map<string, ReportAnnotation>(); // data holder for annot editor

    public rightClickedPlotIndex: number;
    public plotClicClosestPoints: any[] = [];
    public isCommentCreatingMode = false;
    public editorForceUpdate = 0;

    constructor(public myapp: AppService, public api: ApiService,
                public overlay: Overlay, public site: SiteService) {
        this.triggerActionsObs = new BehaviorSubject<any>(null);
    }

    // metre les bordure de fred comme pdf et rendre epaix bordure et fleche si highlight
    // haut normal info: par defaut = normal milioeu
    // couleur rouge de la fleche aussi modifier
    // normal BIEN
    // Basse consommation urgent
    // remplissage piscine = level info
    get commentToEdit() {
        if (this.isCommentCreatingMode) return this.newComment;
        else return this.selectedComment;
    }

    set commentText(arg) {
        this.commentToEdit.comment = arg;
    }

    get commentText() {
        let tempComm = '';
        if (!this.commentToEdit) return null;
        else if (this.commentToEdit.comment) return this.commentToEdit.comment;
        else if (this.isCommentCreatingMode) {
            this.annotationsMapForNewComment.forEach((v, k) => {
                tempComm += v.initial_text + ' - ';
            });
        } else {
            this.annotationsMapForSelectedComment.forEach((v, k) => {
                tempComm += v.initial_text + ' - ';
            });
        }
        return tempComm;
    }

    set commentTitle(arg) {
        this.commentToEdit.title = arg;
    }

    get commentTitle() {
        let tempComm = '', visual_key = '', plotData = null;
        if (!this.commentToEdit) return null;
        else if (this.commentToEdit.title) return this.commentToEdit.title;
        else {
            if (this.isCommentCreatingMode) {
                this.annotationsMapForNewComment.forEach((v, k) => {
                    tempComm += (v.point_cache ? v.point_cache.name + ' - ' : '');
                    visual_key = v.visual_key;
                });
                plotData = this.visualKeyToPlotDataMap.get(visual_key)
                console.log("Plotdata for new:", this.visualKeyToPlotDataMap.get(visual_key));
            } else {
                this.annotationsMapForSelectedComment.forEach((v, k) => {
                    tempComm += (v.point_cache ? v.point_cache.name + ' - ' : '');
                    visual_key = v.visual_key;
                });
                plotData = this.visualKeyToPlotDataMap.get(visual_key)
                console.log("Plotdata for selected:", this.visualKeyToPlotDataMap.get(visual_key));
                if (plotData)
                    console.log("Plotdata for selected:", plotData.layout.title);
            }
            if (plotData && plotData.layout && plotData.layout.title) tempComm = plotData.layout.title + tempComm;
            this.commentTitle = tempComm;
        }

        return tempComm;
    }

    get annotKeyToEdit() {
        const sourceMap = this.isCommentCreatingMode ? this.annotationsMapForNewComment : this.annotationsMapForSelectedComment;
        // console.log("annotKeyToEdit()", sourceMap);
        if (!sourceMap || !sourceMap.size) return [];
        return Array.from(sourceMap.keys()).sort();
    }

    resetAllMaps() {
        this.commentsMap = new Map<string, Comment>();
        this.report_comments = [];
        this.annotationsMapForSelectedComment = new Map<string, ReportAnnotation>();
        this.annotationsMapForNewComment = new Map<string, ReportAnnotation>();
        this.visualKeyToPlotDataMap = new Map<string, any>();
        this.visualKeyToPlotIndexMap = new Map<string, number>();
        this.visualKeysToAnnotationsMap = new Map<string, Map<string, ReportAnnotation>>();
    }

    resetAllSelections() {
        this.selectedComment = null;
        this.selectedAnnotationUID = null;
        this.selectedVisualKeyForAnnotation = null;
        this.selectedPointForAnnotation = null;
        this.selectedPlotIndexForAnnotation = -1;
        // this.visualKeyToPlotIndexMap = new Map<string, number>();
        this.indexToVisualKeyMap = new Map<number, string>();
        this.setCommentEditingMode(false);
        this.annotationsMapForNewComment = new Map<string, ReportAnnotation>();
        this.annotationsMapForSelectedComment = new Map<string, ReportAnnotation>();
        this.plotClicClosestPoints = [];
        this.rightClickedPlotIndex = -1;
    }

    navigateToNewPage(newPage: ProtoReportPage) {
        this.currentPage = newPage;
        this.resetAllSelections();
        //this.triggerActionsObs.next({sideNavMode: 'comment', sidenavToggle: true});
        this.triggerActionsObs.next({sideNavMode: 'comment'});
        this.annotationsMapPopulatefromComments();
    }

    assignVisualKeyToGraphData(plotData: any, visualKey: string) {
        this.visualKeyToPlotDataMap.set(visualKey, plotData);
    }

    assignVisualKeyToPlotIndex(visualKey: string, plotIndex: number) {
        this.visualKeyToPlotIndexMap.set(visualKey, plotIndex);
        this.indexToVisualKeyMap.set(plotIndex, visualKey);
    }

    annotationsMapPopulatefromComments() {
        // this.visualKeysToAnnotationsMap = new Map<string, Map<string, any>>();
        let comments = this.report_comments.filter(com =>
            this.currentPage && com.key_page === this.currentPage.page_key && com.status === "PROPOSAL"
        );
        if (this.listPagesPerYear > 0)
            comments = this.report_comments.filter(com =>
                // this.currentPage && com.key_page === this.currentPage.page_key &&
                com.year === this.listPagesPerYear && com.status === "PROPOSAL"
            );

        comments.forEach(com => {
            com.annotationsMap.forEach((ann, key) => {
                if (!ann.ts)
                    ann.ts = com.ts_created;
                if (!this.visualKeysToAnnotationsMap.has(ann.visual_key))
                    this.visualKeysToAnnotationsMap.set(ann.visual_key, new Map<string, any>());
                // console.log("seekAndPopulateAnnotationsMap()------", ann.visual_key, ann.uid,ann);
                this.visualKeysToAnnotationsMap.get(ann.visual_key).set(ann.uid, ann);

            });
        });
    }

    // send annotations in visualKeysToAnnotationsMap to plotly
    annotationShow(visualKey: string = null, from = '') {
        if (!visualKey) visualKey = this.selectedVisualKeyForAnnotation;
        if (!this.visualKeyToPlotIndexMap.has(visualKey) && visualKey !== this.selectedVisualKeyForAnnotation) {
            //console.error("visualKey Nexiste pas: ", visualKey);
            return;
        }
        const plotIndex = this.visualKeyToPlotIndexMap.get(visualKey);

        const annotsToShow = this.visualKeysToAnnotationsMap.get(visualKey);
        if (annotsToShow) {
            const annotsToShowArray = Array.from(annotsToShow.values());
            this.triggerActionsObs.next({showAnnotations: {plotIndex, annotations: annotsToShowArray}});
        }

        // console.log("annotationShow()------", visualKey, annotsToShow);
        // update annotations from From if edited or new
        /*
        if (this.selectedCommentAnnotationMap && this.selectedCommentAnnotationMap.size) {
            this.selectedCommentAnnotationMap.forEach((v, k) => {
                if (visualKey === v.visual_key) {
                    annotsToShow.set(k, v);
                }
            });
        }*/

    }

    // update store annotObj in visualKeysToAnnotationsMap for preview. Not saved at this step
    annotationUpdate(annotObj, $ev = null) {
        const {visual_key, uid} = annotObj;
        if (!this.visualKeysToAnnotationsMap.has(this.selectedVisualKeyForAnnotation))
            this.visualKeysToAnnotationsMap.set(this.selectedVisualKeyForAnnotation, new Map<string, any>());
        this.visualKeysToAnnotationsMap.get(this.selectedVisualKeyForAnnotation).set(uid, annotObj);

        this.annotationShow(visual_key, 'annotationUpdate');
    }

    setAllAnnotsPriority($ev) {
        console.log('setAllAnnotsPriority:', $ev.value, $ev);
        if (this.isCommentCreatingMode) {
            this.annotationsMapForNewComment.forEach((v, k) => {
                v.priority = $ev.value;
                this.annotationsMapForNewComment.set(k, v);
                this.annotationShow(v.visual_key, 'annotationUpdate');
            })
        } else {
            this.annotationsMapForSelectedComment.forEach((v, k) => {
                v.priority = $ev.value;
                this.annotationsMapForSelectedComment.set(k, v);
                this.annotationShow(v.visual_key, 'annotationUpdate');
            });
        }

    }

    plotAnnotationClic(event, plotIndex, visual_key, plot: PlotlyComponent) {
        // plotAnnotationClic() and selectPointToAnnotate() will set isCommentCreatingMode <<<<----- IMPOIRTANT
        const annotArrayOfSelectedVisual = this.visualKeysToAnnotationsMap.get(visual_key);
        const {index, annotation} = event;
        console.log('plotAnnotationClic:', index, annotation, this.newComment, this.selectedComment);
        this.rightClickedPlotIndex = -1;
        this.plotClicClosestPoints = [];
        if (annotation.uid_comment === '') {
            this.setCommentEditingMode(true);
        } else {
            this.setCommentEditingMode(false);
            this.selectCommentById(annotation.uid_comment);
        }
        this.selectedAnnotationUID = annotation.uid;
        this.annotationPriority = annotation.priority;
        this.selectedVisualKeyForAnnotation = visual_key;
        this.selectedPlotIndexForAnnotation = plotIndex;
        // console.log('annotationSelect', ev, plot, index, this.selectedAnnotation,   'annotArrayOfSelectedVisual', annotArrayOfSelectedVisual);
        this.annotationShow(visual_key);//<--- to update annotation color to selected theme
    }

    plotClic(event, plotIndex, plot) {
        if (event.event.button !== 0) return false;// dismiss all but leftclic
        //************

        const data = [];
        for (let i = 0; i < event.points.length; i++) {
            const serieData = this.getPayloadFromSerie(event.points[i]);
            if (serieData)
                data.push(serieData);
        }

        if (event.points.length) {
            this.rightClickedPlotIndex = plotIndex;
            this.plotClicClosestPoints = data;
            this.selectedVisualKeyForAnnotation = this.indexToVisualKeyMap.get(plotIndex);
            this.selectedPlotIndexForAnnotation = plotIndex;
            console.log("plotClic", event, data, plot);

            if (this.selectedComment && this.sideNavMode === "openAnnotMap") {
                this.setCommentEditingMode(false);
            } else {
                this.setCommentEditingMode(true);
                this.newComment = new Comment({
                    uid_site: this.site.uid, type: 'Proto-Hebdo',
                    uid_user: this.myapp.user.uid,
                    key_report: this.site.selectedSiteReport.report_key, key_page: this.currentPage.page_key,
                    display_name: this.myapp.user_display_name
                });
            }
            this.triggerActionsObs.next({sidenavToggle: true});//, sideNavMode: "openAnnotMap"
        }

        return false;
    }

    plotUpdate(event, plotIndex, visual_key, updateType, plot: PlotlyComponent) { //uupdate annotation when moving it
        // console.log("plotUpdate", event, plotIndex, visual_key, updateType, plot);
        let indexOfAnnotationBeingUpdated = -1;
        Object.keys(event).forEach(k => {
            const matches = k.match(/\[(.*?)\]/);
            if (matches) {
                indexOfAnnotationBeingUpdated = Number(matches[1]);
            }
        });
        if (indexOfAnnotationBeingUpdated < 0) {
            console.error("Annotation not found at index: ");
            return;
        }
        const updatedAnnotationObject = plot.layout.annotations[indexOfAnnotationBeingUpdated];
        if (!updatedAnnotationObject) {
            console.error("updatedAnnotationObject Not found at index: ", indexOfAnnotationBeingUpdated);
            return;
        }
        if (this.isCommentCreatingMode) {
            this.annotationsMapForNewComment.set(updatedAnnotationObject.uid, updatedAnnotationObject);
            this.annotationUpdate(updatedAnnotationObject);
        } else {
            this.annotationsMapForSelectedComment.set(updatedAnnotationObject.uid, updatedAnnotationObject);
            this.saveAnnotationToServer(updatedAnnotationObject);
        }
        this.editorForceUpdate++; // <- will force Annot editor refresh with annotations new coord
    }

    selectPointToAnnotate(pt) {
        this.selectedPointForAnnotation = pt;
        //if (this.sideNavMode === "comment") this.selectedComment = null;//<--- To togle creation mode if in comment list mode even if a comment is selected for highlighing
        if (!this.selectedComment) this.setCommentEditingMode(true);
        this.triggerActionsObs.next({sidenavToggle: true, sideNavMode: "openAnnotMap"});//
        console.log('selectPointToAnnotate', pt);
        const {x, y, name, xMin, xMax, yMin, yMax, xTitle, yTitle, pointIndex} = this.selectedPointForAnnotation;
        const newAnnotationUID = 'ann-' + H.getMd5(name + pointIndex + x + xTitle + y + yTitle);
        let numDivider = pointIndex / 2;
        if (numDivider === 0) numDivider = 2;
        const annotTemp = new ReportAnnotation({
            name,
            x: x,
            y: y,
            ax: x,
            ay: (yMin + yMax) / numDivider,
            axref: 'x',
            ayref: 'y',
            align: 'left',
            initial_text: "",
            text: "--",
            report_date: this.site.selectedSiteReport.date_str,
            is_global: false,
            priority: 'NORMAL',
            visual_key: this.selectedVisualKeyForAnnotation,
            uid_comment: this.isCommentCreatingMode ? '' : this.selectedComment.uid,
            ts: H.unixTs(),
            in_creation: true,
            point_cache: new ReportAnnotationPoint(this.selectedPointForAnnotation),
            uid: newAnnotationUID
        });
        const mapToUpdate = this.isCommentCreatingMode ? this.annotationsMapForNewComment : this.annotationsMapForSelectedComment;
        if (!mapToUpdate.has(newAnnotationUID)) {
            mapToUpdate.set(newAnnotationUID, annotTemp);
            this.annotationUpdate(annotTemp);
        } else {
            this.myapp.showError("Il y a déjà une annotation sur ce point", true);
        }
    }

    commentClickFromSideNav(comment: Comment) {
        // console.log("commentClickFromSideNav", comment, this.pagesMapToKey);
        if (!this.pagesMapToKey.has(comment.key_page)) {
            console.error("Page clé supprimée: ", comment.key_page);
            this.myapp.showError("Page supprimée su rapport. Clé: " + comment.key_page);
            return;
        }
        const commentPagePos = this.pagesMapToKey.get(comment.key_page).position;
        this.triggerActionsObs.next({gotoPagePos: commentPagePos, selectComment: comment});
    }

    selectCommentById(id: string) {
        const comment = this.commentsMap.get(id);
        this.selectComment(comment);
    }

    selectComment(comment: Comment) {
        console.log("selectComment", comment)
        this.selectedComment = comment;
        this.annotationsMapForSelectedComment = this.selectedComment.annotationsMap;
        this.refreshAnnotationsdisplay();
    }

    refreshAnnotationsdisplay() {
        this.indexToVisualKeyMap.forEach((v, k) => {
            this.annotationShow(v);
        })
    }

    setCommentPriority(uid: string, priority: boolean) {
        console.log("setCommentPriority", uid, priority);
        this.api.updateCommentData(uid, {priority}).subscribe(resp => {
            this.loadComments(null, true);
        });
    }

    setCommentStatus(uid: string, status: boolean) {
        this.api.updateCommentStatus(uid, status).subscribe(resp => {
            this.loadComments(null, true);
        });
    }

    sendReply(uid) {
        this.commentSavingInProgress = true;
        const commObj = {
            message: this.replyText,
            name: this.myapp.user_display_name,
            userId: this.myapp.user.uid,
            ts: H.unixTs(false)
        };
        this.api.commentReply(uid, JSON.stringify(commObj)).subscribe(resp => {
            this.commentSavingInProgress = false;
            this.replyText = '';
            this.sendNotif('newReply');
            this.loadComments(null, true);
            this.triggerActionsObs.next({commentReplyMode: false});
        });
    }

    saveAnnotationToServer(updatedAnnotationObject) {
        const {uid, uid_comment, visual_key} = updatedAnnotationObject;
        updatedAnnotationObject.ts = H.unixTs();
        // also update comment priority from toggle group
        /// TODO: update comment priority and also desc
        this.updateCommentToServer(uid_comment, {priority: this.annotationPriority});
        this.site.api.updateAnnotation(uid_comment, uid, updatedAnnotationObject).subscribe(resp => {
            this.annotationSavingInProgress = false;
            this.loadComments(null, true);
        });
    }

    updateCommentToServer(uidComment: string, data: any) {
        this.site.api.updateCommentData(uidComment, data).subscribe(resp => {
            console.log('updateCommentToServer', resp);
            this.loadComments(null, true);
        });
    }

    saveComment() {
        let annotMapHolder: Map<string, ReportAnnotation>;
        if (this.isCommentCreatingMode) {
            annotMapHolder = this.annotationsMapForNewComment;
            console.log("save new comment", this.newComment, this.annotationsMapForNewComment);
        } else {
            annotMapHolder = this.annotationsMapForSelectedComment;
            console.log("save selected omment", this.selectedComment, this.annotationsMapForSelectedComment);
        }
        if (!this.commentToEdit.annotations) this.commentToEdit.annotations = {};
        annotMapHolder.forEach((v, k) => {
            v.priority = this.commentToEdit.priority;
            v.uid_comment = this.commentToEdit.uid;
            this.commentToEdit.annotationsMap.set(k, v);
            this.commentToEdit.annotations[k] = v;
            this.annotationShow(v.visual_key);
        });
        this.annotationSavingInProgress = true;
        console.log('saveComment start', {...this.commentToEdit});
        this.api.updateCommentData(this.commentToEdit.uid, {...this.commentToEdit})
            .subscribe(res => {
                console.log('saveComment RESP', res);
                this.annotationSavingInProgress = false;
                this.triggerActionsObs.next({sideNavMode: 'comment'});
                this.sendNotif('newCom');
                this.loadComments(null, true);
            });

    }

    sendNotif(type: string) {
        let notifKey = '';
        let storedType = '';
        if (type = 'newCom') {
            storedType = 'PROTOHEBDO_NEWCOMM';
        }
        if (type = 'newReply') {
            storedType = 'PROTOHEBDO_NEWREPLY';
        }
        notifKey = storedType + '-' + this.commentToEdit.uid;
        this.site.responsibles.forEach(u => {
            if (!u.invited && u.uid !== this.myapp.user.uid) { //
                this.myapp.addNotif(u.uid,
                    notifKey,
                    {
                        route: 'PROTOHEBDO',
                        type: storedType,
                        site: this.site.uid,
                        siteName: this.site.clientSite.name,
                        year: this.site.selectedSiteReport.year,
                        week: this.site.selectedSiteReport.week,
                        position: this.currentPage.position,
                        targetUID: this.commentToEdit.uid,
                        label: this.commentToEdit.title,
                    }
                );
            }
        })

    }

    displayOnlyPendingCommentFor(type: string, value: any) {
        // this.sideNavMode = 'comment';
        if (type === "year") this.listPagesPerYear = value;
        if (type === "level") this.listPagesPerLevel = value;
        const pageKeys = this.commentCountPerYearMap.get(this.listPagesPerYear.toString());
        if (!pageKeys) return;
        const pages = pageKeys.map(p => this.pagesMapToKey.get(p)).sort((p1, p2) => p1.position - p2.position);
        //this.loadJson('', pages[0].position);
        console.log("displayOnlyPendingCommentFor: " + value, this.commentCountPerYearMap, 'pageKeys', pageKeys, pages);
        this.triggerActionsObs.next({sidenavChapterToggle: 'true'});
        // this.sidenav.toggle(true);
    }

    loadComments(e, reload = false) {
        // resetting
        this.commentCountPerYearMap = new Map<string, string[]>();
        this.pagesMapToKey.forEach((v, k) => {
            v.comments_pending = 0;
            v.comments_total = 0;
            this.pagesMapToKey.set(k, v);
            this.pagesKeyToPosition.set(v.page_key, v.position);
        })
        if (!this.site.selectedSiteReport) return;
        if (!this.report_comments
            || (this.report_comments && this.report_comments.length === 0)
            || reload) {
            this.api.loadComments(this.site.uid, this.site.selectedSiteReport.report_key)
                .subscribe(resp => {
                    this.report_comments = [];
                    if (resp && resp.body) {
                        resp.body.forEach(rawComm => {
                            const com = new Comment(rawComm);
                            if (this.selectedComment && this.selectedComment.uid === com.uid)
                                this.selectedComment = com;
                            if (this.pagesKeyToPosition.has(com.key_page)) {
                                com.page_position = this.pagesKeyToPosition.get(com.key_page);
                            }


                            if (this.pagesMapToKey.has(com.key_page)) {//page exists in this report
                                this.pagesMapToKey.get(com.key_page).comments_total++;
                                if (com.status === "PROPOSAL") {
                                    this.pagesMapToKey.get(com.key_page).comments_pending++;
                                    if (!this.commentCountPerYearMap.has(com.year.toString()))
                                        this.commentCountPerYearMap.set(com.year.toString(), []);
                                    this.commentCountPerYearMap.get(com.year.toString()).push(com.key_page);
                                }
                            }
                            this.report_comments.push(com);
                            this.report_comments_years.push(com.year);
                            this.commentsMap.set(com.uid, com);
                        });
                        this.report_comments_years = H.arrayUnique(this.report_comments_years);
                        this.annotationsMapPopulatefromComments();
                        console.log("loadComments()  years", this.report_comments_years);
                        console.log("loadComments()  RESP", resp, 'FORCE_ RELOAD?:', reload, this.report_comments, this.visualKeysToAnnotationsMap);
                    }
                });
        }
    }

    getCommentsForPage(): Comment[] {
        if (!this.currentPage) return [];
        if (!this.report_comments) return [];

        let comments = [];
        if (this.commentType === 'pending')
            comments = this.report_comments.filter(comment =>
                //  comment.key_page === this.currentPage.key &&
                comment.status === 'PROPOSAL' &&
                (this.selectedYear ? comment.year.toString() === this.selectedYear.toString() : true) &&
                (this.listPagesPerYear > 0 ? comment.year.toString() === this.listPagesPerYear.toString() : true)
            ).sort((a, b) => {
                return (a.page_position || 10000) - (b.page_position || 10000);
            });

        if (this.commentType === 'completed') {
            comments = this.report_comments.filter(comment => {
                return comment.status === 'COMPLETED' &&
                    (this.selectedYear ? comment.year.toString() === this.selectedYear.toString() : true)
            });
        }
        if (this.commentType === 'all') {
            comments = this.report_comments.filter(comment => {
                return (this.selectedYear ? comment.year.toString() === this.selectedYear.toString() : true)
            });
        }
        //.filter(comment => comment.key_page === this.currentPage.key);

        // console.log("getCommentsForPage(): " + this.currentPage.page_key, comments, this.report_comments);
        return comments;
    }

    getCommentsPending() {
        if (!this.report_comments) return [];

        let comments = [];
        comments = this.report_comments.filter(comment =>
            //  comment.key_page === this.currentPage.key &&
            comment.status === 'PROPOSAL' &&
            (this.listPagesPerYear > 0 ? comment.year.toString() === this.listPagesPerYear.toString() : true)
        ).sort((a, b) => {
            return (a.page_position || 10000) - (b.page_position || 10000);
        });
        // console.log("getCommentsForPage(): " + this.currentPage.key, comments, this.report_comments);
        return comments;
    }

    setCommentEditingMode(isNew: boolean) {
        this.isCommentCreatingMode = isNew;
        if (isNew) {
            this.selectedComment = null;
            this.annotationsMapForSelectedComment = new Map<string, ReportAnnotation>();
        } else {
            this.newComment = null;
            this.annotationsMapForNewComment = new Map<string, ReportAnnotation>();
            this.clearNewAnnotations();
        }
    }

    cancelEditng() {
        this.setCommentEditingMode(true);
        this.setCommentEditingMode(false);
        this.triggerActionsObs.next({sideNavMode: 'comment', sidenavToggle: true});
    }

    clearNewAnnotations() {
        this.annotationsMapForNewComment = new Map<string, ReportAnnotation>();
        this.visualKeysToAnnotationsMap.forEach((annMap, visualKey) => {
            // remove from displayed annpt on plot the new annotations that were cancelled before saving
            let removedAnnotationFromPlotCount = 0;
            annMap.forEach((ann, k) => {
                if (ann.uid_comment === '') {
                    if (!this.annotationsMapForNewComment.has(ann.uid)) annMap.delete(ann.uid);
                    removedAnnotationFromPlotCount++;
                }
            });
            if (removedAnnotationFromPlotCount) {
                this.visualKeysToAnnotationsMap.set(visualKey, annMap);
                this.annotationShow(visualKey, 'clearNewAnnotations');
            }
        });
    }

    /// CALLBAKS
    updateAnnotationsFromEditor($event) {
        const {uid, ann, act, visual_key, focusType} = $event;
        if (act === "highlightPlot") {
            this.highlightChart(visual_key, focusType);
        }
        if (act === "update") {
            this.annotationsMapForSelectedComment.set(uid, ann);
            this.annotationUpdate(ann);
        }
        if (act === "delete") {
            this.annotationsMapForSelectedComment.delete(uid);
            const annotsToShow = this.visualKeysToAnnotationsMap.get(ann.visual_key);
            if (annotsToShow && annotsToShow.has(ann.uid)) {
                annotsToShow.delete(ann.uid);
                this.visualKeysToAnnotationsMap.set(ann.visual_key, annotsToShow);
            }
            this.annotationShow(ann.visual_key);
        }
        // console.log("updateAnnotationsFromEditor", uid, ann.is_global, ann.priority);
    }

    highlightChart(visualKey, dir) {
        if (!this.visualKeyToPlotIndexMap.has(visualKey)) return;
        const plotIndex = this.visualKeyToPlotIndexMap.get(visualKey);
        this.triggerActionsObs.next({do: 'highLight', plotIndex, dir});
    }

    getPayloadFromSerie(serie) {
        console.log('getPayloadFromSerie()', serie);
        const {fullData} = serie;
        /// TODO: charts of type pie have x undefined, adapt code to also work with pie charts
        if (!fullData.x) {
            return null;
        }
        let xMin = Math.min(...serie.fullData.x);
        let xMax = Math.max(...serie.fullData.x);
        const yMin = Math.min(...serie.fullData.y);
        const yMax = Math.max(...serie.fullData.y);
        if (typeof serie.fullData.x[0] === 'string') {
            const xSerie = serie.fullData.x.map(d => new Date(d));
            //console.log(...xSerie, typeof xSerie[0]);
            xMin = Math.min(...xSerie);
            xMax = Math.max(...xSerie);
        }
        const pointIndex = serie.pointIndex;
        const dateStamp = serie.data.dateStamps ? serie.data.dateStamps[pointIndex] : null;
        const text = serie.data.hovertext ? serie.data.hovertext[pointIndex] : "";
        const name = serie.data.name;
        const xTitle = serie.xaxis.title.text;
        const yTitle = serie.yaxis.title.text;
        const color = serie.data.marker ? serie.data.marker.color : "blue";
        return {
            pointIndex,
            dateStamp,
            text,
            name,
            xTitle,
            yTitle,
            x: serie.x,
            y: serie.y,
            xMin,
            xMax,
            yMin,
            yMax,
            color
        };
    }
}

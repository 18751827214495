export const plotUsageLayout = {
    barmode: 'group',
    margin: {
        b: 60,
        l: 0,
        pad: 20,
        r: 0,
        t: 40
    },
    datarevision: 1,
    font: {
        family: 'Roboto, sans-serif',
        size: 10,
    },
    "yaxis": {
        "title": "Consommation [MWh]",
        "automargin": true,
        "hoverformat": ".1f",
        "showspikes": true,
        "tickprefix": "      ",
        "showtickprefix": "all",
        "rangemode": "tozero",
        "separatethousands": true,
        "exponentformat": "none",
        "type": "linear",
        "autorange": true
    },
    xaxis: {
        title: "Mois",
        ticklen: 5,
        showspikes: true,
        tickvals: [],
        ticktext: []
    },
    "hovermode": "closest",
    "separators": ".'",
    "legend": {
        "xanchor": "center",
        "yanchor": "bottom",
        "orientation": "h",
        "x": 0.5,
        "y": 1
    }
};
export const plotCostLayout = {
    datarevision: 1,
    margin: {
        b: 60,
        l: 0,
        pad: 20,
        r: 0,
        t: 40
    },
    barmode: 'group',
    font: {
        family: 'Roboto, sans-serif',
        size: 10,
    },
    "yaxis": {
        "title": "Coùts cumulés [Fr.]",
        "automargin": true,
        "hoverformat": ".1f",
        "showspikes": true,
        "tickprefix": "      ",
        "showtickprefix": "all",
        "rangemode": "tozero",
        "separatethousands": true,
        "exponentformat": "none",
        "type": "linear",
        "autorange": true
    },
    xaxis: {
        title: "Mois",
        ticklen: 5,
        showspikes: true,
        tickvals: [],
        ticktext: []
    },
    "hovermode": "closest",
    "separators": ".'",
    "legend": {
        "xanchor": "center",
        "yanchor": "bottom",
        "orientation": "h",
        "x": 0.5,
        "y": 1
    }
};
export const plotUsageData = [
    {
        name: "Consommation cumulée",
        marker: {
            "color": []
        },
        type: "bar",
        x: [],
        y: [],
    },
];

export const plotCostsData = [
    {
        name: "Coûts cumulés",
        marker: {
            color: [],
        },
        type: "bar",
        x: [],
        y: [],
    },
];

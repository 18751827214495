import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {PdmYearConfig} from "../../../shared/models/PdmYearConfig";
import {AppService} from "../../../shared/services/app.service";
import {SiteService} from "../../../shared/services/site.service";
import moment from "moment";
import {ReportSuivi} from 'app/shared/models/Suivi.model';

@Component({
    selector: 'suivi-page-de-garde',
    template: `
        <div class="bloc" #html>
            <table class="table-page-de-garde">
                <tr class="row-logo">
                    <td class="logo"><img src="logosrc" height="100px"/></td>
                    <td class="date"> Date :{{ date }}</td>
                </tr>
                <tr class="row">
                    <td colspan="2">
                        <h5>{{ site.clientSite.typeSuiviStr }}</h5>
                        <h6 *ngIf="report.year===reportRef.year">
                            Situation de référence {{ reportRef.year }}</h6>
                        <h6 *ngIf="report.year!==reportRef.year">
                            Année {{ report.year }}</h6>
                    </td>
                </tr>
                <tr class="row">
                    <td colspan="2" class="head">
                        <span *ngIf="site.clientSite.gc">L'entreprise « grand consommateur» d'énergie</span>
                        <span *ngIf="!site.clientSite.gc">L'entreprise</span>
                    </td>
                </tr>
                <tr class="row">
                    <td colspan="2" class="client-name">
                        <b class="color-blue">{{ site.clientSite.name }}</b><br>
                        {{ site.clientSite.address }}<br>
                        {{ site.clientSite.npaLoc }}
                    </td>
                </tr>
                <tr class="row">
                    <td colspan="2" class="head">
                        Edité par
                    </td>
                </tr>
                <tr class="row">
                    <td colspan="2" [innerHTML]="rep.infos.referent_externe|nl2br">
                    </td>
                </tr>
                <tr class="row" *ngIf="rep.infos.aLattentionDe">
                    <td colspan="2" class="head">
                        À l'attention de :
                    </td>
                </tr>
                <tr class="row" *ngIf="rep.infos.aLattentionDe">
                    <td colspan="2" [innerHTML]="rep.infos.aLattentionDe|nl2br">

                    </td>
                </tr>
            </table>
        </div>
    `,
    styleUrls: ['./suivi.component.scss']
})
export class SuiviPageDeGardeComponent implements OnInit, OnChanges {
    @ViewChild('html') html: ElementRef;
    @Input('report') report: ReportSuivi;
    @Input('reportRef') reportRef: ReportSuivi;

    date: string;

    get isRef() {
        return this.reportRef && this.report && this.report.type === 'HIDDEN_REF_DATA';
    }

    get rep() {
        if (this.isRef) return this.reportRef;
        else return this.report;
    }

    constructor(public myapp: AppService, public site: SiteService) {
    }

    ngOnInit() {
        //console.log("PdmYearlyConfigComponent", 1);
        this.site.siteSpecificEventTriggerer.subscribe(eventName => {
            if (eventName === SiteService.PDMS_CONFIG_RELOADED) {
                this.bootstrap();
            }
        });
    }

    bootstrap() {
        this.date = moment().format("LL");
    }

    ngOnChanges(changes: SimpleChanges) {
        //console.log('ngOnChanges():', changes);
        this.bootstrap();
    }

}

import {Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {AppService} from "../../../shared/services/app.service";
import {SiteService} from "../../../shared/services/site.service";
import {Optimise} from "../../../shared/helpers/Optimise";
import {K} from "../../../shared/models/K";
import moment from "moment";
import {PlotlyService} from "angular-plotly.js";
import {H} from "../../../shared/helpers/H";
import {ApiService} from "../../../shared/services/api.service";
import {egretAnimations} from "../../../shared/animations/egret-animations";
import {ReportRevision, ReportSuivi, SuiviDocument} from "../../../shared/models/Suivi.model";
import {EnergyStats} from "../../../shared/models/EnergyStats.model";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {SuiviPageDeGardeComponent} from "./suivi-page-garde.component";
import * as Sentry from "@sentry/browser";
import {SiteTariffData} from "../../../shared/models/models";
import {random} from "mathjs";

@Component({
    selector: 'app-suivi',
    templateUrl: './suivi.component.html',
    styleUrls: ['./suivi.component.scss'],
    animations: egretAnimations
})
export class SuiviComponent implements OnInit, OnDestroy {
    @ViewChildren('bloc') blocs: QueryList<ElementRef>;
    @ViewChild('cover') cover: SuiviPageDeGardeComponent;
    @ViewChild('blocValidation') blocValidation: ElementRef;
    public routeSubscription: Subscription = null;

    urlRef: string;
    selectedYear: number;

    showRevision: boolean = false;
    reportsItemsRef: ReportSuivi [] = [];
    reportsItems: ReportSuivi [] = [];
    reportsIteratifs: ReportSuivi [] = [];

    selectedReportRef: ReportSuivi;
    selectedReport: ReportSuivi;
    // energyStatsForCurrentYear: EnergyStats = null;

    pdfLink = "";
    pdfAge = 0;
    pdfDate = '';
    reportFiles: any[] = [];
    reportMainPdfInfo: any = null;// run pdfinfo on generated pdf
    reportMergeErrors: string[] = [];
    reportMergeResult: string[] = [];
    reportMergeOutputPdfInfos: string[] = [];
    reportMergeOutput: string = '';
    revisionMessage: string = '';
    files_synced = false

    revisionNextStatus = '';// SUBMITTED, COMMENT, VALIDATED
    justSubmitted = false;
    blocMessageVisible = false;
    /*
    Goals
     */
    fpForMetricRefYear = {};
    fpForMetric = {};
    cegesForMetric = {};
    goals: any[] = [];

    /*
      Documents
     */
    metas: any;
    pdfSource: string = '';
    allEnergyUsagesArray: SiteTariffData[] = [];

    constructor(public myapp: AppService, public site: SiteService,
                private currentRoute: ActivatedRoute,
                public plotlyServ: PlotlyService) {
    }

    get isOldPdf() {
        const retval = ((H.unixTs() - this.pdfAge) / 60) > 5;

        return retval;
    }

    get isRef() {
        return this.selectedReportRef && this.selectedReport
            && this.selectedReport.type === 'HIDDEN_REF_DATA';
    }

    ngOnInit(): void {
        if (this.myapp.user)
            this.myapp.storeCurrentRoute();
        else
            this.myapp.appInitStatus.subscribe(status => {
                if (status === 1)
                    this.myapp.storeCurrentRoute();
            });

        this.site.siteLoadingStatus.subscribe(status => {
            if (status === SiteService.MAX_SITE_LOADING_STATUS) {
                this.bootstrap();
                this.routeSubscription = this.currentRoute.params.subscribe(queryParams => {
                    if (queryParams['site'] && queryParams['year']) {
                        this.urlRef = queryParams['site'];
                        const urlYear = queryParams['year'];
                        this.selectedYear = Number(urlYear);
                        //console.log("queryParams:CHANGE:SUIVI", queryParams, this.urlRef);
                        if (this.selectedReportRef)
                            this.selectReportRef(this.selectedReportRef);
                    }

                    //console.log("queryParams:CHANGE:SUIVI", queryParams);
                });
            }
        });

    }

    bootstrap(selectedReportUID: string = null) {

        this.site.api.getSiteSuiviReports(this.site.uid).subscribe(resp => {
            this.reportsItems = [];
            this.reportsItemsRef = [];
            if (resp) {
                resp.body.forEach((report, i) => {
                    //console.log("getSiteSuiviReports: 1111", report.year, report['influence_factors'], report.uid);
                    const rep = new ReportSuivi(report);
                    //console.log("getSiteSuiviReports: 2222", rep.year, rep.influence_factors, rep.uid);

                    if (rep.uid_parent)
                        this.reportsItems.push(rep);
                    else {
                        this.reportsItemsRef.push(rep);
                    }
                });
            }

            if (this.reportsItemsRef.length)
                this.selectReportRef(this.reportsItemsRef[this.reportsItemsRef.length - 1], selectedReportUID);
        });
    }

    ngOnDestroy(): void {
        console.log("Suivi:ngOnDestroy()", 'unsubscribe route');
        if (this.routeSubscription)
            this.routeSubscription.unsubscribe();
    }

    selectReportByYear(year: number) {

    }

    selectReportRef(report: ReportSuivi, selectedReportUID: string = null) {
        console.log('selectReportRef()', selectedReportUID, report);

        this.selectedReportRef = report;
        this.reportsIteratifs = this.reportsItems.filter(rep => rep.uid_parent === this.selectedReportRef.uid);

        if (selectedReportUID) {
            this.selectedReport = this.reportsItems.find(it => it.uid === selectedReportUID);
        }
        if (this.selectedYear) {
            this.selectedReport = this.reportsItems.find(it => it.year === this.selectedYear);
        }
        if (!this.selectedReport)
            this.selectedReport = this.reportsItems.find(it => it.uid_parent === this.selectedReportRef.uid && it.year_offset === 0);

        this.selectReport(this.selectedReport);
    }

    selectReport(report: ReportSuivi) {
        if (!report) return;
        console.log('SUIVI:: selectReport()', report.isRef, report.year, report);
        if (!report.notes_tariffs) report.notes_tariffs = {};
        this.selectedReport = report;
        this.selectedYear = this.selectedReport.year;
        this.reportMergeResult = null;
        this.reportMergeErrors = null;
        this.reportMergeOutput = null;
        if (this.site.clientSite.tariff_config) {
            if (this.selectedReportRef)
                this.calcGoals();
        }

        this.checkIfPdfExist();
    }

    cbFromChlid($event) {
        const {arg, metas, pdfSource} = $event;
        console.log('cbFromChlid()', metas, pdfSource);
        this.metas = metas;
        this.pdfSource = pdfSource;
        this.myapp.globalDocumentViewer.next({title: "Titre", pdfSource, metas: this.metas});
    }

    calcGoals() {
        this.goals = [];
        const goals = Optimise.generateGoals(this.selectedReportRef.goal);

        this.allEnergyUsagesArray = this.site.clientSite.energy_usage ? Array.from(Object.values(this.site.clientSite.energy_usage)) : [];
        this.allEnergyUsagesArray = this.allEnergyUsagesArray.map(it => {
            it._tariff = this.site.clientSite.tariff_config_map.get(it.uid_tarif);
            return it;
        })
        console.log('calcGoals():allEnergyUsagesArray', this.allEnergyUsagesArray);
        //console.log('calcGoals():START', goals, this.selectedReportRef.goal);
        let realEconAccumulator = 0;
        let usageAccumulator = 0;
        let usageFirstYear = 0;
        let estimatedEconAccumulator = 0;
        let estimatedEconElectAccumulator = 0;
        let estimatedEconChaleurAccumulator = 0;
        let estimatedEconCarbonAccumulator = 0;
        let prevUsage = 0;
        let prevRealEfficiency = 0;
        goals.index.forEach((i, index) => {
            const currYear = this.selectedReportRef.year_start + i - 1;

            const usageOfYear = this.getWeightedUsagePerYear(currYear);
            if (usageFirstYear === 0) usageFirstYear = usageOfYear;

            usageAccumulator += usageOfYear;

            if (usageOfYear > 0) prevUsage = usageOfYear;
            const usage = usageOfYear || prevUsage;

            const realEconOfYear = this.getWeightedEconomyPerYear(currYear, true);
            if (currYear <= this.selectedYear) {
                realEconAccumulator += realEconOfYear;
            }
            // console.log('calcGoals()', realEconAccumulator);

            estimatedEconAccumulator += this.getWeightedEconomyPerYear(currYear, false) || 0;
            const estimatedEconPerAgentAndYear = this.getWeightedEconomyPerYearAndMetric(currYear);
            const financialDataPerYear = this.getFinancialDataPerYear(currYear);
            estimatedEconElectAccumulator += estimatedEconPerAgentAndYear.ELEC || 0;
            estimatedEconChaleurAccumulator += estimatedEconPerAgentAndYear.CHALEUR || 0;
            estimatedEconCarbonAccumulator += estimatedEconPerAgentAndYear.CARBON || 0;


            const curr_acc_econ = this.goals.reduce((acc, v) => {
                return acc + v['economy'];
            }, financialDataPerYear['economy']);
            const curr_acc_inv = this.goals.reduce((acc, v) => {
                return acc + v['invest_net'];
            }, financialDataPerYear['invest_net']) || 0;

            const acc_acc_eco = this.goals.reduce((acc, v) => {
                return acc + v['acc_econ'];
            }, curr_acc_econ);

            // console.log('calcGoals()', i, acc_acc_eco, curr_acc_inv);
            const flow = acc_acc_eco - curr_acc_inv;
            let goalForYear = {
                ...financialDataPerYear,
                acc_econ: curr_acc_econ,
                acc_invest: curr_acc_inv,
                acc_acc_eco,
                flow,
                index: i,
                year: currYear,
                goal_theorical: goals.theoretical[i],
                goal_minimum: goals.minimum[i],
                usage: 100 * (usage) / usageFirstYear,
                efficiency_predicted: 100 * (estimatedEconAccumulator + usage) / usage,
                efficiency_real: 100 * (realEconAccumulator + usage) / usage,
                estimated_economy_chaleur: estimatedEconChaleurAccumulator,
                estimated_economy_elec: estimatedEconElectAccumulator,
                estimated_economy_carbon: estimatedEconCarbonAccumulator,
            };
            if (currYear > this.selectedYear)
                goalForYear['efficiency_real'] = prevRealEfficiency
            this.goals.push(goalForYear);

            prevRealEfficiency = goalForYear['efficiency_real'];
        });
    }

    getWeightedUsagePerYear(year) {
        let retVal = 0;
        const usagePerAgent = {};
        this.site.metricsMap.forEach((metric, key) => {
            usagePerAgent[metric.fluid] = [];
        });
        this.fpForMetric = {};
        this.cegesForMetric = {};

        if (!this.site.clientSite.energy_usage) {
            console.error('getWeightedUsagePerYear():old_energy_stats_map null!', this.site.clientSite);
            return retVal;
        }
        const usagesForYear = this.allEnergyUsagesArray.filter(it => it.year === year);
        //console.log('usagesForYear:', usagesForYear, year);
        if (usagesForYear) {
            usagesForYear.forEach(usage => {
                this.fpForMetric[usage.uid_tarif] = usage.fp;
                this.cegesForMetric[usage.uid_tarif] = usage.ceges;
                if (usage._tariff) {
                    const fluid = usage._tariff.fluid;
                    const agent = usage._tariff.agent;
                    if (fluid) {
                        let weight = K.EnergyAgentsFactors[agent];
                        weight = usage.fp;
                        retVal += usage.energy * weight;
                        // console.log('-------', fluid, usagePerAgent);
                        usagePerAgent[fluid].push(usage.energy * weight);
                    }

                } else {
                    this.myapp.showError("Tarif: " + usage.uid_tarif + " non défini!");
                }

                // retVal += usage.amount * usage.fp;
            });

            //console.log('getWeightedUsagePerYear():' + year, usagePerAgent, retVal);
        }
        /// TODO
        ///if (energystats.default) this.fpForMetricRefYear = this.fpForMetric;
        return retVal * 1e-6;
    }

    getWeightedEconomyPerYear(year, real = false) {
        let retVal = 0;

        //  x= year
        //  reportYEar= report.year

        let actions = this.site.actions.filter(act =>
                act.gc === 1
                && act.is_deleted === 0
                && moment(act.ts_due * 1000).year() === year
                && !(act.status === "REFUSED" && moment(act.ts_completed * 1000).year() <= year)
            /*
            && (!(act.status === "REFUSED" && moment(act.ts_completed * 1000).year() === year)
                 || (act.status !== "REFUSED" && moment(act.ts_due * 1000).year() === year))
                 */
        );
        // console.log("Actions NOIR " + year, actions);

        if (real)//bleu
            actions = this.site.actions.filter(act =>
                act.status === 'COMPLETED'
                && act.is_deleted === 0
                && act.gc === 1
                && moment(act.ts_completed * 1000).year() === year);

        const econPerAgent = {};
        const econPerAgenttotal = {};
        this.site.metricsMap.forEach((metric) => {
            const key = metric.fluid;
            econPerAgent[key] = [];
            econPerAgenttotal[key] = 0;
        });

        if (real) {
            // if (year > this.selectedYear)  return 0;
            //console.log("Actions BLEU " + year, {actions, econPerAgent, econPerAgenttotal});
        }
        actions.forEach(act => {
            act.economies.forEach(econ => {

                const refTariffData = this.site.getTariffDataForYear(econ.uid_tariff, 0);//real ? year

                if (refTariffData) {
                    let fp = refTariffData.fp || 1;
                    retVal += econ.econEstimated * fp;
                    econPerAgenttotal[econ.fluid] += (econ.econEstimated * fp) || 0;
                } else {
                    console.error('getWeightedEconomyPerYear() forEach:' + year, econ, act.num);
                }

                //
            });
        });
        // console.log('getWeightedEconomyPerYear() econPerAgenttotal:' + year, real, retVal, econPerAgenttotal);

        return retVal * 1e-6;
    }

    getWeightedEconomyPerYearAndMetric(year) {
        let retVal = {'ELEC': 0, "CHALEUR": 0, 'CARBON': 0};

        let actions = this.site.actions.filter(act =>
            act.status !== 'REFUSED'
            && (this.isRef || act.yearProposed <= this.selectedReport.year)
            && act.yearDue === year
            && act.is_deleted === 0
            && act.gc === 1
        );
        this.site.actions.forEach(act => {
            // console.log('---getWeightedEconomyPerYearAndMetric():' + year, act.yearProposed);
        });

        //console.log('getWeightedEconomyPerYearAndMetric():' + year, actions, this.site.actions);
        actions.forEach(act => {
            act.economies.forEach(econ => {
                const tariffConfigObj = this.site.getTariffObj(econ.uid_tariff);
                if (tariffConfigObj) {

                    const refTariffData = this.site.getTariffDataForYear(econ.uid_tariff, 0);
                    let fp = refTariffData.fp || 1;
                    // fp = K.EnergyAgentsFactors[metricObj.agent];// to compare

                    const ceges = refTariffData ? refTariffData.ceges : 1;

                    const economyToAdd = econ.econEstimated * fp;
                    retVal[tariffConfigObj.fluid] += economyToAdd;
                    retVal['CARBON'] += econ.econEstimated * ceges * 1e-9;

                }
            });
        });
        return retVal;
    }

    getFinancialDataPerYear(year): any {
        const retVal = {
            //flow: 0,
            economy: 0,
            invest_net: 0, invest_real: 0
        };
        const actions = this.site.actions.filter(act =>
            act.status !== 'REFUSED'
            && act.gc === 1
            && act.is_deleted === 0
            && (this.isRef || act.yearProposed <= this.selectedReport.year)
            && moment(act.ts_due * 1000).year() === year
        );
        actions.forEach(act => {
            // console.log('--------getFinancialDataPerYear()', year, act.num, act.investment_real);
            retVal.economy += Number(act.economies_total);
            retVal.invest_net += Number(act.investment_real);
            retVal.invest_real += Number(act.investment);
            // retVal.flow = retVal.economy + retVal.invest_real;
        });
        // console.log('getFinancialDataPerYear()', year, actions, retVal);
        return retVal;
    }

    async getPlotsImages() {
        const toImageConf = {height: 500, width: 980, format: 'svg'};
        const plotEfficiencyInstance = this.plotlyServ.getInstanceByDivId('plotEfficiency');
        const plotGoalsInstance = this.plotlyServ.getInstanceByDivId('plotGoals');
        const plotFinancialInstance = this.plotlyServ.getInstanceByDivId('plotFinancial');
        const plotly = await this.plotlyServ.getPlotly();

        if (!plotEfficiencyInstance) return

        const plotEfficiency = await plotly.toImage(plotEfficiencyInstance, toImageConf);
        const plotGoals = await plotly.toImage(plotGoalsInstance, toImageConf);
        const plotFinancial = await plotly.toImage(plotFinancialInstance, toImageConf);

        return {plotEfficiency, plotGoals, plotFinancial};
    }

    async getBlocsHtml() {
        this.files_synced = false
        const coverRawHtml = this.cover.html.nativeElement as HTMLElement;
        const coverRawHtmlCleaned = this.visit_nodes_recursive(this.callback, coverRawHtml);
        const blocsRawHtml = [];
        const plotImages = await this.getPlotsImages();

        this.blocs.forEach(elm => {
            const el = elm.nativeElement as HTMLElement;
            // console.log("Native element", el);
            const cleanedEl = this.visit_nodes_recursive(this.callback, el);
            blocsRawHtml.push(cleanedEl.outerHTML);
            //console.log("Native element", el.nativeElement.innerHTML, b64);
        });
        const b64Cover = ApiService.bs64FromJsonStr((coverRawHtmlCleaned.outerHTML));
        const b64Html = ApiService.bs64FromJsonStr(JSON.stringify(blocsRawHtml));
        const b64Images = plotImages ? ApiService.bs64FromJsonStr(JSON.stringify(plotImages)) : '';
        //console.log("plotImages 64", b64Images);
        //console.log("coverRawHtml", coverRawHtmlCleaned, b64Cover);

        const copySelectedreportUID = this.selectedReport.uid;
        this.site.api.printSuivi(b64Cover, b64Html, b64Images, this.isRef ? this.selectedReportRef.uid : this.selectedReport.uid)
            .subscribe(resp => {
                this.pdfLink = this.site.api.getLink(resp.url);
                console.log("respPrint", resp);
                this.selectedReport = null;
                this.selectedReportRef = null;
                this.bootstrap(copySelectedreportUID);
            });
    }

    private visit_nodes_recursive(callback, node, depth = 0) {
        if (!node) return;
        this.callback(null, node);
        if (node.children && node.children.length > 0) {
            Array.from(node.children).forEach(child => {
                this.visit_nodes_recursive(this.callback, child, depth + 1);
            });
        }
        return node;
    }

    private callback(arg, node: HTMLElement) {
        if (node && node.hasAttributes()) {
            // console.log("callback", node.tagName, node.className);
            node.classList.remove('ng-star-inserted');
            if (node.tagName.includes('suivi'))
                node.setAttribute("style", 'display:block');
            else
                node.removeAttribute('style');
            node.getAttributeNames().forEach(attr => {
                const attrVal = node.getAttribute(attr);
                if (attrVal === undefined || attrVal === '') node.removeAttribute(attr);
                if (attr.includes('ng') || attr.includes('fx')) node.removeAttribute(attr);
            })
        }
    }

    syncSuiviDocuments() {
        this.myapp.loader.open("Analyse des annexes en cours");
        this.myapp.api.syncSuiviDocuments(this.site.uid, this.selectedReport.uid)
            .subscribe(resp => {
                this.myapp.loader.close();
                console.log("syncSuiviDocuments", resp);
                console.log("Files", resp.files);
                this.reportFiles = resp.pdfData;
                if (this.reportFiles.length === 0)
                    this.myapp.showMessage("Pas de fichiers annexes trouvés !")
                this.files_synced = true;
            });
    }

    mergeFiles() {
        this.myapp.loader.open("Combinaison des fichiers en cours");
        let tablesToDisplay = [];
        const docsTechnical = [];
        const docsAppendix = [];
        const docsToScan = this.selectedReport.documents.map(it => {
            it.full_path = this.site.documentsMap.has(it.uid) ? this.site.documentsMap.get(it.uid).full_path : '';
            return it;
        })
        docsToScan.forEach(doc => {
            const _doc = new SuiviDocument(doc);
            if (doc.section === 'TECHNICAL')
                docsTechnical.push(_doc);
            if (doc.section === 'APPENDIX')
                docsAppendix.push(_doc);
        });
        if (this.isRef) tablesToDisplay = [docsTechnical, docsAppendix];
        else tablesToDisplay = [docsAppendix];

        //console.log("loadDocs()", this.isRef, docsToScan, tablesToDisplay, docsTechnical, docsAppendix);
        console.log("loadDocs()", tablesToDisplay);
        this.myapp.api.printSuiviAnnexes(
            tablesToDisplay,
            this.site.clientSite.ref,
            this.isRef ? this.selectedReportRef.uid : this.selectedReport.uid,
            this.selectedReport.year.toString(),
            this.isRef ? '1' : '0',
            this.selectedReport.nextRevisionVersion.toString()
        ).subscribe(resp => {
            this.myapp.loader.close();
            console.log('mergeFiles', resp);
            const output = resp.output;
            const url = output.replaceAll('/var/www/html/api', 'https://tool.optimise.swiss/api');
            console.log("Merged File:", url, this.isRef, this.selectedReportRef.uid, this.selectedReport.uid);
            if (resp.success == 1) {
                this.blocMessageVisible = true;
                this.reportMergeResult = resp.result;
                this.reportMergeErrors = resp.errors;
                this.reportMergeOutput = url;
                this.reportMergeOutputPdfInfos = resp.pdfInfos;
                this.revisionNextStatus = 'SUBMITTED';
            } else {
                console.error("Errors:", resp.errors);
                Sentry.captureException(new Error("Error while merging annexe"), {
                    tags: {
                        url: url,
                        isRef: this.isRef,
                        success: resp.success,
                        ref: resp.ref,
                        withMain: resp.withMain,
                    },
                    //fingerprint: resp.errors
                });
            }


        });
    }

    createRevision(forcedStatus: string = '') {
        if (forcedStatus !== '') this.revisionNextStatus = forcedStatus;
        const revisionItem = new ReportRevision();
        revisionItem.user = this.myapp.user.metas;
        revisionItem.ts = H.unixTs();
        revisionItem.status = this.revisionNextStatus;
        if (this.revisionNextStatus === 'SUBMITTED') {
            revisionItem.version = this.selectedReport.nextRevisionVersion;
            revisionItem.path_report = this.pdfLink;
            revisionItem.path_full = this.reportMergeOutput;
            revisionItem.page_count_main = Number(this.reportMainPdfInfo['Pages']);
            revisionItem.page_count_full = Number(this.reportMergeOutputPdfInfos['Pages']);
        } else
            revisionItem.version = this.selectedReport.latestRevisionVersion;

        if (this.revisionNextStatus !== 'VALIDATED')
            revisionItem.message = this.revisionMessage;

        this.justSubmitted = true;
        this.blocMessageVisible = false;
        this.revisionMessage = '';
        console.log('Revision', revisionItem);

        if (!this.selectedReport.validation) this.selectedReport.validation = [];
        this.selectedReport.validation.push(revisionItem);
        this.selectedReport.validation = Optimise.sortArrayByKeyVal(this.selectedReport.validation, 'ts').reverse();
        const dataToDave = {};
        dataToDave['validation'] = this.selectedReport.validation;
        this.saveSuivi(this.selectedReport, dataToDave);

    }

    openRevisionLink(rev: ReportRevision, withAnnexe: boolean) {
        if (withAnnexe) window.open(rev.path_full);
        else window.open(rev.path_report);
    }

    clearAllRevisions() {
        if (!this.myapp.isAdminOrSuperUser) return;
        this.myapp.confirm.confirm({title: "Sure?"}).subscribe(ok => {
            if (ok) {
                const dataToDave = {};
                dataToDave['validation'] = [];
                this.saveSuivi(this.selectedReport, dataToDave);
            }
        });
    }

    clearAllReports() {
        if (!this.myapp.isAdminOrSuperUser) return;
        this.myapp.confirm.confirm(
            {
                title: "êtes-vous sûrs ?",
                message: "Tous les rapports seront supprimés!"
            }
        ).subscribe(ok => {
            if (ok) {
                const dataToDave = {};
                dataToDave['validation'] = [];
                this.site.api.suiviDeleteAll(this.site.uid).subscribe(resp => {
                    console.log("clearAllReports", resp);
                    if (resp.status === 1) {
                        this.selectedYear = 0;
                        this.selectedReport = null;
                        this.selectedReportRef = null;
                        this.bootstrap();
                    }
                });
            }
        });
    }

    saveSuivi(suivi: ReportSuivi, dataToSave: any) {
        this.site.api.saveSuivi(suivi, dataToSave)
            .subscribe(resp => {
                if (resp.status == 1) {
                    this.blocMessageVisible = false;
                    this.myapp.showMessage("Rapport mit à jour !");
                }
                console.log("saveSuivi ", suivi, dataToSave, resp);
            });
    }

    createSuivi() {
        this.site.api.createSuivi(this.site.uid, this.myapp.user.uid_bdd)
            .subscribe(resp => {
                if (resp.status == 1) {
                    this.bootstrap();
                }
                console.log("createSuivi ", resp);
            });
    }

    checkIfPdfExist() {
        moment().locale('fr');
        this.pdfLink = null;
        this.pdfAge = 0;
        this.site.api.getIsSuiviPdfExist(this.isRef ? this.selectedReportRef.uid : this.selectedReport.uid).subscribe(resp => {
            if (resp.status === 1) {
                this.pdfLink = this.site.api.getLink(resp.url, true) + "&html=0";
                this.pdfAge = Number(resp.age);
                this.reportMainPdfInfo = resp.pdfInfos;
                const pdfDateMom = moment(this.pdfAge * 1000);
                this.pdfDate = pdfDateMom.format('LLL');
                // if (!this.isOldPdf)  this.blocValidation.nativeElement.toggleAttribute('hidden', true);
            }
            console.log("this.reportMainPdfInfo", this.reportMainPdfInfo);
            console.log("getIsSuiviPdfExist", resp);
        });
    }
}

import {Component, OnInit} from '@angular/core';
import {AppService} from "../../../shared/services/app.service";
import {SiteService} from "../../../shared/services/site.service";
import {ApiService} from "../../../shared/services/api.service";
import {MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {SiteEvent} from "../../../shared/models/Notifs";
import {User} from "../../../shared/models/models";
import {H} from "../../../shared/helpers/H";

@Component({
    selector: 'app-bs-inmailer',
    templateUrl: './bs-inmailer.component.html',
    styleUrls: ['./bs-inmailer.component.scss']
})
export class BsInmailerComponent implements OnInit {

    isArchive = false;

    constructor(
        public myapp: AppService,
        public site: SiteService,
        public api: ApiService,
        private _bottomSheetRef: MatBottomSheetRef<BsInmailerComponent>) {
    }

    ngOnInit(): void {
        //this.site.currentSiteEvent.target = [];
        // console.log("ngOnInit", 'BsInmailerComponent:currentSiteEvent 11', this.site.currentSiteEvent);
        //   console.log("ngOnInit", 'BsInmailerComponent', this.site.responsibles);
        if (this.site.currentSiteEvent.target.length === 0) {
            // if share event, while when opening LOG event, targets are already set
            this.site.responsibles.forEach(u => {
                if (this.getIfUserDefaultRecipient(u)) {
                    this.addUser(u);
                }
            });
        } else {
            this.isArchive = true;
        }


        //  console.log("ngOnInit", 'BsInmailerComponent:currentSiteEvent 22', this.site.currentSiteEvent);
    }

    send(): void {
        console.log("save", this.site.currentSiteEvent);
        this.site.currentSiteEvent.user_metas = this.myapp.user.metas;
        this.site.api.sendShareEvent(this.site.currentSiteEvent).subscribe(resp => {
            console.log("save::Resp", resp);
            if (resp.status === 1) {
                this.myapp.showMessage("Rapport partagé avec succès");
                this.dismiss();
            }
        });
    }

    dismiss(): void {
        this._bottomSheetRef.dismiss();
    }

    getIfUserDefaultRecipient(user: User) {
        let routeName = "";
        if (this.site.currentSiteEvent.wat === 'share-proto-hebdo') routeName = 'PROTOHEBDO';
        if (this.site.currentSiteEvent.wat === 'share-bmens') routeName = 'BMENS';
        const rights = user.getRightsForSiteAndRoute(routeName);
        // console.log('getIfUserDefaultRecipient', user.fullName(), rights);
        return (rights.includes('DEFAULT_RECIPIENT'))
    }

    select(wat: string) {
        if (wat === 'all') {
            this.site.currentSiteEvent.target = [];
            this.site.responsibles.forEach(u => {
                this.addUser(u);
            })
        }
        if (wat === 'none') {
            this.site.currentSiteEvent.target = [];
        }
    }

    addUser(u: User) {
        if (u.email === '' || u.email === 'NC') {
            console.log('addUser', u.fullName(), u.email, u);
            this.myapp.showError("L'email de l'utilisateur " + u.fullName() + " est mal définit !");
            return;
        }
        if (this.isArchive) return;// cant change targets of archive, useless
        if (this.site.currentSiteEvent.target.includes(u.email)) {
            this.site.currentSiteEvent.target =
                this.site.currentSiteEvent.target.filter(it => it !== u.email);
        } else {
            this.site.currentSiteEvent.target.push(u.email);
        }
        // console.log('addUser', u.email, this.site.currentSiteEvent.target);
    }
}

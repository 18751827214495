import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from "@angular/core";
import {H} from "../../../shared/helpers/H";
import * as mexp from 'math-expression-evaluator';
import {Optimise} from "../../../shared/helpers/Optimise";
import {PdmRegDef} from "../../../shared/models/PdmRegDef";
import {PdmDataRow} from "../../../shared/models/PdmDataRow";
import {Pdm} from "../../../shared/models/Pdm";
import {AppService} from "../../../shared/services/app.service";
import {SiteService} from "../../../shared/services/site.service";
import {MatMenuTrigger} from "@angular/material/menu";

@Component({
    selector: 'saisie-row',
    template: `
        <div class="data-row-holder" [ngClass]="{ 'year-end':rowData.releve_num===12, 'row-new':rowData.is_in_creation }">
            <div class="date pl-4 mono" fxLayout="row" fxLayoutAlign="space-between center">

                <div fxFlex="30px" (dblclick)="reset()">
                    <span *ngIf="rowData.releve_num<10">0</span>
                    <span>{{rowData.releve_num}}</span>
                </div>
                <div fxFlex="45px" (dblclick)="del()">{{rowData.releve_year}}</div>
                <div fxFlex (click)="picker1.open()">{{dateDayName|uppercase}} {{rowData.date}} </div>
                <input style="position: relative;width: 0" #input
                       matInput [min]="rowData.rowMinDate"
                       [class.cdk-visually-hidden]="true"
                       [matDatepicker]="picker1"
                       (dateChange)="rowData.setRowDate($event.value)">
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between stretch">
                <mat-datepicker [startAt]="rowData.rowDate" #picker1></mat-datepicker>
                <ng-container *ngFor="let reg of regs;trackBy: trackByFn">
                    <ng-container *ngFor="let f of fixedFields;let i=index;trackBy:i">
                        <ng-container *ngIf="reg.shouldShow(f)">
                            <div #cell
                                 class="field field-width"
                                 (click)="clic(reg,f,$event)"
                                 (dblclick)="emitEditStart(reg,f)"
                                 [attr.field]="f"
                                 [attr.num]="reg.num"
                                 [attr.order]="reg.disp_order"
                                 [attr.date]="rowData.date"
                                 [title]=" f + reg.num+': '+rowData.date+'-'+rowData.dist">

                                <ng-container *ngIf="rowData.is_in_creation&&reg.isManualEntry(f)">
                                    <input matInput
                                           class="input-creation-mode"
                                           (keyup)="setValue($event,reg,f)"
                                           [value]="rowData.data[f+reg.num]?rowData.data[f+reg.num]:0"
                                           (blur)="inputBlur(reg,f,$event)"
                                           [tabIndex]="getTabIndex(f,reg.disp_order)"
                                           [attr.field]="f + reg.num">
                                </ng-container>


                                <ng-container *ngIf="!rowData.is_in_creation||!reg.isManualEntry(f)">
                                    <span *ngIf="editMode!==(f+reg.num)&&rowData.data[f + reg.num]!==undefined">{{rowData.data[f + reg.num]|number}}</span>
                                    <input #input matInput type="number" class="input-edit-mode"
                                           *ngIf="editMode===(f+reg.num)"
                                           step="0.001"
                                           (keydown.enter)="manualUpdate(f,reg,$event.target.value )"
                                           (keydown.escape)="dismissAll()"
                                           [attr.field]="f + reg.num"
                                           [tabIndex]="getTabIndex(f,reg)"
                                           [placeholder]="getTabIndex(f,reg)+' T'"
                                           [value]="rowData.data[f+reg.num]">
                                </ng-container>


                            </div>

                        </ng-container>

                    </ng-container>
                </ng-container>
            </div>

            <!-- totals-->
            <div #cell class="field field-width" *ngFor="let extraf of autoFields" [title]="extraf">
                <span *ngIf="rowData.tots[extraf]">{{rowData.tots[extraf]|number}}  </span>
            </div>

            <div #cell class="field field-width">
                <span *ngIf="rowData.days">{{rowData.days}}</span>
            </div>
            <div #cell class="field field-width">
                <span *ngIf="rowData.ts">{{rowData.ts}}</span>
            </div>

        </div>
    `,

    styleUrls: ['./saisie-row-component.scss']
})
export class SaisieRowComponent implements OnInit, AfterViewInit, OnChanges {
    @ViewChildren('cell') cells: QueryList<any>;
    @ViewChildren('input') inputEl: QueryList<ElementRef>;

    @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger;
    @Input('index') index: number;
    @Input('ts') ts: number;// <--- to force reload of component
    @Input('ctrlPressField') ctrlPressField: string;
    @Input('firstRowDateMassEdit') firstRowDateMassEdit: string;
    @Input('lastRowDateMassEdit') lastRowDateMassEdit: string;
    @Input('regs') regs: PdmRegDef[];
    @Input('rowData') rowData: PdmDataRow;

    @Output('cb') cb: EventEmitter<any> = new EventEmitter<any>();

    fixedFields = ['i', 'k', 'd', 'a', 't', 'c'];
    autoFields = ['dTot', 'cTot'];
    editMode = '';
    classList = [];
    prevDataMd5 = "";
    dateDayName = "";
    loaded = false;

    constructor(private cdRef: ChangeDetectorRef, public myapp: AppService, public site: SiteService) {
    }

    emitEditStart(reg: PdmRegDef, f: string) {
        if (reg.getEntryType(f) === "MANUAL") {
            this.editMode = (f + reg.num);
            //console.log("this.inputEl 11", this.inputEl.first);
            this.cdRef.detectChanges();
            // console.log("this.inputEl 22", this.inputEl.first.nativeElement);
            this.inputEl.first.nativeElement.focus();
            this.cb.emit({arg: 'setEditStartRowDate', value: this.rowData.date, reg});
        } else {
            this.myapp.toastr.error("On ne peut éditer les valeurs calculées", "Attention !", {timeOut: 5000});
        }
    }

    getTabIndex(field: string, disp_order: number) {
        let tabIndex = (100000 - this.index * 1000) + disp_order * 10;
        tabIndex += this.fixedFields.indexOf(field);
        return tabIndex.toString();
        //return Math.floor(this.rowData.rowDate.getTime() / 1000) + reg.disp_order * 100 + this.fixedFields.indexOf(field);
    }

    getMaxRegsDispOrder() {
        let max = 0;
        this.regs.forEach(reg => {
            if (reg.disp_order > max) max = reg.disp_order;
        });
        return max;
    }

    manualUpdate(field, reg, value) {
        const obj = {field, reg, value};
        this.cb.emit({arg: 'massUpdate', obj});// save actual state for undo
        this.editMode = '';
    }

    clic(reg, f, $event) {
        const cellEl = $event.target as HTMLDivElement;
        console.log("clic", this.rowData);
        this.cb.emit({arg: 'selectRow', value: this.rowData});
        if (cellEl.classList.contains('ctrl')) {
            const date = cellEl.getAttribute('date');
            this.cb.emit({arg: 'setEditEndRowDate', value: date});
        }
    }

    del() {
        if (this.rowData.is_in_creation)
            this.cb.emit({arg: 'deleteRow', value: this.rowData.date});
    }

    reset() {
        if (this.rowData.is_in_creation)
            this.rowData.reset();
    }

    adaptCellsStyling() {
        if (this.cells) {
            //  console.log("adaptCellsStyling()", this.index);
            const firstDate = new Date(this.firstRowDateMassEdit);
            const lastDate = new Date(this.lastRowDateMassEdit);
            const currDate = new Date(this.rowData.date);
            this.cells.forEach(cell => {
                const cellEl = cell.nativeElement as HTMLDivElement;
                const cellVal = cellEl.getElementsByTagName('span');
                const inputCreation = cellEl.getElementsByClassName('input-creation-mode');

                const dataField = cellEl.getAttribute('field');
                const regNum = cellEl.getAttribute('num');
                const regOrder = cellEl.getAttribute('order');
                const varName = dataField + regNum;

                cellEl.classList.add(
                    'reg-num-' + regNum
                );
                if (dataField) cellEl.classList.add(
                    'field-' + dataField,
                    'field-' + dataField.toUpperCase()
                );
                if (this.rowData.is_in_creation) cellEl.classList.add(
                    'creation-mode'
                );
                if (inputCreation.length > 0) {
                    inputCreation.item(0).setAttribute("placeholder", this.getTabIndex(dataField, Number(regOrder)));
                    // inputCreation.item(0).setAttribute("tabIndex", this.getTabIndex(dataField, Number(regOrder)));
                }

                cellEl.classList.remove('massEditTarget');

                if (this.ctrlPressField && varName === this.ctrlPressField) {
                    if (firstDate.getTime() <= currDate.getTime())
                        cellEl.classList.add('ctrl');
                }

                if (this.lastRowDateMassEdit && this.lastRowDateMassEdit.length > 5) {
                    cellEl.classList.remove('ctrl');
                    if (lastDate && currDate &&
                        currDate.getTime() <= lastDate.getTime() &&
                        firstDate.getTime() <= currDate.getTime() &&
                        varName === this.ctrlPressField) {
                        cellEl.classList.add('massEditTarget');
                    }
                }
                if (cellVal.length === 0)
                    cellEl.classList.add('empty');
                else {
                    cellEl.classList.remove('empty');


                    /*
                    ng-star-inserted empty fieldD fieldd field field-width reg-num-25 manual-entry creation-mode
                    if (dataInputType && dataInputType.includes('MACRO:'))
                        cellEl.classList.add('field-macro');
                    if (dataInputType && dataInputType.includes('CALC:'))
                        cellEl.classList.add('field-calc');
                    if (dataInputType && dataInputType.includes('MAP:'))
                        cellEl.classList.add('field-map');
                    if (dataInputType && dataInputType.includes('EDITONE'))
                        cellEl.classList.add('field-edit-one');
                    if (dataInputType && dataInputType.includes('EDITMANY'))
                        cellEl.classList.add('field-edit-many');
                    */
                }

                // {'field'+f.toUpperCase() ,'field' ,'reg-num-'+reg.num ,'field-map':rowData['_'+f + reg.num]&&rowData['_'+f + reg.num].includes('MAP')}
            });
        }

    }

    dismissAll() {
        this.editMode = '';
        this.ctrlPressField = '';
        this.cb.emit({arg: 'cancelEdit'});
    }

    saveRow() {

    }

    showHistory($event) {
        console.log("History", this.rowData.metas);
    }

    setValue($event: KeyboardEvent) {
        const elm = $event.target as HTMLInputElement;
        let t = 0;
        const val = elm.value
            .replace(/[^\d.]/g, '')
            .replace(/\./g, match => ++t === 2 ? '' : match);
        elm.value = val.toString();
        //console.log("setValue", val, Number(elm.value), $event);
    }

    inputBlur(reg, f, $event) {
        // console.log("inputBlur", $event.target.value);
        this.rowData.setFieldValue(reg, f, Number($event.target.value), "MANUAL:");
        const currentMD5 = H.getMd5(JSON.stringify(this.rowData.data));
        // console.log("inputBlur", currentMD5);
        if (currentMD5 !== this.prevDataMd5) {
            this.rowData.calcRow(this.regs, this.site.selectedPdm);
        }
        this.prevDataMd5 = currentMD5;
    }

    trackByFn(index, reg) {
        return reg.num;
    }

    ngOnInit() {
        // console.log("ngOnInit()", this.index);
    }

    ngAfterViewInit(): void {
        //   console.log("ngAfterViewInit()", this.index);
        this.loaded = true;
        this.adaptCellsStyling();
    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log("ngOnChanges()", this.index, changes);
        setTimeout(() => {
            if (this.loaded)
                this.adaptCellsStyling();
            this.dateDayName = H.getDayName(this.rowData.date).replace(".", "");
        }, 50);

    }

}

import {AfterViewInit, Component, Input} from "@angular/core";
import {SiteService} from "../../shared/services/site.service";
import {H} from "../../shared/helpers/H";

@Component({
    selector: 'table-page',
    template: `
        <div class="proto-table-bloc">
            <table class="proto-table">
                <tr *ngIf="data[0]">
                    <td class="date-cell"
                        *ngFor="let dt of data[0].data[0].dateStamps;let c=index">
                        {{formatDate(dt)}}
                    </td>
                    <td></td>
                    <td class="cell-head"> Dates</td>
                </tr>
                <tr *ngIf="data[0]">
                    <td class="date-cell"
                        *ngFor="let dt of data[0].meta['main-x-axis'].x;let c=index">
                        {{dt}}
                    </td>
                    <td></td>
                    <td class="cell-head"> Semaine N°</td>
                </tr>
                <tr *ngFor="let row of data[0].data;let r=index">

                    <td class="value-cell" *ngFor="let cell of row.dateStamps;let c=index" [matTooltip]="row.unit">
                        {{row.y[c] |currency:' '}}
                    </td>
                    <td class="cell-unit">  {{row.unit}}  </td>
                    <td class="cell-head" [colSpan]="!row.unit?13:1">
                        {{row.name}}
                    </td>
                </tr>
            </table>
        </div>
    `,

    styleUrls: ['./proto-hebdo.component.scss']
})
export class PageTableComponent implements AfterViewInit {
    @Input('data') data: any;

    constructor(public site: SiteService) {
    }

    formatDate(dt) {
        return H.dateToStr(new Date(dt), true, true);
    }

    ngAfterViewInit(): void {

        console.log("TABLE", this.data);
    }

}

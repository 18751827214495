import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {BaseComponent} from "../../shared/BaseComponent";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AppService} from "../../shared/services/app.service";
import {SiteService} from "../../shared/services/site.service";
import {AppConfirmService} from "../../shared/services/app-confirm/app-confirm.service";
import {BmensItem, BmensMeter} from "../../shared/models/Bmens.model";
import {EnergyAgent, EnergyStats, EnergyStatsItem, EnergyStatsProduction, Metric} from "app/shared/models/EnergyStats.model";
import {H} from "../../shared/helpers/H";
import {ClientSite, SiteTariffConfig, SiteTariffData} from "../../shared/models/models";
import {Action} from "../../shared/models/Action.model";
import {Observable} from "rxjs";

@Component({
    selector: 'bloc-energy-stats',
    template: `
        <div fxLayout="row" class="full-width">
            <div fxLayout="column" fxFlex="1080px">
                <div class="bloc-year-selector" fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxFlex (dblclick)="myapp.showConsole(site.clientSite.old_energy_stats_map)">
                        <h2 *ngIf="selectedEnergyStatsYearData">
                            Conso. d'énergie et d'eau {{ selectedEnergyStatsYearData.year }}
                        </h2>
                        <h2 *ngIf="!selectedEnergyStatsYearData">Edition des tarifs</h2>
                    </div>
                    <!-- LEGACY to remove -->
                    <!--
                    <div class="p-4" fxLayout="row" fxLayoutAlign="space-between center">
                        <input #yearToAdd type="number" class="year-to-add" placeholder="Année"/>
                        <button mat-raised-button class="btn-xs-25"
                                [disabled]="yearToAdd.valueAsNumber<1900||yearToAdd.valueAsNumber>2030"
                                (click)="addYear(yearToAdd.value)">Ajouter année
                        </button>
                    </div>
                   
                    <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                        <button mat-raised-button class="m-4 ml-0 btn-xs-25" color="primary"
                                [ngClass]="{active:this.selectedEnergyStatsYearData&&this.selectedEnergyStatsYearData.year==yearsKv.key}"
                                *ngFor="let yearsKv of site.clientSite.old_energy_stats_map|keyvalue"
                                (click)="selectEnergyStatsYear(yearsKv.key)">
                            <span>{{ yearsKv.key }}</span>
                            <span *ngIf="yearsKv.value.default">(Réf)</span>
                        </button>
                        <button mat-raised-button class="m-4 ml-16 btn-xs-25"
                                [class.active]="!selectedEnergyStatsYearData"
                                (click)="selectEnergyStatsYear('')">
                            <span>Configuration</span>
                        </button>
                    </div> 
                    -->
                </div>
                <!-- LEGACY to remove -->
                <!-- FORMS 
                <div class="energy-form-table-holder" *ngIf="selectedEnergyStatsYearData">

                    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-16 row-header">
                        <h2 (click)="myapp.showConsole(metricsArray)">Consommation</h2>

                        <mat-select (valueChange)="addRow('usage',$event)" placeholder="Ajouter un agent énergetique">
                            <mat-option [value]="metric" *ngFor="let metric of metricsArray">
                                {{ metric.clear_agent_metric }}
                            </mat-option>
                        </mat-select>

                    </div>

                    <mat-card *ngIf="selectedEnergyStatsYearData.usage">
                        <table class="energy-form-table">
                            <tr class="header-row">
                                <td class="icon"></td>
                                <td class="col1">Libellé</td>
                                <td class="auto"></td>
                                <td>Quantité</td>
                                <td>Coûts/an</td>
                                <td>Prix unit.</td>
                                <td>FP</td>
                                <td>CEGES</td>
                            </tr>

                            <tr *ngFor="let item of selectedEnergyStatsYearData.usage">

                                <td class="icon">
                                    <mat-icon (click)="delItem(item,'usage')" inline>close</mat-icon>
                                </td>

                                <td class="col1" [title]="item.clear_agent_metric">
                                    <input [(ngModel)]="item.label" type="text"/>
                                </td>
                                <td class="auto">
                                </td>
                                <td>
                                    <input [(ngModel)]="item.adapted_amount" (keyup)="onType($event,item,'amount')"
                                           type="number"/>
                                    <span class="unit">{{ item.unit_amount }}</span>
                                </td>
                                <td><input [(ngModel)]="item.cost" (keyup)="onType($event,item,'price')" type="number"/>
                                    Fr.
                                </td>
                                <td>
                                    <input title="Lecture seule" disabled [(ngModel)]="item.adapted_price"
                                           type="number"/>
                                    <span class="unit">{{ item.unit_price }}</span>
                                </td>
                                <td><input [(ngModel)]="item.fp" type="number"/></td>
                                <td><input [(ngModel)]="item.ceges" type="number"/>
                                    <span class="unit">x10^-9</span>
                                </td>
                            </tr>
                        </table>
                    </mat-card>

                    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-16 row-header">
                        <h2 fxFlex>Achats</h2>

                        <mat-select (valueChange)="addRow('purchase',$event)"
                                    placeholder="Ajouter un agent énergetique">
                            <mat-option [value]="metric" *ngFor="let metric of metricsArray">
                                {{ metric.clear_agent_metric }}
                            </mat-option>
                        </mat-select>

                    </div>

                    <mat-card *ngIf="selectedEnergyStatsYearData.purchase">
                        <table class="energy-form-table" *ngIf="selectedEnergyStatsYearData.purchase">
                            <tr class="header-row">

                                <td class="icon"></td>

                                <td class="col1">Agent énergétique</td>
                                <td class="auto"></td>
                                <td>Quantité</td>
                                <td>Coûts/an</td>
                                <td>Prix unitaire</td>
                            </tr>
                            <tr *ngFor="let item of selectedEnergyStatsYearData.purchase">

                                <td class="icon">
                                    <mat-icon (click)="delItem(item,'purchase')" inline>close</mat-icon>
                                </td>

                                <td class="col1" [title]="item.clear_agent_metric">
                                    <input [(ngModel)]="item.label" type="text"/>
                                </td>
                                <td class="auto">
                                </td>
                                <td>
                                    <input [(ngModel)]="item.adapted_amount" (keyup)="onType($event,item,'amount')"
                                           type="number"/>
                                    <span class="unit">{{ item.unit_amount }}</span>
                                </td>
                                <td><input [(ngModel)]="item.cost" (keyup)="onType($event,item,'price')" type="number"/>
                                    Fr.
                                </td>
                                <td>
                                    <input title="Lecture seule" disabled mask="separator.2"
                                           [(ngModel)]="item.adapted_price" type="number"/>
                                    <span class="unit">{{ item.unit_price }}</span>
                                </td>
                            </tr>
                        </table>
                    </mat-card>

                    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-16 row-header">
                        <h2 fxFlex>Production</h2>
                        <button mat-raised-button class="btn-xs-25" (click)="addProductionRow()">Ajouter production
                        </button>
                    </div>

                    <mat-card *ngIf="selectedEnergyStatsYearData.production">
                        <table class="energy-form-table" *ngIf="selectedEnergyStatsYearData.production">
                            <tr class="header-row">
                                <td class="icon"></td>
                                <td class="col1">Type de production</td>
                                <td class="auto"></td>
                                <td>Quantité</td>
                                <td>efficacité</td>
                                <td>Autoconsommé</td>
                            </tr>
                            <tr *ngFor="let item of selectedEnergyStatsYearData.production">
                                <td class="icon">
                                    <mat-icon (click)="delItem(item,'production')" inline>close</mat-icon>
                                </td>
                                <td class="col1">
                                    <input [(ngModel)]="item.name" type="text"/>
                                </td>
                                <td class="auto"></td>
                                <td>
                                    <input [(ngModel)]="item.amount" type="number"/>
                                    <input style="width: 35px" [(ngModel)]="item.unit" type="text"/>
                                </td>
                                <td><input (keyup)="onTypeProduction($event,item,'efficiency')"
                                           [(ngModel)]="item.efficiency_percent" type="number"/> %
                                </td>
                                <td><input (keyup)="onTypeProduction($event,item,'selfConsumption')"
                                           [(ngModel)]="item.selfConsumption_percent" type="number"/> %
                                </td>
                            </tr>
                        </table>
                    </mat-card>

                </div>-->

                <!--- TARIFF CONFIGS -->
                <div class="tariff-config-bloc" *ngIf="!selectedEnergyStatsYearData">

                    <div class="tariff-config-intro">
                        Configuration globale des tarifs pour ce batiment. Les valeurs numériques de consommation et de
                        couts seront saisies par année.
                    </div>

                    <!--- Table tariff configs -->
                    <table>
                        <tr class="head">
                            <td style="width: 30px"> Hid</td>
                            <td style="width: 300px"> Libellé</td>
                            <td>Prod</td>
                            <td>Fluide</td>
                            <td>Agent</td>
                            <td>Unité</td>
                            <td style="width: 50px"> Début</td>
                            <td style="width: 50px"> Fin</td>
                            <td style="width: 120px"> Lieu</td>
                            <td style="width: 50px"> Ordre</td>
                            <td style="width: 50px"></td>
                        </tr>
                        <tr class="tarif-form-row" *ngFor="let tarif of site.clientSite.tariff_config"
                            [class.active]="selectedTariff&&selectedTariff.uid===tarif.uid">
                            <td>
                                <mat-checkbox [(ngModel)]="tarif.hidden"></mat-checkbox>
                            </td>
                            <td>
                                <input matInput [(ngModel)]="tarif.label" type="text" placeholder="Libellé"
                                       class="large-input">
                            </td>
                            <td>
                                <mat-checkbox [(ngModel)]="tarif.is_prod"></mat-checkbox>
                            </td>
                            <td>
                                <mat-select [(ngModel)]="tarif.fluid" placeholder="Fluide">
                                    <mat-option [value]="fl" *ngFor="let fl of myapp.k.fluids">
                                        {{ fl }}
                                    </mat-option>
                                </mat-select>
                            </td>
                            <td>
                                <mat-select [(ngModel)]="tarif.agent">
                                    <mat-option [value]="agent.key"
                                                *ngFor="let agent of myapp.k.EnergyAgentsDisplayNames|keyvalue">
                                        {{ agent.value }}
                                    </mat-option>
                                </mat-select>
                            </td>
                            <td>
                                <mat-select [(ngModel)]="tarif.unit">
                                    <mat-option [value]="unit.key"
                                                *ngFor="let unit of myapp.k.unitDisplayName|keyvalue">
                                        {{ unit.value }}
                                    </mat-option>
                                </mat-select>
                            </td>
                            <td>
                                <input matInput [(ngModel)]="tarif.year_start" type="number" placeholder="année début"
                                       class="small-input">
                            </td>
                            <td>
                                <input matInput [(ngModel)]="tarif.year_end" type="number" placeholder="Année fin"
                                       class="small-input">
                            </td>
                            <td>
                                <input matInput [(ngModel)]="tarif.lieu" type="text" placeholder="Lieu">
                            </td>
                            <td>
                                <input matInput [(ngModel)]="tarif.disp_order" type="number" placeholder="Ordre"
                                       class="small-input">
                            </td>
                            <td>
                                <button mat-raised-button (click)="showTarifData(tarif)" class="btn-xs-20 mr-4">
                                    Evolution
                                </button>
                            </td>
                        </tr>
                    </table>

                    <!--- buttons row 1 -->
                    <div fxLayout="row" class="group-form-row footer p-4">
                        <button (click)="addTarif()" mat-raised-button color="default" class="btn-xs-25 pl-4 m-4">
                            Nouveau tarif
                        </button>
                        <span fxFlex></span>
                        <button (click)="saveTarifsConfig()" mat-raised-button color="primary" class="btn-xs-25 m-4">
                            Sauvegarder la configuration
                        </button>
                    </div>

                    <!--- TARIFF VALUES  -->
                    <div *ngIf="selectedTariff">
                        <div class="p-8" fxLayout="row">
                            <div *ngIf="tariffRelatedActions.length===0" class=" " fxFlex="50">
                                <button mat-raised-button (click)="deleteTariff()" class="btn-xs-20 mr-4" color="accent">
                                    Supprimer le compteur
                                </button>
                                <span>Pas d'APE liées</span>
                            </div>
                            <div *ngIf="tariffRelatedActions.length>0" class="bloc-related-actions p-8">
                                <label>APE liées</label>
                                <span *ngFor="let act of tariffRelatedActions">{{ act.num }}</span>
                            </div>

                            <div class="bloc-related-actions p-8" fxFlex fxLayoutAlign="end center">
                                <label>Compteur Bmens liée</label>
                                <mat-select [(ngModel)]="selectedTariff.uid_meter" class="select-group" placeholder="Selectionner SVP">
                                    <ng-container *ngFor="let meter of bmensMeters">
                                        <mat-option *ngIf="selectedTariff.fluid===meter.fluid"
                                                    [value]="meter.uid" ngDefaultControl>{{ meter.label }}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </div>
                        </div>
                        <table>
                            <ng-container *ngIf="selectedTariff.is_prod===1">
                                <tr>
                                    <td colspan="10" class="subcateg">
                                        <div>
                                            <span fxFlex>Production</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="head-data">
                                    <td style="text-align: left">Année</td>
                                    <td style="width: 80px"> Production</td>
                                    <td></td>
                                    <td style="width: 80px"> AutoConso</td>
                                    <td></td>
                                    <td style="width: 80px"> Excédentaire</td>
                                    <td></td>
                                    <td style="width: 80px"> Efficacité.</td>
                                    <td></td>
                                    <td>m²</td>
                                </tr>

                                <ng-container class="tarif-form-row" *ngFor="let prod of site.clientSite.production_map|keyvalue">
                                    <tr class="tarif-form-row" *ngIf="prod.value.uid_tarif===selectedTariff.uid">
                                        <td class="tariff-data-year">
                                            {{ prod.value.year }}
                                        </td>
                                        <td>
                                            <input matInput [(ngModel)]="prod.value.prod" type="number"
                                                   (keyup)="onType($event,prod.value,'prod','production')"
                                                   placeholder="Consommation" class="medium-input">
                                        </td>
                                        <td class="unit">
                                            {{ selectedTariff.unitStr }}
                                        </td>
                                        <td>
                                            <input matInput [(ngModel)]="prod.value.energy" type="text"
                                                   placeholder="Libellé" class="medium-input">
                                        </td>
                                        <td class="unit">
                                            {{ selectedTariff.unitStr }}
                                        </td>
                                        <td>
                                            <input matInput [(ngModel)]="prod.value.excedentaire" type="text"
                                                   placeholder="Excédentaire" class="medium-input">
                                        </td>
                                        <td class="unit">
                                            {{ selectedTariff.unitStr }}
                                        </td>
                                        <td>
                                            <input matInput [(ngModel)]="prod.value.efficiency" type="number"
                                                   placeholder="Efficacité" class="medium-input">
                                        </td>
                                        <td class="unit">
                                            %
                                        </td>
                                        <td>
                                            <input matInput [(ngModel)]="prod.value.arg" type="number"
                                                   placeholder="Param de prod." class="medium-input">
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>

                            <tr>
                                <td colspan="10" class="subcateg">
                                    <div><span fxFlex>Consommation</span></div>
                                </td>
                            </tr>
                            <tr class="head-data">
                                <td style="text-align: left">Année</td>
                                <td style="width: 80px"> Conso</td>
                                <td></td>
                                <td style="width: 80px"> Cout</td>
                                <td></td>
                                <td style="width: 80px"> Prix unit.</td>
                                <td></td>
                                <td style="width: 50px"> CEGES</td>
                                <td></td>
                                <td style="width: 50px"> FP</td>
                            </tr>

                            <!--- conso rows  -->
                            <ng-container class="tarif-form-row" *ngFor="let usage of site.clientSite.usage_map|keyvalue">
                                <tr class="tarif-form-row" *ngIf="usage.value.uid_tarif===selectedTariff.uid">
                                    <td class="tariff-data-year">
                                        {{ usage.value.year }}
                                        <button mat-raised-button class="btn-xs ml-8"
                                                *ngIf="usage.value.year>selectedTariff.year_end||usage.value.year<selectedTariff.year_start"
                                                (click)="deleteTariffDataItem('usage',usage.value)">Supprimer
                                        </button>
                                    </td>
                                    <td>
                                        <input matInput [(ngModel)]="usage.value.energyAdapted" type="number"
                                               [disabled]="selectedTariff.is_prod===1"
                                               (keyup)="onType($event,usage.value,'energy','usage')"
                                               placeholder="Consommation" class="medium-input">
                                    </td>
                                    <td class="unit">
                                        {{ selectedTariff.unitStr }}
                                    </td>
                                    <td>
                                        <input matInput [(ngModel)]="usage.value.cost" type="number"
                                               [disabled]="selectedTariff.is_prod===1"
                                               (keyup)="onType($event,usage.value,'cost','usage')"
                                               placeholder="Coût" class="medium-input">
                                    </td>
                                    <td class="unit">
                                        Fr.
                                    </td>
                                    <td>
                                        <input matInput [(ngModel)]="usage.value.priceAdapted" type="number"
                                               disabled placeholder="Prix unitaire" class="medium-input">
                                    </td>
                                    <td class="unit">
                                        <ng-container *ngIf="selectedTariff.unit">
                                            {{ selectedTariff.priceUnitStr }}
                                        </ng-container>
                                        <ng-container *ngIf="!selectedTariff.unit">
                                            <span [matTooltip]="'Saisir unité du tarif'">ERR</span>
                                        </ng-container>
                                    </td>
                                    <td>
                                        <input matInput [(ngModel)]="usage.value.ceges" type="number"
                                               placeholder="CEGES" class="medium-input">
                                    </td>
                                    <td class="unit"> x10^-9</td>
                                    <td>
                                        <input matInput [(ngModel)]="usage.value.fp" type="number" placeholder="FP"
                                               class="small-input">
                                    </td>
                                </tr>
                            </ng-container>

                            <tr>
                                <td colspan="10" class="subcateg">
                                    <div><span fxFlex>Achats</span></div>
                                </td>
                            </tr>

                            <tr class="head-data">
                                <td style="text-align: left">Année</td>
                                <td style="width: 80px">Conso</td>
                                <td></td>
                                <td style="width: 80px">Cout</td>
                                <td></td>
                                <td style="width: 80px">Prix unit.</td>
                                <td></td>
                                <td style="width: 50px">CEGES</td>
                                <td></td>
                                <td style="width: 50px">FP</td>
                            </tr>
                            <!--- purchase rows VALUES  -->
                            <ng-container class="tarif-form-row" *ngFor="let purchase of site.clientSite.purchase_map|keyvalue">
                                <tr class="tarif-form-row" *ngIf="purchase.value.uid_tarif===selectedTariff.uid">
                                    <td class="tariff-data-year">
                                        {{ purchase.value.year }}
                                        <button mat-raised-button class="btn-xs ml-8"
                                                *ngIf="purchase.value.year>selectedTariff.year_end||purchase.value.year<selectedTariff.year_start"
                                                (click)="deleteTariffDataItem('purchase',purchase.value)">Supprimer
                                        </button>
                                    </td>
                                    <td>
                                        <input matInput [(ngModel)]="purchase.value.energyAdapted" type="number"
                                               [disabled]="selectedTariff.is_prod!==1"
                                               (keyup)="onType($event,purchase.value,'energy','purchase')"
                                               placeholder="Achat" class="medium-input">
                                    </td>
                                    <td class="unit">
                                        {{ selectedTariff.unitStr }}
                                    </td>
                                    <td>
                                        <input matInput [(ngModel)]="purchase.value.cost" type="number"
                                               [disabled]="selectedTariff.is_prod!==1"
                                               (keyup)="onType($event,purchase.value,'cost','purchase')"
                                               placeholder="Coût" class="medium-input">
                                    </td>
                                    <td class="unit">
                                        Fr.
                                    </td>
                                    <td>
                                        <input matInput [(ngModel)]="purchase.value.priceAdapted" type="number" disabled
                                               placeholder="Prix unitaire" class="medium-input">
                                    </td>
                                    <td class="unit">
                                        {{ selectedTariff.priceUnitStr }}
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </ng-container>

                            <tr>
                                <td colspan="10" class="footer">
                                    <div>
                                        <span fxFlex></span>
                                        <button (click)="saveTarifsData()" mat-raised-button color="primary"
                                                class="btn-xs-25 m-4">
                                            Sauvegarder les données annuelles
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

            </div>

            <!--- DEFAULT YEARs CONFIGS -->
            <div fxLayout="column" class="p-8 energystats-config">
                <h2>Configuration globale</h2>
                <div fxLayout="row">
                    <label fxFlex="200px">Année de réf tarifaire:</label>
                    <mat-select placeholder="Unité Y" class="select-unit"
                                [value]="site.clientSite.year_tariff_default">
                        <mat-option [value]="y" *ngFor="let y of availableYears" (click)="setYear('defaultTariff',y )">
                            {{ y }}
                        </mat-option>
                    </mat-select>
                </div>

                <div fxLayout="row">
                    <label fxFlex="200px">Année de cons affichée:</label>
                    <mat-select placeholder="Année affichées" class="select-unit"
                                [value]="site.clientSite.year_tariff_display">
                        <mat-option [value]="y" *ngFor="let y of availableYears" (click)="setYear('display',y )">
                            {{ y }}
                        </mat-option>
                    </mat-select>
                </div>

            </div>
        </div>

    `,
    styleUrls: ['./site-home.component.scss']
})

export class BlocEnergyStatsComponent extends BaseComponent implements AfterViewInit, OnInit, OnChanges {
    @Input('forceUpdate') forceUpdate: number;
    selectedTariff: SiteTariffConfig;

    selectedEnergyStatsYearData: EnergyStats;
    lastBmensItem: BmensItem = null;
    lastBmensItemRows: any[] = [];
    totAdaptedAmount = 0;
    amountUnit = '';
    totCost = 0;

    bmensMeters: BmensMeter[] = [];
    bmensMetersMap: Map<string, BmensMeter> = new Map<string, BmensMeter>();//for convenience, getById like

    metricsArray: EnergyAgent[] = [];
    availableYears: number[] = [];

    tariffRelatedActions: Action[] = [];

    constructor(
        public myapp: AppService,
        public site: SiteService,
        public confirmService: AppConfirmService,
        private fb: FormBuilder,
    ) {
        super();
    }


    ngOnInit() {
        this.site.siteLoadingStatus.subscribe(eventName => {
            if (eventName === SiteService.MAX_SITE_LOADING_STATUS) {
                this.run();
            }
        });
    }

    run() {
        this.site.api.getBmensMeters(this.site.uid).subscribe(resp => {
            if (resp && resp.body && resp.body.length > 0) {
                resp.body.forEach(it => {
                    const meter = new BmensMeter(it);
                    if (meter.is_in_bmens) {
                        this.bmensMeters.push(meter);
                        this.bmensMetersMap.set(meter.uid, meter);
                    }
                });
                this.bmensMeters = this.bmensMeters.sort((a, b) => a.disp_order - b.disp_order)

            }
        });

        this.metricsArray = Array.from(this.site.energyAgentsMap.values());
        this.metricsArray = this.metricsArray.sort((a, b) => {
            return a.fluid.localeCompare(b.fluid);
        });

        this.totAdaptedAmount = 0;
        this.totCost = 0;
        if (this.site.clientSite.tariff_config) {
            this.site.clientSite.tariff_config.forEach(conf => {
                for (let y = conf.year_start; y <= conf.year_end; y++) this.availableYears.push(y);
            });
        }

        this.availableYears = H.arrayUnique(this.availableYears).sort();

    }

    loadData() {
        this.site.api.getBalanceSheets(this.site.uid).subscribe(resp => {
            if (resp.body) {
                if (resp.body.length > 0) {
                    this.lastBmensItem = new BmensItem(resp.body[resp.body.length - 1]);
                    const groupConfig = this.lastBmensItem.group_config.find(gr => gr.label.toLowerCase() === 'total');
                    if (groupConfig) {
                        this.lastBmensItemRows = this.lastBmensItem.rows.filter(row => row.group === groupConfig.id);
                    }
                }
            }
        });
    }

    showTarifData(tarif: SiteTariffConfig) {
        this.tariffRelatedActions = [];
        this.selectedTariff = tarif;
        const yearsAv = {cons: [], purs: [], prod: []};
        this.availableYears.forEach(y => {
            if (y >= tarif.year_start && y <= tarif.year_end) {
                if (this.site.clientSite.usage_map.has(y + '-' + this.selectedTariff.uid))
                    yearsAv.cons.push(y);
                if (this.site.clientSite.purchase_map.has(y + '-' + this.selectedTariff.uid))
                    yearsAv.purs.push(y);
                if (this.site.clientSite.production_map.has(y + '-' + this.selectedTariff.uid))
                    yearsAv.prod.push(y);
            }
        });
        this.site.actions.forEach(act => {
            act.economies.forEach(econ => {
                if (econ.uid_tariff === tarif.uid) {
                    this.tariffRelatedActions.push(act);
                    // console.log("APE: ", act.num, econ);
                }

            });
        });
        //console.log("Check cons for", yearsAv, this.tariffRelatedActions);
    }

    deleteTariffDataItem(valueType: string, tariffDataItem: SiteTariffData) {
        console.log("deleteTariffDataItem", valueType, tariffDataItem);
        if (valueType === "usage") {
            Object.keys(this.site.clientSite.energy_usage).forEach(key => {
                if (key === tariffDataItem.uid) {
                    delete this.site.clientSite.energy_usage[key];
                    this.site.clientSite.usage_map.delete(key);
                    this.saveSite(true);
                    this.myapp.showError('Suppression consommation de ' + key)
                }
            });
        }
        if (valueType === "purchase") {
            Object.keys(this.site.clientSite.energy_purchase).forEach(key => {
                if (key === tariffDataItem.uid) {
                    delete this.site.clientSite.energy_purchase[key];
                    this.site.clientSite.purchase_map.delete(key);
                    this.saveSite(true);
                    this.myapp.showError('Suppression achat de ' + key)
                }
            });
        }
    }

    deleteTariff() {
        this.site.clientSite.tariff_config = this.site.clientSite.tariff_config
            .filter(t => t.uid !== this.selectedTariff.uid);
        console.log("deleteTariff()", this.site.clientSite.energy_usage, this.selectedTariff);

        Object.keys(this.site.clientSite.energy_usage).forEach(key => {
            if (key.includes(this.selectedTariff.uid)) {
                console.log("Deleting energy_usage: ", key);
                delete this.site.clientSite.energy_usage[key];
            }
        });
        Object.keys(this.site.clientSite.energy_purchase).forEach(key => {
            if (key.includes(this.selectedTariff.uid)) {
                console.log("Deleting purchase_map: ", key);
                delete this.site.clientSite.energy_purchase[key];
            }
        });
        if (this.site.clientSite.energy_production)
            Object.keys(this.site.clientSite.energy_production).forEach(key => {
                if (key.includes(this.selectedTariff.uid)) {
                    console.log("Deleting production_map: ", key);
                    delete this.site.clientSite.energy_production[key];
                }
            });
        this.saveSite(true);
    }

    addRow(section, agentObj: EnergyAgent) {
        console.log("addRow", section, agentObj);
        const newline = new EnergyStatsItem({
            uid: H.randomStr(30),
            clear_agent_metric: agentObj.clear_agent_metric,
            agent_metric: agentObj.agent_metric,
            label: agentObj.clear_agent_metric.replaceAll('-', ' ').toUpperCase(),
            adapted_amount: 0,
            adapted_price: 0,
            amount: 0,
            ceges: 0,
            cost: 0,
            fp: this.myapp.k.EnergyAgentsFactors[agentObj.agent] ? this.myapp.k.EnergyAgentsFactors[agentObj.agent] : 1,
            price: 0,
            unit_amount: this.myapp.k.metricUnit[agentObj.unit],
            unit_price: this.myapp.k.getDefaultCostUnits(agentObj.unit),
        });

        if (!this.selectedEnergyStatsYearData[section]) this.selectedEnergyStatsYearData[section] = [];
        this.selectedEnergyStatsYearData[section].push(newline);
    }

    addProductionRow() {
        const newline = new EnergyStatsProduction({
            uid: H.randomStr(30),
            name: 'Nouvelle prod'
        });
        if (!this.selectedEnergyStatsYearData['production']) this.selectedEnergyStatsYearData['production'] = [];
        this.selectedEnergyStatsYearData['production'].push(newline);
    }

    onTypeProduction(ev, item, field) {
        const currValue = ev.target.valueAsNumber;
        item[field] = currValue / 100;
        console.log("onTypeProduction", currValue, item);
    }

    onType(ev, item: SiteTariffData, changingSource, field) {
        // changingSource=  energy od cost
        // field=           prod od usage or purchase
        console.log('onType', item, changingSource, field);
        const currValue = ev.target.valueAsNumber;
        let factor1 = 1e6;
        if (item.unit === 'volume') {
            factor1 = 1;
        }

        // cost price energy | cost = energy x price
        if (changingSource === 'energy') {
            item.price = item.cost / (currValue * factor1);
        }
        if (changingSource === 'cost') {
            item.price = currValue / item.energy;
        }
        if (field === 'usage') {
            if (!this.selectedTariff.is_prod) {
                const purchaseParallelItem = this.site.clientSite.purchase_map.get(item.uid);
                if (purchaseParallelItem) {
                    purchaseParallelItem.energy = item.energy;
                    purchaseParallelItem.price = item.price;
                    purchaseParallelItem.cost = item.cost;
                    this.site.clientSite.purchase_map.set(item.uid, purchaseParallelItem);
                }
            } else {

            }
        }
        if (changingSource === 'prod') {
            const usageParallelItem = this.site.clientSite.usage_map.get(item.uid);
            const purchaseParallelItem = this.site.clientSite.purchase_map.get(item.uid);
            if (usageParallelItem && purchaseParallelItem) {
                usageParallelItem.energy = purchaseParallelItem.energy + item.prod * factor1;
                usageParallelItem.price = usageParallelItem.cost / usageParallelItem.energy;
                this.site.clientSite.usage_map.set(item.uid, usageParallelItem);
            }
        }
        if (field === 'purchase') {
            /// TOnly if production to production
            if (this.selectedTariff.is_prod) {
                console.log("Updating purchase as um prod+purchase");
                const usageParallelItem = this.site.clientSite.usage_map.get(item.uid);
                const prodParrallelItem = this.site.clientSite.production_map.get(item.uid);
                if (changingSource === 'cost') {
                    usageParallelItem.cost = item.cost;
                    usageParallelItem.price = usageParallelItem.cost / usageParallelItem.energy;
                }
                if (changingSource === 'energy') {
                    if (usageParallelItem && prodParrallelItem) {
                        usageParallelItem.energy = (currValue * factor1) + prodParrallelItem.prod * factor1;
                        usageParallelItem.price = usageParallelItem.cost / usageParallelItem.energy;
                        this.site.clientSite.usage_map.set(item.uid, usageParallelItem);
                    }
                }

            }
        }

    }

    setYear(type: string, year: number) {
        if (type === 'defaultTariff') this.site.clientSite.year_tariff_default = year;
        if (type === 'display') this.site.clientSite.year_tariff_display = year;

        this.saveSite();
    }

    saveSite(withEnergyData = false) {
        const dataToSend = {};
        dataToSend['tariff_config'] = this.site.clientSite.tariff_config;
        dataToSend['year_tariff_default'] = this.site.clientSite.year_tariff_default;
        dataToSend['year_tariff_display'] = this.site.clientSite.year_tariff_display;
        if (withEnergyData) {
            dataToSend['energy_usage'] = this.site.clientSite.energy_usage;
            dataToSend['energy_purchase'] = this.site.clientSite.energy_purchase;
            dataToSend['energy_production'] = this.site.clientSite.energy_production;
        }

        console.log('saveSite', dataToSend);
        //****
        this.site.api.updateSite(dataToSend, this.site.clientSite.uid).subscribe(resp => {
            if (resp.status === 1) {
                console.log('savedSite - Updated', resp.body);
                Object.assign(this.site.clientSite, resp.body);
                this.site.clientSite = new ClientSite(resp.body);
                this.myapp.toastr.success("Données sauvegardées", "Succès", {timeOut: 2000});
            } else
                console.error('updateSite status 0', resp);
        });
    }

    addTarif() {
        const tarifObj = new SiteTariffConfig();
        tarifObj.uid = H.randomStr(30);
        tarifObj.year_start = 2023;
        tarifObj.year_end = 2024;
        tarifObj.ts = H.unixTs();
        tarifObj.label = 'Nouveau';
        tarifObj.unit = 'energy';
        if (!this.site.clientSite.tariff_config) this.site.clientSite.tariff_config = [];
        this.site.clientSite.tariff_config.push(tarifObj);
        this.saveSite();
    }

    saveTarifsConfig() {
        if (!this.site.clientSite.tariff_config || !Array.isArray(this.site.clientSite.tariff_config))
            this.site.clientSite.tariff_config = [];
        this.site.clientSite.tariff_config = this.site.clientSite.tariff_config.map(it => {
            it.is_prod = it.is_prod ? 1 : 0;
            return it;
        });
        this.saveSite();
    }

    saveTarifsData(type: string = '') {
        // saving usage and puschase together
        /// TODO: do the same in other sections
        // correcting tariffData unit when saving if was changed after data input
        this.site.clientSite.usage_map.forEach((v, k) => {
            const parentTarif = this.site.clientSite.tariff_config_map.get(v.uid_tarif);
            if (parentTarif) {
                v.unit = parentTarif.unit;
                /*
                if (v.uid.includes("2000-")
                    || v.uid.includes("2016-")
                    || v.uid.includes("2017-")
                    || v.uid.includes("2018-")

                )
                    this.site.clientSite.usage_map.delete(k)
                else*/
                this.site.clientSite.usage_map.set(v.uid, v);
            }

        });

        // SAVING
        this.site.clientSite.energy_usage = H.customMapToObject(this.site.clientSite.usage_map);
        this.site.clientSite.energy_purchase = H.customMapToObject(this.site.clientSite.purchase_map);
        if (this.selectedTariff.is_prod === 1)
            this.site.clientSite.energy_production = H.customMapToObject(this.site.clientSite.production_map);

        console.log('Saving this.site.clientSite.energy_purchase::: ', this.selectedTariff.uid, this.site.clientSite.energy_purchase);

        this.saveSite(true);
    }

    bootstrap() {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.bootstrap();
    }

    ngAfterViewInit(): void {
    }
}

import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";

import {SiteService} from "../../shared/services/site.service";
import {
    Plot
} from "../../shared/models/models";
import {BaseComponent} from "../../shared/BaseComponent";
import {AppService} from "../../shared/services/app.service";
import {DomSanitizer} from "@angular/platform-browser";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'statmens-table',
    template: `
        <mat-card class="bloc-statmens-table m-8 mb-4 p-4" fxLayout="column">

            <h4>{{plot.id}}</h4>
            <div *ngFor="let serie of site.seriesData[plot.id]|keyvalue;let i=index" fxLayout="row">
                <div># {{serie.key}}</div>
                <div fxLayout="column">
                    <div class="row-head" *ngFor="let v of serie.value[0]|keyvalue;let i=index">{{v.key}}</div>
                </div>
                <div *ngFor="let pt of serie.value" fxLayout="column">
                    <div class="value" *ngFor="let v of pt|keyvalue;let i=index" [matTooltip]="v.key">
                        <span *ngIf="v.key!=='date'&&v.key!=='custom'&&!isArray(v.value)">{{v.value|number}}</span>
                        <span *ngIf="v.key==='date'||v.key==='custom'||isArray(v.value)">{{v.value}}</span>
                    </div>
                </div>
            </div>
        </mat-card>
    `,

    styleUrls: ['../../shared/components/shared-styles/shared.scss']
})
export class StatmensTableComponent extends BaseComponent implements AfterViewInit, OnInit, OnChanges {

    @Input('plot') plot: Plot;
    @Input('forceUpdate') forceUpdate: number;


    constructor(public myapp: AppService, public site: SiteService, private snackBar: MatSnackBar, public sanitizer: DomSanitizer) {
        super();
    }

    isArray(v) {
        return Array.isArray(v);
    }

    bootstrap() {

        // this.renderChart();
    }


    ngOnChanges(changes: SimpleChanges) {
        // console.log("StatmensTableComponent::ngOnChanges---------- ", changes, this.site.seriesData);
        this.bootstrap();
    }

    ngOnInit() {
        this.bootstrap();
    }


    ngAfterViewInit(): void {

    }

    openSnackBar() {
        this.snackBar.open('ok', null, {
            duration: 5 * 1000,
        });
    }

}

import {PdmSummary} from "./Pdm";
import {Duration} from 'luxon';
import moment, {Moment} from "moment";
import {H} from "../helpers/H";

export class ProtoReportJar {
    date_modified: string;
    date_created: string;
    date_accessed: string;
    ts_modified: number;
    ts_created: number;
    ts_accessed: number;
    size: number;
    sizeStr: string;
    regenUrl: string;
    mom: Moment;

    date_str_full: string;
    year: number;
    week: number;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
        this.sizeStr = this.bytes2Size();
        this.mom = moment.unix(this.ts_created);
        this.date_str_full = this.mom.format('DD MMMM YYYY HH:MM');
        this.week = this.mom.isoWeek();
        this.year = this.mom.year();
    }

    bytes2Size() {
        const units = ["Bytes", "KB", "MB", "GB", "TB"];
        const kb = 1024;
        let kounter = 0;
        let div = this.size / 1;
        while (div >= kb) {
            kounter++;
            div = div / kb;
        }
        return div.toFixed(1) + " " + units[kounter];
    }
}

export class ProtoReport {
    uid: string;
    date_str: string;//"2023-06-07"
    date_str_full: string;//"2023-06-07"

    pub_status: string;// READY, PENDING, PUB_OK
    pub_log: any;

    report_key: string;
    page_count: number;
    week: number;
    year: number;

    domain: string;
    domain_ref: string;

    date_create_str: string;// "2023-06-08 09:30:39"
    date_create_ts: number;
    date_ts: number;

    infos_google: any;
    infos_jar: any;
    infos_mongo: any;
    infos_ssd: any;
    infos_visuals: any;

    pages: any[] = [];
    mom: Moment;

    jarData: ProtoReportJar[];

    get isReady() {
        return this.pub_status && this.pub_status === 'READY';
    }

    constructor(dynProps: any = {}, jarData = null) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
        this.mom = moment(this.date_str, 'YYYY-MM-DD');
        this.date_str_full = this.mom.format('DD MMMM YY');
        this.week = this.mom.isoWeek();
        this.year = this.mom.year();
        if (jarData)
            this.jarData = jarData.map(it => new ProtoReportJar(it));
    }
}

export class ProtoReportRawPage {
    uid: string;
    page_key: string;
    report_key: string;
    domain_ref: string;
    report_dates: any;
    published_by_date: any;
    infos_by_date: any;
    shapes_by_date: any;
    last_pub_date: string;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
    }

    getPage(date: string): ProtoReportPage {
        return new ProtoReportPage(this, date);
    }
}

export class ProtoReportPage {
    uid: string;
    page_key: string;
    report_key: string;
    domain_ref: string;
    title: string;
    shape: any;
    position: number;
    level: number;
    mom: Moment;

    rawVisualsUrl: string;
    comments_total: number = 0;
    comments_pending: number = 0;
    title_chunks = [];
    title_last: string;

    constructor(rawPage: ProtoReportRawPage, date: string) {
        Object.keys(rawPage).forEach(key => this[key] = rawPage[key]);
        if (rawPage.infos_by_date && rawPage.infos_by_date[date]) {
            const infos = rawPage.infos_by_date[date];
            this.level = infos.level;
            this.position = infos.position;
            this.title = infos.title;
            this.shape = infos.shape;
        }
        this.mom = moment(date, 'YYYY-MM-DD');
    }
}

/*
Monitoring
 */
export class ReportPublishEvent {
    uid: string;
    subject: string;
    body: string;
    target: string[];
    user_metas: any;
    related_data: any;
    ts: number;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
    }
}

export class ReportPublishLog {
    pageCount: number;
    len: number;
    email: string;
    event: string;
    oldStatus: string;
    newStatus: string;
    ts: number;
    dateStr: string;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
        const mom = moment(this.ts);
        this.dateStr = mom.format("DD.MM.YYYY HH:mm");
    }
}

export class ReportPublish {
    uid: string;
    domain: string;
    domain_ref: string;
    report_key: string;

    date_str: string;//	"2024-04-03"
    date_create_str: string;//"2024-04-04 13:30:02"

    date_create_ts: number;
    date_ts: number;

    page_count: number;
    pub_status: string;
    ts_update: number;

    events: ReportPublishEvent[] = [];
    pub_log: any;
    _history: Map<number, ReportPublishLog> = new Map<number, ReportPublishLog>();
    _log = {};
    _log_final = {};//same as _log but adds user
    _momCreated: Moment;
    _momPublished: Moment;

    _year: number;
    _month: number;
    _week: number;
    _day: string;
    _dayNo: number;

    get dateTimeStr() {
        return H.ucfirst(this._momCreated.format("ddd d MMM hh:mm"))
    }

    get pubLogClear() {
        return Array.from(this._history.values());
    }

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
        if (this.pub_log) {
            Object.keys(this.pub_log).forEach(ts => {
                const obj: ReportPublishLog = new ReportPublishLog(this.pub_log[ts]);
                obj.ts = Number(ts);
                const dateStr = moment.unix(obj.ts).format('llll');
                const dateStrWeek = moment.unix(obj.ts).format('ddd mm:HH');
                if (obj.len) {
                    this._log['PENDING'] = obj.ts;
                    this._log_final['PENDING'] = {
                        date: dateStrWeek,
                        email: null,
                        first_name: null,
                        user: null
                    };
                    obj.event = "PUBLISH";
                } else {
                    this._log[obj.newStatus] = obj.ts;
                    this._log_final[obj.newStatus] = {
                        date: dateStrWeek, email: obj.email,
                        first_name: H.getFirstNameFromEmail(obj.email),
                        user: H.getInitialsFromEmail(obj.email)
                    };
                }
                this._history.set(obj.ts, obj);
            });
        }
        if (this.events) {
            this.events = this.events.map(it => new ReportPublishEvent(it));
            this.events.forEach(ev => {
                const dateStrWeek = moment.unix(ev.ts).format('ddd mm:HH');
                this._log['SHARED'] = ev.ts;
                this._log_final['SHARED'] = {
                    date: dateStrWeek, email: ev.user_metas.email,
                    first_name: H.getFirstNameFromEmail(ev.user_metas.email),
                    user: H.getInitialsFromEmail(ev.user_metas.email)
                };
            });
        }
        this._momCreated = moment.unix(this.date_create_ts);
        this._month = Number(this._momCreated.format('MM'));
        this._day = this._momCreated.format('dddd');
        this._dayNo = this._momCreated.day();
        this._year = this._momCreated.year();
        this._week = this._momCreated.week();
        this._momPublished = moment.unix(this.date_ts);
    }

    getMinutesOffset(eventLog: ReportPublishLog) {
        //       'READY'  'PENDING'   'PUB_OK'
        const eventOffset = eventLog.ts - this.date_create_ts;
        const durationLuxon = Duration.fromObject({seconds: eventOffset});
        const luxonObjShifting = durationLuxon.shiftTo("days", "hours", "minutes");
        let luxonObjReadable = luxonObjShifting.minutes.toFixed(0) + " min";
        if (luxonObjShifting.hours > 0) luxonObjReadable = luxonObjShifting.hours.toFixed(0) + 'h ' + luxonObjReadable
        if (luxonObjShifting.days > 0) luxonObjReadable = '<b>' + luxonObjShifting.days.toFixed(0) + ' j </b>' + luxonObjReadable

        let initials = '';
        let first_name = '';

        const email = this._history.get(eventLog.ts).email;
        if (email) {
            initials = H.getInitialsFromEmail(email);
            first_name = H.getFirstNameFromEmail(email);
        }
        let className = '';
        if (luxonObjShifting.days > 0) {
            className = "VERY_LATE";
        }
        if (luxonObjShifting.days > 8) {
            className = "LATE";
        }
        return {
            email,
            user: initials,
            first_name,
            label: luxonObjReadable,
            class: className,
            days: luxonObjShifting.days,
            hours: luxonObjShifting.hours
        };
    }
}

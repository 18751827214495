<div class="flex-container" fxLayout="column" style="height: 100%" #plotHolder>

    <div fxLayout="column" class="toolbar-top" style="height: auto"
         [@animate]="{ value: '*', params: { y: '-190px', delay: '300ms' } }">
        <h2 fxFlex class="ml-16">
            Bilan des économies et pertes énergétiques et financières

        </h2>
        <div fxLayout="row" style="height: 50px;position: relative;margin-bottom: -5px" fxFlexAlign="space-between end">

            <mat-form-field fxFlex="200px" style="height: 50px;margin: 0 15px " *ngIf="availableYears&&availableYears.length">
                <mat-select placeholder="Selectionner l'année" [value]="selectedYear">
                    <mat-option (click)="selectYear(y)" [value]="y" *ngFor="let y of availableYears">{{y}}</mat-option>
                </mat-select>
            </mat-form-field>

            <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                <div *ngIf="entryForYearAndDate" class="ref-year-div" [@animate]="{ value: '*', params: { y: '90px', delay: '100ms' } }">
                    Année de réference: <strong class="color-blue fw-500">{{entryForYearAndDate.year_ref}}</strong>
                </div>
                <div fxlex fxLayout="row" fxLayoutAlign="space-evenly center"
                     *ngIf="selectedGroup||(entryForYearAndDate&&entryForYearAndDate.justInserted)">
                    <button mat-raised-button (click)="selectedGroup=null">Annuler</button>
                    <button mat-raised-button color="primary" (click)="saveRow()">Sauvegarder</button>
                </div>
            </div>
            <div *ngIf="selectedYear" style="height: 40px;vertical-align: bottom;margin-top: 8px;">
                <button mat-button *ngFor="let item of availableMonthsForYear[selectedYear];" class="btn-tab"
                        [ngClass]="{active:selectedMonth===item.monthIndex}"
                        (click)="selectMonth(item)">
                    <mat-icon *ngIf="item.justInserted" inline style="color: red">warning</mat-icon>
                    {{item.dateStrShort}}
                </button>
            </div>

            <button mat-raised-button class="btn-xs-25"
                    *ngIf="latestBmensForYear&&latestBmensForYear.monthIndex<11"
                    (click)="addMonth()">
                <mat-icon inline>add</mat-icon>
            </button>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex="">
        <div fxLayout="row">
            <ng-container *ngIf="entryForYearAndDate">
                <div fxFlex="1230px" fxLayout="column" #form>
                    <mat-card class="p-4">
                        <table class="table-bmens">
                            <tr class="head">
                                <td class="col1">
                                    <span>Catégorie</span>
                                </td>
                                <td>
                                    <span>PDF</span>
                                </td>
                                <td>
                                    <span>Évolution conso. corrélée</span>
                                </td>
                                <td>
                                    <span>Évolution corr. en Fr.</span>
                                </td>
                                <td>
                                    <span>Cumul</span>
                                </td>
                                <td>
                                    <span>Coûts cumulés</span>
                                </td>
                                <td>
                                    <span>Tarif moy.</span>
                                </td>
                                <td>
                                    <span>Mois.année / ref.</span>
                                </td>
                            </tr>
                        </table>
                    </mat-card>
                    <ng-container *ngFor="let group of entryForYearAndDate.group_config">
                        <mat-card class="p-0">
                            <table class="table-bmens">
                                <!-- group header row -->
                                <tr>
                                    <td (click)="show(group)" class="group-row" colspan="8">
                                        <ng-container *ngIf="!selectedGroup||selectedGroup.id!==group.id">
                                            {{group.label}}
                                            <mat-icon inline class="cursor-pointer inline" (click)="selectedGroup=group">edit</mat-icon>
                                            <mat-icon inline class="cursor-pointer inline" (click)="addRow(group)">add</mat-icon>
                                        </ng-container>

                                        <ng-container *ngIf="selectedGroup&&selectedGroup.id===group.id">
                                            <div fxLayout="row">
                                                <div fxFlex>
                                                    <input style="width:300px" [(ngModel)]="group.label">
                                                    <label class="ml-32">Afficher total
                                                        <input type="checkbox" [(ngModel)]="group.showTotal">
                                                    </label>
                                                    <label class="ml-32">Afficher part
                                                        <input type="checkbox" [(ngModel)]="group.showPartColumn">
                                                    </label>
                                                    <mat-icon (click)="movegroup(group,'up')" class="cursor-pointer p-4" inline>keyboard_double_arrow_up
                                                    </mat-icon>
                                                    <mat-icon (click)="movegroup(group,'down')" class="cursor-pointer p-4" inline>keyboard_double_arrow_down
                                                    </mat-icon>
                                                </div>

                                                <select fxFlex="200px" [(ngModel)]="group.categ">
                                                    <option value="energy">Energie</option>
                                                    <option value="indice">Indices</option>
                                                    <option value="varexp">variable explicative</option>
                                                </select>

                                                <mat-icon (click)="selectedGroup=null" class="cursor-pointer p-4" inline>close</mat-icon>
                                            </div>

                                        </ng-container>
                                    </td>
                                </tr>

                                <ng-container *ngFor="let row of rowsGroupedMap.get(group.id)">
                                    <!-- value row -->
                                    <tr [ngClass]="{active:row.uid===rowId}" *ngIf="!selectedGroup||selectedRow!==row">
                                        <td class="col1 color-blue">
                                            {{row.label}}
                                            <mat-icon inline class="cursor-pointer inline" (click)="editRow(row,group)">edit</mat-icon>
                                        </td>
                                        <td fxLayout="row">
                                            <mat-icon *ngIf="row.file&&row.file.length"
                                                      (click)="openAttachment(row)" inline style="font-size: 20px">
                                                assessment
                                            </mat-icon>
                                            <mat-icon (click)="selectRow(row)" inline style="font-size: 20px">equalizer</mat-icon>
                                        </td>
                                        <td>
                                            <span class="p-0" *ngIf="row.economy_evol_value>0">+</span>
                                            <span>{{row.economy_evol_value}}</span>
                                            <span class="unit">{{row.energy_usage_unit}}</span>
                                            <ng-container *ngIf="row.economy_evol_percent">
                                                <span style="width: 30px">{{row.economy_evol_percent}}</span>
                                                <span>%</span>
                                            </ng-container>
                                        </td>
                                        <td>
                                            <span>{{row.cost_corr_evol}}</span>
                                            <span *ngIf="row.cost_corr_evol" class="unit">Fr. HTVA</span>
                                        </td>
                                        <td title="energy_usage and unit">
                                            <span>{{row.energy_usage}}</span>
                                            <span class="unit">{{row.energy_usage_unit}}</span>
                                        </td>
                                        <td>
                                            <span>{{row.energy_cost|number:'1.0'}}</span>
                                            <span *ngIf="row.energy_cost" class="unit">Fr.</span>
                                        </td>
                                        <td title="tariff">
                                            <span>{{row.tariff|number:'1.1-2'}}</span>
                                            <span *ngIf="row.tariff" class="unit">{{row.tariff_unit}}</span>
                                        </td>
                                        <td>
                                            <span>{{row.date_str}}</span>
                                        </td>
                                    </tr>
                                    <!-- form row -->
                                    <tr *ngIf="selectedGroup&&selectedRow===row" class="form-row-bmens">
                                        <td style="text-align: left">
                                            <input style="width:300px;margin-left: 15px" [(ngModel)]="row.label">
                                            <mat-icon inline class="cursor-pointer inline"
                                                      style="font-size: 20px"
                                                      (click)="delRow(row,group)">delete</mat-icon>
                                            <br>
                                            <span class="pl-16">Afficher dans: </span>
                                            <label class="ml-32">Bmens
                                                <input type="checkbox" [(ngModel)]="row.show_bmens">
                                            </label>
                                            <label class="ml-32">Suivi
                                                <input type="checkbox" [(ngModel)]="row.show_suivi">
                                            </label>
                                        </td>
                                        <td>
                                            <mat-icon inline style="font-size: 20px"> upload</mat-icon>
                                        </td>
                                        <td>
                                            <input [(ngModel)]="row.economy_evol_value">
                                            <input style="width: 30px;margin-left: 3px" [(ngModel)]="row.economy_evol_percent">
                                        </td>
                                        <td>
                                            <input [(ngModel)]="row.cost_corr_evol">
                                        </td>
                                        <td>
                                            <input style="width: 30px;margin-right: 3px" [(ngModel)]="row.energy_usage">
                                            <input style="width: 30px" [(ngModel)]="row.energy_usage_unit">
                                        </td>
                                        <td>
                                            <input [(ngModel)]="row.energy_cost" placeholder="index de fin">
                                        </td>
                                        <td>
                                            <input style="width: 30px;margin-right: 3px" [(ngModel)]="row.tariff">
                                            <input style="width: 30px" [(ngModel)]="row.tariff_unit">
                                        </td>
                                        <td>
                                            <input style="width: 90px" [(ngModel)]="row.date_str" placeholder="index de fin">
                                        </td>
                                    </tr>
                                </ng-container>

                                <!-- total row -->
                                <tr class="row-total" *ngIf="group.showTotal">
                                    <td class="col1 color-blue">

                                    </td>
                                    <td fxLayout="row">

                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                        <span class="p-0" *ngIf="rowsGroupedTotalMap.get(group.id).cost_corr_evol>0">+</span>
                                        <span>{{rowsGroupedTotalMap.get(group.id).cost_corr_evol|number:'1.0'}}</span>
                                        <span *ngIf="rowsGroupedTotalMap.get(group.id).cost_corr_evol" class="unit">Fr. HTVA</span>
                                    </td>

                                    <td title="energy_usage and unit">

                                    </td>
                                    <td>
                                        <span>{{rowsGroupedTotalMap.get(group.id).energy_cost|number:'1.0'}}</span>
                                        <span *ngIf="rowsGroupedTotalMap.get(group.id).energy_cost" class="unit">Fr.</span>
                                    </td>
                                    <td title="tariff">

                                    </td>
                                    <td>

                                    </td>
                                </tr>
                            </table>
                        </mat-card>
                    </ng-container>
                </div>

                <div fxFlex="1000px">

                    <mat-card class="p-8" *ngIf="entryForYearAndDate.notes&&!selectedGroup">
                        <div style="max-height: 800px; overflow: auto; ">
                            <markdown [data]="entryForYearAndDate.notes" [baseUrl]="'http://www.codap.ch'"></markdown>
                        </div>
                    </mat-card>
                    <mat-card class="p-4" *ngIf="selectedGroup">
                        <mat-form-field class="full-width p-4" appearance="fill">
                            <mat-label>Note pour le mois selectionné</mat-label>
                            <textarea matInput style="min-height: 250px"
                                      [(ngModel)]="entryForYearAndDate.notes"
                                      placeholder="Note pour le mois selectionné"></textarea>
                        </mat-form-field>

                    </mat-card>
                    <mat-card class="p-8" *ngIf="selectedRow">
                        <div class="mb-32" fxLayout="column" fxLayoutAlign="start stretch">
                            <h2>Récap graphique de l'année <b>{{selectedYear}}</b></h2>
                            <p>{{selectedRow.type}}->{{selectedRow.label}}</p>
                            <plotly-plot #plotUsage class="plotly-plot"
                                         [updateOnDataChange]="true"
                                         [updateOnLayoutChange]="true"
                                         [divId]="'plotUsage'"
                                         [data]="plotUsageData"
                                         [layout]="plotUsageLayout"
                                         [config]="myapp.k.defaultConfig">
                            </plotly-plot>
                            <plotly-plot #plotCost class="plotly-plot"
                                         [updateOnDataChange]="true"
                                         [updateOnLayoutChange]="true"
                                         [divId]="'plotCost'"
                                         [data]="plotCostData"
                                         [layout]="plotCostLayout"
                                         [config]="myapp.k.defaultConfig">
                            </plotly-plot>
                            <div fxFlex></div>
                        </div>
                    </mat-card>
                </div>
            </ng-container>
            <div fxFlex></div>
        </div>
    </div>
</div>

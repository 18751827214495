<ng-container *ngIf="myapp.user&&site.clientSite">
    <div fxLayout="row" *ngIf="site&&site.clientSite" fxLayoutAlign="space-between end" class="sub-header relative">

        <div fxFlex="600px">
            <div class="bat-card">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <h1>
                        <span>{{ site.clientSite.name }}</span>
                        <ng-container *ngIf="myapp.isAdminOrSuperUser">
                            <mat-icon (click)="manageFav('remove')"
                                      *ngIf="myapp.user.favs&&myapp.user.favs.length&&myapp.user.favs.includes(site.uid)"
                                      inline
                                      style="font-size: 25px;color:#fbc02d">star
                            </mat-icon>
                            <mat-icon (click)="manageFav('add')"
                                      *ngIf="!myapp.user.favs||!myapp.user.favs.includes(site.uid)" inline
                                      style="font-size: 25px;color: gray">star_outline
                            </mat-icon>
                        </ng-container>

                    </h1>
                    <div fxFlex></div>
                    <button mat-button (click)="mode='editSite'" *ngIf="mode==='std'&&myapp.isAdminOrSuperUser">
                        <mat-icon style="font-size: 17px">edit</mat-icon>
                        Editer
                    </button>
                    <button mat-button (click)="mode='std'" *ngIf="mode!=='std'">
                        <mat-icon style="font-size: 17px">arrow_back</mat-icon>
                        Retour
                    </button>
                </div>

                <p class="p-0 font-weight-normal">
                    {{ site.clientSite.address }}<br>{{ site.clientSite.npaLoc }}
                </p>
            </div>
        </div>

        <div fxLayout="column">
            <div class="avatars-bloc-title mb-8 mr-32" fxFlex="60" fxLayout="row" (dblclick)="myapp.sendErrorReport()">
                Utilisateurs du site
            </div>
            <div class="avatars mb-8 mr-32" fxFlex="60" fxLayout="row" fxLayoutAlign="end center">
                <div class="avatar-holder-group" *ngFor="let gr of userGroups"  fxLayout="row" fxLayoutAlign="end center">
                    <div class="group-title">{{ gr }}</div>
                    <ng-container *ngFor="let u of site.responsibles">
                        <div class="avatar-holder" *ngIf="u.group===gr">
                            <ngx-avatar class="cursor-pointer"
                                        [title]="u.group"
                                        [name]="u.first_name+' '+u.last_name"
                                        [size]="40"
                                        (click)="selectedUser=u">
                            </ngx-avatar>
                        </div>
                    </ng-container>

                </div>

            </div>
        </div>

        <div class="user-card" *ngIf="selectedUser" fxLayout="column">
            <h6 fxLayout="row" fxLayoutAlign="space-between center">
                <span>{{ selectedUser.first_name + ' ' + selectedUser.last_name }}</span>
                <span fxFlex>({{ selectedUser.group }})</span>
                <div *ngIf="selectedUser.site_access&&selectedUser.site_access.get(site.clientSite.uid) as role">
                    <mat-chip>{{ role.responsability }}</mat-chip>
                </div>
                <mat-icon fxFlex="30px" class="m-4" (click)="selectedUser=null">close</mat-icon>
            </h6>
            <div class="">
                <div class="user-card-line">
                    <label>E-mail</label>
                    <span>{{ selectedUser.email }}</span>
                </div>
                <div class="user-card-line" *ngFor="let kv of selectedUser.infos|keyvalue">
                    <label>{{ myapp.k.VCardLabels[kv.key] }}</label>
                    <span>{{ kv.value }}</span>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="mode==='editSite'">
        <mat-card class="p-4 m-4">
            <site-editor fxFlex="1600px" [forceUpdate]="site.clientSite.ts"></site-editor>
        </mat-card>
    </ng-container>

    <div *ngIf="mode==='std' && myapp.appInitStatusLevel===3" fxLayout="row" style="height: 100%">
        <div fxFlex="70" fxLayout="column" [@animate]="{value:'*',params:{delay:'100ms',y:'50px'}}">
            <div fxFlex="" fxLayout="row">
                <div fxFlex fxLayout="column">
                    <bloc-cons *ngIf="clientSiteLoaded"></bloc-cons>
                </div>
            </div>
        </div>
        <div fxFlex="30" class="right-pane">

            <div [@animate]="{value:'*',params:{delay:'100ms',y:'50px'}}">
                <bloc-actions-summary *ngIf="myapp.getIfUserCanDo('ACTIONS_SUMMARY')"
                                      [actions]="site.actions"></bloc-actions-summary>

                <mat-card *ngIf="!myapp.getIfUserCanDo('ACTIONS_SUMMARY')" class="p-16">
                    <h2>
                        Bilan des actions
                    </h2>
                    <i>Accès réservé</i>
                </mat-card>
            </div>
        </div>
    </div>
</ng-container>

import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {H} from "../../../../shared/helpers/H";
import {isMoment} from "moment";
import {AppService} from "../../../../shared/services/app.service";
import {SiteService} from "../../../../shared/services/site.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BaseComponent} from "../../../../shared/BaseComponent";
import {K} from "../../../../shared/models/K";
import {Pdm} from "../../../../shared/models/Pdm";
import {PdmRegDef} from "../../../../shared/models/PdmRegDef";

@Component({
    selector: 'app-form-pdm',
    template: `
        <div fxLayout="column" fxLayoutAlign="space-between none" class="flex-container">
            <div fxFlex>
                <form [formGroup]="formPdm" *ngIf="formPdm" class="p-8">
                    <div fxLayout="row">
                        <div fxFlex="50">
                            <div class="p-10">
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <mat-form-field class="full-width" fxFlex="50">
                                        <mat-select formControlName="type" placeholder="Type" [value]="pdm.type" (valueChange)="updateCheckBoxes($event)">
                                            <mat-option [value]="'ACHAT'">ACHAT</mat-option>
                                            <mat-option [value]="'PRODUCTION'">PRODUCTION</mat-option>
                                            <mat-option [value]="'CONSOMMATION'">CONSOMMATION</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                                        <mat-checkbox style="margin:-15px 2px 0 !important" class="mr-8 checkbox" 
                                                      formControlName="is_entrant">Entrant</mat-checkbox>
                                        <mat-checkbox style="margin:-15px 2px 0 !important" class="mr-8 checkbox" [(ngModel)]="pdm.is_vpdm" [ngModelOptions]="{standalone: true}">
                                            VPDM
                                        </mat-checkbox>
                                    </div>
                                </div>

                                <mat-form-field class="full-width">
                                    <input matInput formControlName="label" placeholder="Libellé">
                                </mat-form-field>
                                <mat-form-field class="full-width">
                                    <input matInput formControlName="label_short" placeholder="Libellé court">
                                </mat-form-field>
                                <mat-form-field class="full-width">
                                    <input matInput formControlName="label_bilan" placeholder="Libellé bilan">
                                </mat-form-field>
                                <mat-form-field class="full-width">
                                    <mat-select formControlName="offset" placeholder="Mois début" [value]="pdm.offset">
                                        <mat-option strEnh enh="ucfirst" [value]="i" *ngFor="let mon of months;let i=index"> {{mon}}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field class="full-width">
                                    <mat-label>Agent énergetique</mat-label>
                                    <mat-select formControlName="agent" placeholder="agent" [value]="pdm.agent">
                                        <mat-option [value]="agent.key" *ngFor="let agent of myapp.k.EnergyAgents|keyvalue">  {{agent.key}}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field class="full-width">
                                    <textarea matInput formControlName="description" placeholder="Descriptif"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="50">
                            <div class="p-10">
                                <div fxLayout="row">
                                    <mat-form-field class="m-4 full-width" fxFlex="50">
                                        <input matInput formControlName="date_start" [matDatepicker]="picker1" placeholder="Date mise en service">
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>

                                    <mat-form-field class="m-4 full-width" fxFlex="50">
                                        <input matInput formControlName="date_end" [matDatepicker]="picker2" placeholder="Date mise hors service (vide si actif)">
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </mat-form-field>
                                </div>


                                <mat-form-field class="full-width">
                                    <mat-select formControlName="status" placeholder="Statut" [value]="pdm.status">
                                        <mat-option [value]="'DRAFT'">DRAFT</mat-option>
                                        <mat-option [value]="'PUBLISHED'">PUBLISHED</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field class="full-width">
                                    <mat-label>Emplacement pour le PDM</mat-label>
                                    <mat-select formControlName="uid_place" placeholder="Emplacement" [value]="pdm.uid_place">
                                        <mat-option [value]="placeKV.key" *ngFor="let placeKV of site.clientSite.places|keyvalue">  {{placeKV.value.label}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="full-width">
                                    <mat-label>Variable expliquée</mat-label>
                                    <mat-select formControlName="ve1" placeholder="Emplacement" [value]="pdm.ve1">
                                        <mat-option value="dTot"> dTot</mat-option>
                                        <mat-option value="dTotImpDe"> dTotImpDe</mat-option>
                                        <mat-option value="dTotExpDe"> dTotExpDe</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="full-width">
                                    <mat-label>Variable relative (serie 2 batonets)</mat-label>
                                    <mat-select formControlName="ve2" placeholder="Emplacement" [value]="pdm.ve2">
                                        <mat-option [value]="''"> Pas de série secondaire</mat-option>
                                        <mat-option [value]="'reg'+reg.num" *ngFor="let reg of filteredRegsForVe2">
                                            {{reg.t_type|uppercase}}  : {{reg.reg_label}}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field class="full-width prefixed">
                                    <input matInput formControlName="xls" placeholder="xls (ou extrait du nom)">
                                    <span matPrefix>XLS</span>
                                </mat-form-field>

                                <div class="form-section" fxLayout="row" fxLayoutAlign="space-between center">
                                    <mat-checkbox class="mr-8 checkbox" *ngIf="pdm.fluid==='CHALEUR'" formControlName="is_pci">PCI</mat-checkbox>
                                    <mat-checkbox class="mr-8 checkbox" [(ngModel)]="pdm.is_bmens_included" [ngModelOptions]="{standalone: true}">Inclut dans bilan</mat-checkbox>
                                    <mat-checkbox class="mr-8 checkbox" [(ngModel)]="pdm.is_bmens_total" [ngModelOptions]="{standalone: true}">
                                        Is Total Bilan
                                    </mat-checkbox>
                                </div>

                                <div *ngIf="pdmSaveError!==''">{{pdmSaveError}}</div>
                            </div>

                        </div>


                    </div>
                </form>
            </div>
            <div fxLayout="row" class="">
                <div style="flex-grow: 1"></div>
                <button-loading
                        [loading]="pdmSaveProgress"
                        class="mb-16"
                        color="primary"
                        (click)="savePdm()"> Sauvegarder
                </button-loading>
            </div>
        </div>
    `,
    styleUrls: ['./form-pdm.component.scss']
})
export class FormPdmComponent extends BaseComponent implements AfterViewInit, OnInit, OnChanges {
    @Input('pdm') pdm: Pdm;
    @Input('pdmUID') pdmUID: string;// to force update on pdm select
    @Input('regs') regs: PdmRegDef[];
    @Input('autoFields') autoFields: any = {};
    @Input('ts') ts: number;// to force update on pdm update
    months = K.months;
    formPdm: FormGroup = null;
    pdmSaveError = '';
    pdmSaveProgress = false;
    insertMode = true;

    get filteredRegsForVe2() {
        return this.regs.filter(item => {
            return (item.is_derniere_cons && item.d_type === PdmRegDef.TYPE_D_ENERGY);
        });
    }

    constructor(public myapp: AppService, public site: SiteService, private fb: FormBuilder) {
        super();
    }

    initForm() {

        if (!this.formPdm) return;
        this.formPdm.controls['offset'].patchValue(this.pdm.offset);
        this.formPdm.controls['label_bilan'].patchValue(this.pdm.label_bilan);
        this.formPdm.controls['uid_place'].patchValue(this.pdm.uid_place);
        this.formPdm.controls['label'].patchValue(this.pdm.label);
        this.formPdm.controls['label_short'].patchValue(this.pdm.label_short);
        this.formPdm.controls['ve1'].patchValue(this.pdm.ve1);
        this.formPdm.controls['ve2'].patchValue(this.pdm.ve2);
        this.formPdm.controls['xls'].patchValue(this.pdm.xls);
        this.formPdm.controls['status'].patchValue(this.pdm.status);
        this.formPdm.controls['agent'].patchValue(this.pdm.agent);

        this.formPdm.controls['is_entrant'].patchValue(this.pdm.is_entrant);
        this.formPdm.controls['is_pci'].patchValue(this.pdm.is_pci);

        this.formPdm.controls['type'].patchValue(this.pdm.type);
        this.formPdm.controls['description'].patchValue(this.pdm.description);
        this.formPdm.controls['date_start'].patchValue(this.pdm.date_start);
        this.formPdm.controls['date_end'].patchValue(this.pdm.date_end);
    }

    savePdm() {
        this.pdmSaveProgress = true;
        Object.assign(this.pdm, this.formPdm.value);
        this.pdm.ve1 = this.formPdm.value.ve1;
        this.pdm.ts_update = H.unixTs();

        // if (this.pdm.uid_place)
        //     this.pdm.label_place = this.site.clientSite.places[this.pdm.uid_place].label;
        //console.log('saveSite1 VE1: ' + this.formPdm.value.ve1, this.pdm, this.formPdm.value);

        Object.keys(this.pdm).forEach(key => {
            if (key.includes("date_") && this.pdm[key] !== undefined && this.pdm[key]) {
                if (isMoment(this.pdm[key])) {
                    this.pdm[key] = this.pdm[key].format('YYYY-MM-DD');
                }
            }
            if (key.includes("is_") && this.pdm[key] !== undefined) {
                if (String(this.pdm[key]) === "true") this.pdm[key] = 1;
                if (String(this.pdm[key]) === "false") this.pdm[key] = 0;
            }
        });
        //  console.log('saveSite2', this.pdm, this.formPdm.value);
        //if (3 > 1) return;


        this.site.api.savePdm(this.pdm).subscribe(resp => {
            /*
            if (resp.status === 1) {
                Object.assign(this.pdm, resp.body);
                this.cb.emit({arg: 'REG', obj: this.pdm});
            } else {
                Object.keys(resp.errors).forEach(field => {
                    this.pdmSaveError += field + ":" + resp.errors[field][0] + "<br>";
                });

            }*/
            console.log('updateSite', resp);
            this.pdmSaveProgress = false;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.initForm();
        // console.log("FormPdmComponent", 'ngOnChanges', changes);
        this.insertMode = true;
    }

    ngOnInit(): void {
        this.formPdm =
            this.fb.group({
                uid_place: ['', [Validators.required]],
                label: ['', [Validators.required]],
                label_short: ["", [Validators.required]],
                label_bilan: ["", []],
                ve1: ["", [Validators.required]],
                ve2: ["", [Validators.required]],
                xls: ['', [Validators.required]],
                offset: ['', []],
                status: ['', [Validators.required]],
                agent: ['', [Validators.required]],

                is_pci: ['', []],
                is_entrant: ['', []],
                type: ['', [Validators.required]],
                description: ['', []],
                date_start: ['', [Validators.required]],
                date_end: ['', []],
            });

        this.initForm();

    }

    updateCheckBoxes($event) {
        if ($event === "PRODUCTION") this.formPdm.controls['is_entrant'].patchValue(true);
        if ($event === "ACHAT") this.formPdm.controls['is_entrant'].patchValue(true);
        if ($event === "CONSOMMATION") this.formPdm.controls['is_entrant'].patchValue(false);
    }

    ngAfterViewInit(): void {

    }
}

import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import * as Highcharts from 'highcharts';
import PatternFill from "highcharts/modules/pattern-fill";
import ExportData from "highcharts/modules/export-data";
import Exporting from "highcharts/modules/exporting";
import Annotations from "highcharts/modules/annotations";
import Debugger from "highcharts/modules/debugger";

import {H} from "../../shared/helpers/H";

import {SiteService} from "../../shared/services/site.service";
import {
    Plot, PlotUnit
} from "../../shared/models/models";
import {BaseComponent} from "../../shared/BaseComponent";
import {AppService} from "../../shared/services/app.service";
import {AnnotationsOptions, Chart, ChartCallbackFunction, SeriesOptionsType, XrangePointOptionsObject} from "highcharts";
import {K} from "../../shared/models/K";
import {OptimiseChart} from "../../shared/helpers/OptimiseChart";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {PdmYearConfig, PdmYearModel, PdmYearSignature} from "../../shared/models/PdmYearConfig";
import {DomSanitizer} from "@angular/platform-browser";
import * as Buffer from "buffer";
import {Optimise} from "../../shared/helpers/Optimise";
import {MatSnackBar} from "@angular/material/snack-bar";

if (typeof Highcharts === 'object') {
    PatternFill(Highcharts);
    //  Exporting(Highcharts);
    //   ExportData(Highcharts);
    Annotations(Highcharts);
    //  Debugger(Highcharts);
}

@Component({
    selector: 'statmens-plot',
    template: `
        <mat-card class="bloc-statmens-plot m-8 mb-4 p-4" fxLayout="column">
            <div class="bloc-statmens-plot-title" (dblclick)="isDebugMode=true">
                <mat-select placeholder="Unité Y" class="select-unit"
                            *ngIf="!plot.isCost()"
                            [value]="site.selectedPdmConfigForYear?site.selectedPdmConfigForYear.getUnitForPlot(plot.getKey()).unit_plot:''"
                            (selectionChange)="setUnitFactor($event.value)" style="float: left">
                    <mat-option [value]="'K'">Kilo</mat-option>
                    <mat-option [value]="'M'">Mega</mat-option>
                    <mat-option [value]="'G'">Giga</mat-option>
                </mat-select>
                <span [innerHTML]="title"></span>
                <div class="last-releve-date">
                    Dernier relevé: <span>{{ lastReleve }}</span>
                </div>
            </div>
            <div *ngIf="isDebugMode" fxLayout="row">
                <span fxFlex="100px" (dblclick)="isDebugMode=false">##</span>
                <span>Month: {{ site.selectedMonthIndex }}</span>
                <button (click)="addAxis()">Ax</button>
                <button (click)="bootstrap('but')">Boo</button>
                <button (click)="renderChart('but')">Ren</button>
                <button (click)="chartUpdate('but')">Upd</button>
                <button (click)="setLegendOnTopIfNeeded()">Lg</button>
            </div>
            <div class="bloc-statmens-plot-subtitle  " [innerHTML]="subTitle"></div>
            <!--chart -->
            <div class="full-width" fxLayout="row" *ngIf="site.refYear&&chartOptions">
                <highcharts-chart [Highcharts]="Highcharts"
                                  [options]="chartOptions"
                                  [oneToOne]="true"
                                  [(update)]="updateFlag"
                                  (chartInstance)="onChartInstance($event)"
                                  [style]="{'margin-right':mr}"
                                  style="width: 950px; height: 550px; display: block;margin: 5px">
                </highcharts-chart>

            </div>
            <div *ngIf="errors.length>0">
                <mat-card *ngFor="let err of errors" class="p-4 orange-200">
                    <b>{{ err.year }}:</b> {{ err.message }}
                    <button mat-raised-button color="primary"
                            class="btn-xs-25" *ngIf="err.type==='StatMensConfig'"
                            (click)="this.site.selectReportYearAndInitPdmYearlyConfig(err.year)">Configurer
                    </button>
                </mat-card>
            </div>

            <ng-container *ngIf=" plot.section === 'QUALITY'">
                <div class="p-8 m-4" *ngIf="tempModel ">
                    <h3>Configuration modèle pour année {{ site.refYear }}
                        <button mat-raised-button class="btn-xs-25 mr-4" color="primary" (click)="saveModel()">Sauvegarder</button>
                        <button mat-raised-button class="btn-xs-25" color="accent" (click)="tempModelVars=[];updateModelConf()">RAZ</button>
                    </h3>
                    <div fxLayout="row">
                        <div class=" p-8 config-panel" fxFlex>
                            <div fxLayout="column">
                                <div fxLayout="row wrap" class="p-4">
                                    <mat-checkbox class="mr-8" fxFlex="30" *ngFor="let v of varExplMap|keyvalue"
                                                  (change)="updateModelConf()" [(ngModel)]="tempModelVars[v.key]">
                                        {{ v.value }}
                                        <span *ngIf="tempModel.used_cassure>0&&v.key==='DJ_EP'">{{ tempModel.used_cassure|number }}</span>
                                    </mat-checkbox>
                                    <mat-checkbox class="mr-8 varExtraCheckBox" fxFlex="30" *ngFor="let v of site.clientSite.vars_extra|keyvalue"
                                                  (change)="updateModelConf()" [(ngModel)]="tempModelVars['EXTRA_'+v.key]">
                                        {{ v.key }}
                                    </mat-checkbox>
                                    <mat-checkbox class="mr-8 varRegCheckBox" fxFlex="30"
                                                  *ngFor="let v of site.varsExplFromRegs|keyvalue"
                                                  (change)="updateModelConf()" [(ngModel)]="tempModelVars['REG_'+v.key]">
                                        {{ v.value }}
                                    </mat-checkbox>
                                </div>
                                <div fxLayout="row wrap" class="p-4">
                                    <mat-checkbox fxFlex="33" (change)="updateModelConf()"
                                                  [(ngModel)]="tempModel.onlyPositiveCons">Uniquement conso positive
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class=" p-8 " fxFlex="400px">
                            <pre style="font-size: 10px; line-height: 15px; overflow-y: scroll;height: 110px;">{{ tempModel|json }}</pre>
                        </div>
                    </div>

                </div>
            </ng-container>

        </mat-card>
    `,
    styleUrls: ['../../shared/components/shared-styles/shared.scss']
})
export class StatmensPlotComponent extends BaseComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
    @Input('plot') plot: Plot;
    @Input('plotId') plotId: string;
    @Input('updated') updated: any;
    @Output('configure') configure: EventEmitter<any> = new EventEmitter<any>();
    @Output('debug') debug: EventEmitter<any> = new EventEmitter<any>();
    @Output('save') save: EventEmitter<any> = new EventEmitter<any>();
    isDebugMode = false;
    svg: any;
    mr = "0px";

    debugData = {};
    debugVarexpl = {};
    errorMessages = [];
    public varExplMap = this.myapp.k.varExplMap;

    chart: Chart;
    errors: any[] = [];
    Highcharts: typeof Highcharts = Highcharts;

    chartOptions: Highcharts.Options;
    updateFlag: boolean = false;
    optimiseChart: OptimiseChart;

    historyCount: number;

    title: string;
    subTitle: string;
    lastReleve: string;
    previewPath: string;
    signature: any; // for SIGNATURE
    cassure = 0;    // for SIGNATURE
    pointsForSignatureCalc = [];
    renderingCount = 0;

    public tempSignature: PdmYearSignature = new PdmYearSignature({});
    public tempModel: PdmYearModel = new PdmYearModel({});
    public tempModelVars: any = {};
    public rawDataFromManager: any = null;
    subsStatMensConfig: Subscription;

    previousSerie = [];

    constructor(public myapp: AppService,
                public site: SiteService,
                private snackBar: MatSnackBar,
                public sanitizer: DomSanitizer) {
        super();
    }

    bootstrap(debugCalledBy = "") {
        this.errors = [];
        console.info('bootstrap: ' + debugCalledBy, this.plot.getKey(), this.site.selectedPdmConfigForYear);
        if (!this.site.refYear) {
            console.error('StatmensPlotComponent::bootstrap: refYear ERROR: ', this.site.refYear);
            return;
        }
        // this.tempSignature = new StatMensConfigSignature({});
        // this.tempModel = new StatMensConfigModel({});
        this.tempModelVars = {};

        this.initStatMensConfig();

        this.optimiseChart = new OptimiseChart(
            this.plot,
            this.site.selectedPdm,
            this.site.selectedPdmConfigForYear,
            this.site.dataSummaryMap
        );
        //this.chartOptions =
        this.optimiseChart.apply(this);
        this.addAxis();
        this.setLegendOnTopIfNeeded();
        //this.updateFlag = true;

        this.renderChart('bootstrap');
    }

    initStatMensConfig() {
        if (this.site.selectedPdmConfigForYear) {
            this.plot.plotUnit = this.site.selectedPdmConfigForYear.getUnitForPlot(this.plot.getKey());
        }

        if (this.site.selectedPdmConfigForRefYear && this.site.selectedPdmConfigForRefYear.model_year) {
            this.tempModel = new PdmYearModel(this.site.selectedPdmConfigForRefYear.model_year);
            this.tempModel.used_cassure = this.site.selectedPdmConfigForRefYear.signature_year ? this.site.selectedPdmConfigForRefYear.signature_year.cassure : -1;
        }

        if (this.site.selectedPdmConfigForYear && this.site.selectedPdmConfigForYear.signature_year)
            this.tempSignature = new PdmYearSignature(this.site.selectedPdmConfigForYear.signature_year);
        else
            this.tempSignature = new PdmYearSignature({});

        if (this.tempModel && this.tempModel.varExpList) {
            this.tempModel.varExpList.forEach(item => {
                this.tempModelVars[item] = true;
            });
        }
    }

    renderChart(debugCalledBy = "") {
        if (!this.chart) {
            console.log('renderChart', 'chart null');
            return;
        }
        if (!this.site.refYear) {
            console.error('StatmensPlotComponent::renderChart: yearsAndConfigsSetAndCHECKED ERROR: ', this.site.refYear);
            return;
        }
        //console.log("renderChart() " + debugCalledBy, this.plot.id, '----------------------------');
        //  this.chartOptions.plotOptions.series.point.events.click = (pt) => this.fromChart(pt);

        this.previousSerie = [];
        if (this.plot.section === Plot.SECTION_SIGNATURE || this.plot.section === Plot.SECTION_BASIC) {
            for (let y = 0; y < this.plot.yearCountHistory; y++) {
                let data = [];
                let data2 = [];
                const yearOfCurrentSerie = this.site.year + y + 1 - this.historyCount;

                this.rawDataFromManager = this.site.selectedPdm.getDataForYear(yearOfCurrentSerie, this.plot);
                //console.log('renderChart:' + yearOfCurrentSerie, this.plot, this.rawDataFromManager);
                if (this.plot.section === Plot.SECTION_BASIC) {
                    if (this.plot.cumul) {

                        let cum = 0;
                        data = this.rawDataFromManager.map((item, i) => {
                            cum += item.val;
                            return {x: item.dist, y: cum, date: item.date, custom: '0', name: 22};//index: (i + 1),
                            //return this.getPointsForCumul(item, i);
                        });

                        if (yearOfCurrentSerie === this.site.refYear)
                            this.previousSerie = data;
                        // data.unshift({x: 0, y: 0, index: 0,});

                        if (this.site.selectedPdm.getIsHaveMultipleSeries() && this.plot.isMain()) {
                            data2 = this.rawDataFromManager.map((item, i) => {
                                return this.getPointsForCumul(item, i, 2);
                            });

                            //console.log("getPointsForCumul", y, yearOfCurrentSerie, data, data2, this.rawDataFromManager);
                        }
                    } else {
                        data = this.rawDataFromManager.map((item, i) => {
                            return this.getPointsForBars(item, i, y, 1);
                        });
                        data = K.months.map((month, i) => {
                            if (data[i]) return data[i]; else return {x: i, y: 0, category: H.ucfirst(K.months[i]), custom: ""};
                        });
                        if (yearOfCurrentSerie === this.site.refYear)
                            this.previousSerie = data;
                        if (this.site.selectedPdm.getIsHaveMultipleSeries() && this.plot.isMain()) {
                            data2 = this.rawDataFromManager.map((item, i) => {
                                return this.getPointsForBars(item, i, y, 2);
                            });
                            data2 = K.months.map((month, i) => {
                                if (data2[i]) return data2[i]; else return {x: i, y: 0, category: H.ucfirst(K.months[i]), custom: ""};
                            });

                            // substract y2 from y1
                            data = K.months.map((month, i) => {
                                //console.log("DATA SUBSTRACT", data[i], data2[i]);
                                return {...data[i], y: (data[i].y - data2[i].y)};
                            });

                            // console.log("getPointsForBars", y, yearOfCurrentSerie, data, data2, this.rawDataFromManager);

                        }
                    }
                    if (yearOfCurrentSerie === this.site.year && this.site.selectedMonthIndex < 11) {
                        this.applySelectedMonthOnSerieData(data);
                        if (data2.length > 0)
                            this.applySelectedMonthOnSerieData(data2);
                    }/*
                    K.months.forEach((month, i) => {
                        if (i > this.site.selectedMonthIndex) {
                            data[i] = {...data[i], y: 0};
                            if (data2.length > 0 && data2[i] !== undefined)
                                data2[i] = null;//{...data2[i], y: 0, custom: ""};
                        }
                    });*/
                    this.debugData['plot1'] = data;
                    this.debugData['plot2'] = data2;

                    this.chartUpdateSerie(y, data, 'Update SECTION_BASIC' + y);
                    if (data2.length)
                        this.chartUpdateSerie(y + this.plot.yearCountHistory, data2, 'Update SECTION_BASIC' + y);
                }

                if (this.plot.section === Plot.SECTION_SIGNATURE && y < (this.historyCount)) {
                    const pointsForSignatureCalc = this.getSignaturePoints(yearOfCurrentSerie);

                    if (yearOfCurrentSerie === this.site.year && this.site.selectedMonthIndex < 11)
                        this.applySelectedMonthOnSerieData(pointsForSignatureCalc);

                    this.chartUpdateSerie(y, pointsForSignatureCalc, 'Update signatureBase ' + y);

                    const configForSerieYear = this.site.sitePdmsYearlyConfigMap.get(this.site.selectedPdm.uid + "_" + yearOfCurrentSerie);
                    if (configForSerieYear && configForSerieYear.signature_year) {
                        const sign = new PdmYearSignature(configForSerieYear.signature_year);
                        const tempSerie = sign.getPlotData();
                        this.debugData['plot1'] = tempSerie;
                        this.debugData['signature'] = sign;
                        console.log("Ploting signature", this.debugData);
                        this.chartUpdateSerie(Number(this.historyCount + y), tempSerie, "updateSignature index:" + y);
                    } else {
                        console.log("Failed plot signature, No cassure for: " + yearOfCurrentSerie, this.site.sitePdmsYearlyConfigMap);
                        // this.raiseError("Pas de cassure définit  ", yearOfCurrentSerie, 1, "StatMensConfig");

                    }

                }
            }
        }

        try {
            if (this.plot.section === Plot.SECTION_ANNUAL)
                this.drawAnnual();
            if (this.plot.section === Plot.SECTION_QUALITY)
                this.drawModelQuality();

            if (this.plot.section === Plot.SECTION_DERNIERE)
                this.drawDerniere();
            else {
                if (this.chart && this.chart.xAxis && this.chart.xAxis.length > 0) {
                    this.chart.xAxis[0].removePlotLine('day30');
                }
            }

            if (this.chart && this.chart.xAxis && this.chart.xAxis.length > 0)
                this.chart.xAxis[0].removePlotLine('cassure');
        } catch (e) {
            this.manageException(e);
        }

        this.savePlotDataCache();
        this.debug.emit({plotId: this.plotId, data: this.debugData});
    }

    getPointsForCumul(item, i, serieNum = 1) {
        let deltaPrev = 0;
        let percent = 0;
        let val = item.val;
        if (serieNum === 2) val = item.val2;

        if (this.previousSerie[i] && this.previousSerie[i].y)
            deltaPrev = Number(val - this.previousSerie[i].y) || 0;

        if (deltaPrev !== 0 && this.previousSerie[i].y !== 0) {
            percent = 100 * (deltaPrev / this.previousSerie[i].y);
        }

        const retVal = {x: item.dist, y: val, date: item.date, custom: percent, name: deltaPrev};//index: (i + 1),
        console.log("getPointsForCumul", retVal, item, i, serieNum);
        return retVal;
    }

    getPointsForBars(item, i, indexOfSerie, serieNum = 1) {
        let deltaPrev = 0;
        let percent = 0;
        let val = item.val;
        if (serieNum === 2) val = item.val2;

        const yearOfCurrentSerie = this.site.year + indexOfSerie + 1 - this.historyCount;
        //console.log("prev" + i, item, prevSerie);
        if (this.previousSerie[i] && this.previousSerie[i].y) deltaPrev = Number(val - this.previousSerie[i].y) || 0;
        if (deltaPrev !== 0 && this.previousSerie[i].y !== 0) {
            percent = 100 * (deltaPrev / this.previousSerie[i].y);
        }

        const retVal = {x: i, y: val, year: yearOfCurrentSerie, date: item.date, category: H.ucfirst(K.months[i]), custom: percent, name: deltaPrev};
        return retVal;

    }

    getSignaturePoints(yearOfCurrentSerie: number) {
        const dataRows = this.site.selectedPdm.getMensualData(yearOfCurrentSerie);
        let points = dataRows.map((dataForMonth, i) => {
            const ptX = dataForMonth.vars_meteo ? dataForMonth.vars_meteo['m1'] : -1;
            const ptY = dataForMonth.ve * this.plot.getUnitFactor();
            return {x: ptX, y: ptY, category: ptX, custom: (i + 1), date: dataForMonth.date};
            // return {x: ptX, y: item.val };
        });
        return Optimise.sortArrayByKeyVal(points, 'x');
    }

    drawAnnual() {
        let data = [];
        let data2 = [];
        this.site.dataSummaryMap.forEach((dataSummaryforYear, year) => {
            const val = this.plot.getUnitFactor() * dataSummaryforYear[this.plot.valKey + "_cumul"];
            data.push({x: Number(year), y: val, year, custom: 1, name: "Not Corrected: "});
        });
        this.site.dataSummaryMap.forEach((dataSummaryforYear, year) => {
            const val = this.plot.getUnitFactor() * dataSummaryforYear[this.plot.valKey + "_cumul"];
            data2.push({
                x: Number(year),
                y: 365 * val / dataSummaryforYear.dist,
                year,
                custom: 1,
                name: "Corrected: " + dataSummaryforYear.dist
            });
        });
        console.log("this.site.dataSummaryMap", this.site.dataSummaryMap);


        /*
        data = K.months.map((month, i) => {
            if (data[i]) return data[i]; else {
                return {x: i, y: 0, category: H.ucfirst(K.months[i]), custom: ""};
            }
        });*/
        this.debugData['plot1'] = data;
        //  this.debugData['plot2'] = data2;
        this.chartUpdateSerie(0, data, 'Update SECTION_BASIC');
        // this.chartUpdateSerie(1, data2, 'Update SECTION_BASIC');
    }


    applySelectedMonthOnSerieData(data: any[]) {
        let filteredData = [];
        console.log("Apply truc before: ", this.plot.id, data);
        K.months.forEach((month, i) => {
            if (i > this.site.selectedMonthIndex) {
                data[i] = {...data[i], y: null, custom: "", name: ""};
                //filteredData[i] = data[i];//{...data2[i], y: 0, custom: ""};
            }
        });
        data = data.filter(item => !!item);
        data = Optimise.sortArrayByKeyVal(data, 'x');
        console.log("------Apply truc after ", this.plot.id, data, filteredData);
        // return filteredData;
    }

    setTitle() {
        this.lastReleve = " ";
        if (this.site.year && this.site.dataSummaryMap.has(this.site.year.toString()))
            this.lastReleve = this.site.dataSummaryMap.get(this.site.year.toString()).last_date;
        this.subTitle = this.site.selectedPdm.label;
        let text = "Consommation d";
        if (this.plot.isCost()) text = " Couts d";
        if (this.site.selectedPdm.fluid === "EAU") text += "'eau: ";
        if (this.site.selectedPdm.fluid === "CHALEUR") text += "e chaleur: ";
        if (this.site.selectedPdm.fluid === "ELEC") text += "'électricité: ";
        if (this.site.selectedPdm.fluid === "FROID") text += "e de froid: ";
        if (this.plot.isPointe()) text += " Pointe ";
        if (this.plot.cumul) text += " (cumulée) ";
        //------
        if (this.plot.correct) {
            if (this.plot.section === Plot.SECTION_ANNUAL)
                text = text + "  (valeurs corrigé à 365 jours)";
            else
                text = text + "  (valeurs corrigées à 30 jours)";
        }
        if (this.plot.section === Plot.SECTION_BASIC || this.plot.section === Plot.SECTION_ANNUAL)
            this.title = text;
        if (this.plot.section === Plot.SECTION_QUALITY) {
            this.title = text;
            this.subTitle = "  Modèle: " + this.tempModel.getModelStr();
        }
        if (this.plot.section === Plot.SECTION_DERNIERE) {
            this.title = text;
            this.subTitle = "  Modèle: " + this.tempModel.getModelStr();
        }
        if (this.plot.section === Plot.SECTION_SIGNATURE)
            this.title = text + " en fonction de la température" + " (" + this.site.getWeatherStation() + ", °C)";
        ;
        if (this.plot.section === Plot.SECTION_SIGNATURE_FINDER)
            this.title = "Recherche graphique de la cassure";


    }

    setLegendOnTopIfNeeded() {
        // console.log("setLegendOnTopIfNeeded() " + this.plot.cumul, this.chartOptions);
        this.setTitle();

        if (this.plot.section === Plot.SECTION_DERNIERE || this.plot.section === Plot.SECTION_QUALITY) {
            this.mr = '20px';
            this.chartOptions.legend.layout = "horizontal";
            this.chartOptions.legend.itemDistance = 50;
            this.chartOptions.legend.itemMarginBottom = 5;
            this.chartOptions.legend.verticalAlign = "top";
            this.chartOptions.legend.align = "center";
        } else {
            this.mr = '0px';
            this.chartOptions.legend.layout = "vertical";
            this.chartOptions.legend.itemMarginBottom = 10;
            this.chartOptions.legend.verticalAlign = "middle";
            this.chartOptions.legend.align = "right";

        }

        /*
       this.chartOptions.series.forEach(serie => {
           serie.name = serie.name + ":" + serie.index;
       });
       /*    const dis = this;
       this.chartOptions.legend.labelFormatter = function () {
           /// console.log("legend", this);
           if (this.index === (dis.historyCount - 1))
               return this.name + " \n<br>Corrigé à 365 jours";
           else return this.name  ;
       };*/
    }

    getSVG() {
        this.svg = this.chart.getSVG();
        this.svg = this.sanitizer.bypassSecurityTrustHtml(this.svg);
        return this.svg;
    }

    savePlotDataCache() {
        if (this.chart && this.chart.series) {
            //  console.log("this.chart.series 1111:" + this.plot.section, this.chart.series, this.chartOptions.series);
            this.chart.series.forEach((ser, i) => {
                this.chartOptions.series[i] = ser.options;
            });
            this.chart.xAxis.forEach((axis, i) => {
                this.chartOptions.xAxis[i] = axis.options;
            });
            this.chart.yAxis.forEach((axis, i) => {
                this.chartOptions.yAxis[i] = axis.options;
            });
        } else return;
        const dataJson = JSON.stringify(this.chartOptions.series);
        let sectionKey = this.plot.section;
        if (this.plot.section === "BASIC") {
            sectionKey += "_" + this.plot.valKey.toUpperCase();
            if (this.plot.cumul)
                sectionKey += "_CUMUL";
        }


        const svgObj = this.getSVG();
        //console.log("SVVVG " + this.plot.cumul, svgObj.changingThisBreaksApplicationSecurity);
        const svgObj64 = Buffer.Buffer.from(JSON.stringify(svgObj)).toString('base64');
        const dataJson64 = Buffer.Buffer.from(dataJson).toString('base64');
        const mess = {key: sectionKey, raw: dataJson64, svg: svgObj64, title: this.title, subTitle: this.subTitle, lastReleve: this.lastReleve};
        // console.log("savePlotDataCache2444:", mess);

        this.save.emit(mess);
    }

    drawSignature(leftOrRight, signature: PdmYearSignature, serieIndex = 0) {
        const tempSerie = signature.getPlotData();
        console.info("drawSignature: ", this.plot.id, tempSerie);
        this.chartUpdateSerie(serieIndex, tempSerie, "updateSignature " + serieIndex);
        this.savePlotDataCache();
    }

    drawDerniere() {

        console.log("DRAW DERNIERER", this.site.selectedPdmConfigForRefYear);
        let days = 0;
        let cumul = 0;
        let cumulCost = 0;
        const realCons: number[] = [];
        const realCost: number[] = [];
        const predictedCons: number[] = [];
        let data = this.site.selectedPdm
            .getMensualData(this.site.year)
            .map((dataForMonth, i) => {
                const ptY = dataForMonth.ve * this.plot.getUnitFactor();
                days += Number(dataForMonth.days);
                cumul += ptY;
                realCons.push(cumul);//for final data below

                // retrieve energy cost
                if (dataForMonth.exported && dataForMonth.exported.ve1_c) {
                    const ptYCost = dataForMonth.exported.ve1_c;
                    cumulCost += ptYCost;
                    realCost.push(cumulCost);
                }

                return {x: days, y: cumul, category: days};
            });
        //*******
        if (this.site.selectedPdmConfigForRefYear && this.site.selectedPdmConfigForRefYear.model_year) {

            const dataForRefYear = this.site.selectedPdm.getMensualData(this.site.refYear);
            const dataPredicted = this.tempModel.predict(dataForRefYear, this.debugVarexpl);
            let predictedCumul = 0;
            console.log("drawDerniere():here:  ", dataForRefYear, dataPredicted, data);
            let dataPredictedToPlot = dataPredicted.map((item, i) => {
                predictedCumul += item * this.plot.getUnitFactor();
                predictedCons.push(predictedCumul);//for final data below
                const currX = data[i] ? data[i]['x'] : null;
                return {x: currX, y: predictedCumul, category: currX};
            });
            data = data.map((item, i) => {
                const pty = item.y;
                const delta = -dataPredictedToPlot[i]['y'] + item.y;
                const deltaPercent = 100 * (delta / dataPredictedToPlot[i]['y']);
                return {x: item.x, y: pty, name: delta, custom: deltaPercent, category: item.x};
            });
            if (this.site.selectedMonthIndex < 11) {
                this.applySelectedMonthOnSerieData(data);
                this.applySelectedMonthOnSerieData(dataPredictedToPlot);
            }
            data.unshift({x: 0, y: 0, category: 0});
            dataPredictedToPlot.unshift({x: 0, y: 0, category: 0});

            this.debugData['plot1'] = data;
            this.debugData['plot2'] = dataPredictedToPlot;
            this.chartUpdateSerie(0, data, 'Update drawDerniere-this.refYear' + this.site.refYear);
            this.chartUpdateSerie(1, dataPredictedToPlot, 'Update drawDerniere-this.refYear' + this.site.refYear);
            const dataAndItsCache = {
                agent: this.site.selectedPdm.agent,
                pdm_type: this.site.selectedPdm.type,
                ts_updated: H.unixTs(),
                real_cost: realCost,
                real_cons: realCons,
                predicted_cons: predictedCons,
                model_year_ref: this.tempModel,
                cassure: this.tempSignature.cassure
            };
            this.site.saveSelectedPdmConfig('derniere_data', dataAndItsCache);

        } else {
            this.chartUpdateSerie(0, data, 'Update drawDerniere-this.refYear' + this.site.refYear);
            this.raiseError("Le modèle n'est pas définie", this.site.refYear, 1, "Model");
        }
        ///TODO: check this
        if (!this.chart) return;
        if (!this.chart.xAxis) return;
        this.chart.xAxis[0].addPlotLine({
            id: 'day30',
            color: '#FF0000',
            width: 1.5,
            dashStyle: 'Dash',
            value: 30
        });
        this.savePlotDataCache();
    }

    drawModelQuality() {
        console.log("DRAW QUALITE", this.site.selectedPdmConfigForRefYear);
        let data = this.site.selectedPdm
            .getMensualData(this.site.refYear)
            .map((dataForMonth, i) => {
                const ptY = dataForMonth.ve * this.plot.getUnitFactor();
                return {x: i, y: ptY, category: H.ucfirst(K.months[i])};
            });

        const dataPredicted = this.tempModel.predict(
            this.site.selectedPdm.getMensualData(this.site.refYear),
            this.debugVarexpl
        );
        const dataPredictedToPlot = dataPredicted.map((item, i) => {
            const delta = item - data[i]['y'];
            const deltaPercent = 100 * (delta / data[i]['y']);
            const normalizedUnitItem = item * this.plot.getUnitFactor();
            return {x: i, y: normalizedUnitItem, category: H.ucfirst(K.months[i]), custom: deltaPercent};
        });
        data = data.map((item, i) => {
            const delta = -dataPredictedToPlot[i]['y'] + item.y;
            const deltaPercent = 100 * (delta / dataPredictedToPlot[i]['y']);
            return {...item, name: delta, custom: deltaPercent};
        });
        this.debugData['plot1'] = data;
        this.debugData['plot2'] = dataPredictedToPlot;
        this.chartUpdateSerie(0, data);
        this.chartUpdateSerie(1, dataPredictedToPlot, 'Update drawModelQuality-this.refYear' + this.site.refYear);
        this.subTitle = "  Modèle: " + this.tempModel.getModelStr();
    }

    addAxis() {
        if (!this.plot || !this.plot.cumul || !this.chart) return;

        try {
            let monthIndex = 0;
            if (this.chart) {
                console.log("addAxis()" + this.plot.section, this.chart.xAxis);
                this.chart.addAxis({
                    id: "axisHaut-months",
                    tickAmount: 12,
                    labels: {
                        formatter: (s) => {
                            if (s.pos === 15) monthIndex = 0;
                            const monthName = monthIndex < 12 ? K.months[monthIndex] : "Jan";
                            const lb = H.ucfirst(monthName);
                            monthIndex++;
                            return lb;
                        },
                    },
                    lineColor: "#fff",
                    tickColor: "#fff",
                    tickmarkPlacement: "on",
                    tickInterval: 30,
                    offset: -50,
                    tickPositions: K.months.map((v, i) => i * 30 + 15),
                    endOnTick: false,
                    opposite: true,
                    visible: true,
                    alignTicks: false,
                    startOnTick: false,
                    linkedTo: 0
                }, true);
                //this.chartUpdate();
            }
            // this.updateFlag = true;
            this.savePlotDataCache();
            this.chart.redraw();
            //this.chartUpdate('addAxis');
        } catch (e) {
            console.error("Error Add Axis", e);
        }

    }

    updateModelConf() {
        this.tempModel.varExpList = [];
        Object.keys(this.tempModelVars).forEach(k => {
            if (this.tempModelVars[k])
                this.tempModel.varExpList.push(k);
        });
        if (this.tempModel.varExpList.length === 0) return;

        try {
            this.tempModel.varExpList = this.tempModel.varExpList.sort();
            this.tempModel.calcModel(this.site.selectedPdm.getMensualData(this.site.refYear));
            this.optimiseChart.pdmYearConfig.model_year_ref = this.tempModel;
            this.drawModelQuality();
        } catch (e) {
            console.error("TRY_CATCH:updateModelConf()", e.message, e);
            this.manageException(e);
        }
    }

    saveModel() {
        this.savePlotDataCache();
        this.site.saveSelectedPdmConfig('model', this.tempModel, true);
    }

    showDebug() {
        //console.log("Show debug", this.debugData);
        this.debug.emit(this.debugData);
    }

    chartClear() {
        this.chartOptions.series = [];

        if (this.chart && this.chart.series) this.chart.series.forEach(serie => {
            serie.remove();
        });
        /*
        this.bootstrap("chartClear");
        console.log("chartCleared---------- ", this.chart.series);
        if (this.chart.series === []) {
            this.chartOptions.series.forEach(serOption => {
                this.chart.addSeries(serOption);
            });

        }*/
    }

    /*
    UPDATE SERIES
    */
    chartUpdateSerie(index, data, debugInfos = "") {
        if (!this.chart) {
            // console.log("chartUpdateSerie CHART Null" + this.plot.plotType, this.plot, debugInfos, this.plot.section);
            return;
        }
        //this.optimiseChart.apply(this);
        if (this.chart.series && this.chart.series[index] && this.chartOptions.series[index]) {
            console.log("chartUpdateSerie() " + this.plot, data, "Updated: ", index, this.chart.series, this.chartOptions.series);
            this.chart.series[index].userOptions = this.chartOptions.series[index];
            // this.chart.series[index].userOptions = {...this.chartOptions.series[index]};//{x: 1, y: 2}, {x: 2, y: 4}
            this.chart.series[index].setData(data, true);

            //this.chart.series[index].userOptions.index = index;
            // console.log("chart UPDATE " + this.plot.id, data, "Updated: ", index, this.chart.series);
            //  this.chart.xAxis[0].userOptions.id = this.plot.id + "_x";
            //console.log("chartUpdateSerie CHART Null" + this.plot.plotType, this.plot.haveCategs());
            /*
             if (this.plot.haveCategs()) {
                //this.chart.xAxis[0].setCategories(undefined);
                this.chart.xAxis[0].setCategories(data.map(item => item.category));
                this.chart.xAxis[0].userOptions.type = "category";
                this.chart.xAxis[0].userOptions.startOnTick = true;
                this.chart.xAxis[0].userOptions.min = 0;
            } else {
                this.chart.xAxis[0].setCategories(undefined);
                this.chart.xAxis[0].userOptions.type = "linear";
                this.chart.xAxis[0].userOptions.categories = [];
            }*/
            /*
            if (this.plot.plotType === "scatter") {
                this.chart.xAxis[0].userOptions.tickInterval = undefined;
                this.chart.xAxis[0].userOptions.tickAmount = undefined;
                this.chart.xAxis[0].userOptions.tickPixelInterval = 80;
            }
        */
            //this.generateDebugData(data, index);

        } else console.log("chart ERRUPDTE " + this.plot.id, index + " Not FOUND", data, this.chart.series);
    }

    // called from OptimiseChart
    public chartUpdate(callerDebug = "", redraw = false) {
        if (!this.optimiseChart) {
            this.bootstrap('chartUpdate ' + this.plot.id);
            return;
        }
        console.info("chartUpdate() " + callerDebug, this.plot.id);
        if (this.chart && this.chartOptions) {
            // this.addAxis();
            // this.updateFlag = true;
            // this.setLegendOnTopIfNeeded();
            //
            // this.chart.update(this.chartOptions, redraw, redraw);
            this.renderChart("chartUpdate");
        }
    }

    setUnitFactor(newValue) {
        console.log("setUnitFactor::this.plot", this.plot, " New Unit: ", newValue);
        const plotUnit = new PlotUnit(this.plot.getKey(), this.site.selectedPdm.fluid);
        plotUnit.setUnit(newValue);
        this.site.saveSelectedPdmConfig('units', {key: this.plot.getKey(), plotUnit}, true);
    }

    //************************** LIFE CYCLE HOOKS
    openSnackBar(mess: string) {
        this.snackBar.open(mess, null, {
            duration: 5 * 1000,
        });
    }

    raiseError(message, year: number, severity, type = 'data') {
        this.errors.push({message, severity, ts: H.unixTs(), year, type});
    }

    manageException(e) {
        if (e.message.includes('dataForMonth.vars_meteo'))
            this.debug.emit({value: "Pas de données meteo, Synchronier SVP", arg: 'plotError'});
        if (e.message.includes('rawDataForYear is null'))
            this.debug.emit({value: "Données manquantes, ou décalées", arg: 'plotError'});
        if (e.message.includes('multiplyScalar'))
            this.debug.emit({value: "Erreur calcul matriciel", arg: 'plotError'});
        if (e.message.includes('EP-ERR'))
            this.debug.emit({value: e.message, arg: 'plotError'});
        console.error(e);
        this.openSnackBar(e.message);
    }

    onChartInstance(chart) {
        console.log('onChartInstance:' + this.plot.plotType, chart);
        this.chart = chart;
        this.bootstrap("chartInstance");
        this.setTitle();
        this.addAxis();
        this.renderChart('onChartInstance');
    }

    ngOnInit() {
        this.setTitle();
        console.info('ngOnInit():' + this.plot.plotType);

        this.historyCount = this.plot.yearCountHistory;
        //this.bootstrap("ngOnInit " + this.plot.id);
        this.subsStatMensConfig = this.site.siteSpecificEventTriggerer.subscribe(event => {
            //if (event === SiteService.STATMENS_CONFIG_RELOADED) {
            if (event === SiteService.PDMS_DATA_POPULATED) {
                console.log("StatmensPlotComponent:ngOnInit|RXJS:" + this.plot.section, event, " yearsAndConfigsCheck:", this.site.refYear);
                if (this.site.selectedPdm && this.chart !== undefined && this.site.refYear) {
                    // this.dataPdm.setStatMensConfig(this.site.statMensConfigs);
                    if (this.plot.section === Plot.SECTION_QUALITY || this.plot.section === Plot.SECTION_DERNIERE)
                        this.bootstrap("ngOnInit|RXJS " + this.plot.section);
                    if (this.plot.section === Plot.SECTION_SIGNATURE)
                        this.bootstrap("ngOnInit|RXJS " + this.plot.section);
                }
            }
        });
    }

    ngOnDestroy() {
        // if (this.chart) Highcharts.charts.splice(this.chart.index, 1);
    }

    ngOnChanges(changes: SimpleChanges) {
        console.info('ngOnChanges():' + this.plot.plotType, changes);
        this.bootstrap('ngOnChanges 2 ');
    }

    ngAfterViewInit(): void {
        // console.info('ngAfterViewInit():' + this.plot.plotType);

    }
}

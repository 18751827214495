import * as d3 from 'd3';
import {COLORS, defaultLayout, SYMBOLES} from '../constants'
import {
    bestFit,
    cleanSeries,
    configureTicks,
    configureTicksFromY1,
    enerplanWeek,
    findXValues,
    findYValues,
    rangeFromValues,
} from '../utils'
import * as assign from 'assign-deep';

const WeatherTitles = [
    'Rayonnement solaire global',
    'Enthalpie calculée',
]

class Signature {
    static layout = (data, layout) => {
        const {yaxis2} = layout
        const xValues = findXValues(data)
        const tickmode = 'tozero'
        const yRange = [
            d3.min(findYValues(data, (serie) => serie.mode !== 'lines')),
            d3.max(data, (serie) => d3.max(serie.y)),
        ]
        const yaxisTicks = configureTicks({range: yRange, nticks: 7, tickmode: 'tozero', padding: 0.4})
        let yaxis2Config = {}
        if (yaxis2) {
            // find values in the second axis
            const y2Values = findYValues(data, (serie) => serie.yaxis === 'y2')
            // calculate the range according to the tickmode (tozero or nonnegative)
            const y2Range = rangeFromValues(y2Values, tickmode)
            // get the configuration to display ticks
            const yaxis2Ticks = configureTicksFromY1(yaxisTicks, y2Range)
            yaxis2Config = {
                yaxis2: {
                    ...yaxis2Ticks,
                    side: 'right',
                    overlaying: 'y',
                    rangemode: 'tozero',
                    showgrid: false,
                },
            }
        }
        return assign({}, defaultLayout({data, layout}), {
            hovermode: 'closest',
            hoverformat: '',
            xaxis: {
                ...configureTicks({values: xValues, nticks: 7}),
            },
            yaxis: {
                ...yaxisTicks,
            },
            ...yaxis2Config,
        })
    }

    static getConfig(type, index) {
        return Signature.config[type](index)
    }

    /**
     * Multiple SignatureAdapter Graphs can be drawn in a single plot and
     * each signature graphs have different type of data.
     *
     * This config object hold options to be merged with a serie with x,y values
     * based on the signature number and type of the serie.
     */
    static config = {
        markers(index) {
            return {
                mode: 'markers',
                legendgroup: `group${index}`,
                cliponaxis: false,
                marker: {
                    color: COLORS[index],
                    symbol: SYMBOLES[index],
                    size: 8.5,
                },
            }
        },

        recentMarkers(index) {
            return {
                mode: 'markers+text',
                name: 'recentMarkers',
                legendgroup: `group${index}`,
                cliponaxis: false,
                marker: {
                    color: 'white',
                    symbol: SYMBOLES[index],
                    size: 7.5,
                    line: {
                        color: 'red',
                        width: 1.3,
                    },
                },
                textposition: 'right',
            }
        },

        lastMarker(index) {
            return {
                mode: 'markers+text',
                name: 'lastMarker',
                legendgroup: `group${index}`,
                cliponaxis: false,
                marker: {
                    color: 'red',
                    symbol: SYMBOLES[index],
                    size: 8.5,
                },
                textposition: 'right',
            }
        },

        signature(index) {
            return {
                mode: 'lines',
                name: 'signature',
                legendgroup: `group${index}`,
                line: {
                    color: COLORS[index],
                    width: 1.8,
                },
                connectgaps: true,
            }
        },

        divider(index) {
            return {
                mode: 'lines',
                name: 'divider',
                legendgroup: `group${index}`,
                line: {
                    color: COLORS[index],
                    dash: 'dashdot',
                    width: 1.8,
                },
            }
        },
    }

    static reduce(data, layout) {
        const {title} = layout
        const isWeather = WeatherTitles.includes(title)
        const nextData = Signature.reduceData(data, {
            calculateBestFit: isWeather,
            disableLegend: isWeather,
        })
        const nextLayout = Signature.reduceLayout(layout, nextData)
        return {
            data: nextData,
            layout: nextLayout,
        }
    }

    /**
     * calculate a regression trace for a group of series
     * @param data
     * @param group
     * @return {{x: *, y: *, type: string, group: *}}
     */
    static getRegressionTrace(data, group) {
        const trace = data
            .filter(serie => serie.group === group && /marker/i.test(serie.type))
            .reduce((acc, serie) => ({
                x: [...acc.x, ...serie.x],
                y: [...acc.y, ...serie.y],
            }), {x: [], y: []})
        return {
            ...bestFit(trace),
            type: 'signature',
            group,
        }
    }

    /**
     * Adapt the data array adding options to be correctly
     * understood by Plotly.js
     *
     * @param: data array containing data series that should be drawn as curve
     * @returns: A new data object that can be understood by Plotly
     */
    static reduceData(data, opts) {
        const {disableLegend, calculateBestFit} = opts || {}

        // Hold trace names for each group
        // all traces inside the same group share the same name
        const names = []
        data.forEach(serie => {
            const group = serie.group || 0
            names[group] = names[group] || serie.name
        })

        // Determine if a trace should be visible in the legend
        // only the first trace of the list of types should.
        const showLegend = (series, group, type) => {
            if (disableLegend) {
                return false
            }
            const types = ['markers', 'recentMarkers', 'lastMarker', 'signature', 'divider']
            const typeIndex = types.indexOf(type)
            if (typeIndex < 0) {
                // Unknown type
                return false
            }
            // Show legend for the current type
            // if no trace of previous type was found
            const previousTypes = types.slice(0, typeIndex)
            return series.findIndex(s => s.group === group && previousTypes.includes(s.type)) === -1
        }

        const signatures = []
        if (calculateBestFit) {
            const trace = Signature.getRegressionTrace(data, 0)
            if (trace) {
                signatures.push(trace)
            }
        }

        return cleanSeries([...data, ...signatures])
            .map((serie, index, series) => {
                const {group = 0, dateStamps = []} = serie;
                const config = Signature.getConfig(serie.type, group)

                // Add week numbers as text for most recent markers
                // Add week numbers as hover infos for old markers
                let text
                let weeks
                if (serie.type === 'markers') {
                    weeks = dateStamps.slice(0, serie.x.length).map(enerplanWeek)
                } else if (serie.type === 'recentMarkers') {
                    const numMarkers = serie.x.length
                    weeks = dateStamps.slice(-numMarkers - 1, -1).map(enerplanWeek)
                    text = [...weeks]
                } else if (serie.type === 'lastMarker') {
                    weeks = dateStamps.slice(-1).map(enerplanWeek)
                    text = [...weeks]
                }

                return {
                    ...serie,
                    ...config,
                    name: names[group],
                    showlegend: showLegend(series, group, serie.type),
                    text,
                    hoverinfo: weeks ? 'x+y+text+name' : undefined,
                    hovertext: weeks ? weeks.map(w => `sem. ${w}`) : undefined,
                }
            })
    }

    /**
     * Merge default signature layout with the layout
     * passed as parameter which may contain minimal info
     * like titles for x axis and y axis
     */
    static reduceLayout(layout, data) {
        return assign({}, Signature.layout(data, layout), layout)
    }
}

export default Signature

<mat-card class="p-0">
    <div [ngClass]="{'page-break':index>0}" class="page">

        <div class="feeter">
            <div class="feeter-l">
                Date:{{today | date:'M.d.y'}} {{today | date:'HH:mm'}}
            </div>
            <div class="feeter-c">
                <b>{{this.page.title}}</b>
            </div>
            <div class="feeter-r">
                Page: {{page.position}}
            </div>
        </div>

        <table class="print-table">

            <ng-container *ngFor="let row of [0,1]">
                <ng-container *ngIf="row===0">
                    <tr>
                        <ng-container *ngFor="let gr of graphsRow1">
                            <td [colSpan]="gr.shape.cols" class="title">
                                {{graphsTitles[gr.key]}}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <ng-container *ngFor="let gr of graphsRow1">
                            <td [colSpan]="gr.shape.cols">
                                <img [id]="gr.key"
                                     *ngIf="plotsToPrint[gr.key]"
                                     [src]="sanitizer.bypassSecurityTrustUrl(plotsToPrint[gr.key])"/>
                            </td>
                        </ng-container>
                    </tr>
                </ng-container>
                <ng-container *ngIf="row===1&&graphsRow2.length>0">
                    <tr>
                        <ng-container *ngFor="let gr of graphsRow2">
                            <td [colSpan]="gr.shape.cols" class="title">
                                {{graphsTitles[gr.key]}}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <ng-container *ngFor="let gr of graphsRow2">
                            <td [colSpan]="gr.shape.cols">
                                <img [id]="gr.key"
                                     *ngIf="plotsToPrint[gr.key]"
                                     [src]="sanitizer.bypassSecurityTrustUrl(plotsToPrint[gr.key])"/>
                            </td>
                        </ng-container>
                    </tr>
                </ng-container>

                <tr *ngIf="row===1&&graphsRow2.length===0">
                    <td [colSpan]="3"  class="title">

                    </td>
                </tr>

            </ng-container>

        </table>

        <div class="feeter">
            <div class="feeter-l">
                Optimise v2.0
            </div>
            <div class="feeter-c">
                <b>enerplan s.a.</b> - 1029 VILLARS-STE-CROIX - Tél. +41 21 546 05 00 - info@enerplan.swiss
            </div>
            <div class="feeter-r">
            </div>
        </div>
    </div>
</mat-card>

<div class="page-wrap h-full">
    <div class="session-form-hold">
        <mat-progress-bar mode="determinate" class="session-progress"></mat-progress-bar>
        <mat-card>
            <mat-card-content>
                <div class="text-center pt-8 pb-16">
                    <img width="60px" src="assets/images/enerplan.svg" alt="" class="mb-8">
                    <p class="text-muted m-0">Veuillez saisir votre nouveau mot de passe</p>
                </div>
                <form #fpForm="ngForm" (ngSubmit)="updatePassword()">

                    <div class="">
                        <mat-form-field class="full-width">
                            <input matInput name="newPassword" required [(ngModel)]="newPassword"
                                   type="password" #pass1 placeholder="Mot de passe" value="">
                        </mat-form-field>

                        <mat-form-field class="full-width">
                            <input matInput name="email" required [(ngModel)]="newPasswordConf"
                                   type="password" #pass2 placeholder="Confirmer le mot de passe" value="">
                        </mat-form-field>

                        <small *ngIf="fpForm.errors && (fpForm.dirty || fpForm.touched) && (fpForm?.errors?.required)"
                               class="form-error-msg"> Email is required </small>
                        <small *ngIf="fpForm.errors && (fpForm.dirty || fpForm.touched) && (fpForm?.errors?.required)"
                               class="form-error-msg"> Email is required </small>
                        <small *ngIf="fpForm.errors && (fpForm.dirty || fpForm.touched) && (fpForm?.errors?.required)&&pass1.value!==pass2.value"
                               class="form-error-msg"> Les mots de passe ne concordent pas </small>
                    </div>

                    <button mat-raised-button class="mat-primary full-width mb-16"
                            [disabled]="fpForm.invalid||pass1.value!==pass2.value">
                        Envoyer
                    </button>
                    <div class="text-center">
                        <a [routerLink]="'/sessions/login'" class="mat-primary text-center full-width">
                            Connection
                        </a>
                        <span fxFlex></span>
                        <a [routerLink]="'/'" class="mat-primary text-center full-width">
                            Accueil
                        </a>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>


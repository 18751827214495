import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {PdmFieldConfig, PdmRegDef, PdmRegSyncConfig} from "../../../../shared/models/PdmRegDef";
import {AppService} from "../../../../shared/services/app.service";
import {SiteService} from "../../../../shared/services/site.service";
import {K} from "../../../../shared/models/K";

@Component({
    selector: 'app-form-field',
    template: `
        <div class="p-4 m-4 field-configurator-bloc" *ngIf="fieldConf">
            <div fxLayout="row" fxLayoutAlign="space-between start" *ngIf="optionsForDropDown">
                <mat-form-field fxFlex="75" class="mr-4">
                    <mat-label class="color-blue" *ngIf="field==='d'"><b>[d]</b>: Nature de donnée</mat-label>
                    <mat-label class="color-blue" *ngIf="field==='t'"><b>[t]</b>: Type de tarif</mat-label>
                    <mat-label class="color-blue" *ngIf="field==='c'"><b>[c]</b>: Mode de calcul du cout</mat-label>
                    <mat-select [(ngModel)]="fieldConf.type" [value]="fieldConf.type">
                        <mat-option [value]="key" *ngFor="let key of optionsForDropDown">
                            <span *ngIf="field==='t'">{{ tariffNamesMap[key] }}</span>
                            <span *ngIf="field==='c'">{{ calcTypesLabelsMap[key] }}</span>
                            <span *ngIf="field==='d'">{{ key }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex="180px">
                    <mat-label>Multiplicateur</mat-label>
                    <input matInput [(ngModel)]="fieldConf.conv_fact" placeholder="Multiplicateur {{field|uppercase}}">
                </mat-form-field>

            </div>
            <div fxLayout="row">
                <mat-form-field class="full-width" *ngIf="fieldConf.type!=='NA'">
                    <mat-label><b>[{{ field }}]: </b>Mode de saisie</mat-label>
                    <mat-select [(ngModel)]="fieldConf.data_entry"
                                [disabled]="entryTypeEditionDisabled"
                                [value]="fieldConf.data_entry"
                                (selectionChange)="entryType.emit({field,value:$event.value})">
                        <mat-option [value]="''"><i>NA</i></mat-option>
                        <mat-option [value]="'ENTRY_MANUAL'" [disabled]="getCantEntryBeManual" fxLayout="row">
                            <span fxFlex>Saisie manuelle ou Xls</span>
                            <span *ngIf="getCantEntryBeManual" class="warning">
                                Peut pas avoir [t] et [c] en saisie, un des deux doit etre calculé
                            </span>
                        </mat-option>
                        <ng-container *ngIf="field!=='i'">
                            <mat-option [value]="'CALC_REG'">
                                <span>Calculée</span>
                                <p *ngIf="entryTypeEditionDisabled" class="warning">
                                    Obligatoire car [i] est saisi
                                </p>
                            </mat-option>
                            <mat-option [value]="'CALC_MACRO'">Macro</mat-option>
                        </ng-container>

                        <mat-option [value]="'ENTRY_LINK_EP'" [disabled]="!epLinksForFluid||epLinksForFluid.length===0">
                            <span fxFlex>Lien EO</span>
                            <span *ngIf="!epLinksForFluid||epLinksForFluid.length===0" class="warning">
                                Pas de liens EP trouvés pour [{{ site.clientSite.ep_db_name }}] Et [{{ site.selectedFluid|uppercase }}]
                            </span>

                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="row" *ngIf="fieldConf.data_entry==='CALC_MACRO'">
                <mat-form-field class="full-width macro">
                    <span matPrefix style="font-size: 9px">MACRO de  {{ field }}</span>
                    <input matInput [(ngModel)]="fieldConf.macro" placeholder="Macro pour {{field|uppercase}}" (focus)="showMacrosArgs.emit(field)">
                    <mat-icon matSuffix (click)="fieldConf.macro=''">clear</mat-icon>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center"
                 *ngIf="fieldConf.data_entry==='ENTRY_LINK_EP'">
                <mat-form-field fxFlex>
                    <mat-label>Nom interne de BD</mat-label>
                    <mat-select [(ngModel)]="fieldConf.ep_link_key"
                                [value]="fieldConf.ep_link_key">
                        <mat-option [value]="''"> Pas de Synchronisation</mat-option>
                        <mat-option [value]="d.id" *ngFor="let d of epLinksForFluid">
                            <b> {{ d.id }}</b>  {{ d.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div fxFlex="180px" class="snifer-col-handle-choser" *ngIf="fieldConf&&fieldConf.ep_link_key"
                     (click)="myApp.globalPopupsIgniter.next({type:'sniffer',arg:fieldConf.ep_link_key,field})">
                    Sync: <br>[{{ fieldConf.ep_link_key }}]

                </div>

            </div>
        </div>
    `,
    styleUrls: ['../shared.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFieldComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {
    @Input('reg') reg: PdmRegDef;
    @Input('field') field: string;
    @Input('append') append: string;//string to append
    @Output() showMacrosArgs: EventEmitter<any> = new EventEmitter<any>();
    @Output() entryType: EventEmitter<any> = new EventEmitter<any>();

    //fieldConf: PdmFieldConfig = new PdmFieldConfig({});
    typesD = PdmRegDef.TYPES_OF_D;
    typesT = PdmRegDef.TYPES_OF_T;
    typesC = PdmRegDef.TYPES_OF_C;
    tariffNamesMap = {
        'NA': "Non applicable",
        'NT': "NT: Normal tarif",
        'BT': "BT: Bas Tarif",
        'HT': "HT: Haut Tarif",
        'FT': "FT: Tarif fixe (taxes/abo etc)",
    };
    calcTypesLabelsMap = {
        'NA': "N/A",
        'EO': "EO: Energy Only",
        'ED': "ED: Energy + Days",
        'DO': "DO: Days Only",
        'MN': "MN: Manual entry",
        'MC': "MC: Macro",
    };
    entryTypeEditionDisabled = false;
    arr = K.months;
    fieldConf: PdmFieldConfig;
    optionsForDropDown = [];
    mapFieldsToSnifferCol = {
        mon: 'i', wed: 'i', month_end: 'i', hebdo: 'd', daily: 'd'
    };
    dbNames = [];
    epLinksForFluid = [];
    timer: any;

    constructor(public myApp: AppService, public site: SiteService, private cdr: ChangeDetectorRef) {

    }

    get getCantEntryBeManual() {
        return this.field === 'c' && this.reg.t_conf.data_entry === 'ENTRY_MANUAL'
            || this.field === 't' && this.reg.c_conf.data_entry === 'ENTRY_MANUAL';
    }

    ngOnInit(): void {
        this.bootstrap();

        this.timer = setInterval(() => {
            this.updateReg();
        }, 500);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.initEpLinksForFluid();
        if (changes['reg']) {
            this.bootstrap();
        } else {

        }
    }

    //2024
    initEpLinksForFluid() {
        if (true) return;///TODO: check this feature and refactor it
        this.epLinksForFluid = [];
        if (!this.site.selectedSiteEpDataBase) {
            this.myApp.showError("Pas de liens EP trouvés");
            return;
        }
        Object.values(this.site.selectedSiteEpDataBase).forEach(v => {
            if (v['fluid'].toLowerCase() === this.site.selectedFluid.toLowerCase()
                && v['batID'] === this.site.clientSite.ep_bat_id) this.epLinksForFluid.push(v);
        });
        //  console.log("eplink", v['fluid'].toLowerCase(), 'this.site.selectedFluid', this.site.selectedFluid.toLowerCase());

    }

    bootstrap() {
        if (this.field === 'i') {
            this.optionsForDropDown = null;
            this.fieldConf = this.reg.i_conf;
        }
        if (this.field === 'd') {
            this.optionsForDropDown = this.typesD;
            this.fieldConf = this.reg.d_conf;
        }
        if (this.field === 't') {
            this.optionsForDropDown = this.typesT;
            this.fieldConf = this.reg.t_conf;
        }
        if (this.field === 'c') {
            this.optionsForDropDown = this.typesC;
            this.fieldConf = this.reg.c_conf;
        }
        if (this.fieldConf && this.field === 'i') this.fieldConf = new PdmFieldConfig(this.reg.i_conf);
        if (this.fieldConf && this.field === 'd') this.fieldConf = new PdmFieldConfig(this.reg.d_conf);
        if (this.fieldConf && this.field === 't') this.fieldConf = new PdmFieldConfig(this.reg.t_conf);
        if (this.fieldConf && this.field === 'c') this.fieldConf = new PdmFieldConfig(this.reg.c_conf);

        if (!this.fieldConf && this.field === 'i') this.fieldConf = new PdmFieldConfig();
        if (!this.fieldConf && this.field === 'd') this.fieldConf = new PdmFieldConfig();
        if (!this.fieldConf && this.field === 't') this.fieldConf = new PdmFieldConfig();
        if (!this.fieldConf && this.field === 'c') this.fieldConf = new PdmFieldConfig();

    }

    updateReg() {
        if (this.reg) {
            if (this.field === 'i') {
                this.reg.i_conf = this.fieldConf;
            }
            if (this.field === 'd') {
                if (this.reg.i_conf && (this.reg.i_conf.data_entry === 'ENTRY_MANUAL' || this.reg.i_conf.data_entry === 'ENTRY_LINK_EP')) {
                    this.entryTypeEditionDisabled = true;
                    this.fieldConf.data_entry = "CALC_REG";
                    // console.log("i est saisi:", this.reg.i_conf);
                } else {
                    this.reg.is_k_visible = false;
                    this.entryTypeEditionDisabled = false;
                }
                this.reg.d_conf = this.fieldConf;
            }
            if (this.field === 't') {
                this.reg.t_conf = this.fieldConf;
            }
            if (this.field === 'c') {
                this.reg.c_conf = this.fieldConf;
            }
        }
    }

    ngAfterViewChecked() {
        // console.log("ngAfterViewChecked()");
        // this.dateNow = new Date();
        this.cdr.detectChanges();
    }

    ngOnDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
}

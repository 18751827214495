import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from "rxjs/Observable";
import {FileUploader} from "ng2-file-upload";
import {UploadMetadata, UploadTaskSnapshot} from "@angular/fire/compat/storage/interfaces";
import {AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask} from "@angular/fire/compat/storage";
import {AppService} from "../../shared/services/app.service";
import {SiteService} from "../../shared/services/site.service";
import {H} from "../../shared/helpers/H";
import {StorageDocument} from "../../shared/models/models";

@Component({
    selector: 'app-storage-uploader',
    template: `
        <mat-card class="m-0 p-4">
            <h2 class="p-8 m-0 color-blue" *ngIf="type==='Document'">Document à uploader</h2>
            <h2 class="p-8 m-0 color-blue" *ngIf="type==='Thread'">Ajouter annexe</h2>
            <mat-card-content class="p-8">
                <div fxLayout="column">
                    <input type="file" ng2FileSelect [uploader]="uploader" (change)="selectFile($event)"/>
                </div>
                <div *ngIf="uploader.queue.length">
                    <div *ngFor="let item of uploader.queue" fxLayout="column">
                        <div>{{ item?.file?.size / 1024 / 1024 | number:'.2' }} Mo</div>
                        <div fxFlex="1 1">
                            <div class="progress" style="margin: 10px;height: 30px">
                                <div class="progress-bar" role="progressbar"
                                     [ngStyle]="{ 'width': item.progress + '%' }"></div>
                                <mat-progress-bar
                                        class=""
                                        color="primary"
                                        mode="determinate"
                                        [value]="uploadProgress | async">
                                </mat-progress-bar>
                            </div>
                        </div>
                        <div>
                            <mat-divider></mat-divider>
                            <br>
                            <button mat-raised-button
                                    class="mat-primary "
                                    (click)="upload(item)"
                                    [disabled]="item.isReady || item.isUploading || item.isSuccess">Uploader
                            </button>
                            <button mat-raised-button
                                    class="mat-warn  m-left"
                                    (click)="item.remove()">Annuler
                            </button>
                        </div>
                    </div>
                </div>
            </mat-card-content>

        </mat-card>
    `,
    styleUrls: ['./subs.scss']
})
export class StorageUploaderComponent implements OnInit {
    @Output('uploadComplete') uploadComplete = new EventEmitter<object>();
    @Input('type') type = 'Document';

    public uploader: FileUploader = new FileUploader({url: 'upload_url'});
    task: AngularFireUploadTask;
    uploadProgress: Observable<any>;

    uploadState: any;
    metaData: Observable<any>;
    fileStorageRef: AngularFireStorageReference;
    documentToStore: StorageDocument;

    constructor(public storage: AngularFireStorage, public myapp: AppService, public site: SiteService) {

    }

    ngOnInit() {
        this.documentToStore = new StorageDocument();
        this.documentToStore.user_metas = this.myapp.user.metas;
        this.documentToStore.uid_domain = this.site.uid;
        this.documentToStore.uid_user = this.myapp.user.uid_bdd;
        this.documentToStore.ts = H.unixTs();
    }

    selectFile(e) {
        console.log(this.uploader.queue, e);
        if (this.uploader.queue.length > 1) {
            this.uploader.queue[0] = this.uploader.queue[1];
            // delete this.storage-uploader.queue[1];
            this.uploader.queue.pop();
        }
    }

    upload(e) {
        const file = e.some;
        this.documentToStore.type = this.type;
        this.documentToStore.title = file.name;
        this.documentToStore.mime_type = file.type;
        if (this.type === 'Thread')
            this.documentToStore.chapter = "13. Annexes APE";//Annexes APE
        this.documentToStore.ts_file_created = file.lastModified.toString();
        console.log("Upload file", file);
        this.site.api.newDocument(this.documentToStore).subscribe(resp => {
            if (resp.status == 1) {
                const path = "documents/" + this.site.clientSite.ref + "/" + resp.body.uid;

                this.documentToStore.uid = resp.body.uid;
                this.documentToStore.user_metas = this.myapp.user.metas;
                this.documentToStore.full_path = path;
                this.fileStorageRef = this.storage.ref(path);
                this._upload(e, resp.body.title);
            }
        });
    }

    _upload(e, bddDocTitle: string) {
        const file = e.some;
        console.log("file", file);
        const metadata: UploadMetadata = {
            customMetadata:
                {
                    'name': this.documentToStore.title,
                    'type': this.type,
                    'lastModified': file.lastModified
                }
        };

        this.task = this.fileStorageRef.put(file, metadata);

        let f: UploadTaskSnapshot;
        this.uploadState = this.task.snapshotChanges().subscribe((a) => {
                console.log("Upload state:" + a.totalBytes + "|" + a.bytesTransferred, a);
                f = a;
                if (a.totalBytes === a.bytesTransferred) {

                }
            },
            (error) => {
                // upload failed
                console.log("Error upload");
                console.log(error);
                this.uploadComplete.emit({act: 'error', error});
                this.uploader = new FileUploader({url: 'upload_url'});
            },
            () => {
                this.metaData = this.fileStorageRef.getMetadata();
                this.metaData.subscribe(v => {
                    this.documentToStore.storage_metas = v;
                    this.site.api.saveDocument(this.documentToStore).subscribe(resp => {
                        this.uploadComplete.emit({act: 'done', doc: this.documentToStore});
                        this.uploader = new FileUploader({url: 'upload_url'});
                    });
                    console.log("metaData", v);
                });
                console.log("Complete");
            });
        this.uploadProgress = this.task.percentageChanges();
    }
}

import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AppService} from "../../shared/services/app.service";
import {ApiService} from "../../shared/services/api.service";
import {SiteService} from "../../shared/services/site.service";
import {LayoutService} from "../../shared/services/core/layout.service";
import {DomSanitizer} from "@angular/platform-browser";
import {DatePipe} from "@angular/common";
import moment from "moment";
import {Action, ActionAssignee} from 'app/shared/models/Action.model';
import {Comment, ObsWrapper, ReportAnnotation, User} from 'app/shared/models/models';
import {AnnotationService} from "./annot.service";
import {egretAnimations} from "../../shared/animations/egret-animations";
import {ProtoReportPage} from "../../shared/models/ProtoHebdo";
import {Observable} from "rxjs";
import {Optimise} from "../../shared/helpers/Optimise";
import {PlotlyService} from "angular-plotly.js";

@Component({
    selector: 'plot-comm',
    template: `
        <div [id]="'comment-'+comment.uid" class="p-8 m-8 page" (dblclick)="myapp.showConsole(comment)">
            <table class="plot-comm">
                <ng-container *ngIf="visualKeys[0] as visual1">
                    <tr>
                        <td class="header-row">
                            <b>Page: {{ this.comment.page_position }}</b> |
                            {{ titleByVisualKey.get(visual1) }}
                            <!--
                            <button (click)="switchSizeTo('small');annotServ.commentType='all';scanComments();">Petit</button>
                            <button (click)="switchSizeTo('std')">Normal</button>
                            <button (click)="switchSizeTo('big')">Grand</button>
                            <button mat-raised-button class="btn-xs-25" (click)="genPngPlot()">Redessiner</button>
                            -->
                        </td>
                        <td class="plot-holder-td" rowspan="2" [style.height.px]="plotHeight">
                            <img *ngIf="plotsToPrint[visual1]" [id]="'img-'+visual1"
                                 [src]="sanitizer.bypassSecurityTrustUrl(plotsToPrint[visual1])"/>
                            <plotly-plot #plot *ngIf="plotsByVisualKey.get(visual1)&&!plotsToPrint[visual1]" class="plotly-plot"
                                         [divId]="visual1"
                                         [class]="'plot-'"
                                         [data]="plotsByVisualKey.get(visual1).plotData.data"
                                         [layout]="plotsByVisualKey.get(visual1).plotData.layout"
                                         [updateOnLayoutChange]="true"
                                         [config]="defaultConfig"
                                         [useResizeHandler]="false">
                            </plotly-plot>
                            <div class="m-8" *ngIf="!comment.page_position" inline>Commentaire orphelin</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="message-list">
                                <div class="comment-header" fxLayout="row" fxLayoutAlign="space-between center">
                                    <table>
                                        <tr>
                                            <td class="color-blue name">
                                                {{ comment.display_name }}
                                                <i *ngIf="comment.page_position">(p {{ comment.page_position }})</i>
                                                <span class="completed" *ngIf="comment.status==='COMPLETED'">Terminé</span>
                                            </td>
                                            <td class="comment-ago">
                                                <span class="text-muted"> {{ comment.ts_created * 1000|amTimeAgo }} </span>
                                            </td>
                                            <td class="comment-date">
                                                <span>{{ comment.ts_created * 1000  | date }}</span>
                                            </td>
                                        </tr>
                                    </table>


                                    <mat-icon *ngIf="comment.status==='COMPLETED'">check</mat-icon>
                                    <!--
                                    <mat-checkbox *ngIf="myapp.isAdminOrSuperUser"
                                                  [checked]="comment.status==='COMPLETED'"
                                                  (change)="annotServ.setCommentStatus(comment.uid,$event.checked)" name="agreed" class="ml-8">
                                    </mat-checkbox>
                                    -->
                                </div>

                                <div class="comment-title" [innerHTML]="comment.title"></div>
                                <div class="final-comment" [innerHTML]="comment.finalComment"></div>

                                <!--
                                <div class="text-muted " fxLayout="row" fxLayoutAlign="space-between center"
                                     [@animate]="{ value: '*', params: { y: '100px', delay: '200ms' } }"
                                     *ngIf="comment.repliesItems.length||(annotServ.selectedComment&&annotServ.selectedComment.uid===comment.uid)">
 

                                    <span fxflex></span>
                                    <span fxFlex="60px" class="text-green cursor-pointer" (click)="commentReplyMode=true; "> Répondre  </span>
                                </div>-->

                                <div *ngIf="comment.repliesItems.length" class="comment-reply-bloc">
                                    <div *ngFor="let rep of comment.repliesItems" class="reply-bloc">
                                        <b>{{ rep['name'] }}</b> |
                                        <small class="text-muted">{{ rep['timestamp'] | date }}</small>
                                        <div>{{ rep['message'] }}</div>
                                    </div>
                                </div>
                                <!--
                                <div class="comment-reply-form">
                                    <div *ngIf="commentReplyMode&&canCommentReply" fxLayout="column">
                                        <mat-form-field>
                                            <mat-label>Votre réponse</mat-label>
                                            <textarea matInput #autosize cdkTextareaAutosize="true" [cdkAutosizeMinRows]="0"
                                                      [(ngModel)]="annotServ.replyText" placeholder="Réponse  ici..."></textarea>
                                        </mat-form-field>

                                        <mat-divider></mat-divider>
                                        <div fxLayout="row">
                                            <button class="btn-xs" mat-stroked-button (click)="commentReplyMode=false;">Annuler</button>
                                            <div fxFlex></div>
                                            <button-loading [btnClass]="'btn-xs mat-stroked-button'"
                                                            [loading]="annotServ.commentSavingInProgress"
                                                            [raised]="false"
                                                            color="primary"
                                                            (click)="annotServ.sendReply(comment.uid)">Sauvegarder
                                            </button-loading>
                                        </div>
                                    </div>
                                    <div fxLayout="row" *ngIf="!commentReplyMode">
                                        <button class="btn-xs" mat-stroked-button *ngIf="canCommentReply" (click)="commentReplyMode=true">Répondre
                                        </button>
                                    </div>
                                </div>-->
                            </div>
                        </td>

                    </tr>
                </ng-container>

            </table>

        </div>
    `,
    styleUrls: ['./proto-hebdo.component.scss'],
    animations: egretAnimations
})
export class PlotComComponent implements OnInit, OnChanges {
    @Input('index') index: number;
    @Input('year') year: number;
    @Input('comment') comment: Comment;
    @Input('reportDate') reportDate: string;
    @Input('forceUpdate') forceUpdate: number;
    @Output('cb') cb: EventEmitter<any> = new EventEmitter<any>();

    public defaultConfig = {
        // hide plotly logo
        displayLogo: false,
        // disable native (plotly.js) toolbar
        displayModeBar: false,
        editable: false,
        responsive: true,
        edits: {
            annotationPosition: true,
            annotationTail: true,
            annotationText: false,
            shapePosition: false,
            axisTitleText: false,
            colorbarPosition: false,
            colorbarTitleText: false,
            legendPosition: false,
            legendText: false,
            titleText: false,
        },
        locale: 'fr-CH',
    };

    commentReplyMode = false;
    canCommentReply = true;
    plotData = null;
    pleaseWait = true;
    visualKeys: string[] = [];
    annotationsByVisualKey: Map<string, ReportAnnotation> = new Map<string, ReportAnnotation>();
    plotsByVisualKey: Map<string, any> = new Map<string, any>();
    titleByVisualKey: Map<string, any> = new Map<string, any>();

    plotWidth = 750;
    plotHeight = 500;
    public plotsToPrint = {};

    constructor(public myapp: AppService,
                public api: ApiService,
                public site: SiteService,
                public annotServ: AnnotationService,
                public sanitizer: DomSanitizer, public plotlyServ: PlotlyService,
                public layout: LayoutService) {
    }

    ngOnInit(): void {
        this.scanComments();
    }

    scanComments() {
        this.comment.annotationsMap.forEach((v, k) => {
            this.annotationsByVisualKey.set(v.visual_key, v);
            this.visualKeys.push(v.visual_key);
        })
        if (this.visualKeys.length > 0) {
            this.getPlot(this.reportDate);
        }
    }

    getPlot(date: string = null) {
        this.api.getPageVisuals(this.site.clientSite.ref, date, this.comment.key_page, this.comment.page_position ? this.comment.page_position : 0)
            .subscribe(resp => {
                if (!resp) {
                    console.error('getPageVisuals', "resp null", resp);
                    return;
                }
                this.pleaseWait = false;
                if (resp.status == 0) {
                    console.error("GetPageVisual:: Visuel non trouvé, Probablement enlevé du rapport");
                }
                // console.log("PlotComComponent:getPageVisuals()  :", resp.body, resp.body[this.visualKeys[0]]);
                const visualKey1 = this.visualKeys[0];
                if (resp.body && resp.body[visualKey1]) {
                    const adaptedPlot = Optimise.fixRawGraphData(resp.body[visualKey1]);

                    if (adaptedPlot.plotData.layout) {
                        const currTitle = adaptedPlot.plotData.layout['title']
                        this.titleByVisualKey.set(visualKey1, currTitle);

                        adaptedPlot.plotData.layout['width'] = 750;
                        adaptedPlot.plotData.layout['height'] = 450;
                        if (typeof adaptedPlot.plotData.layout['title'] === 'object')
                            this.titleByVisualKey[visualKey1] = '';
                        adaptedPlot.plotData.layout['title'] = "";
                    }

                    this.plotsByVisualKey.set(visualKey1, adaptedPlot);
                    this.showAnnotations(visualKey1);
                    this.genPngPlot();
                }

            }, err => {
                console.log(" wrapObserver() AFTER---ERROR ::", err, this.comment);
            });
    }

    showAnnotations(visualKey) {
        const plot = this.plotsByVisualKey.get(visualKey);
        // console.log("showAnnotations:", visualKey, plot, this.comment);
        plot.plotData.layout.annotations = [];
        this.comment.annotationsMap.forEach((it, k) => {
            it = new ReportAnnotation(it);
            it.styling(this.annotServ.selectedComment);
            plot.plotData.layout.annotations.push({...it});
        });
    }

    switchSizeTo(target: string) {
        const visualKey = this.visualKeys[0];
        const adaptedPlot = this.plotsByVisualKey.get(visualKey);
        if (target === 'small') {
            this.plotHeight = 250;
            this.plotWidth = 750;
        }
        if (target === 'std') {
            this.plotWidth = 750;
            this.plotHeight = 420;
        }
        if (target === 'big') {
            this.plotWidth = 750;
            this.plotHeight = 700;
        }
        adaptedPlot.plotData.layout['width'] = this.plotWidth;
        adaptedPlot.plotData.layout['height'] = this.plotHeight;
    }

    genPngPlot() {
        const visualKey = this.visualKeys[0];
        const fixedGraph = this.plotsByVisualKey.get(visualKey);
        let elem = document.createElement('div') as HTMLDivElement;
        let annotsToShow: ReportAnnotation[] = [];

        let layout = fixedGraph.plotData['layout'];
        let shape = fixedGraph.shape;
        const pageCols = 3;//this.page.shape.cols
        const qualityFact = 1;//this.page.shape.cols
        layout.width = this.plotWidth * qualityFact;
        layout.height = this.plotHeight * qualityFact;
        layout['legend'] = {
            bgcolor: "transparent",
            orientation: "h",
            tracegroupgap: 0,
            traceorder: "normal",
            borderwidth: 1,
            pad: 0,
            bordercolor: '#fff',
            x: 0.5,
            xanchor: "center",
            y: 1.01,
            yanchor: "bottom",
            font: {
                family: 'sans-serif',
                size: 10,
                color: '#333'
            }
        };
        layout['margin'] = {
            pad: 1, r: 50,
            t: 5,
            b: 40,
            l: 50
        };

        // layout.annotations = this.getStyledAnnotsforVisualKey(fixedGraph.key);

        //console.log('generatePlotsToPngOfCurrentPage', fixedGraph, layout, annotsToShow);
        this.plotlyServ.newPlot(elem, fixedGraph.plotData['data'], layout, {staticPlot: false}).then(res => {
            this.plotlyServ.getPlotly()
                .then(plotly => {
                    return plotly.toImage(res, {
                        format: 'svg',
                        width: layout['width'],
                        height: layout.height
                    });
                })
                .then(i => {
                    this.plotsToPrint[visualKey] = i;
                });
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log("PlotComComponent:ngOnChanges()  :");
    }
}

import {AfterViewInit, Component, Input, OnInit} from "@angular/core";
import {SiteService} from "../../shared/services/site.service";
import {ProtoReport} from "../../shared/models/ProtoHebdo";
import {AppService} from "../../shared/services/app.service";

@Component({
    selector: 'report-cover',
    template: `
        <div class="proto-cover-bloc">
            <table>
                <tr>
                    <td colspan="2" class="title"> Protocole Hebdomadaire sem. {{report.week}} - {{report.date_str_full}}</td>
                </tr>
                <tr>
                    <td class="pane-left">
                        <div fxLayout="row" *ngIf="myapp.isAdminOrSuperUser">
                            <button mat-raised-button class=" m-4"
                                    *ngIf="site.selectedSiteReport.pub_status!=='PENDING'"
                                    (click)="revertToPending()">Revert to pending
                            </button>
                            <div fxFlex></div>
                            <button mat-raised-button class=" m-4" color="primary"
                                    *ngIf="site.selectedSiteReport.pub_status==='PENDING'"
                                    (click)="validatePublication()">Publication OK
                            </button>
                            <button mat-raised-button class=" m-4" color="accent"
                                    *ngIf="site.selectedSiteReport.pub_status==='PUB_OK'"
                                    (click)="setReportReadyToComment()">Prêt au partage
                            </button>
                        </div>
                        <div class="section-body">
                            Ce protocole hebdomadaire est édité et généré avec le logiciel
                            <b class="font-weight-bold color-blue">OPTIMISE©</b>, propriété d'Enerplan SA.
                            <br>
                            <br>
                            Les données représentées sont issues de relevés et d'enregistrements des états réels des installations du site. Elles sont en partie le résultat des
                            enregistrements des acquisiteurs de données baptisés
                            <b class="font-weight-bold color-blue">RADIS</b>, développés par Enerplan SA.

                            <br><br>

                            Les données météorologiques utilisées et représentées sont des informations officielles de Météo Suisse.
                        </div>
                        <div class="jar-infos" *ngIf="report.jarData&&myapp.isAdminOrSuperUser">
                            <div class="mt-8 mb-4">
                                <h4>Fichier d'archive disponible</h4>
                            </div>
                            <div fxLayout="row" *ngFor="let jar of report.jarData">
                                <div fxFlex>
                                    <div>
                                        <label>Date de génération</label>
                                        <span>{{jar.date_str_full}}</span>
                                    </div>
                                    <div>
                                        <label>Taille</label>
                                        <span>{{jar.sizeStr}}</span>
                                    </div>
                                </div>

                                <button (click)="regenFromJar(jar.regenUrl)" mat-raised-button
                                        class="btn-xs-25">Régénérer
                                </button>
                            </div>
                        </div>
                    </td>
                    <td class="pane-right">
                        <div class="section">
                            <br>
                            <br>
                            <div class="section-title color-blue">Objet</div>
                            <div class="section-body text-center">
                                <h5>{{site.clientSite.name}}</h5>
                                {{site.clientSite.address}}<br>
                                {{site.clientSite.npaLoc}}
                            </div>
                        </div>

                        <div class="section">
                            <div class="section-title color-blue">Contacts</div>
                            <div fxLayout="row" class="pt-32 pb-32">
                                <div fxFlex="30" class="label">Enerplan</div>
                                <div fxFlex>
                                    <div *ngFor="let u of enerplanUsers">{{u}}</div>
                                </div>
                            </div>
                            <div fxLayout="row">
                                <div fxFlex="30" class="label">Client</div>
                                <div fxFlex>
                                    <div *ngFor="let u of clientUsers">{{u}}</div>
                                </div>
                            </div>
                            <div fxLayout="row">
                                <div fxFlex="30" class="label ">Site</div>
                                <div fxFlex>
                                    <div *ngFor="let u of siteUsers">{{u}}</div>
                                </div>
                            </div>
                        </div>


                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="logo">
                        <img src="https://api.optimigration.ch/web/reports/suivi/logo-srg.png"
                             alt="logo srg engineering"/>
                    </td>
                </tr>
            </table>

        </div>
    `,

    styleUrls: ['./proto-hebdo.component.scss']
})
export class ReportCoverComponent implements AfterViewInit, OnInit {
    @Input('report') report: ProtoReport;
    clientUsers: string[];
    enerplanUsers: string[];
    siteUsers: string[];
    regenUrl: string;
    weekNo = 0;

    constructor(public site: SiteService, public myapp: AppService) {

    }

    ngOnInit() {
        this.enerplanUsers = [];
        this.clientUsers = [];
        this.siteUsers = [];
        //console.log("COVER", this.site.responsibles);
        this.site.responsibles.forEach(u => {
            const respObj = u.site_access.get(this.site.clientSite.uid);
            if (u.site_access && respObj) {
                if (respObj.responsability === ('TECHNICAL_MANAGER'))
                    this.siteUsers.push(u.first_name + ' ' + u.last_name);
                if (respObj.responsability === ('ENERGY_MANAGER'))
                    this.enerplanUsers.push(u.first_name + ' ' + u.last_name);
                if (respObj.responsability === ('DOMAIN_REFERENT'))
                    this.clientUsers.push(u.first_name + ' ' + u.last_name);

            }
        });
        this.enerplanUsers = this.enerplanUsers.sort();
        this.clientUsers = this.clientUsers.sort();
    }

    revertToPending() {
        this.sendRequest('PENDING');
    }

    setReportReadyToComment() {
        this.sendRequest('READY');
    }

    validatePublication() {
        this.myapp.confirm
            .confirm({title: "Confirmation!", message: "Valider cette publication"})
            .subscribe(confirm => {
                if (confirm) this.sendRequest('PUB_OK');
            });
    }


    sendRequest(newStatus: string) {
        const targets = [];
        this.site.responsibles.forEach(u => {
            const rights = u.getRightsForSiteAndRoute('PROTOHEBDO');
            // console.log("User", u.email, rights);
            if ((rights.includes('DEFAULT_COMMENTOR')))
                targets.push(u.email);
        });
        this.site.api.setReportStatus(this.report.uid, newStatus, this.myapp.user.email, targets)
            .subscribe(resp => {
                if (resp.status === 1) {
                    if (resp.body) {
                        const updatedReport = new ProtoReport(resp.body);
                        this.site.selectedSiteReport = updatedReport;
                        this.site.loadReportsAndDetectNew();

                        if (this.site.selectedSiteReport.pub_status === 'PUB_OK') {
                            this.myapp.confirm.confirm({
                                title: "Message envoyé aux commentateurs ",
                                message: "Utilisateurs: " + targets.join(', ')
                            }).subscribe(ok => {

                            });
                        }

                    }
                }
            })
    }

    regenFromJar(url) {
        this.site.api.sendGet(url)
            .subscribe(resp => {
                console.log("regenFromJar::Resp", this.regenUrl, resp);
                this.site.loadReportsAndDetectNew();
            });
    }

    ngAfterViewInit(): void {

    }

}

/*
DataStructure of summary item for every year, calculated before generating statmens plots
 */

/// TODO: check if this must be saved in pdm
export class PdmDataSummary {
    ve2Label: string;
    days: number;
    dist: number;
    er_c_cumul: number;
    er_c_last: number;
    er_d_cumul: number;
    er_d_last: number;
    last_date: string;
    pt_c_cumul: number;
    pt_c_last: number;
    pt_d_cumul: number;
    pt_d_last: number;
    releves_count: number;
    ve1_c_cumul: number;
    ve1_c_last: number;
    ve1_d_cumul: number;
    ve1_d_last: number;
    ve2_c_cumul: number;
    ve2_c_last: number;
    ve2_d_cumul: number;
    ve2_d_last: number;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
    }
}

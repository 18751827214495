import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {BaseComponent} from "../../shared/BaseComponent";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AppService} from "../../shared/services/app.service";
import {SiteService} from "../../shared/services/site.service";
import {H} from "../../shared/helpers/H";
import {BatimentTemp, ClientSite, SiteReleveDelay} from "../../shared/models/models";
import {K} from "../../shared/models/K";
import moment, {Moment} from 'moment';
import {Optimise} from "../../shared/helpers/Optimise";

@Component({
    selector: 'site-editor',
    templateUrl: './SiteEditorCpnt.html',
    styleUrls: ['./site-home.component.scss']
})

export class SiteEditorComponent extends BaseComponent implements AfterViewInit, OnInit, OnChanges {
    @Input('forceUpdate') forceUpdate: number;

    formSite: FormGroup = null;
    globalPdm = [];
    featuresList = [
        {id: 'mdg_dashboard', name: 'MDG Dashboard'},
        {id: 'mdg_edit', name: 'MDG Edit'},
    ];
    editPlaceMode = '';
    editReleveDelayMode = '';
    extraVars = [];
    epLinks = [];
    batimentsForBddMap: Map<string, BatimentTemp> = new Map<string, BatimentTemp>();
    yearsForButtons = [];
    months = K.months;

    calendarItemsforYear = [];
    selectedEnergyStatsYearData: any = null;

    constructor(
        public myapp: AppService,
        public site: SiteService,
        private fb: FormBuilder,
    ) {
        super();
    }

    test() {
        console.log("test", this.epLinks, this.site.selectedSiteEpDataBase);
    }

    ngOnInit() {
        this.bootstrap();
        this.getGlobalPdms();
        this.getExtraVars();
        this.getEpLinks();
        this.loadBatimentsTempList();
    }

    bootstrap() {
        this.yearsForButtons = [];
        this.yearsForButtons.push(this.site.year);
        this.yearsForButtons.push(this.site.year - 1);
        this.yearsForButtons.push(this.site.year - 2);
        if (this.site.clientSite) {
            this.site.loadPdmEpLinkData();//todo check usefulness
            if (!this.site.clientSite.vars_extra) this.site.clientSite.vars_extra = {};
            this.formSite =
                this.fb.group({
                    name: ['', [Validators.required]],
                    ref: ["", [Validators.required]],
                    weather: ["", [Validators.required]],
                    features: new FormGroup({
                        mdg_dashboard: new FormControl(''),
                        mdg_edit: new FormControl('')
                    }),
                    year_tariff_default: ['', [Validators.required, Validators.min(1950)]],
                    year_tariff_display: ['', [Validators.required, Validators.min(1950)]],
                    canton: ['', [Validators.required]],
                    address: ['', [Validators.required]],
                    npaLoc: ['', [Validators.required]],
                    releve_day: ["", []],
                    map_url: ["", []],
                    ep_db_name: ["", []],
                    ep_tab_name: ["", []],
                    notes: ["", []],
                    statmens_prefix: ["", []],
                    type_suivi: ["", []],
                    gc: ["", []],
                });

        }
        this.initForm();
        this.populateLocalCalendarItems();
    }

    selectYearForDelays(year) {
        this.site.year = year;
        this.site.generateCalendar(year);
        this.site.generateCalendar(year - 1);
        this.populateLocalCalendarItems();
    }

    initForm() {
        if (!this.site.clientSite.vars_extra) this.site.clientSite.vars_extra = {};
        console.log(' this.formSite.controls', this.site.clientSite, this.formSite.controls);
        this.formSite.controls['year_tariff_default'].patchValue(this.site.clientSite.year_tariff_default);
        this.formSite.controls['year_tariff_display'].patchValue(this.site.clientSite.year_tariff_display);
        this.formSite.controls['year_tariff_default'].disable();
        this.formSite.controls['year_tariff_display'].disable();
        this.formSite.controls['canton'].patchValue(this.site.clientSite.canton);
        this.formSite.controls['address'].patchValue(this.site.clientSite.address);
        this.formSite.controls['npaLoc'].patchValue(this.site.clientSite.npaLoc);
        this.formSite.controls['name'].patchValue(this.site.clientSite.name);
        this.formSite.controls['ref'].patchValue(this.site.clientSite.ref);
        this.formSite.controls['ref'].disable();
        this.formSite.controls['gc'].patchValue(this.site.clientSite.gc);
        this.formSite.controls['type_suivi'].patchValue(this.site.clientSite.type_suivi);
        this.formSite.controls['map_url'].patchValue(this.site.clientSite.map_url);
        this.formSite.controls['ep_db_name'].patchValue(this.site.clientSite.ep_db_name);
        this.formSite.controls['ep_tab_name'].patchValue(this.site.clientSite.ep_tab_name);
        this.formSite.controls['weather'].patchValue(this.site.clientSite.weather);
        this.formSite.controls['releve_day'].patchValue(this.site.clientSite.releve_day);
        this.formSite.controls['notes'].patchValue(this.site.clientSite.notes);
        this.formSite.controls['statmens_prefix'].patchValue(this.site.clientSite.statmens_prefix);

        if (this.site.clientSite.features) {
            this.formSite.controls['features'].patchValue(this.site.clientSite.features);
        }
    }

    getEpLinks() {
        this.site.api.getEpLinks("").subscribe(resp => {
            console.log("Resp", resp);
            if (resp && resp.data && resp.data.length > 1)
                this.epLinks = resp.data;
        });
    }

    getGlobalPdms() {
        this.site.api.getMeteoStations().subscribe(resp => {
            this.globalPdm = Object.values(resp.body) || [];
            console.log('api::getMeteoStations()', resp, this.site.clientSite, this.globalPdm);
            this.initForm();
        });
    }

    getExtraVars() {
        this.site.api.getGlobalVarExpl().subscribe(resp => {
            console.log("getExtraVars()", resp);
            this.extraVars = resp.body;
        });
    }

    setExtraVars(varName: string, checked: boolean) {
        if (Array.isArray(this.site.clientSite.vars_extra))
            this.site.clientSite.vars_extra = {};
        if (checked)
            this.site.clientSite.vars_extra[varName] = 1;
        else delete this.site.clientSite.vars_extra[varName];
        console.log("SetExtra-Var", varName, checked, this.site.clientSite.vars_extra);
    }

    saveSite(extraData = null) {
        console.log('this.site.year', this.site.year, this.formSite.value['gc']);
        if (this.formSite.value['gc'] === true) this.formSite.value['gc'] = 1;
        if (!this.formSite.value['gc']) this.formSite.value['gc'] = 0;
        //  else this.formSite.value['gc'] = 0;

        let dataToSend = this.formSite.value;
        if (extraData) dataToSend = extraData;
        dataToSend['ep_bat_name'] = this.site.clientSite.ep_bat_name;
        dataToSend['ep_bat_id'] = this.site.clientSite.ep_bat_id;
        dataToSend['vars_extra'] = this.site.clientSite.vars_extra;
        dataToSend['releve_delays'] = Array.from(this.site.releveDelaysMap.values());

        console.log('saveSite', dataToSend);
        //****
        this.site.api.updateSite(dataToSend, this.site.clientSite.uid).subscribe(resp => {
            if (resp.status === 1) {
                this.editPlaceMode = '';
                const updatedSite = new ClientSite(resp.body);
                this.site.selectSite(updatedSite);
                console.log('savedSite - Updated', resp.body, this.site.clientSite);
                //Object.assign(this.site.clientSite, resp.body);
                this.myapp.toastr.success("Données sauvegardées", "Succès", {timeOut: 2000});

                //this.site.resetFieldsAfterSiteSelect(this.site.clientSite, 0, 0);
                this.myapp.getSites(true);
                //this.loadBatimentsTempList();
            } else
                console.error('updateSite status 0', resp);
        });
    }

    deleteReleveDelay(id) {
        this.site.releveDelaysMap.delete(id);
        this.saveSite();
    }

    saveReveleDelay(date: HTMLInputElement, monthNumber: number, oldWinner: string) {
        const oldWinnerMoment = moment(oldWinner, "YYYY-MM-DD ddd");
        const dateMoment = moment(date.value, "YYYY-MM-DD");
        const daysAway = dateMoment.diff(oldWinnerMoment, 'days');

        if (Math.abs(daysAway) > 15) {
            date.value = oldWinnerMoment.add(1, 'week').format("YYYY-MM-DD");
            this.myapp.toastr.error("Attention date éloignée de plus de 15j", "Erreur", {timeOut: 2000});
        } else {
            if (!this.site.clientSite.releve_delays) this.site.clientSite.releve_delays = [];
            if (!Array.isArray(this.site.clientSite.releve_delays)) this.site.clientSite.releve_delays = [];
            if (moment(date.value, 'YYYY-MM-DD', true).isValid()) {
                date.classList.remove("error");
                const siteDelay = new SiteReleveDelay(this.site.year, monthNumber, date.value, 1);
                this.site.releveDelaysMap.set(siteDelay.id, siteDelay);
                this.saveSite();
            } else {
                this.myapp.toastr.error("Attention format date: [YYYY-MM-DD]", "Erreur", {timeOut: 2000});
                date.value = oldWinnerMoment.add(1, 'week').format("YYYY-MM-DD");
                date.classList.add("error");
            }
        }

    }

    savePlace(add = false, del = null) {
        if (this.site.clientSite.places && JSON.stringify(this.site.clientSite.places).length === 2)
            this.site.clientSite.places = {};
        const updCmd = {places: this.site.clientSite.places || {}};
        if (del && del.length > 3) {
            delete updCmd['places'][del];
        }
        if (add) {
            const uid = 'pl-' + H.randomInt(100000, 99999999);
            updCmd['places'][uid] = {uid: uid, label: uid};
        }
        this.saveSite(updCmd);
    }

    selectTab(e) {
        console.log("SelectTab", e);
        if (e.index === 2) {
            if (!this.site.clientSite.releve_day) {
                this.myapp.toastr.error("Vous devez definir le jour de relevé", "Erreur", {timeOut: 4000});
                return;
            }
        }
    }


    linkToEpBat(bat: BatimentTemp) {
        this.site.clientSite.ep_bat_name = bat.batName;
        this.site.clientSite.ep_bat_id = bat.batID;
        this.saveSite();
    }

    loadBatimentsTempList() {
        if (!this.site.selectedSiteEpDataBase) {
            console.error("Site::loadBatimentsTempList()", 'this.site.selectedSiteEpDataBase: null');
            return;
        }
        Object.keys(this.site.selectedSiteEpDataBase).forEach(k => {
            const bat = new BatimentTemp(this.site.selectedSiteEpDataBase[k]);
            this.batimentsForBddMap.set(bat.uid, bat);
        });
    }

    populateLocalCalendarItems() {
        this.calendarItemsforYear = [];
        for (let i = 0; i < 12; i++) {
            const id = SiteReleveDelay.getIdFromIndex(this.site.year, i);
            const r = this.getItemFromArrayByFieldVal(this.site.siteCalendar, 'relatedId', id);
            this.calendarItemsforYear.push(r);
        }
    }

    getVal(index) {
        const id = SiteReleveDelay.getIdFromIndex(this.site.year, index);
        if (this.site.releveDelaysMap.has(id))
            return this.site.releveDelaysMap.get(id).date;
        return "";
    }

    ngOnChanges(changes: SimpleChanges) {
        this.bootstrap();
        console.log("SiteEditorComponent::ngOnChanges---------- ", changes, this.site.releveDelaysMap);
    }

    ngAfterViewInit(): void {
        this.getExtraVars();
    }
}

/*
address "Rue du Léman 10,CH-1020 Renens"
gc null
name "Collège du Léman"
ref "Collège du Léman"
uid "5b33ab1f3c6dea00045da06e"
uid_client "f8751dd47b2d17357254365ef4ffbab0"
uid_convention "60cf7d83e341b7643637de04"
uid_stats "5efc9b07513fdb737d62c1b1" */


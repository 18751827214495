<div class="flex-container" fxLayout="column" style="height: 100%" #plotHolder>

    <div fxLayout="row" class="toolbar-top ">
        <div fxFlex="600px" fxLayout="row">
            <button mat-raised-button color="primary"
                    fxFlex="24"
                    class="btn-xs-25 mr-4"
                    [ngClass]="{active:site.selectedFluid===fl}"
                    (click)="site.selectedFluid=fl;sidenav.open()"
                    *ngFor="let fl of myapp.k.fluids">{{fl}}</button>
        </div>
        <div fxFlex class="border-left">
            <ng-container *ngIf="site.selectedPdm">
                <button mat-raised-button (click)="addRow()" class="ml-16 btn-xs-25" color="accent">Ajouter ligne</button>
                <button mat-raised-button class="ml-16 btn-xs-25" color="primary"
                        (click)="saveNewRows()">Sauvegarder
                </button>
            </ng-container>
        </div>


        <button mat-raised-button routerLink="/statmens-conf" class="ml-16 btn-xs-25" color="warn">StatMens</button>
        <button mat-raised-button routerLink="/pdm-editor" class="ml-16 btn-xs-25" color="warn">Tableur</button>
        <button mat-raised-button class="ml-16 btn-xs-25"
                (click)="sidenav.opened?sidenav.toggle(false):sidenav.toggle(true)">
            <span *ngIf="!sidenav.opened">Menu</span>
            <span *ngIf="sidenav.opened">Fermer</span>
        </button>
    </div>
    <mat-sidenav-container fxFlex fxFlexFill [hasBackdrop]="false">
        <mat-sidenav #sidenav [mode]="'side'" [position]="'start'" style="width: 300px;" (openedChange)="sideNavListener($event)">
            <mat-list class="full-width mb-32 compact-list">
                <ng-container *ngFor="let pdm of site.pdms">
                    <mat-list-item class="full-width pt-4 pr-4 mat-list-p-0"
                                   *ngIf="pdm.fluid===site.selectedFluid"
                                   [ngClass]="{active:site.selectedPdm&&pdm.uid===site.selectedPdm.uid}"
                                   (click)="selectPdm(pdm)">
                        <icon-fluid style="width: 20px" [fluid]="pdm.fluid"></icon-fluid>
                        <div fxLayout="row" fxFlex="100">
                            <h6 class="m-0 ml-8" fxFlex="70">{{pdm.label}}<br>
                                <span class="text-muted"><b>{{pdm.pdm_prefix}}</b> | {{pdm.label_place}} | {{pdm.xls}}</span>
                            </h6>
                            <span fxFlex></span>
                            <mat-chip class="p-4 mr-4 text-small-1">{{pdm.status}}</mat-chip>
                            <mat-icon inline="true" *ngIf="pdm.is_vpdm" class="icon-vpdm">bluetooth_connected</mat-icon>
                        </div>
                    </mat-list-item>
                </ng-container>

            </mat-list>

        </mat-sidenav>

        <mat-sidenav-content>
            <mat-card class="p-16 m-4" id="table-saisie" style="margin-top: 0px !important;" *ngIf="site.selectedPdm&&regs&&regs.length">
                <div class="table-regs-holder">
                    <div [style]="{width:(regs.length*810 )+'px',height:'151px'}" fxLayout="row" fxLayoutAlign="start start">
                        <table class="table-regs" *ngFor="let reg of regs;let i=index;">
                            <tr *ngFor="let field of regFieldsToDisplayInReleve">
                                <td class="reg-def-head" *ngIf="i===0">{{field}}</td>
                                <td colspan="5" class="reg-def-val" [title]="field+':'+reg[field]">
                                    <span>{{reg[field]}}</span>
                                </td>
                            </tr>
                            <tr class="rows-but">
                                <td class="date" *ngIf="i===0">Saisie</td>
                                <td class="field" colspan="5" style="height: 25px;position: relative">
                                    <div fxLayout="column" fxLayoutAlign="space-between none" class="field-buttons-panel"
                                         *ngIf="selectedFieldForActions&&selectedFieldForActions.includes(reg.num)">
                                        <div class="field-buttons-panel-head">
                                            Options pour [{{selectedFieldForActions}}]
                                        </div>
                                        <button class="btn-xs-25 mb-4" fxFlex="32" mat-raised-button (click)="delField(selectedFieldForActions,'notEdited')">
                                            Del not edited
                                        </button>
                                        <button class="btn-xs-25 mb-4" fxFlex="32" mat-raised-button (click)="delField(selectedFieldForActions,'all')">
                                            Del all
                                        </button>
                                        <button class="btn-xs-25 mb-4" fxFlex="32" mat-raised-button (click)="delField(selectedFieldForActions,'map')">
                                            Del xls map
                                        </button>
                                        <button class="btn-xs-25" fxFlex="32" color="accent" mat-raised-button (click)="selectedFieldForActions=''">
                                            close
                                        </button>
                                    </div>
                                    <div fxLayout="row">
                                        <div class="flex-container-td" fxLayout="row">
                                            <ng-container *ngFor="let f of ['i','k','d','a','t','c']">
                                                <div class="field-entry cell-width"
                                                     *ngIf="reg.shouldShow(f)">
                                                    {{reg.getEntryType(f)}}
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr class="rows-head">
                                <td class="date" *ngIf="i===0"></td>
                                <td fxLayout="row">
                                    <div class="flex-container-td" fxLayout="row">
                                        <ng-container *ngFor="let f of ['i','k','d','a','t','c']">
                                            <div class="field cell-width"
                                                 *ngIf="reg.shouldShow(f)"
                                                 [ngClass]="{active:selectedFieldForActions===(f+reg.num)}"
                                                 (click)="reg.getEntryType(f)==='MANUAL'?selectedFieldForActions=f+reg.num:selectedFieldForActions='';">
                                                {{f + reg.num|uppercase}}
                                            </div>
                                        </ng-container>

                                    </div>
                                </td>
                            </tr>
                        </table>
                        <table class="table-regs">
                            <tr *ngFor="let field of [1,2,3,4,5]">
                                <td title="cTotPaid">+</td>
                                <td class="field cell-width" *ngFor="let autoField of autoFields">-</td>
                                <td>.</td>
                            </tr>
                            <tr class="rows-head">
                                <td class="field cell-width field-extra">cTotPaid</td>
                                <td class="field cell-width field-extra" *ngFor="let f of autoFields" [matTooltip]="f">{{f}}</td>
                                <td class="field cell-width field-extra">Jours</td>
                            </tr>
                        </table>
                    </div>
                    <div [style]="{width:(tableWidth)+'px'}" class="releves-scroller" (dblclick)="tableWidth=tableWidth+50"
                         (keydown)="startEdit($event)">
                        <ng-container *ngFor="let row of rows;let i = index;trackBy: trackByFn">
                            <saisie-row [index]="i"
                                        [ts]="row.ts"
                                        [rowData]="row"
                                        (cb)="formCallBack($event)"
                                        [regs]="regs"
                                        [ctrlPressField]="ctrlPressed"
                                        [firstRowDateMassEdit]="firstRowDateMassEdit"
                                        [lastRowDateMassEdit]="lastRowDateMassEdit"

                            ></saisie-row>
                        </ng-container>

                    </div>
                </div>
            </mat-card>

            <mat-card class="m-4">
                <!--
                <ngx-json-viewer [style]="{'font-size':'10px'}" [json]="selectedRow" [expanded]="false"></ngx-json-viewer>
                -->

            </mat-card>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" name="spinnerSaveServer"
             type="timer" [fullScreen]="false">
    <p style="color: white"> Sauvegarde en cours {{saveProgress}}% </p>
    <mat-progress-bar
            style="height: 30px"
            [color]="'primary'"
            [mode]="'determinate'"
            [value]="saveProgress">
    </mat-progress-bar>
</ngx-spinner>

import moment from 'moment'
import {COLORS, defaultLayout, SYMBOLES_BAR} from '../constants'
import {
    configureTicks,
    configureTicksFromY1,
    enerplanWeek,
    findStackedYValues,
    findYValues,
    rangeFromValues,
} from '../utils'
import * as d3 from 'd3';
import * as assign from 'assign-deep';

function getDuration(xAxis) {
    const n = xAxis.length;
    if (xAxis.length < 2) {
        return 'week'
    }
    const diff = moment(xAxis[n - 1]).diff(xAxis[n - 2], 'days');
    return Math.abs(diff) >= 20 ? 'month' : 'week'
}

function adaptXRange(range, duration) {
    const durationInDays = duration === 'week' ? 7 : 30;
    const [a, b] = range;
    return [
        moment(a).subtract(durationInDays * 0.5, 'days').valueOf(),
        moment(b).add(durationInDays * 0.5, 'days').valueOf(),
    ]
}

class BarAdapter {
    /**
     * Default Options for layout
     */
    static layout = (data, layout) => {
        const {yaxis2} = layout
        const xAxis = data.length ? data[0].x : []
        const lastValue = xAxis.slice(-1)[0]
        const tickmode = 'tozero'

        const duration = getDuration(xAxis)
        const tickFunction = duration === 'week'
            ? enerplanWeek
            : (date) => moment(date).month()

        // Configure the range to always show a period of 57 weeks
        const xrange = [
            moment(lastValue).subtract(57, duration).valueOf(),
            moment(lastValue).valueOf(),
        ]

        const y1Values =
            // accumulate series of type bar
            findStackedYValues(data)
                // add other values in the first y axis
                .concat(findYValues(data, serie => serie.yaxis !== 'y2' && serie.type !== 'bar'))

        const yRange = [d3.min(y1Values), d3.max(y1Values)]
        const yaxisTicks = configureTicks({range: yRange, tickmode, nticks: 7, padding: 0.5})

        let yaxis2Config
        if (yaxis2) {
            // find values in the second axis
            const y2Values = findYValues(data, (serie) => serie.yaxis === 'y2')
            // calculate the range according to the tickmode (tozero or nonnegative)
            const y2Range = rangeFromValues(y2Values, tickmode)
            // get the configuration to display ticks
            const yaxis2Ticks = configureTicksFromY1(yaxisTicks, y2Range)
            yaxis2Config = {
                yaxis2: {
                    ...yaxis2Ticks,
                    side: 'right',
                    overlaying: 'y',
                    hoverformat: '.2r',
                    rangemode: 'nonnegative',
                    showgrid: false,
                },
            }
        }

        const ticks = xAxis.filter((v, index) => index % 4 === 0)
        return assign({}, defaultLayout({data, layout}), {
            xaxis: {
                type: 'date',
                hoverformat: '%d.%m.%Y',
                tickvals: ticks.map(v => moment(v).valueOf()),
                ticktext: ticks.map(tickFunction),
                range: adaptXRange(xrange, duration),
                showgrid: false,
            },
            yaxis: {
                hoverformat: '.2r',
                ...yaxisTicks,
            },
            ...yaxis2Config,
            barmode: 'stack',
            hovermode: 'x',
        })
    }

    /** 1
     * Options for layout with 2 axis
     * Will be merge with Bar.layout if needed
     */
    static layoutYAxis2 = (data) => ({ // eslint-disable-line no-unused-vars
        yaxis2: {
            side: 'right',
            overlaying: 'y',
            rangemode: 'nonnegative',
            showgrid: false,
        },
    })

    static types = {
        BAR: 0,
        SCATTER: 1,
    }

    static getConfig(type, index) {
        return BarAdapter.config[type](index);
    }

    static config = {

        // TYPE: BAR
        // stacked bar with different colors
        0(index) {
            return {
                type: 'bar',
                marker: {color: COLORS[index]},
            }
        },

        // TYPE: SCATTER,
        // marker with different symbols
        1(index) {
            return {
                type: 'scatter',
                mode: 'markers',
                cliponaxis: false,
                marker: {
                    color: 'gray',
                    size: 7,
                    symbol: SYMBOLES_BAR[index],
                    line: {
                        color: 'white',
                        width: 1.5,
                    },
                },
            }
        },
    }

    /**
     * Merge each data series with the corresponding config
     * and attach x values. x axis is common to every series
     *
     * @params data {Array} array of data series containing y values
     *          and other infos about the type.
     * @params layout {Object}
     */
    static reduceData(data, layout) {
        if (!data || !data.length) {
            return []
        }

        const {title: yTitle} = layout.yaxis || {}
        const {title: y2Title} = layout.yaxis2 || {}
        const index = [0, 0]
        // keep only 58 values
        // TODO: keep all value (will be fixed by issue #16)
        const x = data[0].dateStamps
            .slice(0, 58)
            .map(v => moment(v).toISOString())

        return data
            .map(serie => {
                // define the type of the serie
                const type = (serie.type === 'markers') || (serie.yaxis === 'y2')
                    ? BarAdapter.types.SCATTER
                    : BarAdapter.types.BAR

                const unit = serie.yaxis === 'y2' ? y2Title : yTitle

                const currentIndex = index[type]
                index[type] += 1
                return {
                    ...serie,
                    ...this.getConfig(type, currentIndex),
                    name: serie.name,
                    x,
                    hoverinfo: 'x+text+name',
                    hovertext: serie.y.map(v => `${Math.round(v)} ${unit}`),
                }
            })
    }

    static reduce(data, layout) {
        const nextData = BarAdapter.reduceData(data, layout)
        const nextLayout = BarAdapter.reduceLayout(layout, nextData)
        return {
            data: nextData,
            layout: nextLayout,
        }
    }

    /**
     * Merge default bar layout with the layout
     * passed as parameter which may contain minimal info
     * like titles for x axis and y axis
     */
    static reduceLayout(layout, data) {
        const baseLayout = BarAdapter.layout(data, layout)
        // deeply merge `baseLayout` and `layout`
        // `layout` can override `baseLayout`
        return assign({}, baseLayout, layout)
    }
}

export default BarAdapter

import {config} from 'config';

export const environment = {
    production: true,
    ver: '108.0',
    version: 'Ver: 16/09/2024 16:15:31',
    version_ts: '1726496131642',
    firebase: {
        apiKey: 'AIzaSyCiv74FkdNizesgiVNHSrPZUkN90LKZUD8',
        authDomain: 'optimise-enerplan.firebaseapp.com',
        databaseURL: 'https://optimise-enerplan.firebaseio.com',
        projectId: 'optimise-enerplan',
        storageBucket: 'optimise-enerplan.appspot.com',
        messagingSenderId: '559963427417',
        appId: '1:559963427417:web:b12c6f260ab82c2a369748'
    },
    apiURL: config.apiUrl
};

import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {BaseComponent} from "../../shared/BaseComponent";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AppService} from "../../shared/services/app.service";
import {SiteService} from "../../shared/services/site.service";
import {EnergyStats} from "app/shared/models/EnergyStats.model";
import {BmensItem} from "app/shared/models/Bmens.model";
import {environment} from "../../../environments/environment";
import {Notif} from "../../shared/models/Notifs";

@Component({
    selector: 'bloc-usage-for-year',
    template: `
        <mat-card class="p-4 m-4">
            <h4>Conso. d'énergie et d'eau {{year}}</h4>
            <ng-container *ngIf="!energyStatsForRefYear||!energyStatsForRefYear.usage">
                <p><i>Pas de données énergetiques saisies pour {{year}}</i></p>
            </ng-container>
            <table class="home-cons-table" *ngIf="energyStatsForRefYear&&energyStatsForRefYear.usage">
                <tr class="head">
                    <td class="col1">Agent énergétique</td>
                    <td>Consommation/an</td>
                    <td>Coûts/an</td>
                    <td>Prix unitaire</td>
                </tr>

                <tr *ngFor="let item of energyStatsForRefYear.usage">
                    <td class="col1">{{item.label}}</td>
                    <td>{{item.adapted_amount | number}} <span>{{item.unit_amount}}</span></td>
                    <td>{{item.cost | currency}}  </td>
                    <td>{{item.adapted_price| currency}} <span>{{item.unit_price}}</span></td>
                </tr>
                <tr class="row-total">
                    <td class="col1 align-right">Total</td>
                    <td><!--{{totAdaptedAmount | number}} <span>{{amountUnit}}</span>--></td>
                    <td>{{totCost | currency}}</td>
                    <td></td>
                </tr>
               
            </table>
        </mat-card>

    `,
    styleUrls: ['./site-home.component.scss']
})

export class BlocUsageForYearComponent extends BaseComponent implements AfterViewInit, OnInit, OnChanges {
    @Input('forceUpdate') forceUpdate: number;
    @Input('year') year: number;

    env: any;

    energyStatsForRefYear: EnergyStats = null;
    lastBmensItem: BmensItem = null;
    lastBmensItemRows: any[] = [];
    totAdaptedAmount = 0;
    amountUnit = '';
    totCost = 0;

    totEvolCorr = 0;
    totCostCuml = 0;

    constructor(
        public myapp: AppService,
        public site: SiteService,
        private fb: FormBuilder,
    ) {
        super();
    }

    ngOnInit() {
        this.bootstrap();
    }


    bootstrap() {
        this.energyStatsForRefYear = null;
        this.totAdaptedAmount = 0;
        this.totCost = 0;
        if (!this.site.clientSite.old_energy_stats_map) return;

        this.site.clientSite.old_energy_stats_map.forEach((k, year) => {
            if (Number(year) === Number(this.year)) this.energyStatsForRefYear = k;
        });
        if (this.energyStatsForRefYear) {
            if (this.energyStatsForRefYear.usage) {
                this.energyStatsForRefYear.usage.forEach(item => {
                    this.totAdaptedAmount += item.adapted_amount;
                    this.totCost += item.cost;
                    this.amountUnit = item.unit_amount;
                });
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.bootstrap();
    }

    ngAfterViewInit(): void {
    }
}

<mat-card style="height: 725px" fxLayout="column">
    <mat-card-title class="color-blue" fxLayout="row">
        <div *ngIf="act.isDraft">Création d'une action
            <span class="text-muted">Le numéro sera attribué automatiquement</span>
        </div>
        <div *ngIf="!act.isDraft">APE [{{ act.num }}]</div>
        <div fxFlex=""></div>
        <div class="ml-16">
            <p class="m-0 number-label">
                Total économies
                <span class="highlight-number" *ngIf="act.economies_total&&myapp.isNumber(act.economies_total)">
                    | {{ act.economies_total|currency }}
                </span>
            </p>
        </div>
        <div class="ml-16">
            <p class="m-0 number-label">
                Investissement net
                <span class="highlight-number">|  {{ act.investment_real|currency }} </span>
            </p>
        </div>
        <div class="ml-16">
            <p class="m-0 number-label">
                Payback
                <span class="highlight-number">|   {{ act.payback| number: '1.0-2' }} ans  </span>
            </p>
            <!--
            <mat-progress-bar
                    color="accent"
                    mode="determinate"
                    [value]="60"
                    class="rounded"
                    style="height: 6px"
            >
            </mat-progress-bar>
            -->
        </div>
    </mat-card-title>

    <mat-card-content fxLayout="row" fxFlex class="mb-0">

        <div fxFlex="500px" fxLayout="column" class="pr-16">
            <mat-form-field class="m-4">
                <mat-label>Titre</mat-label>
                <input [(ngModel)]="act.title" matInput placeholder="Titre de l'action"/>
            </mat-form-field>

            <div fxLayout="row">
                <mat-form-field fxFlex="30" class="m-4">
                    <input matInput [(ngModel)]="dateProposed" [matDatepicker]="picker1" placeholder="Crée le">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                <mat-form-field fxFlex="30" class="m-4">
                    <input matInput [(ngModel)]="dateDue" [matDatepicker]="picker2" placeholder="Délai">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
                <div fxFlex="30" class="m-4">
                    <mat-form-field *ngIf="dateTerminated" style="width: 150px">
                        <input matInput [(ngModel)]="dateTerminated" [matDatepicker]="picker3"
                               placeholder="Terminée">
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                    </mat-form-field>
                </div>

            </div>

            <div fxLayout="column">
                <mat-form-field class="m-4">
                    <mat-label>Description/Constat</mat-label>
                    <textarea [(ngModel)]="act.description" matInput class=""
                              [disabled]="!canEditRights.includes('EDIT_TEXT')"
                              placeholder="Description/Constat" rows="5"></textarea>
                </mat-form-field>

                <mat-form-field class="m-4">
                    <mat-label>Solution/Essai/Proposition</mat-label>
                    <textarea [(ngModel)]="act.solution" matInput class=""
                              [disabled]="!canEditRights.includes('EDIT_TEXT')"
                              placeholder="Solution/Essai/Proposition" rows="5"></textarea>
                </mat-form-field>

                <mat-form-field class="m-4">
                    <mat-label>Remarque</mat-label>
                    <textarea [(ngModel)]="act.note" matInput
                              [disabled]="!canEditRights.includes('EDIT_TEXT')"
                              placeholder="Remarque" rows="5"></textarea>
                </mat-form-field>
            </div>
            <div fxFlex=""></div>

        </div>

        <div fxFlex="420px" fxLayoutAlign="space-between none"
             fxLayout="column" class="col-2 border-right pr-8">

            <div class="full-width" class="act-special-type-panel full-width mb-16">
                <span class="form-bloc-title">Actions spéciales</span>
                <div fxLayout="row" fxLayoutAlign="space-evenly center">
                    <mat-slide-toggle [checked]="act.gc===1" [(ngModel)]="act.gc"
                                      labelPosition="before">Grand cons
                    </mat-slide-toggle>
                    <div fxFlex="20"></div>
                    <mat-slide-toggle [checked]="act.is_ref===1" [(ngModel)]="act.is_ref"
                                      labelPosition="before">Ref
                    </mat-slide-toggle>
                </div>
            </div>

            <div *ngIf="act.gc" fxLayout="row" class="ape-categ-selector-bloc">
                <span class="form-bloc-title" fxFlex="145px">Catégorie d'APE</span>
                <mat-select [(ngModel)]="act.ape_categ">
                    <mat-option [value]="''">
                        ---
                    </mat-option>
                    <mat-option [value]="cat" *ngFor="let cat of myapp.k.apeCategs">
                        {{ cat }}
                    </mat-option>
                </mat-select>
            </div>

            <div class="">
                <span class="form-bloc-title">Status</span>
                <mat-button-toggle-group fxLayout="row" class="mt-4"
                                         (change)="recalc()"
                                         [(ngModel)]="act.status">
                    <mat-button-toggle value="PROPOSAL"> Proposé</mat-button-toggle>
                    <mat-button-toggle value="ACCEPTED"> Accepté</mat-button-toggle>
                    <mat-button-toggle value="REFUSED"> Refusé</mat-button-toggle>
                    <mat-button-toggle value="PROGRESS"> En cours</mat-button-toggle>
                    <mat-button-toggle value="COMPLETED"> Terminé</mat-button-toggle>
                </mat-button-toggle-group>
            </div>

            <div class="full-width mb-8">
                <span class="form-bloc-title">Priorité</span>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-button-toggle-group fxLayout="row" class="mt-4" [(ngModel)]="act.priority">
                        <mat-button-toggle value="LOW"> Faible</mat-button-toggle>
                        <mat-button-toggle value="MEDIUM"> Moyenne</mat-button-toggle>
                        <mat-button-toggle value="HIGH"> Haute</mat-button-toggle>
                    </mat-button-toggle-group>
                    <div fxFlex></div>
                </div>
            </div>

            <div fxFlex class="assignee-bloc">
                <span class="form-bloc-title">Assignés</span>
                <div fxLayout="row">
                    <ng-container *ngFor="let assignee of act.assignees">
                        <ngx-avatar *ngIf="getAssigneesUser(assignee) as u"
                                    class="cursor-pointer assignee-avatar"
                                    [title]="u.first_name+' '+u.last_name+' ('+u.office+')'"
                                    [name]="u.first_name+' '+u.last_name"
                                    [size]="40">
                        </ngx-avatar>
                    </ng-container>
                </div>
                <div fxLayout="row" *ngIf="canEditRights.includes('ASSIGN_USER')">
                    <mat-form-field>
                        <mat-label>Assigner</mat-label>
                        <mat-select class="assignee-selector">
                            <mat-option *ngFor="let u of siteService.responsibles">
                                <div fxLayout="row" (click)="addAssignee(u)"
                                     fxLayoutAlign="space-between center">
                                    <ngx-avatar [name]="u.first_name+' '+u.last_name"
                                                [size]="38">
                                    </ngx-avatar>
                                    <div fxLayout="column" fxFlex class="pl-8"
                                         style="overflow: hidden">
                                        <label>{{ u.first_name + ' ' + u.last_name }}</label>
                                        <div class="text-muted">{{ u.office }}</div>
                                    </div>
                                    <div fxFlex="30px">
                                        <mat-icon *ngIf="getIfAssigneePresent(u.uid_bdd)">check</mat-icon>
                                    </div>
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
            <!--
            <div class="full-width mb-16">
                <span class="form-bloc-title">Tags</span>
                <div class="full-width">
                    <div fxLayout="row wrap" class="full-width">
                        <mat-checkbox fxFlex="33" labelPosition="after">APE</mat-checkbox>
                        <mat-checkbox fxFlex="33" labelPosition="after">Dérive</mat-checkbox>
                        <mat-checkbox fxFlex="33" labelPosition="after">Param</mat-checkbox>
                        <mat-checkbox fxFlex="33" labelPosition="after">Inventaire</mat-checkbox>
                        <mat-checkbox fxFlex="33" labelPosition="after">Verification</mat-checkbox>
                    </div>
                </div>
            </div>
            -->

            <div class="summary-bloc full-width ">
                <div class="head" fxLayout="row">
                    <div fxFlex="45" class="weight-400">
                        <div class="head  ">Détails financiers</div>
                    </div>
                    <div fxFlex="30" class="text-right pr-8">
                        <div class="head  ">Montant [Fr.]</div>
                    </div>
                    <div fxFlex="25" class="text-right">
                        <div class="head  ">Payback</div>
                    </div>
                </div>
                <div class="line" fxLayout="row">
                    <div fxFlex="50" class="weight-400"> Investissement total</div>
                    <div fxFlex="25" class="text-right pr-8">
                        {{ act.investment|number:'1.0-0' }}
                    </div>
                    <div fxFlex="25" [title]="act.paybackBrut" class="text-right"> {{ act.paybackBrut|number:'1.1-1' }}
                        ans
                    </div>
                </div>
                <div class="line" fxLayout="row">
                    <div fxFlex="50" class="weight-400"> Part attribuable: <span
                            class="color-blue"> {{ act.investment_factor }}%</span></div>
                    <div fxFlex="25" class="text-right pr-8">
                        <span>
                            {{ -act.investment + act.investment_factor * act.investment / 100|number:'1.0-0' }}
                        </span>
                    </div>
                    <div fxFlex="25" class="text-right"></div>
                </div>
                <div class="line" fxLayout="row">
                    <div fxFlex="50" class="weight-400"> Subventions</div>
                    <div fxFlex="25" class="text-right pr-8">
                        <span *ngIf="act.subventions.length">-</span>
                        {{ act.getSubventionsTot()|number:'1.0-0' }}
                    </div>
                    <div fxFlex="25" class="text-right"></div>
                </div>
                <div class="line" fxLayout="row" style="border-bottom: none">
                    <div fxFlex="50" class="weight-400">Investissement net</div>
                    <div fxFlex="25" class="text-right pr-8">
                        <span>  {{ act.investment_real| number:'1.1-1' }}    </span>
                    </div>
                    <div fxFlex="25" class="text-right"> {{ act.payback|number:'1.1-1' }} ans</div>
                </div>
            </div>
        </div>

        <div fxFlex="580px" fxLayout="column" class="pr-8 pl-8">
            <mat-tab-group style="margin-top: -25px;" animationDuration="50ms">
                <mat-tab label="Investissement">
                    <p class="mt-8 mb-16">Saisir les informations d'investissement et de subventions eventuelles</p>
                    <div class=" mb-16" fxLayout="row">
                        <div fxFlex="40" class="pr-16">
                            <mat-form-field class=" full-width" appearance="outline">
                                <mat-label>Investissement total</mat-label>
                                <input matInput placeholder="Investissement total"
                                       (keyup)="recalc()"
                                       [(ngModel)]="act.investment" type="number">
                                <span matSuffix>Fr</span>
                            </mat-form-field>
                        </div>
                        <div fxFlex="30">
                            <mat-form-field class=" full-width" appearance="outline">
                                <mat-label>Part attribuable</mat-label>
                                <input matInput placeholder="Part attribuable"
                                       (keyup)="recalc()"
                                       [(ngModel)]="act.investment_factor" type="number">
                                <span matSuffix>%</span>
                            </mat-form-field>
                        </div>
                        <div fxFlex="30" class="invest-net">
                            <p>Investissement net: </p>
                            <span class="color-blue">
                                {{ act.investment_real| currency }}
                            </span>
                        </div>
                    </div>


                    <!--ECON MONETAIRE-->
                    <div class="form-bloc-title">Economie monetaire
                        <mat-slide-toggle color="primary" #monnetaryEcon
                                          [checked]="act.monetary_economy>0"
                                          (change)="act.monetary_economy = 0;recalc();">
                        </mat-slide-toggle>
                    </div>
                    <ng-container *ngIf="monnetaryEcon.checked">
                        <div class="mb-8" fxLayout="row"
                             fxLayoutAlign="space-between center">
                            <div class="w-40 h-40 mr-16 pr-8 pl-8  rounded-circle mat-bg-chip d-flex align-items-center justify-content-center red-300  blue-A700-fg">
                                <mat-icon>paid</mat-icon>
                            </div>
                            <label fxFlex="60">Economie monetaire</label>
                            <mat-form-field class="mt-8 full-width">
                                <input matInput [(ngModel)]="act.monetary_economy" placeholder="Economie monetaire"
                                       (keyup)="recalc()" type="number">
                                <span matSuffix>Frs</span>
                            </mat-form-field>
                            <button mat-icon-button color="primary" (click)="act.monetary_economy=0">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!monnetaryEcon.checked">
                        <p class="mt-8 mb-16 text-capitalize" style="font-style: italic">Pas d'économies monetaire</p>
                    </ng-container>

                    <br>

                    <!--SUBVENTION-->
                    <span class="form-bloc-title">Subventions</span>
                    <ng-container *ngFor="let subv of act.subventions">
                        <div class="mb-8" fxLayout="row" fxLayoutAlign="space-between center">
                            <div class="w-40 h-40 mr-16 pr-8 pl-8  rounded-circle mat-bg-chip d-flex align-items-center justify-content-center blue-300  blue-A700-fg">
                                <mat-icon>paid</mat-icon>
                            </div>
                            <mat-form-field fxFlex="60" class="full-width mt-8 mr-4">
                                <input matInput [(ngModel)]="subv.label" placeholder="Origine"
                                       (keyup)="recalc()" type="text">
                            </mat-form-field>
                            <mat-form-field class="mt-8 full-width">
                                <input matInput placeholder="Montant" [(ngModel)]="subv.value"
                                       (keyup)="recalc()" type="number">
                                <span matSuffix>Frs</span>
                            </mat-form-field>
                            <button mat-icon-button color="primary" (click)="deleteSubvention(subv.id)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="act.subventions.length===0">
                        <p class="mt-8 mb-16 text-capitalize" style="font-style: italic">Pas de subventions</p>
                    </ng-container>


                    <div fxFlex=""></div>
                    <div fxLayout="row">
                        <div fxFlex=""></div>
                        <div class="border-top color-blue weight-400 p-8">
                            {{ act.getSubventionsTot()|currency }}
                        </div>

                    </div>
                    <div fxLayout="row">
                        <div fxFlex=""></div>
                        <button mat-raised-button color="primary" class="btn-xs mr-4" (click)="addSubvention()">Ajouter
                            subvention
                        </button>
                    </div>
                </mat-tab>
                <!-- <mat-tab label="Economies New">
                     <div class="full-width mb-16 mt-8">
                         <span class="form-bloc-title">Economies</span>
                         <div class="full-width">
                             <div fxLayout="row" class="full-width">
                                 <button mat-stroked-button
                                         *ngFor="let f of K.fluids" class="mr-4 p-0"
                                         (click)="addEconomy(f)" fxFlex="20">
                                     <div fxLayout="row" fxLayoutAlign="space-between center">
                                         <span fxFlex class="pl-8 text-left">{{f}}</span>
                                         <mat-icon fxFlex="30px" inline="true" class="color-blue" style="font-size: 18px">add_circle</mat-icon>
                                     </div>
                                 </button>
                             </div>
                         </div>
                     </div>
                     <div *ngIf="addEconomyFluid" class="full-width mb-4 mt-8 border-bottom p-4 relative"
                          fxLayout="row" fxLayoutAlign="space-between center">
                         <icon-fluid fxFlex="30px" [fluid]="addEconomyFluid"></icon-fluid>
                         <div fxFlex="160px" fxLayout="column">
                             <mat-select class="economy-input-item" placeholder="Cible d'économie">
                                 <mat-option [value]="pdm.label_short"
                                             *ngFor="let pdm of pdmsForfluid"
                                             (click)="selectPdmForEconomy(pdm)">
                                     {{pdm.label_short}}
                                 </mat-option>
                             </mat-select>
                             <mat-select class="economy-input-item" placeholder="Année tarifaire:"
                                         *ngIf="selectedPdmForEconomy&&selectedPdmForEconomy.summary">
                                 <mat-option><b>Année tarifaire</b></mat-option>
                                 <mat-option [value]="year" (click)="selectedYearForEconomy=year;getTariffValueForYear()"
                                             *ngFor="let year of selectedPdmForEconomy.summary.years">{{year}}</mat-option>
                             </mat-select>
                         </div>
                         <div fxFlex="120px" fxLayout="column">
                             <mat-select placeholder="Tarif" class="economy-input-item" *ngIf="selectedPdmForEconomy&&selectedPdmForEconomy.summary">
                                 <mat-option><b>Choix tarif</b></mat-option>
                                 <mat-option (click)="selectedTariffKindForEconomy=tariff;getTariffValueForYear()"
                                             [value]="tariff" *ngFor="let tariff of selectedPdmAvailableTariffs">
                                     {{tariff}}
                                 </mat-option>
                             </mat-select>
                             <div class="economy-input-item font-weight-bold color-blue">{{selectedTariffForEconomyStr}}</div>
                         </div>
                         <div fxFlex="100px" fxLayout="column">
                             <div class=" economy-input-item">
                                 <input class="economy-input  " matInput [(ngModel)]="addEconomyAmount"
                                        (keyup)="enterEconomyValue($event)"
                                        [disabled]="selectedTariffForEconomy<=0"
                                        type="number" placeholder="Economie">
                             </div>

                             <div class="economy-input-item economy-final-value">
                                 <span class="color-blue font-weight-bold pl-4">
                                     {{selectedFinalEconomyStr | currency:'Frs':'symbol':'2.2-2'}}
                                 </span>
                             </div>
                         </div>
                         <div fxLayout="column" fxLayoutAlign="end none">
                             <div fxFlex>.</div>
                             <button mat-raised-button class=" " style="margin-bottom: -5px;line-height: 29px" color="primary" (click)="saveEconomy()">
                                 <mat-icon inline="true" style="font-size: 20px">save</mat-icon>
                             </button>
                             <button class="btn-close-econ-adder" (click)="resetEconomyAdder()">
                                 <mat-icon inline="true">close</mat-icon>
                             </button>
                         </div>
                     </div>

                     <ng-container *ngFor="let f of act.econ_energy|keyvalue">
                         <div class="mb-8" fxLayout="row" fxLayoutAlign="space-between center">
                             <div class="fluid-chip rounded-circle mat-bg-chip d-flex align-items-center justify-content-center">
                                 <icon-fluid [fluid]="f.value.fluid"></icon-fluid>
                             </div>
                             <div>
                                 <div fxLayout="row">
                                     <span fxFlex="100px" class="font-weight-bold">{{f.value.amount}} KWh</span>
                                     <span>{{f.value.pdm_label}}</span>
                                 </div>
                                 <div fxLayout="row">
                                     <span fxFlex="100px" class="text-muted">{{f.value.tariff_cache|number}} ct/KWh</span>
                                     <span class="font-weight-bold">{{f.value.tariff_year}} / </span>
                                     <span>{{f.value.tariff_kind}}</span>
                                 </div>
                             </div>
                             <div fxFlex>

                             </div>

                         </div>
                     </ng-container>

                 </mat-tab>-->
                <mat-tab label="Economies">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div class="econ-toggles-holder" *ngFor="let fluid of siteService.availableFluids" fxFlex="33">

                            <div class="agent-metric-but-adder"
                                 fxLayout="row" fxLayoutAlign="start center"
                                 [class.active]="isEconomiesContainTariff(fluid)"
                                 (click)="addEconomyFluid=fluid">
                                <icon-fluid [fluid]="fluid"></icon-fluid>
                                <span>{{ fluid }}</span>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="addEconomyFluid">
                        <mat-select class="tarif-choser-dropdown" placeholder="Choix du tarif {{addEconomyFluid}}">
                            <ng-container *ngFor="let tc of siteService.clientSite.tariff_config">
                                <mat-option (click)="addLegacyEconomy(tc)" *ngIf="tc.fluid===addEconomyFluid">
                                    <mat-icon *ngIf="isEconomiesContainTariff(tc.uid)">check</mat-icon>
                                    <mat-icon *ngIf="!isEconomiesContainTariff(tc.uid)">add</mat-icon>
                                    <b>{{ tc.label }}</b> -
                                    <span class="m-4"> ({{ siteService.getTariffValueForYear(tc.uid)|number }}
                                        {{ tc.priceUnitStr }})</span>
                                </mat-option>
                            </ng-container>
                        </mat-select>

                    </div>
                    <div fxLayout="column">
                        <action-economy [econ]="econ"
                                        [yearTerminated]="act.yearTerminated"
                                        (cb)="recalc()"
                                        *ngFor="let econ of act.economies"></action-economy>
                    </div>
                </mat-tab>
                <!--Journal-->
                <mat-tab label="Journal" *ngIf="!act.isDraft">
                    <mat-list class="full-width compact-list">
                        <ng-container *ngFor="let comm of act.comments|keyvalue">
                            <mat-list-item class="full-width">
                                <ngx-avatar [name]="comm.value.name" [size]="40"></ngx-avatar>
                                <div fxLayout="row" fxFlex="100">
                                    <h6 class="m-0 ml-16" fxFlex>
                                        {{ comm.value.name }}<br>
                                        <span class="text-muted">{{ comm.value.message }}</span>
                                    </h6>
                                    <div fxFlex="100px" *ngIf="comm.value.ts">
                                        <mat-chip>{{ comm.value.ts * 1000|amTimeAgo }}</mat-chip>
                                    </div>
                                    <div fxFlex="100px" *ngIf="comm.value.timestamp">
                                        <mat-chip>{{ comm.value.timestamp * 1000|amTimeAgo }}</mat-chip>
                                    </div>
                                </div>
                            </mat-list-item>
                        </ng-container>
                    </mat-list>
                    <div fxLayout="column" *ngIf="myapp.getIfUserCanDoForRoute('ACTIONS','COMMENT')">
                        <mat-form-field fxFlex="50" class="m-4">
                            <textarea matInput [(ngModel)]="temp_comment" placeholder="Commentaire">  </textarea>
                        </mat-form-field>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <div fxFlex></div>
                            <button mat-raised-button color="primary" (click)="addComment()">Commenter</button>
                        </div>
                    </div>
                    <div style="height: 240px;overflow: scroll;width: 100%" *ngIf="myapp.isAdminOrSuperUser">
                        <div *ngFor="let logItem of act.log" [innerHTML]="logItem.logHtml"></div>
                    </div>

                </mat-tab>

                <!--documents-->
                <mat-tab label="Annexes" *ngIf="!act.isDraft">
                    <mat-list class="full-width compact-list">
                        <ng-container *ngFor="let docMetas of docsMetas;">
                            <mat-list-item class="full-width doc-list">
                                <div fxLayout="row" fxFlex="100">
                                    <h6 class="m-0 ml-4 mr-16" fxFlex
                                        (click)="openLink(docMetas)">
                                        <span fxFlex>{{ docMetas.name }}</span>
                                        {{ docMetas.generation / 1000|amTimeAgo }}
                                    </h6>
                                    <mat-icon inline
                                              *ngIf="canEditRights.includes('UPLOAD')"
                                              (click)="deleteDocument(docMetas)">
                                        delete
                                    </mat-icon>
                                </div>
                            </mat-list-item>
                        </ng-container>
                    </mat-list>

                    <app-storage-uploader *ngIf="myapp.appInitStatusLevel===3&&canEditRights.includes('UPLOAD')"
                                          [type]="'Thread'"
                                          (uploadComplete)="cbFromUploader($event)"></app-storage-uploader>

                </mat-tab>
            </mat-tab-group>
        </div>
    </mat-card-content>

    <mat-card-footer fxLayout="row" class=" p-8 mt-4">
        <div class="p-8">
            <mat-slide-toggle
                    *ngIf="myapp.isAdminOrSuperUser"
                    [(ngModel)]="act.is_deleted" labelPosition="before">Supprimée
            </mat-slide-toggle>
        </div>
        <div fxFlex=""></div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <!--
            <button mat-raised-button color="default">Sauvegarder le brouillon</button>
             -->
            <div fxFlex *ngIf="canEditRights.length===0">
                <mat-icon inline class="warning">warning</mat-icon>
                <span>Droits manquants</span>
            </div>
            <button mat-raised-button color="default" class="ml-8"
                    (click)="publishAction()">
                <span *ngIf="act.ts_published===0">Publier</span>
            </button>
            <button mat-raised-button color="primary" class="ml-8"
                    [disabled]="!act.title||canEditRights.length===0"
                    (click)="saveAction()">
                <span *ngIf="!act.isDraft">Sauvegarder</span>
                <span *ngIf="act.isDraft">Créer</span>
            </button>
        </div>
    </mat-card-footer>
</mat-card>

<div class="m-8" *ngIf="chapters!==undefined">
    <div class="chapters-header" fxLayout="row" fxLayoutAlign="space-between center">
        Chapitres
        <div fxFlex=""></div>
        <button mat-icon-button
                (click)="site.addChapter(null, getChapterChildren(null).length +'', type);">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <ng-container *ngFor="let ch of getChapterChildren(null); let i = index;">

        <tree-item
                (click)="selectChapter(ch)"
                [treeTogleStatus]="treeTogleStatus"
                [level]="1"
                [index]="i"
                [type]="type"
                [selectedChapter]="selectedChapter"
                [chapters]="getChapterChildren(null)" [ch]="ch">
        </tree-item>

        <ng-container *ngFor="let subCh of getChapterChildren(ch.uid); let j = index;">

            <tree-item
                    [treeTogleStatus]="treeTogleStatus"
                    [level]="2"
                    [type]="type"
                    (click)="selectChapter(subCh)"
                    [index]="j"
                    [selectedChapter]="selectedChapter"
                    [chapters]="getChapterChildren(ch.uid)" [ch]="subCh"></tree-item>

            <ng-container *ngFor="let subSubCh of getChapterChildren(subCh.uid); let k = index;">

                <tree-item
                        [treeTogleStatus]="treeTogleStatus"
                        [level]="3"
                        [type]="type"
                        (click)="selectChapter(subSubCh)"
                        [index]="k"
                        [selectedChapter]="selectedChapter"
                        [chapters]="getChapterChildren(subCh.uid)" [ch]="subSubCh"></tree-item>

                <ng-container *ngFor="let subSubSubCh of getChapterChildren(subSubCh.uid); let l = index;">
                    <tree-item [treeTogleStatus]="treeTogleStatus"
                               [level]="4"
                               [type]="type"
                               (click)="selectChapter(subSubSubCh)"
                               [index]="l"
                               [selectedChapter]="selectedChapter"
                               [chapters]="getChapterChildren(subSubCh.uid)" [ch]="subSubSubCh"></tree-item>
                </ng-container>

            </ng-container>

        </ng-container>

    </ng-container>

</div>

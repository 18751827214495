import {AfterViewInit, Attribute, Directive, ElementRef, Input, OnChanges, OnInit, SimpleChange} from '@angular/core';
import {H} from "../helpers/H";

@Directive({
    selector: '[strEnh]'
})
//string enhancement directive
export class StrEnhDirective implements AfterViewInit, OnInit, OnChanges {

    constructor(private el: ElementRef, @Attribute('strEnh') public enh: string) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: { [propName: string]: SimpleChange }) {

    }

    ngAfterViewInit(): void {
        const htmlEl = this.el.nativeElement as HTMLElement;
        //console.log("StrEnhDirective", this.enh, htmlEl)
        if (this.enh === "ucfirst")
            htmlEl.innerText = H.ucfirst(htmlEl.innerText.trim());
        if (this.enh === "number")
            htmlEl.innerText = Number(htmlEl.innerText.trim()).toFixed(2);
    }

}

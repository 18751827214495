export const plotLayout = {
    barmode: 'group',
    margin: {
        l: 20,
        r: 20,
        b: 80,
        t: 20,
        pad: 0
    }, title: false,
    font: {
        family: 'Roboto, sans-serif',
        size: 12,
    },
    "yaxis": {
        "automargin": true,
        "hoverformat": ".1f",
        "showspikes": true,
        "tickprefix": "      ",
        "showtickprefix": "all",
        "rangemode": "tozero",
        "separatethousands": true,
        "exponentformat": "none",
        "type": "linear",
        "autorange": true
    },
    xaxis: {
        showticklabels: true,
        title: "Mois",
        ticks: 'outside',
        tickcolor: '#000',
        tickvals: [],
        ticktext: [],
    },
    "hovermode": "closest",
    "separators": ".'",
    "legend": {
        "xanchor": "center",
        "yanchor": "bottom",
        "orientation": "h",
        "x": 0.5,
        "y": 1
    }
};
export const plotData = [
    {
        marker: {
            color: [],
        },
        type: "bar",
        x: [],
        y: [],
    },
];

import {Injectable, Optional, SkipSelf} from '@angular/core';
import {isDevMode} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import 'firebase/firestore';
import {BehaviorSubject, EMPTY, Subscription} from 'rxjs';
import * as Sentry from '@sentry/browser';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {K} from "../models/K";
import {AppLoaderService} from "./app-loader/app-loader.service";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {BsInmailerComponent} from "../../views/subs/bs-inmailer/bs-inmailer.component";
import {AppConfirmService} from "./app-confirm/app-confirm.service";
import {ClientSite, User} from '../models/models';
import moment from "moment/moment";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {BsActionComponent} from "../../views/subs/bs-action/bs-action.component";
import {ApiService} from "./api.service";
import {H} from "../helpers/H";
import {environment} from "../../../environments/environment";
import {Notif} from "../models/Notifs";

@Injectable(
    {providedIn: "root"}
)
export class AppService {
    public k = K;// static constant class
    public user: User = null;
    public user_display_name: string = null;
    public user_display_name_short: string = null;

    public env: any;
    public clientSiteList: ClientSite[] = [];

    public appInitStatus: BehaviorSubject<number>;
    public appInitStatusLevel: number;
    public firebaseNewEventsDetector: BehaviorSubject<any[]>;
    public globalPopupsIgniter: BehaviorSubject<any>;
    public globalDocumentViewer: BehaviorSubject<{ title: string, pdfSource: string, metas: any }>;
    public devMode: boolean = null;
    public isDisplayedComponentAdmin = false;
    public userRightsForRoute: string[] = [];
    public usersLastSeenMap: Map<string, any> = new Map<string, any>();

    previousUrl: string;
    currentUrl: string = '';
    pdmEditorTabIndex: number = 0;
    public siteResponsibleSubs: Subscription = null;
    public notifSubscription: Subscription;
    public notifs: Notif[] = [];
    public newerAppVerion = null;//reload

    get isCanton() {
        return this.user && this.user.group && this.user.group === 'CANTON';
    }

    get isAdminOrSuperUser() {
        return this.user && this.user.isAdminOrSuperUser;
    }

    get isProd() {
        return (environment.production) &&
            (!window.location.href.includes('localhost'));
    }

    get isAdmin() {
        return this.user && this.user.isAdmin;
    }

    get nowLongStr() {
        const pdfDateMom = moment();
        return pdfDateMom.format('LLL');
    }

    constructor(
        public rdb: AngularFireDatabase,
        private http: HttpClient,
        public api: ApiService,
        public db: AngularFirestore,
        public storage: AngularFireStorage,
        public activatedRoute: ActivatedRoute,
        public router: Router,
        public spinner: NgxSpinnerService,
        public toastr: ToastrService,
        public loader: AppLoaderService,
        public confirm: AppConfirmService,
        public auth: AuthService,
        private _bottomSheet: MatBottomSheet
    ) {
        this.devMode = isDevMode();
        this.initMomentLocale();

        this.appInitStatus = new BehaviorSubject<number>(0);
        this.firebaseNewEventsDetector = new BehaviorSubject<any[]>([]);
        this.globalPopupsIgniter = new BehaviorSubject<any>({});
        this.globalDocumentViewer = new BehaviorSubject<any>({});

        this.appInitStatus.subscribe(state => {
            const selectedDomainUID = localStorage.getItem("selectedDomainUID");
            this.appInitStatusLevel = state;
            console.info("AppService::appInitStatusLevel=", state);
            if (state === 1) {
                this.tryLoadUser();
            }
            if (state === 2 && !selectedDomainUID) {
                this.getLastRouteAndNavigate();// will navigate to /sites
            }
            if (state === 3) {//after selected site data loaded
                this.run();// set sentry and navigate to last route
            }
        });

        this.getSites(false);
        console.info("AppService::constructor END---", this.user);

    }

    tryLoadUser() {
        this.showMessage("Connexion");
        this.auth.authSubject.subscribe(state => {
            console.log("AppService::authSubject---", this.router.url, state);
            if (state === "error") {
                this.showError(this.auth.error);
            }
            if (state === "notConnected"
                && !this.router.url.includes('/session/reset')
                && !this.router.url.includes('/session/invite')
            ) {
                console.log("AppService::authSubject---> Navigating to session/login", this.router.url, state);
                this.router.navigate(['/session/login']);
            }
            if (state === "updated") {
                this.updateUserLocally();
                this.afterLoadUser(state);
            }
            if (state === "connected") {
                this.updateUserLocally();
                this.afterLoadUser(state);
            }
        });
    }

    getUrlParams() {
        /// TODO: find cleaner way
        const retVal = [];
        if (this.activatedRoute.snapshot) {
            const snapshot = this.activatedRoute.snapshot;
            const segments = snapshot['_urlSegment']['children']['primary']['segments'];
            if (segments.length > 0)
                return segments;
        }
        return retVal;
    }

    afterLoadUser(state: string) {
        this.showMessage("Chargement des données de l'utilisateur");
        console.info('afterLoadUser--here', "User gathered", state);
        /// TODO: test next 2 line if buggy for long term, replaced the fs update on login
        this.user = new User(this.auth.user);
        // console.log('myapp:afterLoadUser()', this.user, state);
        if (this.user && state === 'connected') {
            console.log('myapp:afterLoadUser()', this.user, state, "State is now 2");
            this.appInitStatus.next(2);
            this.notifSubscription = this.rdb.object('notifs/' + this.user.uid)
                .valueChanges()
                .subscribe(node => {
                    this.notifs = [];
                    if (node) {
                        this.notifs = Object.keys(node).map(key => {
                            const notif = new Notif(node[key]);
                            notif.key = key;
                            return notif;
                        });
                        console.log("Notifs", this.notifs);
                    }
                });
        }
    }

    addNotif(userUidTarget: string, notifKey: string, extraData: any) {
        const sender = {name: this.user_display_name_short, uid: this.user.uid}
        extraData['sender'] = sender;
        extraData['ts'] = H.unixTs(true);
        const updCom = {};
        updCom[notifKey] = extraData;
        this.rdb.object('notifs/' + userUidTarget)
            .update(updCom)
            .then(data => {

            })
    }

    getSites(silent = false) {
        this.showMessage("Chargement des bâtiments");
        // console.log("Site::-------------App::getSites - SEND");
        return this.api.getSiteList().subscribe(res => {
            if (res && res.body && res.body.length > 0) {
                this.clientSiteList = res.body.map(it => new ClientSite(it));
                // when silent, reload sites list in background, otherwise re-bootstrap current site
                if (!silent)
                    this.appInitStatus.next(1);
            } else {
                console.error("body is null", res);
                this.showError("Rafraichir la page. Liste des batiments non chargée !")
            }
        }, error => {
            console.error("this.api.getSiteList()::ERROR", error);
        });
    }

    getLastRouteAndNavigate() {
        let path = localStorage.getItem("lastRoute");
        const selectedDomainUID = localStorage.getItem("selectedDomainUID");

        if (!path || path.includes('#')) {
            path = '/site-home';

            if (path.includes('#')) {// <-- correcting migration to useDash removal in routing config
                localStorage.setItem("lastRoute", path);
            }
        }
        if (!selectedDomainUID) {
            path = '/sites';
            // console.log("APP_SERVICE::: getLastRouteAndNavigate::selectedDomainUID NULL", this.appInitStatusLevel, path);
            this.router.navigate([path]);
            return;
        }

        if (this.user?.canSeeUrl(path)) {
            this.router.navigate([path]);
        } else {
            console.log("getLastRouteAndNavigate()---> Navigating to ROOT", this.router.url);
            localStorage.removeItem("lastRoute");
            this.router.navigate(['/']);
        }
    }

    run() {
        // this.setFirebaseEventsNodeListener();
        this.loader.close();
        console.info('myapp:Run()==================================================');
        this.getLastRouteAndNavigate();
        Sentry.configureScope(scope => {
            scope.setExtra('user', this.user.uid);
            scope.setTag('user_mode', 'admin');
            scope.setUser({email: this.user.email, uid: this.user.uid});

            if (this.isProd) {
                const currBuildTS = environment.version_ts;
                const currBuildDate = environment.version;
                const currentSiteREF = localStorage.getItem('selectedDomainRef');
                scope.setTag('currBuildTS', currBuildTS);
                scope.setTag('currBuildDate', currBuildDate);
                scope.setTag('SiteREF', currentSiteREF);
            }

        });
    }

    logout() {
        if (this.notifSubscription)
            this.notifSubscription.unsubscribe();
        if (this.siteResponsibleSubs)
            this.siteResponsibleSubs.unsubscribe();
        //console.log("trying to logout ", this.auth);
        this.auth.doLogout().then(r => {
            this.user = null;
            this.auth.user = null;
            this.auth.fbUser = null;
            this.router.navigate(['/session/login']);
        }).catch(err => {
            console.error("Logout err", err);
        });
    }

    setUserBehaviorTrace(action: string) {
        if (!this.user) return;
        if (!this.isProd) return;
        this.env = environment;
        this.env.version_ts = Number(environment.version_ts);
        const currentSiteREF = localStorage.getItem('selectedDomainRef').replace(".", '_');
        const currentSiteUID = localStorage.getItem('selectedDomainUID');
        const traceObj = {
            lastOnline: H.unixTs(true),
            wat: action,
            appVersion: this.env.version_ts,
            siteUID: currentSiteUID,
            siteREF: currentSiteREF
        };
        const updCommand = {};
        updCommand[currentSiteREF + traceObj.wat] = traceObj;
        this.rdb.object('/users/' + this.user.uid + '/history/')
            .update(updCommand)
            .then(resp => {
                console.log("setUserBehaviorTrace", action, resp);
            });
        this.rdb.object('/users/' + this.user.uid)
            .update({
                siteREF: currentSiteREF,
                lastOnline: traceObj.lastOnline,
                appVersion: this.env.version_ts,
                wat: traceObj.wat,
                name: this.user_display_name_short
            })
            .then(resp => {
                console.log("setUserBehaviorTrace", action, resp);
            });
    }

    sendSpecialErrorReportRDB(error: string, data: any) {
        const currentSiteREF = localStorage.getItem('selectedDomainRef');
        const currentSiteUID = localStorage.getItem('selectedDomainUID');
        const errorObj = {
            ts: H.unixTs(true),
            error,
            data,
            email: this.user && this.user.email ? this.user.email : 'USER_NOT_LOADED_YET',
            siteUID: currentSiteUID,
            siteREF: currentSiteREF
        };
        this.rdb.list('/errors/')
            .push(errorObj)
            .then(resp => {
                console.log("sendSpecialError", errorObj);
            });
    }

    setFirebaseEventsNodeListener() {
        this.rdb.list('/events').snapshotChanges().subscribe((a) => {
            console.log("Firebase snapshotChanges", a);
            const newEvents = [];
            a.forEach(item => {
                if (item.type === 'child_added')
                    newEvents.push(item.payload.toJSON());
            });
            if (newEvents.length) {
                this.showMessage("New event");
                this.firebaseNewEventsDetector.next(newEvents);
            }
        });
    }

    getLatestBuildAndUpdateRDB() {
        if (!this.isProd) return;
        const currBuildTS = environment.version_ts;
        const currBuildDate = environment.version;
        this.rdb.object('/globalConfig/').valueChanges()
            .subscribe(
                resp => {
                    const latestBuildName = resp['latestBuildName'];
                    const latestBuildTS = resp['latestBuildTS'];
                    console.log("getLatestBuildAndUpdateRDB():STORED", window.location.href);
                    console.log("getLatestBuildAndUpdateRDB():STORED", latestBuildName, latestBuildTS);
                    console.log("getLatestBuildAndUpdateRDB():CURR", currBuildDate, currBuildTS);

                    if (Number(currBuildTS) > Number(latestBuildTS)) {
                        // set stored version is current is newer. Will run when developper first run
                        this.rdb.object('/globalConfig/').set({
                            latestBuildTS: Number(currBuildTS),
                            latestBuildName: currBuildDate
                        }).then(resp => {
                            console.log("getLatestBuildAndUpdateRDB():set", resp);
                        })
                    }
                    if (currBuildTS < latestBuildTS) {
                        this.newerAppVerion = {
                            latestBuildName, latestBuildTS
                        };
                    }
                }
            );
    }

    /*
    HELPERS
     */
    getSiteWithId(uid: string) {
        let retval: ClientSite = null;
        this.clientSiteList.forEach(site => {
            if (site.uid === uid) retval = site;
        });
        // console.log('getSiteWithId', uid, retval);
        return retval;
    }

    updateUserLocally() {
        this.user = this.auth.user;
        if (this.user) {
            this.user = new User(this.auth.user);
            this.user_display_name = this.auth.user.gender + " " + this.auth.user.first_name + " " + this.auth.user.last_name;
            this.user_display_name_short = this.auth.user.first_name + " " + this.auth.user.last_name;
            localStorage.setItem('email', this.auth.user.email);
        } else {
            console.error("updateUserLocally() FAILED. auth.user null", this.auth);
        }

    }

    openBottomSheet(): void {
        this._bottomSheet.open(BsActionComponent);
    }

    openBS_Mailer(): void {
        this._bottomSheet.open(BsInmailerComponent);
    }

    showMessage(message) {
        this.toastr.success(message, "Succès", {timeOut: 2000});
    }

    showError(errMessage, istop = false) {
        if (istop)
            this.toastr.error(errMessage, "Erreur", {timeOut: 8000, positionClass: 'toast-top-right'});
        else
            this.toastr.error(errMessage, "Erreur", {timeOut: 2000});

    }

    showInfoPopup(message) {
        this.toastr.info(message, "Info", {timeOut: 8000});
    }

    showConsole(arg1, arg2 = null) {
        console.log("DEBUG:", arg1, arg2);
    }

    sendErrorReport() {
        const scopeContext = {
            user: this.user,
            extra: {
                site_access: this.user && this.user.site_access ? Object.fromEntries(this.user?.site_access.entries()) : [],
                bgUser: this.auth.fbUser,
                tracesLog: this.auth.tracesLog
            }
        };
        if (this.isProd) {
            const currBuildTS = environment.version_ts;
            const currBuildDate = environment.version;
            const currentSiteREF = localStorage.getItem('selectedDomainRef');
            scopeContext.extra['currBuildTS'] = currBuildTS;
            scopeContext.extra['currBuildDate'] = currBuildDate;
            scopeContext.extra['SiteREF'] = currentSiteREF;
        }
        Sentry.captureMessage("ErrorReport", scopeContext);
        const errObj = {traceObj: this.auth.tracesLog, email: this.auth.userEmail, source: "From empty app component"};
        this.sendErrorReportFtp(errObj);
        this.showMessage("Rapport envoyé");
    }

    sendErrorReportFtp(errObj: any) {
        this.api.sendErrorLog(errObj)
            .subscribe(resp => {
                console.log("Error report sent");
                this.showInfoPopup("Rapport d'activité envoyé en date du: " + resp.dateStr);
            })
    }

    getIfUserCanDoForRoute(routeID, wat) {
        const rights = this.user && this.user.getRightsForSiteAndRoute(routeID);
        return (
                (rights && rights.length) && rights.includes(wat)
            )
            || this.isAdminOrSuperUser;
    }

    getIfUserCanDo(wat: string) {
        if (!this.user) return false;
        const rightsForCurrentSiteAndRoute = this.user.getRightsForSiteAndRoute(K.routesConstants[this.router.url]);
        return rightsForCurrentSiteAndRoute.includes(wat) || this.isAdminOrSuperUser;
    }

    storeCurrentRoute() {
        if (this.user) {
            console.log("APP_SERVICE:::storeCurrentRoute() RIGHTS", " CAN SEE: " + this.router.url, this.user.canSeeUrl(this.router.url), this.user);
            if (!this.user.canSeeUrl(this.router.url)) {
                console.log("storeCurrentRoute()---> Navigating to ROOT", this.router.url);
                this.router.navigate(['/']);
            }
            try {
                if (this.auth.afAuth && this.auth.afAuth.currentUser)
                    this.setUserBehaviorTrace(this.router.url);
            } catch (e) {
                console.error(e);
            }

        }
        localStorage.setItem("lastRoute", this.router.url);
    }

    public fileSize(bytes: number, decimals = 2) {
        if (!+bytes) return '0 o';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['o', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    initMomentLocale() {
        moment.locale('fr', {
            months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
            monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
            monthsParseExact: true,
            weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
            weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
            weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
            weekdaysParseExact: true,
            longDateFormat: {
                LT: 'HH:mm',
                LTS: 'HH:mm:ss',
                L: 'DD.MM.YYYY',
                LL: 'D MMMM YYYY',
                LLL: 'D MMMM YYYY HH:mm',
                LLLL: 'dddd D MMMM YYYY HH:mm'
            },
            calendar: {
                sameDay: '[Aujourd’hui à] LT',
                nextDay: '[Demain à] LT',
                nextWeek: 'dddd [à] LT',
                lastDay: '[Hier à] LT',
                lastWeek: 'dddd [dernier à] LT',
                sameElse: 'L'
            },
            relativeTime: {
                future: 'dans %s',
                past: 'il y a %s',
                s: 'quelques secondes',
                m: 'une minute',
                mm: '%d minutes',
                h: 'une heure',
                hh: '%d heures',
                d: 'un jour',
                dd: '%d jours',
                M: 'un mois',
                MM: '%d mois',
                y: 'un an',
                yy: '%d ans'
            },
            dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
            ordinal: function (number) {
                return number + (number === 1 ? 'er' : 'e');
            },
            meridiemParse: /PD|MD/,
            isPM: function (input) {
                return input.charAt(0) === 'M';
            },
            // In case the meridiem units are not separated around 12, then implement
            // this function (look at locale/id.js for an example).
            // meridiemHour : function (hour, meridiem) {
            //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
            // },
            meridiem: function (hours, minutes, isLower) {
                return hours < 12 ? 'PD' : 'MD';
            },
            week: {
                dow: 1, // Monday is the first day of the week.
                doy: 4  // Used to determine first week of the year.
            }
        });
    }

    isNumber(n) {
        return !isNaN(n);
    }
}

import {Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {AppService} from "../../shared/services/app.service";
import {ThemeService} from "../../shared/services/core/theme.service";
import {LayoutService} from "../../shared/services/core/layout.service";
import {Observable, Observer, Subscription} from "rxjs";
import {ApiService} from "../../shared/services/api.service";
import {H} from "../../shared/helpers/H";
import {SiteService} from "../../shared/services/site.service";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {egretAnimations} from "../../shared/animations/egret-animations";
import {MatOptionSelectionChange} from "@angular/material/core";
import {StorageDocument} from "../../shared/models/models";
import {epsilon} from "simple-statistics";

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss'],
    animations: egretAnimations
})
export class DocumentsComponent implements OnInit {
    @ViewChild('listHolder') listHolder: ElementRef;
    searchTermSub: Subscription;
    initVal: string = '';
    pdfSource: string = '';
    filterKey: string = '';
    selectedChapter: string = '';
    meta: any;
    docsToShow: StorageDocument[];
    linkingPreview = "";
    sortDir = 1;
    chapterToCountMap: Map<string, number> = new Map<string, number>();

    constructor(
        public myapp: AppService, public api: ApiService,
        public storage: AngularFireStorage,
        private layout: LayoutService,
        public site: SiteService,
    ) {
    }

    ngOnInit(): void {
        this.layout.publishLayoutChange({sidebarStyle: 'closed'});
        if (this.myapp.user)
            this.myapp.storeCurrentRoute();
        else
            this.myapp.appInitStatus.subscribe(status => {
                if (status === 1)
                    this.myapp.storeCurrentRoute();
            });
        this.site.siteLoadingStatus.subscribe(eventName => {
            if (eventName === SiteService.MAX_SITE_LOADING_STATUS) {
                this.loadDocs();
            }
        });
    }


    loadDocs() {
        this.docsToShow = [];
        let docsToScan = [];
        this.chapterToCountMap = new Map<string, number>();
        this.site.documents.forEach(doc => {
            console.log('loadDocs', doc.uid_domain, doc.type);
            if (doc.uid_domain) {
                if (!this.selectedChapter)
                    this.docsToShow.push(doc);
                else {
                    if (doc.chapter === this.selectedChapter)
                        this.docsToShow.push(doc);
                }
                if (!doc.chapter) doc.chapter = 'NC';
                if (!this.chapterToCountMap.has(doc.chapter))
                    this.chapterToCountMap.set(doc.chapter, 0);

                const currCount = this.chapterToCountMap.get(doc.chapter);
                this.chapterToCountMap.set(doc.chapter, currCount + 1);
            }

        });
    }

    sort(byField: string) {
        this.sortDir++;
        if (this.sortDir === 2) this.sortDir = -1;
        console.log("sort", byField, this.docsToShow, this.sortDir);
        //chapter title firstName updated
        if (byField === "firstName") {
            this.docsToShow = this.docsToShow.sort((d1, d2) => {
                return d1.user_metas.firstName.localeCompare(d2.user_metas.firstName) * this.sortDir;
            });
        } else if (byField === "updated") {
            this.docsToShow = this.docsToShow.sort((d1, d2) => {
                return d1.storage_metas.updated.localeCompare(d2.storage_metas.updated) * this.sortDir;
            });
        } else {
            this.docsToShow = this.docsToShow.sort((d1, d2) => {
                const dd1 = d1[byField] ? d1[byField] : '';
                const dd2 = d2[byField] ? d2[byField] : '';

                if (byField === "updated" || byField === "ts")
                    return (dd1 - dd2) * this.sortDir;
                else
                    return dd1.localeCompare(dd2) * this.sortDir;
            });
        }
    }

    isEmptyChapter(ch) {
        return (!this.site.documents) || this.site.documents.filter(doc => doc.chapter === ch).length === 0;
    }

    setChapter($event: MatOptionSelectionChange, doc: StorageDocument) {
        if ($event.isUserInput) {
            console.log("setChapter", $event, doc);
            doc.chapter = $event.source.value;
            this.site.api.saveDocument(doc).subscribe(resp => {
                console.log("saveDocument", resp, doc);
                if (resp.status == 1) this.myapp.showMessage("Document mit à jour avec succès!");
                else this.myapp.showError("Document non mit à jour. " + resp.errors);
            });
        }
    }

    cbFromUploader(event) {
        const {act, arg1} = event;
        if (act === 'done') {
            this.loadDocs();
        }
    }


    refresh($event) {
        const key = $event.target.value;
        console.log("refresh", key);
        this.docsToShow = this.site.documents.filter(exp => {
            const normArg = key
                .toLocaleLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
            const {title, user_metas} = exp;
            return JSON.stringify({title, user_metas})
                .toLocaleLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .includes(normArg) && exp.type === 'Document';
        });
    }

    selectChapter(ch: string) {
        console.log("selectChapter", ch);
        this.selectedChapter = ch;
        this.loadDocs();
    }

    selectDoc(doc) {
        console.log("selectDoc", doc);
        this.meta = doc.storage_metas;
        let pdfSource;
        doc._storageFileSubscription.subscribe(u => {
            console.log(u);
            pdfSource = "https://api.optimigration.ch/endpoint/cors?f=" + H.utf8_to_b64(u);
            this.myapp.globalDocumentViewer.next({title: "Titre", pdfSource, metas: this.meta});
        });
    }

}

import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AppService} from "../../../shared/services/app.service";
import {SiteService} from "../../../shared/services/site.service";
import {Optimise} from "../../../shared/helpers/Optimise";
import moment from "moment";
import {K} from "../../../shared/models/K";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {ReportSuivi} from 'app/shared/models/Suivi.model';
import {Action, Chapter} from "../../../shared/models/Action.model";

@Component({
    selector: 'suivi-actions',
    template: `
        <div class="component-holder">
            <h2>Actions</h2>
            <table class="table-actions">
                <tr class="table-actions-head">
                    <td (click)="sort(col)" *ngFor="let col of colsActions"
                        class="col-head-{{col}}"
                        [ngClass]="{'total-col':col==='total'}">
                        <span>{{ actionsCols[col] }}</span>
                        <span *ngIf="sortBy===col&&sortDirection">
                                        <mat-icon class="color-blue" inline *ngIf="sortDirection===-1">south</mat-icon>
                                        <mat-icon class="color-blue" inline *ngIf="sortDirection===1">north</mat-icon>
                                    </span>
                    </td>
                </tr>
                <ng-container *ngIf="isRef">
                    <tr class="sub-header-row">
                        <td [colSpan]="colsActions.length">Actions de référence</td>
                    </tr>
                    <tr action-table-row class="table-actions-row "
                        [act]="act" *ngFor="let act of actionsOfConvention"
                        (click)="site.openAction(act)" [colsActions]="colsActions">
                    </tr>
                </ng-container>
                <ng-container *ngIf="!isRef">
                    <tr class="sub-header-row">
                        <td [colSpan]="colsActions.length">Actions terminées avant {{ year }}</td>
                    </tr>
                    <tr action-table-row class="table-actions-row "
                        [act]="act" *ngFor="let act of actionsCompletedBefore"
                        (click)="site.openAction(act)" [colsActions]="colsActions">
                    </tr>
                    <tr class="sub-header-row">
                        <td [colSpan]="colsActions.length">Actions terminées en {{ year }}</td>
                    </tr>
                    <tr action-table-row class="table-actions-row "
                        [act]="act" *ngFor="let act of actionsCompletedThisYear"
                        (click)="site.openAction(act)" [colsActions]="colsActions">
                    </tr>
                    <tr class="sub-header-row">
                        <td [colSpan]="colsActions.length">Actions non terminées en {{ year }}</td>
                    </tr>
                    <tr action-table-row class="table-actions-row "
                        [act]="act" *ngFor="let act of actionsNotCompleted"
                        (click)="site.openAction(act)" [colsActions]="colsActions">
                    </tr>
                </ng-container>

            </table>
        </div>
    `,
    styleUrls: ['./suivi.component.scss']
})
export class SuiviActionsComponent implements OnInit, OnChanges {
    @Input('reportRef') reportRef: ReportSuivi;
    @Input('report') report: ReportSuivi;
    @Input('year') year: number; // force update
    @Input('goals') goals: any[];

    actions: Action[] = [];

    sortBy: string = '';
    sortDirection = 0;

    actionsCols = {
        num: "N°", title: "Titre/description",
        economies_total: 'Économie',
        status: "Statut",
        // isRef: "Réf",
        ts_due: "Délai",
        ts_completed: "Terminé le",
    };
    log = [];

    constructor(public myapp: AppService, public site: SiteService) {
    }

    get actionsOfConvention(): Action[] {
        return this.site.actions.filter(act => act.is_ref && act.is_deleted === 0);
    }

    get actionsCompletedThisYear(): Action[] {
        let acts = this.site.actions.filter(act => {
            // console.log("Filter", act, act.status, act.year, act.status === "COMPLETED", act.year === this.report.year);
            return act.yearTerminated === this.report.year
                && act.gc
                && act.is_deleted === 0
                && (act.status === "COMPLETED" || act.status === "REFUSED")
        });
        acts = Optimise.sortArrayByKeyVal(acts, this.sortBy);
        if (this.sortDirection === -1) acts = acts.reverse();
        return acts;
    }

    get actionsCompletedBefore(): Action[] {
        let acts = this.site.actions.filter(act => {
            // console.log("Filter", act, act.status, act.year, act.status === "COMPLETED", act.year === this.report.year);
            return act.yearTerminated < this.report.year
                && act.gc
                && act.is_deleted === 0
                && (act.status === "COMPLETED" || act.status === "REFUSED")
        });
        acts = Optimise.sortArrayByKeyVal(acts, this.sortBy);
        if (this.sortDirection === -1) acts = acts.reverse();
        return acts;
    }

    get actionsNotCompleted(): Action[] {
        let acts = this.site.actions.filter(act => {
            // console.log("Filter", act, act.status, act.year, act.status === "COMPLETED", act.year === this.report.year);
            return act.yearProposed <= this.report.year
                && act.gc
                && act.is_deleted === 0
                && (act.status !== "COMPLETED" || act.yearTerminated > this.report.year)
            // && act.status !== "REFUSED"
        });
        acts = Optimise.sortArrayByKeyVal(acts, this.sortBy);
        if (this.sortDirection === -1) acts = acts.reverse();
        return acts;
    }

    get colsActions() {
        return Object.keys(this.actionsCols);
    }

    get isRef() {
        return this.reportRef && this.report
            && this.report.type === 'HIDDEN_REF_DATA';
    }

    ngOnInit() {
        this.site.siteSpecificEventTriggerer.subscribe(eventName => {
            if (eventName === SiteService.PDMS_CONFIG_RELOADED) {
                this.bootstrap();
            }
        });
    }

    // check commit  with message 'VERSION MIGRATION' to see version of this component related to Pdm
    bootstrap() {
        this.actions = this.site.actions.filter(act => {
            // console.log("Filter", act, act.status, act.year, act.status === "COMPLETED", act.year === this.report.year);
            return moment(
                    act.ts_completed * 1000).year() === this.report.year
                && act.gc
                && act.is_deleted === 0
                && (act.status === "COMPLETED" || act.status === "REFUSED")
                ; //act.status === "COMPLETED" && //act.year === this.report.year  &&
        });
        this.sort('num');

    }

    changeTab($event: MatTabChangeEvent) {
        console.log("change tab", $event);
        this.sortBy = '';
        this.sortDirection = 0;
    }

    sort(key) {
        this.sortDirection++;
        this.sortBy = key;
        if (this.sortDirection === 2) this.sortDirection = -1;
    }


    ngOnChanges(changes: SimpleChanges) {
        this.bootstrap();
    }

}


@Component({
    selector: '[action-table-row]',
    template: `
        <ng-container>
            <td *ngFor="let col of colsActions" [ngClass]="{'total-col':col==='total','title-col':col==='title'}" class="col-{{col}}">
                <ng-container *ngIf="col!=='status'&&col!=='economies_total'">
                    {{ act.getValueOf(col) }}
                </ng-container>
                <ng-container *ngIf="col==='status'">
                    <mat-icon inline [style.color]="act.getValueOf(col).color">fiber_manual_record</mat-icon>
                    {{ act.getValueOf(col).name }}
                </ng-container>
                <ng-container *ngIf="col==='economies_total'&&act.getValueOf(col)&&myapp.isNumber(act.getValueOf(col))">
                    {{ act.getValueOf(col) |   number: '1.2-2' }} Fr.
                </ng-container>
                <div class="econ-subrow-holder" *ngIf="col==='title'&&economies.length">
                    <img src="https://tool.optimise.swiss/api/web/subdirectory_arrow_right.png" height="30px">
                    <div class="econ-subrow fluid-{{e.fluid}}" *ngFor="let e of economies">
                        <span class="label">{{ e.label }}: </span>
                        <span class="value">{{ e.value |   number: '1.2-2' }}</span>
                        <span class="unit">{{ e.unit }}</span>
                    </div>
                </div>
            </td>
        </ng-container>
    `,
    styleUrls: ['./suivi.component.scss']
})
export class ActionTableRow implements OnInit {
    @Input('act') act: Action;
    @Input('colsActions') colsActions: any;

    economies = []

    constructor(public site: SiteService, public myapp: AppService) {
    }

    ngOnInit() {
        this.act.economies.forEach(econ => {
            const econSummary = {label: econ.label, fluid: econ.fluid, ...econ.renderAmount(econ.econEstimated)}
            this.economies.push(econSummary)
        })
    }
}

import {Observable} from "rxjs";
import {H} from "../helpers/H";
import {Optimise} from "../helpers/Optimise";
import {evaluate, index, mean, multiply} from "mathjs";
import * as MathJS from "mathjs";
import * as MlMatrix from "ml-matrix";
import {PlotUnit} from "./models";
import {PdmDataMeteo, PdmDataRow} from "./PdmDataRow";
import {K} from "./K";

/*
import regression from 'regression';
import {Equation, parse} from "algebra.js";
import {jsstats} from "js-stats/src/jsstats";
*/


export class PdmYearConfig {
    fluid: string;
    agent: string;
    pdm_type: string;

    uid: string;
    uid_site: string;
    uid_client: string;
    uid_pdm: string;

    year: number;
    year_ref: number;
    tarif: number;

    real_cons: number[];
    real_cost: number[];
    predicted_cons: number[];
    predicted_cost: number[];

    economies: string;
    influence_params: string;

    texts: string;
    ts_created: string;
    ts_updated: string;

    plots_units: Map<string, PlotUnit> = new Map<string, PlotUnit>();

    cassure: number = 0;
    investment: number = 0;
    subventions: number = 0;
    fp: number = 0;
    ceges: number = 0;

    signature_year: PdmYearSignature = null;
    signature_year_ref: PdmYearSignature = null;// cache used signature for calculations
    model_year: PdmYearModel = null;
    model_year_ref: PdmYearModel = null;//cache used model for calculations

    ts: number;//ts calced

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
            if (key === 'plots_units' && dynProps[key]) {
                this.plots_units = new Map<string, PlotUnit>(Object.entries(dynProps[key]));
            }
        });

    }

    getUnitForPlot(key: string): PlotUnit {
        if (this.plots_units && this.plots_units.has(key))
            return this.plots_units.get(key);
        else {
            return new PlotUnit(key, this.fluid);
        }
    }

    getJson(): any {
        return H.customObjectToJson(this);
    }
}

export class PdmYearSignature {
    points: PlotPoint[];
    type: string = 'MIXTE'; //CHAUD,FROID
    flat: string = 'NONE'; //LEFT RIGHT
    sd: boolean = false;

    isManual = false;
    cassure: number = 10;

    chosenRegIndex: number = -1;
    regCouplesMap: Map<string, SignRegsCouple> = new Map<string, SignRegsCouple>();
    chosenRegsCouple: SignRegsCouple;

    ts: number;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
        if (this.chosenRegsCouple) {
            this.chosenRegsCouple = new SignRegsCouple(this.chosenRegsCouple);
            if (this.chosenRegsCouple.connection) this.cassure = this.chosenRegsCouple.connection;
        }
        this.ts = H.unixTs();
    }

    calcObs() {
        return new Observable((observer) => {
            this.calc();
            observer.next(this.regCouplesMap);
            observer.complete();
        });
    }

    calc() {

        if (!this.points || this.points.length < 1) return;
        this.points = Optimise.sortArrayByKeyVal(this.points, 'x');
        // console.log("SignatureCalc: ", debug);
        this.regCouplesMap = new Map<string, SignRegsCouple>();
        for (let i = 3; i <= 9; i++) {
            const part1 = this.points.slice(0, i);
            const part2 = this.points.slice(i, this.points.length);
            const regLeft = new PointsReg({}).load(part1, i, this.flat === 'LEFT');
            const regRight = new PointsReg({}).load(part2, i, this.flat === 'RIGHT');
            const signCouple = new SignRegsCouple(
                {
                    regLeft, regRight, index: i,
                    allPoints: this.points,
                    sd: this.sd,
                    connection: this.cassure,
                    isManual: this.isManual
                });
            signCouple.calcEcart();
            this.regCouplesMap.set(i.toString(), signCouple);
        }
        //console.log("SignatureCalc: ", this.regCouplesMap);
        this.regCouplesMap = new Map([...this.regCouplesMap.entries()]
            .sort((a, b) => a[1].ecart - b[1].ecart));
        this.chosenRegsCouple = null;
        this.regCouplesMap.forEach((v, k) => {
            if (this.chosenRegsCouple === null) this.chosenRegsCouple = v;
        });

        // apply calculated connection to signature object
        if (this.chosenRegsCouple && this.chosenRegsCouple.connection) {
            if (!this.isManual)
                this.cassure = this.chosenRegsCouple.connection;
        }
        // console.log("Calc end:", this);
        this.ts = H.unixTs();
    }

    getPlotData() {
        if (!this.chosenRegsCouple || !this.chosenRegsCouple.regLeft || !this.chosenRegsCouple.regRight || !this.points || !this.points.length) return [];
        const lastPoint = this.points[this.points.length - 1];
        const retValPoints = [];
        let predictedY1 = this.chosenRegsCouple.regLeft.predict(this.points[0].x);
        const predictedY2 = this.chosenRegsCouple.regLeft.predict(this.chosenRegsCouple.connection);
        const predictedY3 = this.chosenRegsCouple.regRight.predict(this.chosenRegsCouple.connection);
        let predictedY4 = this.chosenRegsCouple.regRight.predict(lastPoint.x);
        const connectionAvg = (predictedY2 + predictedY3) / 2;

        if (this.flat === "LEFT") predictedY1 = this.sd ? connectionAvg : predictedY2;
        if (this.flat === "RIGHT") predictedY4 = this.sd ? connectionAvg : predictedY3;

        retValPoints.push({x: this.points[0].x, y: predictedY1, index: 1});
        if (!this.sd) {
            retValPoints.push({x: this.chosenRegsCouple.connection - 0.01, y: connectionAvg, index: 2});
            retValPoints.push({x: this.chosenRegsCouple.connection + 0.01, y: connectionAvg, index: 3});
        } else {
            retValPoints.push({x: this.chosenRegsCouple.connection - 0.01, y: predictedY2, index: 2});
            retValPoints.push({x: this.chosenRegsCouple.connection + 0.01, y: predictedY3, index: 3});
        }
        retValPoints.push({x: lastPoint.x + 5, y: predictedY4, index: 4});

        console.log("getPlotData: ", this.chosenRegsCouple, this.points, retValPoints);
        return retValPoints;//Optimise.sortArrayByKeyVal(retValPoints, 'x');
    }


    getUsedRegsCouple(): SignRegsCouple {
        if (this.chosenRegIndex && this.chosenRegIndex >= 0)
            return this.regCouplesMap.get(this.chosenRegIndex.toString());
        else return null;
    }

    getJson(): any {
        return {
            points: this.points,
            sd: this.sd,
            flat: this.flat,
            isManual: this.isManual,
            chosenRegIndex: this.chosenRegIndex,
            chosenRegsCouple: H.customObjectToJson(this.chosenRegsCouple),
            cassure: this.cassure,
            type: this.type,
            ts: this.ts,
        };
    }
}

export class PdmYearModel {
    r2: number;
    model: number[];
    used_cassure: number = 0;
    varExpList: string[];
    onlyPositiveCons = false;
    ts: number;//ts calced
    private x: any[] = [];
    private b: any[] = [];
    plotLabels = {};

    constructor(dynProps: any = {}) {
        this.varExpList = [];
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
        this.ts = H.unixTs();
    }

    calcModel(dataRowsForYear: PdmDataRow[]) {
        this.x = [];
        this.b = [];

        let counterOk = 0, counterKO = 0;
        K.months.forEach((name, m) => {
            const dataItem = dataRowsForYear[m];
            if (!dataItem) {
                console.log("rawDataForYear !dataItem", dataRowsForYear);
                return;
            }
            let consItem = Number(dataRowsForYear[m].ve || 0);//* unit_mult
            if (this.onlyPositiveCons && consItem === 0) {

            } else {
                const xVal = this.getVarExpForMonthIndex(dataRowsForYear[m], m);
                this.x.push(xVal);
                this.b.push([consItem, 0, 0]);
                //console.log('X-B', this.x, this.b, dataRowsForYear);
            }

        });

        try {
            const bMat = new MlMatrix.Matrix(this.b).transpose();
            const xMat = new MlMatrix.Matrix(this.x);
            const xInvTrans = MlMatrix.inverse(xMat, true).transpose();
            const a = multiply(bMat.to2DArray(), xInvTrans.to2DArray());
            this.model = a[0];
            counterOk++;
            this.ts = H.unixTs();
        } catch (e) {
            counterKO++;
            console.log(e.message);
        }
        this.calcModelQuality(dataRowsForYear);
        // console.log('calc', rawDataForYear, this);
    }

    calcModelQuality(rawDataForYear: PdmDataRow[]) {

        let sumPredicted = 0;
        let sumValues = 0;
        let predictedValues = [];
        let deltaInPercent = [];
        let refYearValues = [];
        let SCE = 0;
        let SCR = 0;   // R2=SCE/(SCR+SCE)

        K.months.forEach((m, ind) => {
            const dataForMonth = rawDataForYear[ind];

            if (!dataForMonth) {
                console.error('calcModelQuality:dataForMonth empty for monthIndex', ind, "rawDataForYear", rawDataForYear, "Model:", this.model);
                return;
            }
            const consItem = Number(dataForMonth.ve);
            if (this.onlyPositiveCons && consItem === 0) return;
            refYearValues.push(consItem);
            sumValues += consItem;
            const xVal = this.getVarExpForMonthIndex(dataForMonth, ind);
            // console.log("calcModelQuality", this, this.model, xVal);
            const res = evaluate('a * b', {a: this.model, b: xVal});
            SCR += Math.pow((res - consItem), 2);
            predictedValues.push(res);
            deltaInPercent.push(consItem ? 100 * (res - consItem) / consItem : 0);
            sumPredicted += res;


        });
        /// TODO: number of month is not always 12
        const totAvg = sumValues / 12;
        predictedValues.forEach((predictedVal, index) => {
            const refVal = refYearValues[index];
            SCE += Math.pow((refVal - totAvg), 2);
        });

        this.r2 = Math.sqrt(SCE / (SCE + SCR));
        // console.log("calcModelQuality", this, totAvg);
    }

    predict(rows: PdmDataRow[], debug = {}) {
        const predictedValues = [];
        try {
            K.months.forEach((m, ind) => {
                const dataForMonth = rows[ind];
                if (!dataForMonth) {
                    return;
                }
                const consItem = Number(dataForMonth.ve);
                const xVal = this.getVarExpForMonthIndex(dataForMonth, ind, debug);// xVal in Ax=B
                //debug['var' + ind] = xVal;

                const res = evaluate('a * x', {a: this.model, x: xVal});
                // console.log('predict:Result: ', res);
                // console.log('predictDebug: ', dataForMonth, xVal, this.model);
                if (this.onlyPositiveCons && consItem === 0) predictedValues.push(0);
                else
                    predictedValues.push(res);
            });
        } catch (e) {
            console.error("predict", e.message);
        }

        return predictedValues;
    }

    getVarExpForMonthIndex(dataForMonth, ind, debug = {}) {
        //const dataForMonth = rawDataForYear[ind];

        if (!dataForMonth.vars_meteo_obj)
            dataForMonth.vars_meteo_obj = new PdmDataMeteo(dataForMonth.vars_meteo);
        const varExpls = this.varExpList.map(varname => {
            let retVal = -1;
            const rowDate = new Date(dataForMonth.date);
            const DAY_NUM = H.getDayNum(rowDate);
            if (varname === 'DAY_COS')
                retVal = Math.cos(DAY_NUM * Math.PI / 180);
            if (varname === 'DAY_NUM')
                retVal = DAY_NUM;
            if (varname === 'DAY_CNT')
                retVal = dataForMonth.days;
            if (varname === 'M_1')
                retVal = dataForMonth.vars_meteo_obj.m1;
            if (varname === 'M_2')
                retVal = dataForMonth.vars_meteo_obj.m2;
            if (varname === 'M_3')
                retVal = dataForMonth.vars_meteo_obj.m3;
            if (varname === 'DJ_EP')
                retVal = dataForMonth.vars_meteo_obj.getDJ('c', this.used_cassure, this.used_cassure);
            if (varname === 'DJ_SI')
                retVal = dataForMonth.vars_meteo_obj.getDJ('c', 12, 20);
            if (varname.includes('EXTRA_')) {
                const varNameClean = varname.replaceAll('EXTRA_', '');
                if (dataForMonth.vars_horaire && dataForMonth.vars_horaire[varNameClean])
                    retVal = Number(dataForMonth.vars_horaire[varNameClean]);
                //console.log("getVarExpForMonthIndex", varNameClean, dataForMonth.vars_horaire, retVal);
            }
            if (varname.includes('REG_')) {
                const varNameClean = varname.replaceAll('REG_', '');
                if (dataForMonth.data && dataForMonth.data[varNameClean])
                    retVal = Number(dataForMonth.data[varNameClean]);
                //console.log("varExplReg", varNameClean, dataForMonth.data, retVal);
            }

            if (!debug[ind]) debug[ind] = {};
            debug[ind][varname] = retVal;
            debug[ind]['date'] = dataForMonth.date;
            return retVal;
        });
        //console.log("getVarExpForMonthIndex", this.varExpList, varExpls);
        return varExpls;
    }

    getModelStr() {
        let retVal = "";
        const varexplLabelMap = {
            DAY_CNT: "Nb Jours",
            DAY_COS: "cos(Num jour)",
            DAY_NUM: "Num jour",
            M_1: "T° Moy",
            M_2: "Humidité",
            M_3: "Rayonnement",
            DJ_SI: "DJC SI",
            DJ_EP: "DJC[" + this.used_cassure.toFixed(1) + "]"
        };
        if (this.model) {
            const strArr = this.model.map((item, index) => {
                //console.log("getModelStr", item, index);
                const newLine = (this.model.length >= 5 && index === 3) ? '<br>' : "";
                return Math.round(item * 1000) / 1000 + "<span>®</span> <b>" + varexplLabelMap[this.varExpList[index]] + "</b>" + newLine;
            });

            retVal = strArr.join(" + ") + " r2: " + this.r2.toFixed(3);
        }

        return retVal;
    }

    getJson(): any {
        return {
            r2: this.r2,
            model: this.model,
            used_cassure: this.used_cassure,
            varExpList: this.varExpList,
            onlyPositiveCons: this.onlyPositiveCons,
            ts: this.ts
        };
    }
}

export class SignRegsCouple {
    sd: boolean;
    isManual: boolean;
    allPoints: PlotPoint[];
    regLeft: PointsReg;
    regRight: PointsReg;
    index = 0;
    ecart: number = -5;
    connection: number;
    coupleSeparationX1: number;
    coupleSeparationX2: number;
    nextIndexedPtX: number;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
        if (this.regLeft)
            this.regLeft = new PointsReg(this.regLeft);
        if (this.regRight)
            this.regRight = new PointsReg(this.regRight);

        if (
            this.regLeft && this.regLeft.points && this.regLeft.p1 &&
            this.regRight && this.regRight.points && this.regRight.p1
            && this.regLeft.points.length > 0) this.index = this.regLeft.regIndex;
        else return;
        this.coupleSeparationX1 = this.regLeft.points[this.regLeft.points.length - 1].x;
        this.coupleSeparationX2 = this.regRight.points[0].x;
        if (this.isManual && this.connection && this.connection > 0) {
            // connection is provided manually
            /// TODO: check if it is within boundaries
        } else {
            //Not manual
            if (!this.sd) {
                this.connection = (this.coupleSeparationX1 + this.coupleSeparationX2) / 2;
            } else {
                this.connection = Math.abs((this.regLeft.p1 - this.regRight.p1) / (this.regLeft.p2 - this.regRight.p2));
            }
        }
        this.nextIndexedPtX = this.allPoints.length > (this.index) ? this.allPoints[this.index].x : -1;
        this.calcEcart();
    }

    calcEcart() {
        if (!this.sd) {
            this.ecart = 1 / Math.abs(this.coupleSeparationX1 - this.nextIndexedPtX);
        } else {
            if (this.coupleSeparationX1 < this.connection && this.connection < this.nextIndexedPtX) {
                this.ecart = 0;
            } else {
                this.ecart = Math.abs(this.coupleSeparationX1 - this.connection);
            }
        }
    }

    tStudentTotal(signatureType = 0) {
        return 1 / this.regRight.tStudent + 1 / this.regLeft.tStudent;
    }

}

export class PointsReg {
    regIndex: number;
    points: PlotPoint[];
    isFlat: boolean = false;

    meanPt: PlotPoint;
    r2: number;
    eq: number[];//0=>slope 1=>intercept retrocompatibility
    size: number;// retrocompatibility
    slope: number;
    intercept: number;
    formula: string;

    ssyy: number;
    ssxy: number;
    ssxx: number;
    sse: number;
    s2yx: number;//S2y,x
    stdDevSlope: number;//S2y,x/SSxx
    tStudent: number;

    p1: number;
    p2: number;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
    }

    // load used as an "afterConstructor builder"
    load(points: any[], regIndex: number, flat: boolean): PointsReg {
        this.regIndex = regIndex;
        this.points = points;
        this.isFlat = flat;
        this.size = points.length;
        if (this.size < 1) return;
        this.meanPt = Optimise.mean(this.points);
        this.ssyy = Optimise.ssyy(this.points, this.meanPt);
        this.ssxy = Optimise.ssxy(this.points, this.meanPt);
        this.ssxx = Optimise.ssxx(this.points, this.meanPt);

        this.slope = this.ssxy / this.ssxx;
        this.intercept = this.meanPt.y - this.slope * this.meanPt.x;
        this.eq = [this.slope, this.intercept];
        this.formula = "y =" + this.slope + "x + " + this.intercept;

        this.sse = Optimise.sse(this.points, this.slope, this.intercept);
        this.s2yx = this.size > 2 ? this.sse / (this.size - 2) : -1;
        this.stdDevSlope = this.s2yx / this.ssxx;
        this.r2 = (this.ssyy - this.sse) / this.ssyy;
        this.tStudent = Math.abs(this.slope / Math.sqrt(this.stdDevSlope));
        this.calcP1P2();
        //console.log("CALCED Reg", this);
        return this;
    }

    calcP1P2() {
        if (this.isFlat) {
            this.p1 = this.meanPt.y;
            this.p2 = 0;
        } else {
            this.p1 = this.intercept;
            this.p2 = this.slope;
        }
    }

    predict(x: number): number {
        return this.slope * x + this.intercept;
    }

    getRegPoints(): PlotPoint[] {
        const retVal = this.points.map(pt => new PlotPoint({x: pt.x, y: this.predict(pt.x)}));
        console.log("getRegPoints()", retVal);
        return retVal;
    }
}

export class PlotPoint {
    x: number;
    y: number;
    index: number;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
    }
}

import {BehaviorSubject} from "rxjs";
import moment from "moment";
import {H} from "./helpers/H";
import {AppService} from "./services/app.service";
import {Router} from "@angular/router";
import {Component, OnInit} from "@angular/core";

@Component({
    selector: 'app-base',
    template: `   `,
    styles: []
})
export class BaseComponent implements OnInit {
    public componentLoadingStatus: BehaviorSubject<number>;//if needed

    constructor() {
        //console.log("BaseComponent--constructor", myapp.k);
        moment.locale('fr');
        this.componentLoadingStatus = new BehaviorSubject<number>(0);
    }

    ngOnInit(): void {
    }

    getItemFromArrayByFieldVal(arr: any[], key: string, val: any) {
        let retval = null;
        arr.forEach(item => {
            if (item[key] === val) retval = item;
        })
        return retval;
    }

    getPlainJsSelf(obj) {
        return Object.assign({}, obj);
    }

    delItemFromArray(arr: any[], uidToDel: string, uidKey = 'uid') {
        const retVal = [];
        arr.forEach(item => {
            if (item[uidKey] !== uidToDel)
                retVal.push(item);
        });
        arr = retVal;
        return retVal;
    }

    getMd5(toHash: any) {
        return H.getMd5(toHash);
    }

    clone(o: any) {
        return JSON.parse(JSON.stringify(o));
    }

    sortObjectKeys(o) {
        let sorted = {},
            key, a = [];

        for (key in o) {
            if (o.hasOwnProperty(key)) {
                a.push(key);
            }
        }

        a.sort();

        for (key = 0; key < a.length; key++) {
            sorted[a[key]] = o[a[key]];
        }
        return sorted;
    }

    sortArrayByKeyVal(arr: any[], key: string, sortType = 'number') {
        return arr.sort((a, b) => {
            let x = a[key], y = b[key];
            if (sortType === 'string') {
                x = (x.map(c => c.charCodeAt(0))).join('');
                y = (y.map(c => c.charCodeAt(0))).join('');
            }
            if (x < y) {
                return -1;
            }
            if (x > y) {
                return 1;
            }
            return 0;
        });
    }
}

import {Component, OnInit, AfterViewInit, Renderer2} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router';
import {RoutePartsService} from './shared/services/core/route-parts.service';
import {filter} from 'rxjs/operators';
import {AppService} from "./shared/services/app.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
    appTitle = 'Optimise 2.0';
    pageTitle = '';

    metas: any;
    pdfSource: string = '';
    title: string = null;

    get showButPanel() {
        const url = this.myapp.router.url ? this.myapp.router.url : '';
        const isSessionPart = url.includes('session');
        return !this.myapp.user && !isSessionPart;
    }

    constructor(
        public pageTitleServ: Title,
        private router: Router,
        public myapp: AppService,
        private activeRoute: ActivatedRoute,
        private routePartsService: RoutePartsService,
    ) {
    }

    ngOnInit() {
        this.changePageTitle();
        this.myapp.globalDocumentViewer.subscribe(docEv => {
            const {title, metas, pdfSource} = docEv;
            //  console.log('globalDocumentViewer()', metas, pdfSource);
            this.metas = metas;
            this.title = title;
            this.pdfSource = pdfSource;
        });
    }

    logout() {
        //this.router.navigate(['/session/login']);
        console.log("logout", this.myapp);
        this.myapp.logout();
    }

    ngAfterViewInit() {
    }


    changePageTitle() {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
            const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
            if (!routeParts.length) {
                return this.pageTitleServ.setTitle(this.appTitle);
            }
            // Extract title from parts;
            this.pageTitle = routeParts
                .reverse()
                .map((part) => part.title)
                .reduce((partA, partI) => {
                    return `${partA} > ${partI}`;
                });
            this.pageTitle += ` | ${this.appTitle}`;
            this.pageTitleServ.setTitle(this.pageTitle);
        });
    }
}

import moment from "moment/moment";
import {K} from "./K";
import {H} from "../helpers/H";
import {ClientSite, SiteTariffConfig, SiteTariffData, StorageDocument, User} from "./models";
import {EnergyStats, Metric} from "./EnergyStats.model";
import {SiteService} from "../services/site.service";

export class Chapter {
    uid: string;
    uid_site: string;
    parent: string;
    title: string;
    display_order: number;
    type: string;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
    }
}

export class ActionEconomyLegacy {
    agentMetric: string;
    econEstimated: number;//annual econ expected at creation
    econFinal: number;//annual econ at termination
    econPerYear: {};//real annual econ per year

    // legacy fields
    value: number;
    tempValue: number;
    metric: string;
    ts_created: number;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
    }
}

// new structure, based on legacy and some improvements, no pdm support yet
export class ActionEconomyPerAgent {
    uid: string; // NEW used to delete
    uid_tariff: string;// uid of tariff_config: ID du tarif

    label: string; // copy of arent tarif
    unit: string; // unitType
    fluid: string; // useful to categorize
    agent: string;


    //annual estimated econ at proposal - should not be modified after act is accepted
    econEstimated: number;
    econRealPerYear = {};//real annual econ per year starting from terminated year

    ts_created: number;

    // legacy fields removed or being removed
    clean_label: string;
    agentMetric: string;
    value: number;
    tempValue: number;
    metric: string;
    usageUID: string;// uid of energyStatsUsage: ID du tarif de energystats.usages
    yearCreated: number; // NEW humanly readable ts_created

    constructor(dynProps: any = {}) {
        this.econRealPerYear = {};
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
        // console.log('con action',this)
    }

    set econFinal(e) {
        // econ final setter for backward compatibility,this field is dynamically calculated
    }

    get econFinal() {
        let econFinal = 0;
        let latestYear = 0;
        Object.keys(this.econRealPerYear).forEach(year => {
            if (Number(year) > latestYear) econFinal = Number(this.econRealPerYear[year.toString()]);
        });
        return econFinal;
    }


    /// TODO: Only show econ estimated
    amount(tarifConfig: SiteTariffConfig) {
        if (tarifConfig.isWater) return this.econEstimated;
        else return this.econEstimated / 1e6;
    }

    economyInChf(tariffObj: SiteTariffConfig, tariffData: SiteTariffData) {
        // water management
        const price = tariffData.priceAdapted;
        const econVal = this.econEstimated;
        const tariffMultiplicator = tariffObj.fluid === 'EAU' ? Number(price) : Number(price) / 100;
        const econMultiplicator = tariffObj.fluid === 'EAU' ? Number(econVal) : Number(econVal) / 1000;
        //  const econVal = this.status === 'COMPLETED' ? econ.econFinal : econ.econEstimated;
        const econTotal = tariffMultiplicator * econMultiplicator;
        return econTotal;
    }

    getEmissions(tariffObj: SiteTariffConfig, tariffData: SiteTariffData) {
        // water management
        const econVal = this.econEstimated;
        const tariffMultiplicator = tariffObj.fluid === 'EAU' ? Number(tariffData) : Number(tariffData) / 100;
        const econMultiplicator = tariffObj.fluid === 'EAU' ? Number(econVal) : Number(econVal) / 1000;
        //  const econVal = this.status === 'COMPLETED' ? econ.econFinal : econ.econEstimated;
        const econTotal = tariffMultiplicator * econMultiplicator;
        return econTotal;
    }

    get unitStr() {
        return K.unitShort[this.unit];
    }

    get priceUnitStr() {
        return K.getDefaultCostUnits(this.unit);
    }

    calcAmountToStore(amount: number) {
        if (this.unit === 'energy') return amount * 1e6;
        if (this.unit === 'power') return amount * 1e6;
        if (this.unit === 'volume') return amount;
        if (this.unit === 'petrol') return amount;
        if (this.unit === 'weight') return amount * 1e6;
        if (this.unit === 'no_unit') return amount * 1e6;
    }

    renderAmount(amount: number) {
        if (this.unit === 'energy') return {value: amount / 1e6, unit: Metric.unitShort[this.unit]};
        if (this.unit === 'power') return {value: amount / 1e6, unit: Metric.unitShort[this.unit]};
        if (this.unit === 'volume') return {value: amount, unit: Metric.unitShort[this.unit]};
        if (this.unit === 'petrol') return {value: amount, unit: Metric.unitShort[this.unit]};
        if (this.unit === 'weight') return {value: amount / 1e6, unit: Metric.unitShort[this.unit]};
        if (this.unit === 'no_unit') return {value: amount / 1e6, unit: Metric.unitShort[this.unit]};
    }
}

export class Action {
    uid: string;
    num: number;
    gc: number;
    uid_site: string;
    uid_chapter: string;
    title: string;

    status: string;
    priority: string;

    description: string;
    monetary_economy: number;
    economies_total: number;

    is_deleted: number;
    is_ref: number;

    page_hebdo: string;
    ape_categ: string;

    payback: number;
    investment: number;
    investment_factor: number;
    investment_real: number;
    solution: string;
    note: string;

    //economies: ActionEconomyLegacy[];
    economies: ActionEconomyPerAgent[];
    comments: any;
    comments_count: number;
    assignees: ActionAssignee[] = [];

    attachments: string[];
    subventions: ActionSubvention[] = [];

    // log
    log: ActionLogItem[] = [];
    // timestamps
    ts_created: number;
    ts_proposed: number;
    ts_published: number;
    ts_updated: number;
    ts_last_commented: number;
    ts_completed: number;
    ts_due: number;

    // calculated in constructor
    dateDue: string;
    dateCompleted: string;
    dateCreated: string;
    yearCreated: number;
    dateProposed: string;
    yearProposed: number;
    yearDue: number;
    yearTerminated: number;
    _warning_missing_tarif: boolean = false;
    _tariff_data: any = null;//populated by backenf only in Iso 14001 monitor
    _econsByFluid = {}
    _unitByFluid = {}

    constructor(dynProps: any = {}, siteService: SiteService = null) {
        if (!dynProps) return;
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
        ///console.log("action constructor1111", dynProps.economies);
        /// typeof this.economies === 'object'
        /// TODO: check how much time this line runs

        if (this.log) {
            this.log = this.log.map(it => new ActionLogItem(it));
        } else {
            this.log = [];
        }

        if (this.economies) {
            if (!Array.isArray(this.economies)) {
                console.log("action constructor", 'this.economies is object', this.economies, dynProps);
                this.economies = Array.from(Object.values(this.economies));
            }
            this.economies = this.economies.map(it => new ActionEconomyPerAgent(it));
        }
        if (this.assignees) this.assignees = this.assignees.map(it => new ActionAssignee(it));
        if (!this.note || this.note.trim().length < 2) this.note = null;

        // timestamps
        if (this.ts_proposed) {
            const objMom = moment(this.ts_proposed * 1000);
            this.dateProposed = objMom.format('MMM Y');
            this.yearProposed = objMom.year();
        }
        if (this.ts_created) {
            const objMom = moment(this.ts_created * 1000);
            this.dateCreated = objMom.format('MMM Y');
            this.yearCreated = objMom.year();
        }
        if (this.ts_due) {
            const objMom = moment(this.ts_due * 1000);
            this.dateDue = objMom.format('MMM Y');
            this.yearDue = objMom.year();
        } else this.dateDue = "NC";

        if (this.ts_completed) {
            const objMom = moment(this.ts_completed * 1000);
            this.dateCompleted = objMom.format('MMM Y');//yearTerminated
            this.yearTerminated = objMom.year();
        } else this.dateCompleted = "NC";

        if (this.subventions) {
            this.subventions = this.subventions.map(it => new ActionSubvention(it));
        } else this.subventions = [];

        //*******
        this.investment_factor = Number(this.investment_factor);
        /// TODO: need enhancement
        this.ts_last_commented = 0;
        if (this.comments && !this.comments['none'])
            this.comments_count = Object.keys(this.comments).length;
        if (this.comments_count > 0) {
            Object.keys(this.comments).forEach(idComm => {
                const comment = this.comments[idComm];
                if (comment.ts) {
                    comment.ts = Number(comment.ts);
                    this.comments_count++;
                    if (comment.ts > this.ts_last_commented) this.ts_last_commented = comment.ts;
                }
            });
        }

        // CALC ECON ///
        if (siteService) {
            this.calcEconomyLegacy(siteService);
            this.getEconomiesByFluid(siteService);
        }

    }

    get isDraft() {
        return this.num === undefined;
    }

    get paybackBrut() {
        return this.investment / this.economies_total;
    }

    get isCompleted() {
        return this.status && this.status === 'COMPLETED';
    }

    get year() {
        const tsDueMoment = moment(this.ts_due * 1000);
        return tsDueMoment.year();
    }

    init(site: ClientSite) {
        this.uid_site = site.uid;
        this.economies = [];
    }

    getIsAssignedToUserId(userID) {
        let retVal = false;
        if (this.assignees && this.assignees.length)
            this.assignees.forEach(assignee => {
                if (assignee.assignee === userID) retVal = true;
            });
        return retVal;
    }

    getValueOf(key) {
        moment().locale('fr');
        if (key === "status") {
            const color = K.StatusColor[this.status];
            const statusName = K.StatusDisplayName[this.status];
            return {color, name: statusName};
        }
        if (key === "isRef") {
            return this.is_ref ? 'Oui' : '-';
        }
        if (key === "ts_due") {
            const tsDueMoment = moment(this.ts_due * 1000);
            return tsDueMoment.format('MMM Y');
        }
        if (key === "ts_completed") {
            if (!this.ts_completed)
                return 'NC';
            const tsDueMoment = moment(this.ts_completed * 1000);
            return tsDueMoment.format('DD MMM Y');
        }
        if (this[key] !== undefined) return this[key];
    }

    getSubventionsTot() {
        let tot = 0;
        if (this.subventions)
            this.subventions.forEach(subv => {
                tot += Number(subv['value']);
            });
        return tot || 0;
    }

    loadDatesFromForm(dateProposed: Date, dateDue: Date, dateTerminated: Date) {
        if (dateProposed) {
            const objMom = moment(dateProposed);
            this.ts_proposed = objMom.unix();
            this.dateProposed = objMom.format('MMM Y');
            this.yearProposed = objMom.year();
        } else {
            // create
            this.ts_proposed = H.unixTs(false);
            const objMom = moment.unix(this.ts_proposed);
            this.dateProposed = objMom.format('MMM Y');
            this.yearProposed = objMom.year();
        }
        if (dateDue) {
            const objMom = moment(dateDue);
            this.ts_due = objMom.unix();
            this.dateDue = objMom.format('MMM Y');
        }
        if (dateTerminated) {
            const objMom = moment(dateTerminated);
            this.ts_completed = objMom.unix();
            this.dateCompleted = objMom.format('MMM Y');
        }
    }

    calcEconomyLegacy(siteService: SiteService) {
        let econTotal = 0;
        this._warning_missing_tarif = false;
        this.economies.forEach(econ => {
            const econVal = this.status === 'COMPLETED' ? econ.econEstimated : econ.econEstimated;
            const tariffObj = siteService.getTariffObj(econ.uid_tariff);
            if (tariffObj) {
                const tariffForRefYear = siteService.getTariffValueForYear(econ.uid_tariff);

                // water management
                const tariffMultiplicator = tariffObj.fluid === 'EAU' ? Number(tariffForRefYear) : Number(tariffForRefYear) / 100;
                const econMultiplicator = tariffObj.fluid === 'EAU' ? Number(econVal) : Number(econVal) / 1000;
                //  const econVal = this.status === 'COMPLETED' ? econ.econFinal : econ.econEstimated;
                econTotal += tariffMultiplicator * econMultiplicator;
            } else this._warning_missing_tarif = true;

        });
        const investRatio = this.investment_factor || 100;
        this.investment_real = investRatio * this.investment / 100 - this.getSubventionsTot();
        this.economies_total = econTotal + Number(this.monetary_economy);
        this.payback = this.investment_real / this.economies_total;
        // console.log("calcEconomyLegacy()", 'num' + this.num, econTotal)
        siteService.getActionAvailableYearsPerAgentMetric();
    }

    getEconomiesByFluid(site: SiteService) {
        const economies = {};
        const unitsPerFluid = {};
        this.economies.forEach(econ => {
            const tariffObj = site.getTariffObj(econ.uid_tariff);
            if (tariffObj) {
                if (economies[tariffObj.fluid] === undefined) {
                    economies[tariffObj.fluid] = 0;
                    this._econsByFluid[tariffObj.fluid] = 0;
                }
                const econ_energy = Number(this.isCompleted ? econ.amount(tariffObj) : econ.amount(tariffObj));
                this._econsByFluid[tariffObj.fluid] += Number(Number(econ_energy).toFixed(3));
                economies[tariffObj.fluid] += Number(Number(econ_energy).toFixed(3));
                unitsPerFluid[tariffObj.fluid] = tariffObj.unitStr;
                this._unitByFluid[tariffObj.fluid] = tariffObj.unitStr;
            } else {
                console.error("Economie à tarif orphelin APE N°:" + this.num, econ.label, econ, this);
            }
        });
        return {economies, unitsPerFluid};
    }

}

export class ActionLogItem {
    email: string;
    traces = []; //array of field|oldval|newval
    ts: number;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
    }

    get dateStr() {
        const m = moment.unix(this.ts);
        return m.format('ddd DD MMM HH:mm');
    }

    get traceCount() {
        if (this.traces && Array.isArray(this.traces))
            return this.traces.length;
        else return 0;
    }

    init(user: User) {
        this.traces = [];
        this.ts = H.unixTs(false);
        this.email = user.email;
    }

    loadTrace(field: string, val_old: any, val_new: any) {
        this.traces.push([field, val_old, val_new]);
    }


    get logHtml() {
        let strHtml = '<table class="act-log-table"><tr class="user"><td colspan="4">User: ' + this.email + '</td></tr>';
        strHtml += '<tr class="user"><td colspan="4">Date: ' + this.dateStr + '</td></tr>';
        strHtml += '<tr class="head"><td class="col1">Champs</td><td>Val. préc.</td><td>Nouv. val.</td></tr>';
        if (this.traces && Array.isArray(this.traces)) {
            this.traces.forEach(trace => {
                strHtml += '<tr><td class="col1">' + trace[0] + '</td><td>' + trace[1] + '</td><td>' + trace[2] + '</td></tr>';
            });
        }
        strHtml += '</table>';
        return strHtml;
    }

}

export class ActionAssignee {
    assignee: string;
    role: string;
    type: string;
    name: string;
    _user: User;//populated afterward for convinience

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
    }

    populateFromUser(u: User) {
        this.assignee = u.uid_bdd;
        this.name = u.first_name + ' ' + u.last_name;
        this.type = 'USER';// other type is GROUP, not implemented yet
        this._user = u;
    }
}

export class ActionSubvention {
    id: string;
    label: string;
    value: number;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
    }
}

export class ActionComment {
    uid: string;
    name: string;
    userId: string;
    message: string;
    timestamp: number;

    constructor(user: User, comment: string) {
        this.timestamp = H.unixTs(false);
        this.name = user.first_name + " " + user.last_name;
        this.userId = user.uid;
        this.message = comment;
        this.uid = H.randomStr(30);
    }
}

import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewContainerRef} from '@angular/core';
import {AppService} from "../../../shared/services/app.service";
import {ApiService} from "../../../shared/services/api.service";
import {Overlay} from "@angular/cdk/overlay";
import {SiteService} from "../../../shared/services/site.service";
import {LayoutService} from "../../../shared/services/core/layout.service";
import {DomSanitizer} from "@angular/platform-browser";
import {egretAnimations} from "../../../shared/animations/egret-animations";
import {MatSidenav} from "@angular/material/sidenav";
import moment from "moment";
import {Optimise} from "../../../shared/helpers/Optimise";
import {H} from "../../../shared/helpers/H";
import {plotCostLayout, plotCostsData, plotUsageData, plotUsageLayout} from "./plots-config";
import {Plotly} from "angular-plotly.js/lib/plotly.interface";
import {PlotlyComponent, PlotlyService} from "angular-plotly.js";
import {BmensItem, BmensItemGroup, BmensItemRow} from "../../../shared/models/Bmens.model";

export class Group {
    level = 0;
    parent: Group;
    expanded = true;
    totalCounts = 0;

    get visible(): boolean {
        return !this.parent || (this.parent.visible && this.parent.expanded);
    }
}


@Component({
    selector: 'app-balance-sheets',
    templateUrl: './balance-sheets.component.html',
    styleUrls: ['./balance-sheets.component.scss'],
    animations: egretAnimations
})
// extends ProtoHebdo
export class BalanceSheetsComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('form', {static: false}) form: ElementRef<HTMLDivElement>;
    @ViewChild('sidenav') sidenav: MatSidenav;
    @ViewChild('plotUsage') plotUsage: PlotlyComponent;
    @ViewChild('plotCost') plotCost: PlotlyComponent;

    balanceSheetsItems: BmensItem[] = [];
    availableMonthsForYear = {};
    availableYears: number[];
    selectedYear: number = 0;
    selectedMonthTS: number = 0;
    selectedMonth: number = 0;
    selectedRow: any = null;
    selectedGroup: BmensItemGroup;
    yearMonthKey: string;

    selectedDate: string;
    rowsGroupedMap: Map<string, BmensItemRow[]> = new Map<string, BmensItemRow[]>();
    rowsGroupedTotalMap: Map<string, any> = new Map<string, any>();
    entryForYearAndDate: BmensItem;
    latestBmensForYear: BmensItem;

    pdfSource: string = '';
    meta: any;
    addEntryMode = true;
    entryNew = new BmensItem({});
    plotUsageLayout = plotUsageLayout as Plotly.Layout;
    plotCostLayout = plotCostLayout as Plotly.Layout;
    plotUsageData = plotUsageData;
    plotCostData = plotCostsData;

    lastItem: BmensItem;

    /*

     */
    constructor(public myapp: AppService, public api: ApiService,
                public overlay: Overlay, public site: SiteService,
                public viewContainerRef: ViewContainerRef,
                public plotlyServ: PlotlyService,
                public layout: LayoutService, public sanitizer: DomSanitizer) {
        this.overlay = overlay;
        this.viewContainerRef = viewContainerRef;
    }

    get rowId() {
        return this.selectedRow ? this.selectedRow.uid : null;
    }

    ngOnInit() {
        moment().locale('fr');
        if (this.myapp.user)
            this.myapp.storeCurrentRoute();
        else
            this.myapp.appInitStatus.subscribe(status => {
                if (status === 1)
                    this.myapp.storeCurrentRoute();
            });
        this.site.siteLoadingStatus.subscribe(eventName => {
            if (eventName === SiteService.MAX_SITE_LOADING_STATUS) {
                this.loadData();
            }
        });

    }

    loadData() {
        this.myapp.loader.open("Chargement des bilans mensuels");
        this.site.api.getBalanceSheets(this.site.uid).subscribe(resp => {
            if (resp.body && resp.body.length > 0) {
                this.balanceSheetsItems = [];
                resp.body.forEach(item => {
                    this.balanceSheetsItems.push(new BmensItem(item));
                });
                this.balanceSheetsItems = this.balanceSheetsItems.sort((a, b) => a.date.localeCompare(b.date));
                this.latestBmensForYear = this.balanceSheetsItems[this.balanceSheetsItems.length - 1];
            }
            this.myapp.loader.close();
            this.boostrap();
        });
    }

    boostrap() {
        console.log("boostrap", this.balanceSheetsItems);
        this.availableYears = [];
        this.availableMonthsForYear = {};
        this.balanceSheetsItems.forEach(item => {
            if (!this.availableYears.includes(item.year)) {
                this.availableYears.push(item.year);
                this.availableMonthsForYear[item.year] = [];
                this.availableMonthsForYear[item.year].push(item);
            } else this.availableMonthsForYear[item.year].push(item);
            this.availableMonthsForYear[item.year].sort((a, b) => a.monthIndex - b.monthIndex);
        });
        this.availableYears.sort();
        if (this.selectedYear === 0) this.selectYear(this.site.currYear);
        console.log("boostrap", this.availableYears, this.availableMonthsForYear);
        this.myapp.loader.close();
    }

    /*


     */
    selectYear(y) {
        this.selectedYear = y;
        this.entryForYearAndDate = null;
        if (!this.availableMonthsForYear[y]) {
            this.myapp.showError("Données non synchronisées");
            return;
        }
        setTimeout(() => {
            if (this.selectedMonth) {
                const tsOfSelectedYearOfSelectedMonth = this.availableMonthsForYear[y].find(it => {
                    const mom = moment(it * 1000);
                    return this.selectedMonth === mom.month();
                });
                if (tsOfSelectedYearOfSelectedMonth)
                    this.selectMonth(tsOfSelectedYearOfSelectedMonth);
            } else
                this.selectMonth(this.availableMonthsForYear[y][0]);
        }, 100);
    }

    selectMonth(item: BmensItem) {
        const tsDueMoment = item.mom;
        this.selectedMonthTS = tsDueMoment.unix();
        this.selectedMonth = tsDueMoment.month();
        this.selectedDate = tsDueMoment.format("YYYY-MM-DD");
        const month = Number(this.selectedMonth) + 1;
        const prefix = month < 10 ? '0' : '';
        this.yearMonthKey = prefix + month + "" + this.selectedYear;

        console.log("selectMonthIndex", this.selectedMonth, this.selectedDate, this.entryForYearAndDate, this.rowsGroupedMap, this.rowsGroupedTotalMap);
        this.entryForYearAndDate = new BmensItem(this.balanceSheetsItems.find(item => item.date === this.selectedDate));
        if (!this.entryForYearAndDate) return;

        //-----
        /*
        this.entryForYearAndDate.rows = this.entryForYearAndDate.rows.map(row => {
            return {energy_cost: Math.floor(row._fullData.energy_cost.value), ...row};
        });*/
        this.rowsGroupedMap = null;
        this.rowsGroupedMap = new Map<string, BmensItemRow[]>();
        this.rowsGroupedTotalMap = new Map<string, any>();
        this.entryForYearAndDate.rows.forEach(row => {
            // console.log("Row",row);
            if (!this.rowsGroupedMap.has(row.group))
                this.rowsGroupedMap.set(row.group, []);
            if (!this.rowsGroupedTotalMap.has(row.group))
                this.rowsGroupedTotalMap.set(row.group, {energy_cost: 0, cost_corr_evol: 0});
            this.rowsGroupedMap.get(row.group).push(row);
            this.rowsGroupedTotalMap.get(row.group).energy_cost += row.energy_cost;
            this.rowsGroupedTotalMap.get(row.group).cost_corr_evol += row.cost_corr_evol;
        });

        // Reselect same row
        if (this.selectedRow) {
            this.selectedRow = this.entryForYearAndDate.rows.find(it =>
                it.label === this.selectedRow.label && this.selectedRow.group === it.group);
            this.selectRow(this.selectedRow);//refresh plots
        } else {
            this.selectedRow = null;
        }
        console.log("selectMonthIndex", this.selectedMonth, this.selectedDate, this.entryForYearAndDate, this.rowsGroupedMap, this.rowsGroupedTotalMap);
    }

    /*


     */
    addMonth() {
        const lastItem = this.latestBmensForYear;
        const momObj = moment(lastItem.date, "YYYY-MM-DD");
        const momObjplus1 = momObj.add(1, 'month');
        const newItem = new BmensItem(lastItem);
        newItem.ts_created = H.unixTs();
        newItem.changeDate(momObjplus1.format("YYYY-MM-DD"));
        newItem.justInserted = true;

        this.balanceSheetsItems.push(newItem);
        this.balanceSheetsItems = this.balanceSheetsItems.sort((a, b) => a.date.localeCompare(b.date));

        //this.availableMonthsForYear[lastItem.year].push(momObjplus1.unix());
        console.log('LAst bmens date', lastItem.date, momObjplus1.format('YYYY-MM-DD'));
        console.log('lastItem', lastItem);

        this.latestBmensForYear = this.balanceSheetsItems[this.balanceSheetsItems.length - 1];

        this.boostrap();
    }

    show(col, row) {
        console.log("click cell", col, row, this.entryForYearAndDate);
        this.entryForYearAndDate.rows = Optimise.sortArrayByKeyVal(this.entryForYearAndDate.rows, 'type').reverse();
    }

    selectRow(row) {
        if (!row) {
            this.myapp.showError("Row non défini");
            return;
        }
        this.selectedRow = row;
        const label = row.label;
        const points = [];
        const balanceSheetsForYear = this.balanceSheetsItems.filter(item => item.year === this.selectedYear);
        balanceSheetsForYear.forEach(item => {
            const {year, year_ref, date} = item;
            const rowOfSameId = item.rows.find(it => it.label === label && row.group === it.group);
            if (rowOfSameId) {
                const energy_cost = rowOfSameId.energy_cost;
                const {economy_evol_value, energy_usage, tariff, cost_corr_evol} = rowOfSameId;
                const month = moment(date).month();
                const plotData = {economy_evol_value, energy_usage, energy_cost, tariff, cost_corr_evol, month, year, year_ref, ts_date: moment(date).unix()};
                points.push(plotData);
                //console.log('rowOfSameId: ' + month, row, rowOfSameId, plotData);
            } else console.error("ROW INTROUVABLE", label);
        });
        console.log('points: ' + this.selectedYear, points);
        this.plotUsageData[0].x = [];
        this.plotUsageData[0].marker.color = [];//"#2196F3"
        this.plotCostData[0].x = [];
        this.plotCostData[0].marker.color = [];//""#F44336""
        this.plotUsageData[0].y = [];
        this.plotCostData[0].y = [];

        this.plotUsageLayout = plotUsageLayout as Plotly.Layout;
        this.plotCostLayout = plotCostLayout as Plotly.Layout;

        points.forEach((v, i) => {
            this.plotUsageLayout.xaxis.tickvals[i] = v.month;
            this.plotUsageLayout.xaxis.ticktext[i] = this.myapp.k.months[v.month];
            this.plotCostLayout.xaxis.tickvals[i] = v.month;
            this.plotCostLayout.xaxis.ticktext[i] = this.myapp.k.months[v.month];
            //this.plotCostLayout.xaxis.tickvals.push(Number(v.month));
            //this.plotCostLayout.xaxis.ticktext.push(this.myapp.k.months[i]);
            this.plotUsageData[0].x.push(v.month);
            if (v.month === this.selectedMonth) {
                this.plotUsageData[0].marker.color.push("#ffff66");
                this.plotCostData[0].marker.color.push("#ffff66");
            } else {
                this.plotUsageData[0].marker.color.push("#2196F3");
                this.plotCostData[0].marker.color.push("#F44336");
            }

            this.plotCostData[0].x.push(v.month);
            this.plotUsageData[0].y.push(v.energy_usage);
            this.plotCostData[0].y.push(v.energy_cost);
        });
        this.plotUsageLayout.title = "Consommation cumulés: " + this.selectedRow.group + " | " + this.selectedRow.label;
        this.plotUsageLayout.width = 950;
        this.plotUsageLayout.height = 500;
        this.plotCostLayout.title = "Coûts cumulés: " + this.selectedRow.group + " | " + this.selectedRow.label;
        this.plotCostLayout.width = 950;
        this.plotCostLayout.height = 500;
        /*this.plot.updatePlot().then(resp => {
            console.log("plotUpdate", resp);
        });*/
        console.log("selectRow", row, this.plotCostLayout, this.plotCostData);
        // console.log('selectRow: ', row, row._id["$oid"], this.plotDataGoals[0].x, this.plotDataGoals[0].y);
    }

    addRow(group) {
        this.rowsGroupedMap.get(group.id).push(new BmensItemRow({group: group.id}));
    }

    editRow(row, group) {
        console.log("editRow", typeof this.form, this.form, row);
        this.selectedGroup = group;
        this.selectedRow = row;
    }

    delRow(row, group) {
        console.log("delRow", typeof this.form, this.form, row);
        this.selectedGroup = group;
        this.selectedRow = row;
        const storedGroupItems = this.rowsGroupedMap.get(group.id).filter(it => it.uid !== row.uid);
        this.rowsGroupedMap.set(group.id, storedGroupItems);
    }

    saveRow() {
        this.entryForYearAndDate.rows = [];
        this.rowsGroupedMap.forEach((rowsOfGroup, k) => {
            rowsOfGroup.forEach(row => this.entryForYearAndDate.rows.push(row));
        });
        this.myapp.loader.open("Sauvegarde en cours");
        this.api.saveBalanceSheet(this.entryForYearAndDate).subscribe(resp => {
            console.log("resp", resp);
            if (resp.status === 1) {
                this.myapp.showMessage("Sauvegardé avec succès");
                this.selectedGroup = null;
                this.selectedRow = null;
                this.loadData();
            } else {
                this.myapp.showError("Erreur de sauvegarde");
                console.error(resp);
            }
            this.myapp.loader.close();
        })
    }

    movegroup(group: BmensItemGroup, dir: string) {
        const currgroup = this.entryForYearAndDate.group_config.find(it => it.id === group.id);
        if (dir === 'up')
            currgroup.dispOrder -= 1.1;
        if (dir === 'down')
            currgroup.dispOrder += 1.1;

        this.entryForYearAndDate.group_config.sort((a, b) => a.dispOrder - b.dispOrder);
        let iter = 0;
        this.entryForYearAndDate.group_config.forEach(it => {
            it.dispOrder = iter++;
        });
        //new Map([...map].sort((a,b)=>a-b))
    }

    openAttachment(row: BmensItemRow) {
        const fileName = row['file'];
        console.log(row);
        this.myapp.storage.ref("attachments/" + fileName).getDownloadURL().subscribe(u => {
            console.log(u);

            this.pdfSource = "https://api.optimigration.ch/endpoint/cors?f=" + H.utf8_to_b64(u);
            this.meta = this.myapp.storage.ref("attachments/" + fileName).getMetadata().subscribe(meta => {
                this.myapp.globalDocumentViewer.next({pdfSource: this.pdfSource, metas: this.meta, title: row.label})
            });
        });
    }

    isGroup(index, item): boolean {
        return item.level;
    }

    dateStr(ts) {
        const tsDueMoment = moment(ts * 1000);
        return tsDueMoment.format('MMM Y');
    }

    sideNavListener(e) {
        //  this.detectClientDimensions();
        // this.resetFullScreen();
    }

    /*

    Overrided
     */
    ngAfterViewInit() {
        this.layout.publishLayoutChange({sidebarStyle: 'closed'});
        //  this.detectClientDimensions();
    }

    ngOnDestroy() {
    }
}

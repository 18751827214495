export const plotGoalsLayout = {
    "title": "Synthèse des objectifs d'économies",
    font: {
        family: 'Roboto, sans-serif',
        size: 12,
    },
    "yaxis": {
        "title": "Economies d'énergies [MWh/an]",
        "automargin": true,
        "hoverformat": ".1f",
        "showspikes": true,
        "tickprefix": "      ",
        "showtickprefix": "all",
        "rangemode": "tozero",
        "separatethousands": true,
        "exponentformat": "none",
        "type": "linear",
        "range": [
            0,
            1
        ],
        "autorange": true
    },
    "yaxis2": {
        "title": "Réduction des émissions de GES [to CO2/an]",
        "automargin": true,
        "overlaying": "y",
        "side": "right",
        "hoverformat": ".1f",
        "showgrid": false,
        "showspikes": true,
        "ticksuffix": "      ",
        "showticksuffix": "all",
        "rangemode": "tozero",
        "tickfont": {
            "color": "#607D8B"
        },
        "separatethousands": true,
        "exponentformat": "none",
        "type": "linear",
        "range": [
            0,
            1
        ],
        "autorange": true
    },
    xaxis: {
        "title": "Années",
        "ticklen": 5,
        "showspikes": true,
        "tickvals": [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10
        ],
        ticktext: [
            2017,
            2018,
            2019,
            2020,
            2021,
            2022,
            2023,
            2024,
            2025,
            2026,
            2027
        ]
    },
    "hovermode": "closest",
    "separators": ".'",
    "legend": {
        "xanchor": "center",
        "yanchor": "bottom",
        "orientation": "h",
        "x": 0.5,
        "y": 1
    }
};
export const plotGoalsData = [
    {
        name: "Énergie électrique",
        marker: {
            "color": "#2196F3"
        },
        type: "bar",
        x: [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10
        ],
        y: [
            0,
            96.245,
            98.745,
            99.745,
            129.745,
            129.745,
            129.745,
            129.745,
            129.745,
            129.745,
            129.745
        ],
        legendgroup: "group1"
    },
    {
        name: "Énergie thermique",
        marker: {
            color: "#F44336",
            /*
            pattern: {
                fillmMode: 'replace',
                shape: '.',
                bgcolor: '#ffff00',
                fgcolor: "#000000",
                size: 5,
                solidity: 0.5,
            }*/
        },
        type: "bar",
        x: [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10
        ],
        y: [
            0,
            20,
            70.5,
            86.5,
            86.5,
            86.5,
            86.5,
            86.5,
            86.5,
            86.5,
            86.5
        ],
        legendgroup: "group2"
    },
    {
        "y": [
            0
        ],
        "type": "bar",

        "hoverinfo": "none",
        "showlegend": false,
        "x": [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10
        ],
        "legendgroup": "group3"
    },
    {
        "y": [
            0
        ],
        "type": "bar",
        "hoverinfo": "none",
        "showlegend": false,
        "x": [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10
        ],
        "legendgroup": "group1",
        "yaxis": "y2"
    },
    {
        "y": [
            0
        ],
        "type": "bar",
        "hoverinfo": "none",
        "showlegend": false,
        "x": [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10
        ],
        "legendgroup": "group2",
        "yaxis": "y2"
    },
    {
        "name": "* Émissions GES",
        "marker": {
            "color": "#607D8B"
        },
        "type": "bar",
        "x": [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10
        ],
        "y": [
            0,
            2.4996750000000003,
            7.076635000000001,
            7.955635000000001,
            8.405635,
            8.405635,
            8.405635,
            8.405635,
            8.405635,
            8.405635,
            8.405635
        ],
        "legendgroup": "group3",
        "yaxis": "y2"
    }
];


export const plotFinancialLayout = {
    "title": "Synthèse des flux financiers",
    font: {
        family: 'Roboto, sans-serif',
        size: 12,
    },
    "yaxis": {
        "title": "Flux financiers annuels [CHF]",
        "automargin": true,
        "hoverformat": ".0f",
        "showspikes": true,
        "tickprefix": "      ",
        "showtickprefix": "all",
        "separatethousands": true,
        "exponentformat": "none",
        "separators": "'",
        "type": "linear",
        "range": [
            -1008729.1702142874,
            225854.23407146055
        ],
        "autorange": true
    },
    "yaxis2": {
        "title": "Flux financiers cummulés [CHF]",
        "automargin": true,
        "overlaying": "y",
        "side": "right",
        "hoverformat": ".0f",
        "showgrid": false,
        "showspikes": true,
        "ticksuffix": "      ",
        "showticksuffix": "all",
        "tickfont": {
            "color": "#607D8B"
        },
        "separatethousands": true,
        "exponentformat": "none",
        "type": "linear",
        "range": [
            -1456103.7931742396,
            95780.34942611567
        ],
        "autorange": true
    },
    "xaxis": {
        "title": "Années",
        "ticklen": 5,
        "showspikes": true,
        "tickvals": [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10
        ],
        "ticktext": [
            2017,
            2018,
            2019,
            2020,
            2021,
            2022,
            2023,
            2024,
            2025,
            2026,
            2027
        ]
    },
    "hovermode": "closest",
    "separators": ".'",
    "legend": {
        "xanchor": "center",
        "yanchor": "bottom",
        "orientation": "h",
        "x": 0.5,
        "y": 1
    },
    "margin": {}
};
export const plotFinancialData = [
    {
        "name": "Invest. net CHF",
        "marker": {
            "color": "#EF9A9A"
        },
        "type": "bar",
        "x": [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10
        ],
        "y": [
            0,
            -34916,
            -47550,
            -522500,
            -148510,
            0,
            0,
            0,
            0,
            -40000,
            0
        ]
    },
    {
        "name": "Invest. total CHF",
        "marker": {
            "color": "#F44336"
        },
        "type": "bar",
        "x": [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10
        ],
        "y": [
            0,
            -50485,
            -98550,
            -947000,
            -603910,
            0,
            0,
            0,
            0,
            -40000,
            0
        ]
    },
    {
        "name": "Réduct. coûts CHF/an",
        "marker": {
            "color": "#4CAF50"
        },
        "type": "bar",
        "x": [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10
        ],
        "y": [
            0,
            21290.19887287042,
            41391.53469094061,
            124814.75883089197,
            152125.06385717317,
            152125.06385717317,
            152125.06385717317,
            152125.06385717317,
            152125.06385717317,
            164125.06385717317,
            164125.06385717317
        ]
    },
    {
        "name": "* Flux nets cumulés (non actual.) négatifs CHF",
        "marker": {
            "color": "#607D8B"
        },
        "type": "scatter",
        "yaxis": "y2",
        "x": [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10
        ],
        "y": [
            0,
            -29194.80112712958,
            -86353.26643618898,
            -908538.507605297,
            -1360323.443748124,
            -1208198.3798909509,
            -1056073.3160337778,
            -903948.2521766046,
            -751823.1883194314,
            -627698.1244622582,
            -463573.060605085
        ]
    }
]

export const plotEfficiencyLayout = {
    "title": "Evolution de l’efficacité prévisionnelle et effective",
    font: {
        family: 'Roboto, sans-serif',
        size: 12,
    },
    "xaxis": {
        "title": "Années",
        "tickvals": [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10
        ],
        "ticktext": [
            2017,
            2018,
            2019,
            2020,
            2021,
            2022,
            2023,
            2024,
            2025,
            2026,
            2027
        ],
        "range": [
            -0.3,
            10.3
        ]
    },
    "yaxis": {
        "title": "Efficacité [%]",
        "hoverformat": ".1f"
    },
    "legend": {
        "xanchor": "center",
        "yanchor": "bottom",
        "orientation": "h",
        "x": 0.5,
        "y": 1
    },
    "separators": ".",
    annotations: [
        {
            x: 0,
            y: 132.6,
            xref: "paper",
            yref: "y",
            text: "Cible 132.6%",
            "showarrow": false,
            "xanchor": "left",
            "yshift": 12,
            "xshift": 40
        }
    ]
};
export const plotEfficiencyData = [
    {
        "name": "Efficacité minimale",
        "x": [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10
        ],
        "y": [
            100,
            100.326,
            101.304,
            102.934,
            105.216,
            108.14999999999999,
            111.736,
            115.974,
            120.86399999999999,
            126.40599999999999,
            132.6
        ],
        "type": "scatter",
        "mode": "lines+markers",
        "marker": {
            "color": "#D62728"
        }
    },
    {
        "name": "Efficacité prévisionnelle évolutive*",
        "x": [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10
        ],
        "y": [
            100,
            109.3420991926182,
            110.1715863240603,
            132.84321120689654,
            140.5178859912301,
            140.5178859912301,
            140.5178859912301,
            140.5178859912301,
            140.5178859912301,
            140.5178859912301,
            140.5178859912301
        ],
        "type": "scatter",
        "mode": "lines+markers",
        "marker": {
            "color": "#000"
        }
    },
    {
        "name": "Efficacité réelle",
        "x": [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10
        ],
        "y": [
            100,
            100,
            100,
            100,
            100,
            100,
            100,
            100,
            100,
            100,
            100
        ],
        "type": "scatter",
        "mode": "lines+markers",
        "marker": {
            "color": "#0172FF"
        }
    },
    {
        "x": [
            0,
            10
        ],
        "y": [
            132.6,
            132.6
        ],
        "type": "scatter",
        "mode": "lines",
        "line": {
            "dash": "dash",
            "width": 1.5
        },
        "marker": {
            "color": "#000"
        },
        "showlegend": false
    }
]

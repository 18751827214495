import {Optimise} from "../helpers/Optimise";

export class EnergyAgent {
    agent: string;
    agent_metric: string;
    clear_agent_metric: string;
    fluid: string;
    uid: string;
    unit: string;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
        this.fluid = this.fluid.toUpperCase();
    }

    calcAmountToStore(amount: number) {
        if (this.unit === 'energy') return amount * 1e6;
        if (this.unit === 'power') return amount * 1e6;
        if (this.unit === 'petrol') return amount;
        if (this.unit === 'volume') return amount;
        if (this.unit === 'weight') return amount * 1e6;
        if (this.unit === 'no_unit') return amount * 1e6;
    }

    renderAmount(amount: number) {
        if (this.unit === 'energy') return {value: amount / 1e6, unit: Metric.unitShort[this.unit]};
        if (this.unit === 'power') return {value: amount / 1e6, unit: Metric.unitShort[this.unit]};
        if (this.unit === 'volume') return {value: amount, unit: Metric.unitShort[this.unit]};
        if (this.unit === 'petrol') return {value: amount, unit: Metric.unitShort[this.unit]};
        if (this.unit === 'weight') return {value: amount / 1e6, unit: Metric.unitShort[this.unit]};
        if (this.unit === 'no_unit') return {value: amount / 1e6, unit: Metric.unitShort[this.unit]};
    }
}

export class Metric {
    public static unitDisplayName = {
        petrol: 'Mazout [ℓ]',
        energy: 'Énergie [mWh]',
        power: 'Puissance [mW]',
        volume: 'Volume [m³]',
        weight: 'Masse [tonnes]',
        no_unit: 'Sans Unité',
    };
    /// TODO: To remove, clean litres as it is NOT a "type of units"
    public static unitShort = {
        litres: 'ℓ',//<---- TO REMOVE - RESYNC
        petrol: 'ℓ',
        energy: 'MWh',
        power: 'Mw',
        volume: 'm³',
        weight: 't',
        no_unit: '',
    };
    uid: string;//still needed in some cases
    mid: string;// human friendly unique identifier
    name: string;
    unit: string;
    fluid: string;
    agent: string;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
    }

    get newAgent() {
        if (this.fluid === 'Électricité') return 'ELEC';
        return this.fluid.toUpperCase();
    }

    renderAmount(amount: number) {
        if (this.unit === 'petrol') return {value: amount, unit: Metric.unitShort[this.unit]};
        if (this.unit === 'energy') return {value: amount / 1e6, unit: Metric.unitShort[this.unit]};
        if (this.unit === 'power') return {value: amount / 1e6, unit: Metric.unitShort[this.unit]};
        if (this.unit === 'volume') return {value: amount, unit: Metric.unitShort[this.unit]};
        if (this.unit === 'weight') return {value: amount / 1e6, unit: Metric.unitShort[this.unit]};
        if (this.unit === 'no_unit') return {value: amount / 1e6, unit: Metric.unitShort[this.unit]};
    }
}

export class EnergyStats {
    uid: string;
    uid_site: string;
    ref: string;
    year: number;
    default: boolean;
    display: boolean;
    usage: EnergyStatsItem[];
    purchase: EnergyStatsItem[];
    production: EnergyStatsProduction[];
    subMeters: EnergyStatsSubMeters[];

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
        if (this.usage && this.usage.length > 0) {
            this.usage = this.usage.map(it => new EnergyStatsItem(it));
            this.usage = Optimise.sortArrayByKeyVal(this.usage, 'price').reverse();
        }
        if (this.purchase && this.purchase.length > 0) {
            this.purchase = this.purchase.map(it => new EnergyStatsItem(it));
            this.purchase = Optimise.sortArrayByKeyVal(this.purchase, 'price').reverse();
        }
        if (this.production && this.production.length > 0)
            this.production = this.production.map(it => new EnergyStatsProduction(it));
        if (this.subMeters && this.subMeters.length > 0)
            this.subMeters = this.subMeters.map(it => new EnergyStatsSubMeters(it));

    }
}

export class EnergyStatsItem {
    // usage purchase NEW fields after refactoring 01/07/2023
    agent_metric: string;
    amount: number;
    ceges: number;
    clear_agent_metric: string;
    cost: number;
    fp: number;
    label: string;
    price: number;

    //  uid: string;// useful to delete
    //  categ: string;//useful to see if water
    //  fp: number;
    //  mid: string;
    //  cost: number;
    //  ceges: number;
    //  price: number;
    //  amount: number;

    uid: string;
    metric: string;///Legacy TODO: remove
    unit_amount: string;
    unit_price: string;
    adapted_price: number;
    adapted_amount: number;
    _year: number;//for convenience populated afterward

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
        if (this.isWater) {
            this.unit_amount = "m³";
            this.unit_price = "Fr./m³";
            this.adapted_price = Number((this.price).toFixed(2));
            this.adapted_amount = this.amount;
        } else {
            this.unit_amount = "MWh";
            this.unit_price = "c./kWh";
            this.adapted_price = Number((this.price * 1e5).toFixed(2));
            this.adapted_amount = this.amount * 1e-6;
        }
    }

    get isWater(): boolean {
        return this.clear_agent_metric.includes('EAU');
    }

    get cegesEmitted(): number {
        return this.ceges * this.amount * 1e-9;
    }
}

export class EnergyStatsProduction {
    uid: string;
    categ: string;
    name: string;
    unit: string;
    amount: number;
    efficiency_percent: number;
    selfConsumption_percent: number;
    efficiency: number;
    selfConsumption: number;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
        this.efficiency_percent = this.efficiency * 100;
        this.selfConsumption_percent = this.selfConsumption * 100;
    }
}

export class EnergyStatsSubMeters {
    uid: string;
    amount: number;
    category: string;
    cost: number;
    name: string;
    notes: string;
    unit: string;
    year: string;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
    }
}
